import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'uuid',
    formLabel: 'Throttle Uuid',
  },
  {
    name: 'name',
    formLabel: 'New Name',
  },
  {
    name: 'description',
    formLabel: 'New Description',
  },
  {
    name: 'ticketUrl',
    formLabel: 'Ticket URL',
  },
  {
    name: 'updatedBy',
    formLabel: 'Updated By',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'offer-throttle-svc',
  functionName: 'updateThrottleProperties',
  method: HttpMethod.POST,
});

export const updateThrottleProperties = {
  name: 'Update Throttle Properties',
  description: 'Update properties on an existing throttle',
  inputValues,
  outputValueName: 'Result',
  tags: [Tags.offers, Tags.throttle],
  submit,
};
