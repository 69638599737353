import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'segmentUuid',
    formLabel: 'Segment Uuid',
  },
  {
    name: 'segmentStartDate',
    formLabel: 'Segment Start Date in UTC',
    formSubLabel: 'format: YYYY-MM-DDTHH:MM:SS.000Z',
  },
  {
    name: 'segmentEndDate',
    formLabel: 'Segment End Date in UTC',
    formSubLabel: 'format: YYYY-MM-DDTHH:MM:SS.000Z',
  },
  {
    name: 'offerTitleOverride',
    formLabel: 'Offer Title Override',
  },
  {
    name: 'cashbackDailyLimit',
    formLabel: 'Cashback Daily Limit',
  },
  {
    name: 'segmentPriority',
    formLabel: 'Segment Priority',
  },
  {
    name: 'hideOfferForSegment',
    formLabel: 'Hide Offer For Segment',
  },
  {
    name: 'loyaltyNumberOfPurchases',
    formLabel: 'Loyalty Number of Purchases',
  },
  {
    name: 'deleted',
    formLabel: 'Deleted',
  },
  {
    name: 'updatedBy',
    formLabel: 'UpdatedBy By',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'campaign-service',
  functionName: 'setCouponSegmentProperties',
  method: HttpMethod.POST,
});

export const setCouponSegmentProperties = {
  name: 'Set Coupon Segment Properties',
  description: 'Set coupon segment properties',
  inputValues,
  outputValueName: 'Updated Segment',
  tags: [Tags.offers],
  submit,
};
