import moment from 'moment';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { Tags } from '@/helpers/types';

export const getVisaAuthTemplate = ({
  transactionId,
  transactionAmount,
  transactionTimestamp,
  offerId,
  brandName,
  vmid,
  vsid,
}: {
  transactionId: string | number;
  transactionAmount: string | number;
  transactionTimestamp: string;
  offerId?: string;
  brandName?: string;
  vmid?: string;
  vsid?: string;
}): any => {
  return {
    CardId: '66249532-3ce8-e711-80ef-1c98ec1324d5',
    MessageId: 'd838d715-9638-4d57-9ce4-7ed0e828648d',
    MessageName: 'endpoint526069950000004077',
    UserProfileId: '1dacbe69-9daa-425d-a9e2-6b99d24d4399',
    ExternalUserId: '893cdd8c-0fcf-4499-b14d-b8e2d4044e51',
    MessageElementsCollection: [
      {
        Key: 'User.EUID',
        Value: '893cdd8c-0fcf-4499-b14d-b8e2d4044e51',
      },
      {
        Key: 'Transaction.PanLastFour',
        Value: '0511',
      },
      {
        Key: 'Transaction.VisaMerchantName',
        Value: brandName ? brandName.toUpperCase() : "DOUBLEDAVE'SPIZZAWORKS",
      },
      {
        Key: 'Transaction.VisaStoreName',
        Value: brandName ? brandName.toUpperCase() : 'DOUBLEDAVES PIZZAWORKS',
      },
      {
        Key: 'Transaction.MerchantGroup.0.Name',
        Value: 'Dosh Master MG',
      },
      {
        Key: 'Transaction.MerchantGroup.0.ExternalId',
        Value: brandName ? brandName.toUpperCase() : 'DOUBLEDAVES',
      },
      {
        Key: 'Offer.OfferId',
        Value: offerId || '70412',
      },
      {
        Key: 'Transaction.VipTransactionId',
        Value: transactionId || null,
      },
      {
        Key: 'Transaction.TransactionAmount',
        Value: transactionAmount || null,
      },
      {
        Key: 'Transaction.VisaMerchantId',
        Value: vmid || null,
      },
      {
        Key: 'Transaction.VisaStoreId',
        Value: vsid || null,
      },
      {
        Key: 'Transaction.TimeStampYYMMDD',
        Value: transactionTimestamp || moment().utc().toISOString(),
      },
    ],
    UserDefinedFieldsCollection: [
      {
        Key: 'MessageType',
        Value: 'Auth',
      },
    ],
  };
};

const inputValues = [
  {
    name: 'body',
    formLabel: 'Payload body',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'visa-notification-service',
  functionName: 'authNotification',
  method: HttpMethod.POST,
});

export const visaAuthNotification = {
  name: 'Visa Auth Notification',
  description: 'Send a Visa Auth Notification to visa-notification-service',
  inputValues,
  outputValueName: 'Result',
  submit,
  tags: [Tags.transactions],
};
