import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import { SecureRoute, LoginCallback } from '@okta/okta-react';
import Login from '@/scenes/Login';
import { scenesConfig } from '@/scenes';

const Routes: FunctionComponent = () => (
  <Switch>
    {/* Login */}
    <Route path="/login" component={Login} />
    <Route path="/implicit/callback" component={LoginCallback} />
    {/* Secure Scenes */}
    {scenesConfig.secure.map((config) => (
      <SecureRoute
        key={config.name}
        path={config.path}
        exact={config.exact}
        component={config.component}
      />
    ))}
  </Switch>
);

export default Routes;
