import React, { FunctionComponent, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import queryString from 'query-string';

import lambdaFunctions from '@/services/lambdaFunctions';
import DisplayJson from '@/components/DisplayJson';
import WorkflowForm from '@/components/WorkflowForm';

import './LambdaFunctionPage.css';

interface PathParams {
  lambdaName: string;
}

const LambdaFunctionPage: FunctionComponent = () => {
  const [response, setResponse] = useState({});
  const { lambdaName } = useParams() as PathParams;

  const config = lambdaFunctions[lambdaName];

  if (!config) {
    return (
      <>
        <h1>
          No Lambda function with the name
          {lambdaName}
        </h1>
        <Link to="/">Go Home</Link>
      </>
    );
  }

  return (
    <Container>
      <Row className="justify-content-md-center">
        <h1 className="toolHeader">{config.name}</h1>
      </Row>
      <Row>
        <Col className="toolSection">
          <div className="toolForm">
            <WorkflowForm
              defaultValues={queryString.parse(window.location.search)}
              inputFieldConfigs={config.inputValues}
              formateRequestPayload={config.formateRequestPayload}
              onSubmit={config.submit}
              setResponse={setResponse}
              storeInput
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="toolSection">
          <DisplayJson
            name={config.outputValueName || 'response'}
            json={response}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default LambdaFunctionPage;
