import React from 'react';
import { WithdrawalControlStatus } from '@/services/lambdaFunctions/getWithdrawalControls';
import { SummaryStatus } from '@/scenes/MarketplaceControls/components/WithdrawalControlPanel/helpers/summary';

import './StatusIndicator.css';

export const StatusIndicator = ({
  status,
}: {
  status: WithdrawalControlStatus | SummaryStatus;
}) => {
  let color = '';

  if (status === WithdrawalControlStatus.ENABLED) {
    color = 'green';
  } else if (status === WithdrawalControlStatus.DISABLED) {
    color = 'red';
  } else if (status === SummaryStatus.PARTIALLY_DISABLED) {
    color = 'yellow';
  }

  const className = color ? `statusIndicator ${color}` : '';

  return <div className={className} />;
};
