import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { addManualONVenueToCNIdMapping } from '../lambdaFunctions/addManualONVenueToCNIdMapping';
import { findOrCreateBrandVenueForMerchant } from '../lambdaFunctions/findOrCreateBrandVenueForMerchant';
import { onboardVenueToCardNetworks } from '../lambdaFunctions/onboardVenueToCardNetworks';
import {
  CardNetwork,
  CardNetworkIdType,
  MatchBasis,
  Tags,
} from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Merchant Uuid and Brand Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT m.name, getuuid(m.merchant_id) as merchantuuid, mb.brand as brandid
          FROM merchantdb.merchant_brand mb
          JOIN merchantdb.merchants m on mb.merchant_id = m.merchant_id
          WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
        ;`,
      ),
    },
  },
  {
    name: 'Find/Create Default Nearby ONVenue',
    inputData: {
      merchantId: new InputInjection([1], ({ records }: { records: any[] }) => {
        if (!records || !records.length) {
          return;
        }
        return records[0].merchantuuid;
      }),
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: findOrCreateBrandVenueForMerchant.submit,
    },
  },
  {
    name: 'Register ONVenue with Card Networks',
    inputData: {
      locationId: new InputInjection([2], (results: any) => {
        if (!results || !results.defaultBrandVenue) {
          return;
        }
        return results.defaultBrandVenue.id;
      }),
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: onboardVenueToCardNetworks.submit,
    },
  },
  {
    name: 'Create and Map Mastercard CNId',
    inputData: {
      onvenueUuid: new InputInjection([3], (results: any) => {
        if (!results || !results.venue) {
          return;
        }
        return results.venue.venueUuid;
      }),
      cardNetwork: CardNetwork.MASTERCARD,
      cardNetworkExternalIdType: CardNetworkIdType.BRAND,
      cardNetworkExternalId: new InputInjection(
        [1],
        ({ records }: { records: any[] }) => {
          if (!records || !records.length) {
            return;
          }
          return records[0].brandid;
        },
      ),
      basis: MatchBasis.AGGREGATE_MATCH,
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addManualONVenueToCNIdMapping.submit,
    },
  },
  {
    name: 'Create and Map Visa CNId',
    inputData: {
      onvenueUuid: new InputInjection([3], (results: any) => {
        if (!results || !results.venue) {
          return;
        }
        return results.venue.venueUuid;
      }),
      cardNetwork: CardNetwork.VISA,
      cardNetworkExternalIdType: CardNetworkIdType.BRAND,
      cardNetworkExternalId: new InputInjection(
        [1],
        ({ records }: { records: any[] }) => {
          if (!records || !records.length) {
            return;
          }
          return records[0].brandid;
        },
      ),
      basis: MatchBasis.AGGREGATE_MATCH,
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addManualONVenueToCNIdMapping.submit,
    },
  },
];

export const setupNearbyBrandOfferNetworkVenue = {
  steps,
  inputValues,
  name: 'Setup Nearby Brand Offer Network Venue',
  description: 'Should only be done if all locations are included on offer',
  tags: [Tags.newMerchantOnboarding, Tags.venues],
};
