import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import {
  CardNetwork,
  CardNetworkIdType,
  LocationType,
  MatchBasis,
  Tags,
  Venue,
} from '@/helpers/types';
import { upsertCNVenue } from '../lambdaFunctions/upsertCNVenue';
import { mapONVenueToCNVenue } from '../lambdaFunctions/mapONVenueFromCNVenue';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'providedMid',
    formLabel: 'Provided MID from Paypal/Venmo',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Merchant Uuid and Brand Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT sl.source_location_id as onvenue_uuid
          FROM merchantdb.merchants m
          JOIN merchantdb.locations l on l.merchant_id = m.merchant_id
          JOIN merchantdb.source_locations sl on sl.location_id = l.location_id
          WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
          AND l.street_address = '__UNKNOWN__'
          AND l.type = 'nearby';
          ;`,
      ),
    },
  },
  {
    name: 'Upsert CNVenue',
    inputData: {
      venue: new InputInjection(
        [0],
        ({ providedMid }: { providedMid: string }) => {
          if (!providedMid) {
            return;
          }
          const newVenue: Venue = {
            enabled: true,
            name: providedMid,
            venueSource: CardNetwork.PAYPAL,
            externalId: providedMid,
            locationType: LocationType.PHYSICAL,
            externalGroupId: 'NONE',
            address: {
              address1: '__UNKNOWN__',
              address2: '__UNKNOWN__',
              city: '__UNKNOWN__',
              state: '__',
              postal: '__UNKNOWN__',
              country: '__',
            },
            cardNetworkIds: [
              {
                cardNetwork: CardNetwork.PAYPAL,
                idType: CardNetworkIdType.MID,
                externalId: providedMid,
              },
            ],
          };
          return newVenue;
        },
      ),
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: upsertCNVenue.submit,
    },
  },
  {
    name: 'Map CNVenue To ONVenue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: mapONVenueToCNVenue.submit,
    },
    inputData: {
      offerNetworkVenueUuid: new InputInjection(
        [1],
        ({ records }: { records: { onvenue_uuid: string }[] }) => {
          if (!records) {
            return;
          }
          return records[0].onvenue_uuid;
        },
      ),
      cardNetworkVenueUuid: new InputInjection(
        [2],
        ({ result }: { result: { venue: Venue } }) => {
          if (!result) {
            return;
          }
          return result.venue.uuid;
        },
      ),
      reason: MatchBasis.MANUAL_MATCH,
    },
  },
];

export const createAndMapProvidedPaypalMids = {
  steps,
  inputValues,
  name: 'Create And Map Provided Paypal Mids',
  description:
    'Create cnvenue with Paypal MIDs and map to nearby unknown location for QR code offers',
  tags: [Tags.newMerchantOnboarding, Tags.venues, Tags.specialMerchants],
};
