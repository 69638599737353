import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'cognitoId',
    formLabel: 'Cognito Id',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'user-service-2',
  functionName: 'lockAccount',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});

export const lockAccount = {
  name: 'Lock Account',
  description:
    'Lock account for users suspected of fraud. WARNING: Cannot be undone',
  inputValues,
  outputValueName: 'Results',
  tags: [Tags.csResources],
  submit,
};
