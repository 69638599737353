import { v4 as uuid } from 'uuid';
import post from '@/utils/lambdaApi/post';

const REDSHIFT_USER = 'MERCHANT_OPS';
const REDSHIFT_QUEUE_JOB_ROUTE =
  'https://devtools.dosh.cash/api/us-east-1/803350353089/redshift-access-svc/prod/prod/scheduleQueryToExecuteWithoutUnload';

enum Status {
  ATTEMPTED = 'ATTEMPTED',
  SCHEDULED = 'SCHEDULED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  FULFILLED = 'FULFILLED',
}

interface JobExecuteQueryInterface {
  externalId: string;
  queryId: string;
  query: string;
  requestedBy: string;
  createdAt: string;
  status: Status;
}
interface JobExecuteQuerySuccessInterface {
  success: boolean;
  scheduledJob: JobExecuteQueryInterface;
}

interface JobExecuteQueryErrorInterface {
  error: {
    id: string;
    category: string;
    message: string;
  };
}

const successStatus = (status: Status): boolean => {
  return (
    status === Status.SCHEDULED ||
    status === Status.FULFILLED ||
    status === Status.ATTEMPTED
  );
};

const queueJob = async (query: string): Promise<JobExecuteQueryInterface> => {
  return post({
    route: REDSHIFT_QUEUE_JOB_ROUTE,
    payload: {
      query,
      externalId: uuid(),
      requestedBy: REDSHIFT_USER,
    },
  }).then(
    (res: JobExecuteQuerySuccessInterface | JobExecuteQueryErrorInterface) => {
      // only return when successful
      if (
        'success' in res &&
        res.success &&
        successStatus(res.scheduledJob.status)
      ) {
        return res.scheduledJob;
      }
      throw res;
    },
  );
};

export default queueJob;
