import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { updateVenueProperties } from '../lambdaFunctions/updateVenueProperties';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'website',
    formLabel: 'Website',
    formSubLabel: 'Must start with https',
  },
  {
    name: 'updatedBy',
    formLabel: 'Name of person making the update',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Merchant Uuid and Online Venue Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT getuuid(l.merchant_id) as merchant_uuid, sl.source_location_id as venue_uuid, website AS current_website
        FROM merchantdb.locations l
        JOIN merchantdb.source_locations sl on l.location_id = sl.location_id
        WHERE name ilike '%${redshiftEscape(merchantName)}%'
        AND l.type = 'online';`,
      ),
    },
  },
  {
    name: 'Update Website',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: updateVenueProperties.submit,
    },
    inputData: {
      updatedBy: new InputInjection([0, 'updatedBy']),
      forMerchant: {
        uuid: new InputInjection([1], ({ records }: any) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          if (records.length > 1) {
            return;
          }
          return records[0].merchant_uuid;
        }),
      },
      forVenues: [
        {
          venueUuid: new InputInjection([1], ({ records }: any) => {
            if (!records || !Array.isArray(records)) {
              return;
            }
            if (records.length > 1) {
              return;
            }
            return records[0].venue_uuid;
          }),
        },
      ],
      set: {
        website: new InputInjection([0, 'website']),
      },
    },
  },
];

export const updateURL = {
  steps,
  inputValues,
  name: 'Update URL for Online Venue',
  description: 'Update website',
  tags: [Tags.venues],
};
