import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { offerToggleDynamicVenueAdditions } from '../lambdaFunctions/offerToggleDynamicVenueAdditions';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'targetState',
    formLabel: 'Turn on Venue Discovery',
    formSubLabel: 'true or false',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Nearby Offer',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT
          getuuid(o.offer_id) as offer_uuid,
          l.type as location_type
    FROM merchantdb.offers o
    JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
    JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
    JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
    JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
    JOIN merchantdb.locations l on cl.location_id = l.location_id
    WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
      AND c.end_date > current_date
      AND l.type = 'nearby'
    ;`,
      ),
    },
  },
  {
    name: 'Select Offer -- IMPORTANT: Contact IE if >1 result',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: { offer_uuid: string; location_type: string }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.offer_uuid}`,
              name: `OFFER UUID: ${record.offer_uuid}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Enroll in Venue Discovery',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: offerToggleDynamicVenueAdditions.submit,
    },
    inputData: {
      offerUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
      targetState: new InputInjection(
        [0],
        ({ targetState }: { targetState: string }) => {
          if (!targetState) {
            return false;
          }
          if (targetState.toLowerCase() === 'true') {
            return true;
          }
          return false;
        },
      ),
    },
  },
];

export const dynamicallyAddVenuesToOffer = {
  steps,
  inputValues,
  name: 'Dynamically Add Venues to Offer',
  description:
    'Automatically adds new venues to merchantdb as they are discovered. Should not be used for: 1. Online Offers, 2. Offers with Select Locations Only, 3. Merchants with Outlet Stores, 4. Merchants with Stores within Stores (ex. Sephora inside JCPenney)',
  tags: [Tags.venues, Tags.offers],
};
