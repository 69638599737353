import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { Place, PlaceDescriptionDataSource, Tags } from '@/helpers/types';
import { getPlaceByPlaceDescription } from '../lambdaFunctions/getPlaceByPlaceDescription';
import { csvToJson } from '@/utils/csvToJson';
import { redshiftEscape } from '@/utils/strings';
import { offerAddVenues } from '../lambdaFunctions/offerAddVenues';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'storeList',
    formLabel: 'Store List',
    type: InputFieldTypes.FILE,
  },
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'updatedBy',
    formLabel: 'Updated By',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Preprocess File',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      metaData: {
        autoSkip: false,
      },
      stores: new InputInjection([0], ({ storeList }: { storeList: any }) => {
        if (!storeList) {
          return;
        }
        return csvToJson(storeList);
      }),
    },
  },
  {
    name: 'Build Invocation Array',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      metaData: {
        autoSkip: false,
      },
      invocationArray: new InputInjection(
        [1],
        ({ stores }: { stores: { store_id: string; name: string }[] }) => {
          if (!stores) {
            return;
          }
          return stores.map((store) => {
            return {
              placeDescription: {
                dataSource: PlaceDescriptionDataSource.DOSH_OFFER_NETWORK,
                externalId: `${store.name}:::${store.store_id}`,
              },
            };
          });
        },
      ),
    },
  },
  {
    name: 'Get Place Descriptions',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getPlaceByPlaceDescription.submit,
    },
    inputData: {
      multipleInvocations: true,
      invocationArray: new InputInjection(
        [2],
        ({
          invocationArray,
        }: {
          invocationArray: {
            placeDescription: {
              dataSource: PlaceDescriptionDataSource;
              externalId: string;
            };
          }[];
        }) => {
          if (!invocationArray || !Array.isArray(invocationArray)) {
            return;
          }
          return invocationArray;
        },
      ),
    },
  },
  {
    name: 'Identify Location Uuids',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      metaData: {
        autoSkip: false,
      },
      locationUuids: new InputInjection(
        [3],
        ({ results }: { results: { place: Place }[] }) => {
          if (!results) {
            return;
          }
          return results
            .map(({ place }) => {
              if (!place || !place.knownDescriptions) {
                return;
              }
              const descriptionForONVenue = place.knownDescriptions.find(
                (desc: any) =>
                  desc.dataSource ===
                  PlaceDescriptionDataSource.DOSH_OFFER_NETWORK,
              );
              return !descriptionForONVenue ? null : descriptionForONVenue.uuid;
            })
            .filter((locationUuid) => !!locationUuid);
        },
      ),
    },
  },
  {
    name: 'Create Venue Uuid Array',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      metaData: {
        autoSkip: false,
      },
      venues: new InputInjection(
        [4],
        ({ locationUuids }: { locationUuids: string[] }) => {
          if (!locationUuids || !Array.isArray(locationUuids)) {
            return;
          }
          return locationUuids.map((uuid) => {
            return {
              locationUuid: uuid,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Get Merchant and Offer Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
        }: {
          merchantName: string;
        }) => `SELECT DISTINCT getuuid(o.offer_id) as offer_uuid, getuuid(m.merchant_id) as merchant_uuid, m.name, c.start_date, c.end_date
        FROM merchantdb.offers o
        JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
        JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
        JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
        WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
        AND c.end_date > current_date
        ;`,
      ),
    },
  },
  {
    name: 'Select Merchant',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [6],
        ({
          records,
        }: {
          records: { merchant_uuid: string; name: string }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.merchant_uuid}`,
              name: `Merchant Name: ${record.name}; Merchant Uuid: ${record.merchant_uuid}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Select Offer Uuid',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [6],
        ({
          records,
        }: {
          records: {
            offer_uuid: string;
            start_date: string;
            end_date: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.offer_uuid}`,
              name: `Offer Uuid: ${record.offer_uuid}; Start Date: ${record.start_date}; End Date: ${record.end_date}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Add Locations to Offer',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: offerAddVenues.submit,
    },
    inputData: {
      merchantUuid: new InputInjection([7], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
      offerUuid: new InputInjection([8], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result);
      }),
      venues: new InputInjection(
        [5],
        ({ venues }: { venues: { locationUuid: string }[] }) => {
          if (!venues || !Array.isArray(venues)) {
            return;
          }
          return venues;
        },
      ),
      updatedBy: new InputInjection([0, 'updatedBy']),
    },
  },
];

export const addSelectLocationsToOffer = {
  steps,
  inputValues,
  name: 'Add Select Locations To Offer',
  description: 'Add locations from a store list to an existing offer',
  tags: [Tags.venues],
};
