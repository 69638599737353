import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'merchantId',
    formLabel: 'Merchant Id',
  },
  {
    name: 'website',
    formLabel: 'Website',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'dosh-on-venue-svc',
  functionName: 'findOrCreateOnlineVenueForMerchant',
  method: HttpMethod.POST,
});

export const findOrCreateOnlineVenueForMerchant = {
  name: 'Find or Create Online Venue for Merchant',
  description: 'Create an online venue for merchant or return existing venue',
  inputValues,
  outputValueName: 'venue', // optional
  tags: [Tags.venues],
  submit,
};
