import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'cognitoId',
    formLabel: 'Cognito Id',
  },
  {
    name: 'last4',
    formLabel: 'Last 4 of Card Used',
  },
  {
    name: 'transactionDate',
    formLabel: 'Transaction Date',
    formSubLabel: 'YYYY-MM-DD',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Retrieve Transactions',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          cognitoId,
          last4,
          transactionDate,
        }: {
          cognitoId: string;
          last4: string;
          transactionDate: string;
        }) => `SELECT
        cted.venue_name,
        cted.merchant_name,
        cte.transaction_timestamp,
        cte.type,
        cte.card_network,
        ctecni.card_network_external_id_type,
        ctecni.card_network_external_id,
        cte.transaction_id,
        cte.amount AS ammount_in_pennies
    FROM empyrdb.card_transaction_events cte
    JOIN empyrdb.card_transaction_events_debug cted ON cte.id = cted.card_transaction_event_id
    JOIN empyrdb.card_transaction_events_card_network_ids ctecni ON cte.id = ctecni.card_transaction_event_id
    WHERE 1=1
    AND cte.transaction_timestamp > '${transactionDate}'
    AND cte.card_last_4 = '${last4}'
    AND cte.cognito_id = '${cognitoId}';`,
      ),
      retries: 10,
    },
  },
];

export const getTransactionsByCognito = {
  steps,
  inputValues,
  name: 'Get Transactions By Cognito',
  description:
    'Look up transactions for user by cognito and last 4 of card used',
  tags: [Tags.transactions],
};
