import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';
import { upsertBrandToMerchantMapping } from '../lambdaFunctions/upsertBrandToMerchantMapping';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Name of Merchant',
  },
] as InputFieldConfigs[];
const steps = [
  {
    name: 'Query for Merchant and Brand',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT m.name, getuuid(mb.merchant_id) as merchant_uuid, mb.brand
          FROM merchantdb.merchant_brand mb
          JOIN merchantdb.merchants m on mb.merchant_id = m.merchant_id
          WHERE m.name ILIKE '%${redshiftEscape(merchantName)}%';`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Select Merchant',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            name: string;
            merchant_uuid: string;
            brand: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.name}#${record.merchant_uuid}#${record.brand}`,
              name: `${record.merchant_uuid}: ${record.name}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Subscribe to ONVenue Discovery',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: upsertBrandToMerchantMapping.submit,
    },
    inputData: {
      brand: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return result[0].split('#')[2];
      }),
      merchant: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        const split = result[0].split('#');
        return {
          id: split[1],
          name: split[0],
        };
      }),
      active: true,
    },
  },
];
export const subscribeMerchantToONVenueDiscovery = {
  steps,
  inputValues,
  name: 'Subscribe Merchant to ON Venue',
  description: 'Subscribe Merchant to ON Venue discovery',
  tags: [Tags.merchant, Tags.venues],
};
