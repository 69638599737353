import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { unmapONVenueFromCNVenue } from '../lambdaFunctions/unmapONVenueFromCNVenue';

const inputValues = [
  {
    name: 'offerNetworkVenueUuid',
    formLabel: 'ONVenue Uuid',
  },
  {
    name: 'cardNetworkVenueUuid',
    formLabel: 'CNVenue Uuid',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Unmapping',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: unmapONVenueFromCNVenue.submit,
    },
    inputData: {
      offerNetworkVenueUuid: new InputInjection([0, 'offerNetworkVenueUuid']),
      cardNetworkVenueUuid: new InputInjection([0, 'cardNetworkVenueUuid']),
      reason: 'MANUAL_MATCH',
    },
  },
];

export const unmapCNVenueFromONVenueWithUuids = {
  steps,
  inputValues,
  name: 'Unmap CNVenue From ONVenue with Uuids',
  description: 'Unmap CNVenue from ONVenue when venue uuids are known',
  tags: [Tags.venues],
};
