import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { toggleVenueTransactionFlowByOfferUuid } from '../lambdaFunctions/toggleVenueTransactionFlowByOfferUuid';
import { markCNTransactionsForCNId } from '../lambdaFunctions/markCNTransactionsForCNId';
import {
  CardNetwork,
  CardNetworkIdType,
  CardNetworkTransactionMode,
  LocationStatusToggleMode,
  Tags,
  VisaCommunityCode,
  VisaMerchantGroup,
  YesOrNo,
} from '@/helpers/types';
import {
  offerLocationTypeOptions,
} from '@/helpers/commonInputSelectOptions';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Name of Merchant',
  },
  {
    name: 'locationType',
    formLabel: 'Location Type',
    type: InputFieldTypes.SELECT,
    options: offerLocationTypeOptions,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Offer Uuid and Brand CNIds',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
          locationType,
        }: {
          merchantName: string;
          locationType: string;
        }) =>
          `SELECT DISTINCT getuuid(o.offer_id) as offer_uuid, cnis.card_network as card_network, cnis.external_id as brand_id
          FROM merchantdb.offers o
          JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
          JOIN merchantdb.campaigns c on c.offer_id = o.offer_id
          JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
          JOIN merchantdb.locations l on cl.location_id = l.location_id
          JOIN merchantdb.source_locations sl on sl.location_id = l.location_id
          JOIN cnvenuedb.venues onv on onv.uuid = sl.source_location_id
          LEFT JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.on_venue_id = onv.id
          LEFT JOIN cnvenuedb.venues cnv on otcvm.cn_venue_id = cnv.id
          LEFT JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.venue_id = cnv.id
          LEFT JOIN cnvenuedb.card_network_ids cnis on vtcni.card_network_id = cnis.id
          WHERE 1=1
          AND m.name ilike '%${redshiftEscape(merchantName)}%'
          AND l.type = '${locationType}'
          AND c.end_date > current_date
          AND c.start_date >= current_date
          AND o.deleted = 0
          AND (cnis.id_type = 'BRAND' OR cnis.id_type is null)
          ;`,
      ),
      retries: 20,
    },
  },
  {
    name: 'Toggle By Offer Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: toggleVenueTransactionFlowByOfferUuid.submit,
    },
    inputData: {
      mode: LocationStatusToggleMode.TURN_ON,
      skipCNIdActivation: new InputInjection(
        [0],
        ({ activateTransactions }: { activateTransactions: string }) => {
          return activateTransactions === YesOrNo.no;
        },
      ),
      offerUuid: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            offer_uuid: string;
            card_network: string;
            brand_id: string;
          }[];
        }) => {
          if (!records || !records.length) {
            return;
          }
          return records[0].offer_uuid;
        },
      ),
    },
  },
  {
    name: 'Mark Visa Brand CNId',
    autorun: true,
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [1],
          ({
            records,
            status,
          }: {
            records: {
              offer_uuid: string;
              card_network: string;
              brand_id: string;
            }[];
            status: string;
          }) => {
            return (
              status === 'success' &&
              (!records ||
                !records.length ||
                !records.some(
                  (record) => record.card_network === CardNetwork.VISA,
                ))
            );
          },
        ),
      },
      mode: CardNetworkTransactionMode.ACTIVATE,
      cardNetwork: CardNetwork.VISA,
      cardNetworkExternalIdType: CardNetworkIdType.BRAND,
      cardNetworkExternalId: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            offer_uuid: string;
            card_network: string;
            brand_id: string;
          }[];
        }) => {
          if (!records || !records.length) {
            return;
          }
          const visaRecord = records.find(
            (record) => record.card_network === CardNetwork.VISA,
          );
          return visaRecord ? visaRecord.brand_id : undefined;
        },
      ),
      merchantGroup: VisaMerchantGroup.DEFAULT,
      communityCode: VisaCommunityCode.DOSHCL,
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: markCNTransactionsForCNId.submit,
    },
  },
  {
    name: 'Mark Mastercard Brand CNId',
    autorun: true,
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [1],
          ({
            records,
            status,
          }: {
            records: {
              offer_uuid: string;
              card_network: string;
              brand_id: string;
            }[];
            status: string;
          }) => {
            return (
              status === 'success' &&
              (!records ||
                !records.length ||
                !records.some(
                  (record) => record.card_network === CardNetwork.MASTERCARD,
                ))
            );
          },
        ),
      },
      mode: CardNetworkTransactionMode.ACTIVATE,
      cardNetwork: CardNetwork.MASTERCARD,
      cardNetworkExternalIdType: CardNetworkIdType.BRAND,
      cardNetworkExternalId: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            offer_uuid: string;
            card_network: string;
            brand_id: string;
          }[];
        }) => {
          if (!records || !records.length) {
            return;
          }
          const mastercardRecord = records.find(
            (record) => record.card_network === CardNetwork.MASTERCARD,
          );
          return mastercardRecord ? mastercardRecord.brand_id : undefined;
        },
      ),
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: markCNTransactionsForCNId.submit,
    },
  },
];

export const activateLocations = {
  steps,
  inputValues,
  name: 'Activate Locations',
  description: 'Activate locations for an offer',
  tags: [Tags.newMerchantOnboarding],
};
