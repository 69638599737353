import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'mode',
    formLabel: 'ACTIVATE or DEACTIVATE',
  },
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
  },
  {
    name: 'cardNetworkExternalIdType',
    formLabel: 'Card Network External Id Type',
  },
  {
    name: 'cardNetworkExternalId',
    formLabel: 'Card Network External Id',
  },
  {
    name: 'merchantGroup',
    formLabel: 'Merchant Group (Visa only)',
  },
  {
    name: 'communityCode',
    formLabel: 'Community Code (Visa only)',
  },
  {
    name: 'visaExternalId',
    formLabel: 'Visa External Id (Optional Visa tag)',
    default: null,
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-mgmt-svc',
  functionName: 'toggleCNTransactionsForCNId',
  method: HttpMethod.POST,
});

export const toggleCNTransactionsForCNId = {
  name: 'Toggle Transactions for CNId',
  description: 'Activate or Deactivate a CNId',
  inputValues,
  outputValueName: 'result',
  tags: [Tags.cnids],
  submit,
};
