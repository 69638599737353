import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { ONVenueMapping, Tags, Venue } from '@/helpers/types';
import {
  cardNetworkIdTypeOptions,
  cardNetworkOptions,
} from '@/helpers/commonInputSelectOptions';
import { getVenueMappingsForGenericVenue } from '../lambdaFunctions/getVenueMappingsForGenericVenue';
import { unmapONVenueFromCNVenue } from '../lambdaFunctions/unmapONVenueFromCNVenue';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';
import { getONVenueMappingsForCNIds } from '../lambdaFunctions/getONVenueMappingsForCNIds';

const inputValues = [
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
    options: cardNetworkOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'idType',
    formLabel: 'Id Type',
    options: cardNetworkIdTypeOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'externalId',
    formLabel: 'External Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Mapped ONVenues',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getONVenueMappingsForCNIds.submit,
    },
    inputData: {
      cardNetworkIds: [
        {
          cardNetwork: new InputInjection([0, 'cardNetwork']),
          idType: new InputInjection([0, 'idType']),
          externalId: new InputInjection([0, 'externalId']),
        },
      ],
      includeONVenueCAIDMappings: false,
    },
  },
  {
    name: 'Select ONVenue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({ result }: { result: ONVenueMapping[] }) => {
          if (!result || !Array.isArray(result)) {
            return;
          }
          return result.map((onvenue) => {
            const { offerNetworkVenue } = onvenue;
            return {
              id: offerNetworkVenue.uuid,
              name: `${offerNetworkVenue.name}, ${offerNetworkVenue.locationType}, ${offerNetworkVenue.venueSource}, ${offerNetworkVenue.uuid},`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Get Mapped CNVenues',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueMappingsForGenericVenue.submit,
    },
    inputData: {
      venueUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
    },
  },
  {
    name: 'Process variables',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cardNetwork: new InputInjection([0, 'cardNetwork']),
      cnVenues: new InputInjection([3], ({ result }: { result: Venue[] }) => {
        if (!result || !Array.isArray(result)) {
          return;
        }
        return result;
      }),
    },
  },
  {
    name: 'Select CNVenue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [4],
        ({
          cnVenues,
          cardNetwork,
        }: {
          cnVenues: Venue[];
          cardNetwork: string;
        }) => {
          if (!cnVenues || !Array.isArray(cnVenues) || !cardNetwork) {
            return;
          }
          return cnVenues
            .filter((venue) => venue.venueSource === cardNetwork)
            .map((venue) => {
              const cnids = venue.cardNetworkIds
                .map((cn) => cn.externalId)
                .flat();
              return {
                name: `${venue.name}, ${venue.uuid},
                ${venue.address.address1}, ${venue.address.address2},
                ${venue.address.city}, ${venue.address.state}, ${venue.address.postal},
                CNIDS: ${cnids}`,
                id: venue.uuid,
              };
            });
        },
      ),
    },
  },
  {
    name: 'Combine Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      offerNetworkVenueUuid: new InputInjection(
        [2],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          return getArrayFromCheckboxSelections(result)[0];
        },
      ),
      cardNetworkVenueUuid: new InputInjection(
        [5],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          return getArrayFromCheckboxSelections(result)[0];
        },
      ),
    },
  },
  {
    name: 'Unmapping',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: unmapONVenueFromCNVenue.submit,
    },
    inputData: {
      offerNetworkVenueUuid: new InputInjection([6, 'offerNetworkVenueUuid']),
      cardNetworkVenueUuid: new InputInjection([6, 'cardNetworkVenueUuid']),
      reason: 'MANUAL_MATCH',
    },
  },
];

export const unmapCNVenueFromONVenueWithCNId = {
  steps,
  inputValues,
  name: 'Unmap CNVenue From ONVenue With CNId ',
  description: 'Unmap CNVenue from ONVenue with CNId',
  tags: [Tags.venues, Tags.cnids],
};
