import React, { FunctionComponent } from 'react';
import { Spinner } from 'react-bootstrap';
import './Loader.css';

const Loader: FunctionComponent = (): JSX.Element => {
  return (
    <div className="trv-loader">
      <Spinner animation="grow" className="trv-loader-spinner" />
      <p>Loading</p>
    </div>
  );
};

export default Loader;
