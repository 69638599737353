import buildRoute from '@/utils/lambdaApi/buildRoute';
import get from '@/utils/lambdaApi/get';
import post from '@/utils/lambdaApi/post';
import log from '@/utils/logging';

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
}

// todo: make this a class
// export interface LambdaFunctionConfig {
//   type: string;
//   name: string;
//   description: string;
//   homePage: boolean;  // if true, the function will be displayed on the home page
//   inputValues: InputFieldConfigs[];
//   outputValueName?: string;
//   submit: Function;
//   formateRequestPayload?: Function;
// }

const lambdaConstructor = <I extends Record<string, any>, O>({
  serviceName,
  functionName,
  method,
  alias,
  errorPath,
}: {
  serviceName: string;
  functionName: string;
  method: HttpMethod;
  alias?: string;
  errorPath?: string[];
}) => {
  return async (payload: I): Promise<O> => {
    switch (method) {
      case HttpMethod.GET:
        const getRoute = buildRoute({
          serviceName,
          functionName,
          payload,
          alias,
        });
        return get({ route: getRoute });
      case HttpMethod.POST:
        const postRoute = buildRoute({
          serviceName,
          functionName,
          alias,
        });
        return post({ route: postRoute, payload, errorPath });
      default:
        log.error('lambdaConstructor - Unsupported http method');
        throw new Error('unsupported http method');
    }
  };
};

export default lambdaConstructor;
