import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { WorkflowStepTypes } from '@/components/Workflow';
import { createOrUpdateMarketplaceConfig } from '../lambdaFunctions/createOrUpdateMarketplaceConfig';
import { getMarketplaceConfig } from '../lambdaFunctions/getMarketplaceConfig';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'marketplaceName',
    formLabel: 'Marketplace Name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Marketplace Config',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getMarketplaceConfig.submit,
    },
    inputData: {
      name: new InputInjection([0, 'marketplaceName']),
    },
  },
  {
    name: 'Building JSON',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: (data: any) => {
        const { currentConfig } = data;
        const payload = { ...currentConfig };

        delete payload.offerNetworkConfigs.DOSH.userFiltering;

        delete payload.createdAt;
        delete payload.updatedAt;
        delete payload.effectiveAt;
        delete payload.status;
        delete payload.offerNetworks;

        return payload;
      },
    },
    inputData: {
      currentConfig: new InputInjection([1]),
    },
  },
  {
    name: 'Update marketplace config',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: (input: { body: any }) => {
        const { body } = input;
        return createOrUpdateMarketplaceConfig.submit(body);
      },
    },
    inputData: {
      body: new InputInjection([2], (body: any) => {
        const payload = { ...body };
        delete payload.status;
        return payload;
      }),
    },
  },
];

export const whitelistRemoveFilteringMarketplace = {
  steps,
  inputValues,
  name: 'Remove Whitelist MUG Filtering in Marketplace User Group Config',
  description: 'It will remove userFiltering under offerNetworkConfigs.DOSH',
  tags: [Tags.poweredBy, Tags.partnerOnboarding],
};
