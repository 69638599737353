import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import {
  cardNetworkOptions,
  CNIdDenyListOptions,
} from '@/helpers/commonInputSelectOptions';
import { getCNIdStatus } from '../lambdaFunctions/getCNIdStatus';
import { getVenuesByCNId } from '../lambdaFunctions/getVenuesByCNId';

const inputValues = [
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
    options: cardNetworkOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'idType',
    formLabel: 'ID Type',
    options: CNIdDenyListOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'externalId',
    formLabel: 'External ID',
    type: InputFieldTypes.TEXT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Check CNId status',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getCNIdStatus.submit,
    },
    inputData: {
      cardNetworkId: {
        cardNetwork: new InputInjection([0, 'cardNetwork']),
        idType: new InputInjection([0, 'idType']),
        externalId: new InputInjection([0, 'externalId']),
      },
    },
  },
  {
    name: 'Check Number of CNVenues with CNId',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenuesByCNId.submit,
    },
    inputData: {
      venueSource: new InputInjection([0, 'cardNetwork']),
      cardNetworkId: {
        cardNetwork: new InputInjection([0, 'cardNetwork']),
        idType: new InputInjection([0, 'idType']),
        externalId: new InputInjection([0, 'externalId']),
      },
      limit: 50,
      offset: 0,
    },
  },
  {
    name: 'Process Selections',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cardNetwork: new InputInjection([0, 'cardNetwork']),
      idType: new InputInjection([0, 'idType']),
      externalId: new InputInjection([0, 'externalId']),
      baseExternalId: new InputInjection(
        [0],
        ({ externalId }: { externalId: string }) => {
          if (!externalId) {
            return;
          }
          return externalId.replace(/^0+/g, '');
        },
      ),
      stemmedExternalId: new InputInjection(
        [0],
        ({ externalId }: { externalId: string }) => {
          if (!externalId) {
            return;
          }
          return externalId.replace(/^0+/g, '').replace(/0+$/g, '');
        },
      ),
    },
  },
  {
    name: 'Check Number of RN ONVenues with CNId',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [3],
        ({
          cardNetwork,
          idType,
          externalId,
          baseExternalId,
          stemmedExternalId,
        }: {
          cardNetwork: string;
          idType: string;
          externalId: string;
          baseExternalId: string;
          stemmedExternalId: string;
        }) =>
          `SELECT
          onv.id as id,
          onvs.name as venueSource,
          onv.external_id as externalId,
          onv.uuid as uuid,
          onv.enabled as enabled,
          names.name as name,
          onv.updated_at as updatedAt
        FROM cnvenuedb.venues onv
        JOIN cnvenuedb.venue_sources onvs ON onvs.id = onv.venue_source_id
        JOIN cnvenuedb.names ON names.id = onv.name_id
        JOIN cnvenuedb.venue_to_card_network_id vtcni ON vtcni.venue_id = onv.id
        JOIN cnvenuedb.card_network_ids cni ON cni.id = vtcni.card_network_id
        WHERE cni.card_network IN ('VISA', '${cardNetwork}')
          AND cni.id_type IN ('CAID', '${idType}')
          AND cni.external_id IN ('${externalId}', '${baseExternalId}', '${stemmedExternalId}')
          AND onvs.name IN ('RN', 'R21')
        ORDER BY onv.enabled DESC
        LIMIT 10000;`,
      ),
      retries: 20,
    },
  },
  {
    name: 'Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.DISPLAY_RESULT,
    },
    inputData: {
      blacklisted: new InputInjection([1, 'blacklisted']),
      multipleCNVenueMappings: new InputInjection([2], (result: any) => {
        if (!result || !result.result) {
          return;
        }
        const data = result.result;
        if (data.results.length >= 5) {
          return `Suspected aggregator -> ${data.results} CNVenues mapped to CNId`;
        }
        return 'Fewer than 5 CNVenues are mapped to the CNId';
      }),
      multipleONVenueMappings: new InputInjection(
        [4],
        ({
          records,
        }: {
          records: { percent_of_mapped_vmids: string; vmid: string }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.length > 5
            ? 'Suspected aggregator RN ONVenues mapped to CNId'
            : 'Fewer than 5 RN ONVenues are mapped to the CNId';
        },
      ),
    },
  },
];

export const checkCNIdDenyListStatus = {
  steps,
  inputValues,
  name: 'Check CNId Deny List Status',
  description: 'Description used on the homepage',
  tags: [Tags.cnids, Tags.venues],
};
