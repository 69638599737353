import { FunctionComponent } from 'react';
import MarketplaceControls from '@/scenes/MarketplaceControls';
import Home from './Home';
import LambdaFunctionPage from './LambdaFunctionPage';
import WorkflowPage from './WorkflowPage';
import CustomWorkflowPage from './CustomWorkflowPage';
import NotFound from './NotFound';

interface SceneRoute {
  name: string;
  path: string;
  exact?: boolean;
  component: FunctionComponent;
  showInNav?: boolean;
}

interface ScenesConfig {
  secure: SceneRoute[];
}

export const scenesConfig: ScenesConfig = {
  secure: [
    {
      name: 'Home',
      path: '/',
      exact: true,
      component: Home,
      showInNav: true,
    },
    {
      name: 'Marketplace Controls',
      path: '/marketplace-controls',
      exact: true,
      component: MarketplaceControls,
      showInNav: true,
    },
    {
      name: 'Lambda Functions',
      path: '/function/:lambdaName',
      exact: true,
      component: LambdaFunctionPage,
    },
    {
      name: 'Workflows',
      path: '/workflow/:workflowName',
      exact: true,
      component: WorkflowPage,
    },
    {
      name: 'Custom Workflows',
      path: '/customworkflow/:workflowName',
      exact: true,
      component: CustomWorkflowPage,
    },
    {
      name: 'Not Found',
      path: '*',
      component: NotFound,
    },
  ],
};
