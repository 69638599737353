import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'brand',
    formLabel: 'brand *',
  },
  {
    name: 'brandName',
    formLabel: 'brandName *',
  },
  {
    name: 'cashbackRestrictions',
    formLabel: 'cashbackRestrictions *',
  },
  {
    name: 'offerNetwork',
    formLabel: 'offerNetwork *',
  },
  {
    name: 'offerNetworkMerchantId',
    formLabel: 'offerNetworkMerchantId *',
  },
  {
    name: 'offerNetworkOfferGroupId',
    formLabel: 'offerNetworkOfferGroupId *',
  },
  {
    name: 'offers',
    formLabel: 'array of offer data *',
  },
  {
    name: 'reportingWindowDays',
    formLabel: 'reportingWindowDays *',
  },
  {
    name: 'validationWindowDays',
    formLabel: 'validationWindowDays *',
  },
  {
    name: 'offerNetworkStartDate',
    formLabel: 'offerNetworkStartDate *',
  },
  {
    name: 'offerNetworkEndDate',
    formLabel: 'offerNetworkEndDate *',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'affiliate-offer-svc',
  functionName: 'saveAffiliateOffer',
  method: HttpMethod.POST,
});

export const saveAffiliateOffer = {
  name: 'Save Affiliate Offer',
  description: 'Create a new affiliate offer',
  inputValues,
  outputValueName: 'offer',
  tags: [Tags.offers],
  submit,
};
