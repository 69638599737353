import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'cardNetwork',
    formLabel: 'Card Network  (VISA, MASTERCARD, AMEX)',
  },
  {
    name: 'cardNetworkExternalIdType',
    formLabel:
      'Card Network External Id Type  (VMID, VSID, SE, AuthMID, ClearMID)',
  },
  {
    name: 'cardNetworkExternalId',
    formLabel: 'Card Network External Id',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-mgmt-svc',
  functionName: 'getTransactionFlowSourcesForCNId',
  method: HttpMethod.GET,
});

export const getTransactionFlowSourcesForCNId = {
  name: 'Get Transaction Flow Sources For CNId',
  description: 'check transaction flow state for cnid',
  inputValues,
  outputValueName: 'feed', // optional
  tags: [Tags.cnids, Tags.transactions],
  submit,
};
