import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { offerLocationTypeOptions } from '@/helpers/commonInputSelectOptions';
import { setOfferProperties } from '../lambdaFunctions/setOfferProperties';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'locationType',
    formLabel: 'Location Type',
    options: offerLocationTypeOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'updatedBy',
    formLabel: 'Name of person making updates',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Current Offer',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
          locationType,
        }: {
          merchantName: string;
          locationType: string;
        }) =>
          `SELECT DISTINCT getuuid(o.offer_id) as offer_uuid, c.start_date, c.end_date
          FROM merchantdb.offers o
          JOIN merchantdb.campaigns c on c.offer_id = o.offer_id
          JOIN merchantdb.coupons c2 ON o.coupon_id = c2.coupon_id
          JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
          JOIN merchantdb.locations l on l.location_id = cl.location_id
          WHERE 1=1
          AND m.name ilike '%${
            merchantName ? merchantName.replace(/[',"]/gi, '%') : ''
          }%'
            AND l.type = '${locationType}'
            AND o.visible = 1
            AND c.end_date >= current_date;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Select Offer Uuid',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            offer_uuid: string;
            start_date: string;
            end_date: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.offer_uuid}`,
              name: `OFFER UUID: ${record.offer_uuid} | START DATE: ${record.start_date} | END DATE: ${record.end_date}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Set Offer to Invisible',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: setOfferProperties.submit,
    },
    inputData: {
      offerUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
      visible: 0,
      updatedBy: new InputInjection([0, 'updatedBy']),
    },
  },
];

export const setOfferToInvisible = {
  steps,
  inputValues,
  name: 'Set offer to invisible',
  description:
    'Set an offer invisible - will not show in app, but will still pay out until the offer has been ended',
  tags: [Tags.offers],
};
