import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'offerNetworkVenueUuid',
    formLabel: 'Offer Network Venue (ONVenue) Uuid',
  },
  {
    name: 'cardNetworkVenueUuid',
    formLabel: 'Card Network Venue (CNVenue) Uuid',
  },
  {
    name: 'reason',
    formLabel: 'Type of mapping',
    formSubLabel: 'MANUAL_MATCH',
    default: 'MANUAL_MATCH',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-venue-service',
  functionName: 'mapONVenueToCNVenue',
  method: HttpMethod.POST,
});

export const mapONVenueToCNVenue = {
  name: 'Map ONVenue From CNVenue',
  description: 'Map ONVenue from CNVenue',
  inputValues,
  outputValueName: 'Map',
  tags: [Tags.venues],
  submit,
};
