/*
  TODO:
  - inject conflict message into input
  - Look for conflicts in payload when submitting a step
*/

import { pathOr } from 'ramda';

export const injectInput = (inputData: any, dataSource: any) => {
  const payload: any = inputData instanceof Array ? [] : {};
  if (typeof inputData === 'object') {
    Object.keys(inputData).forEach((key) => {
      if (inputData[key] instanceof InputInjection) {
        const input = pathOr(
          `#### Missing input from step "[${inputData[key].path}]" ####`,
          inputData[key].path,
          dataSource,
        );
        payload[key] = inputData[key].format(input);
      } else if (inputData[key] && typeof inputData[key] === 'object') {
        payload[key] = injectInput(inputData[key], dataSource);
      } else {
        payload[key] = inputData[key];
      }
    });
  }

  return payload;
};

export default class InputInjection {
  path: any[];

  format: Function;

  constructor(path: any[], format: Function = (input: any) => input) {
    this.path = path;
    this.format = format;
  }
}
