import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { BrandPlaceMatcherResults, MatcherObject, Tags } from '@/helpers/types';
import { GET_PLACE_MATCHERS } from '../graphql/getPlaceMatchers.gql';
import { UPSERT_PLACE_MATCHERS } from '../graphql/upsertPlaceMatchers.gql';

const inputValues = [
  {
    name: 'brandId',
    formLabel: 'Brand Id for matchers',
  },
  {
    name: 'matcher',
    formLabel: 'REGEX',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Current Matchers',
    autorun: true,
    inputData: {
      id: new InputInjection([0, 'brandId']),
    },
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_PLACE_MATCHERS,
    },
  },
  {
    name: 'Combine Regex Results with New Regex',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      currentMatchers: new InputInjection(
        [1],
        ({ brand }: { brand: BrandPlaceMatcherResults }) => {
          if (!brand || !brand.rawPlaceMatchers) {
            return;
          }
          return brand.rawPlaceMatchers;
        },
      ),
      newMatcher: new InputInjection(
        [0],
        ({ matcher }: { matcher: string }) => {
          const pattern = {
            pattern: `${matcher ? matcher.trim() : ''}`,
            type: 'REGEX',
          };
          return { ...pattern, options: 'i' };
        },
      ),
    },
  },
  {
    name: 'Upsert New Place Matchers',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: UPSERT_PLACE_MATCHERS,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ matcher }: { matcher: string }) => {
            if (!matcher) {
              return;
            }
            return matcher.length < 4;
          },
        ),
      },
      id: new InputInjection([0, 'brandId']),
      matchers: new InputInjection(
        [2],
        ({
          currentMatchers,
          newMatcher,
        }: {
          currentMatchers: MatcherObject[];
          newMatcher: MatcherObject;
        }) => {
          if (!newMatcher) {
            return;
          }
          const matchers =
            !currentMatchers || !currentMatchers.length
              ? [newMatcher]
              : [...currentMatchers, newMatcher];
          return matchers;
        },
      ),
    },
  },
];

export const appendNewPlaceMatcherToExisting = {
  steps,
  inputValues,
  name: 'Append New Place Matcher To Existing',
  description:
    'Add additional manually created regex to existing place matchers',
  tags: [Tags.venues],
};
