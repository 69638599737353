import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'userId',
    formLabel: 'User ID',
  },
  {
    name: 'publicUUID',
    formLabel: 'Public UUID',
  },
  {
    name: 'marketplaceUserId',
    formLabel: 'Marketplace User ID',
  },
  {
    name: 'marketplace',
    formLabel: 'Marketplace ID',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'user-service-2',
  functionName: 'getUserDetailsBy',
  method: HttpMethod.GET,
});

export const getUser = {
  inputValues,
  submit,
  name: 'Get User',
  description: 'Find user by marketplace, internal ID, or public Uuid',
  outputValueName: 'user', // optional
  tags: [Tags.csResources],
};
