import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { ADSOffer } from './adsGet';

export const buildRtmEvent = ({
  transactionIdentifier,
  sourceCustomerId,
  offer,
}: {
  transactionIdentifier: string;
  sourceCustomerId: string;
  offer: ADSOffer;
}) => ({
  transactionIdentifier,
  externalAccountIdentifier:
    '22815f408fe6a9174b71fd0e6cc2ece3cefcb6bb0c5422e8b43ebe63e7b3aef3',
  // TODO: use the CTE auth timestamp here
  sourceAuthorizationTimeStamp: '2021-04-29T17:14:15',
  merchantName: offer.brandName,
  authorizationAmount: 6.31,
  preMessageText: offer.description,
  shortPreMessageText: 'Earn 5%!',
  rewardsItemName: offer.brandName,
  thankYouMessageText: offer.creatives[0].thankYouMessage || offer.description,
  alertCategoryTypeName: 'Processing',
  offerIdentifier: Number.parseInt(offer.brandId.replace('cdlx:', ''), 10),
  transactionTimeStamp: '2021-04-29T21:14:15',
  paymentNetworkName: 'C',
  offerGroupIdentifier: 2,
  sourceCustomerId,
});

const inputValues = [
  {
    name: 'sourceCustomerId',
    formLabel: 'Source Customer ID (Cognito ID)',
  },
  {
    name: 'offer',
    formLabel: 'ADS Offer',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cdlx-exchange-svc',
  functionName: 'rtmRewardEvent',
  method: HttpMethod.POST,
});

export const rtmRewardEvent = {
  name: 'Send RTM Reward Event to cdlx-exchange-svc',
  inputValues,
  tags: [Tags.transactions, Tags.cdlx],
  submit,
};
