import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'name',
    formLabel: 'Marketplace User Group Name',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'marketplace-user-groups-svc',
  functionName: 'userGroupGetIdByName',
  method: HttpMethod.GET,
});

export const userGroupGetIdByName = {
  name: 'Get Marketplace User Group Id',
  description: 'retrieve group id for passed in marketplace user name',
  inputValues,
  tags: [Tags.mugs],
  submit,
};
