import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'subscriber',
    formLabel: 'Subscriber',
    default: 'MERCHANT_SERVICE',
  },
  {
    name: 'externalIdPrefix',
    formLabel: 'External Id Prefix',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'audience-svc',
  functionName: 'getAudienceSubscriptionsForSubscriber',
  method: HttpMethod.GET,
});

export const getAudienceSubscriptionsForSubscriber = {
  name: 'Get Audience Subscription For Subscriber',
  description: 'Get audience subscriptions for a subscriber',
  inputValues,
  outputValueName: 'subscriptions',
  submit,
};
