import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'venue.enabled',
    formLabel: 'Enable or Disable Venue',
    formSubLabel: 'true or false',
  },
  {
    name: 'venue.name',
    formLabel: 'Merchant Name',
  },
  {
    name: 'venue.venueSource',
    formLabel: 'CNId Source Type',
  },
  {
    name: 'venue.externalId',
    formLabel: 'CNId',
  },
  {
    name: 'venue.locationType',
    formLabel: 'Location Type',
    formSubLabel: 'ONLINE or NEARBY',
  },
  {
    name: 'venue.address.address1',
    formLabel: 'Street',
  },
  {
    name: 'venue.address.address2',
    formLabel: 'Suite',
  },
  {
    name: 'venue.address.city',
    formLabel: 'City',
  },
  {
    name: 'venue.address.state',
    formLabel: 'State',
  },
  {
    name: 'venue.address.postal',
    formLabel: 'Postal',
  },
  {
    name: 'venue.address.country',
    formLabel: 'Country',
  },
  {
    name: 'venue.cardNetworkIds.cardNetwork',
    formLabel: 'Card Network',
  },
  {
    name: 'venue.cardNetworkIds.idType',
    formLabel: 'CNId Type',
  },
  {
    name: 'venue.cardNetworkIds.externalId',
    formLabel: 'CNId',
  },
  {
    name: 'venue.mode',
    formLabel: 'Mode',
    formSubLabel: 'UPSERT or APPEND_EXISTING_CNIS',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-venue-service',
  functionName: 'upsertCNVenue',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});

export const upsertCNVenue = {
  name: 'Upsert AMEX Online CNVenue',
  description: 'Upserts an SE to an Online CNVenue',
  inputValues,
  tags: [Tags.venues, Tags.cnids],
  submit,
};
