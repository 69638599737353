import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags, Venue } from '@/helpers/types';
import { getVenueMappingsForGenericVenue } from '../lambdaFunctions/getVenueMappingsForGenericVenue';

const inputValues = [
  {
    name: 'offerUuid',
    formLabel: 'QR Offer Uuid',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Associated Locations For Offer',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ offerUuid }: { offerUuid: string }) =>
          `SELECT DISTINCT l.street_address, l.status, sl.source_location_id
          FROM merchantdb.offers o
          JOIN merchantdb.campaigns c ON o.offer_id = c.offer_id
          JOIN merchantdb.coupons c2 ON o.coupon_id = c2.coupon_id
          JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
          JOIN merchantdb.locations l on cl.location_id = l.location_id
          JOIN merchantdb.source_locations sl on sl.location_id = l.location_id
          WHERE getuuid(o.offer_id) = '${offerUuid}'
          ;`,
      ),
    },
  },
  {
    name: 'Get Mappings',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueMappingsForGenericVenue.submit,
    },
    inputData: {
      venueUuid: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            street_address: string;
            status: string;
            source_location_id: string;
          }[];
        }) => {
          if (!records) {
            return;
          }
          const unknownLocation = records.find(
            (record) => record.street_address === '__UNKNOWN__',
          );
          return unknownLocation ? unknownLocation.source_location_id : '';
        },
      ),
    },
  },
  {
    name: 'Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      numberOfLocationsOnOffer: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            street_address: string;
            status: string;
            source_location_id: string;
          }[];
        }) => {
          if (!records) {
            return;
          }
          return records.length;
        },
      ),
      nearbyUnknownLocationOnOffer: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            street_address: string;
            status: string;
            source_location_id: string;
          }[];
        }) => {
          if (!records) {
            return;
          }
          return records.some(
            (record) => record.street_address === '__UNKNOWN__',
          );
        },
      ),
      nearbyUnknownLocationIsActive: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            street_address: string;
            status: string;
            source_location_id: string;
          }[];
        }) => {
          if (!records) {
            return;
          }
          const unknownLocation = records.find(
            (record) => record.street_address === '__UNKNOWN__',
          );
          return unknownLocation && unknownLocation.status === 'active';
        },
      ),
      mappedCnids: new InputInjection(
        [2],
        ({ result }: { result: Venue[] }) => {
          if (!result) {
            return;
          }
          return result.map((venue) => venue.cardNetworkIds).flat();
        },
      ),
    },
  },
];

export const qrCodeQASession = {
  steps,
  inputValues,
  name: 'QA QR Offer',
  description: 'Check QR code offer setup',
  tags: [Tags.qa, Tags.newMerchantOnboarding, Tags.specialMerchants],
};
