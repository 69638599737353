import React, { FunctionComponent } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const MainLayout: FunctionComponent = ({ children }): JSX.Element => {
  const { authState } = useOktaAuth();

  return (
    <>
      {authState && !authState.isPending && authState.isAuthenticated && (
        <Navbar />
      )}
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default MainLayout;
