import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { getAudienceSubscriptionsForSubscriber } from '../lambdaFunctions/getAudienceSubscriptionsForSubscriber';
import { manuallyUpsertAudienceMembership } from '../lambdaFunctions/manuallyUpsertAudienceMembership';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'cognitoId',
    formLabel: 'Cognito Id',
  },
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'audienceType',
    formLabel: 'Audience Type',
    options: [
      { value: 'LOYAL_USER', label: 'LOYAL_USER' },
      { value: 'LAPSED_USER', label: 'LAPSED_USER' },
      { value: 'NEW_USER', label: 'NEW_USER' },
      {
        value: 'COMPETITIVE_CATEGORIES_USER',
        label: 'COMPETITIVE_CATEGORIES_USER',
      },
    ],
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'action',
    formLabel: 'Action',
    options: [
      { value: 'ADDED', label: 'ADDED' },
      { value: 'REMOVED', label: 'REMOVED' },
    ],
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Merchant Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
          audienceType,
        }: {
          merchantName: string;
          audienceType: string;
        }) =>
          `SELECT getuuid(merchant_id) as merchant_uuid, '${audienceType}' as audience_type
          FROM merchantdb.merchants
          WHERE name ilike '%${redshiftEscape(merchantName)}%'`,
      ),
    },
  },
  {
    name: 'Get Audience Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getAudienceSubscriptionsForSubscriber.submit,
    },
    inputData: {
      subscriber: 'MERCHANT_SERVICE',
      externalIdPrefix: new InputInjection(
        [1],
        ({ records }: { records: any }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return `${records[0].merchant_uuid}:::${records[0].audience_type}`;
        },
      ),
    },
  },
  {
    name: 'Get Live Audience Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [2],
        ({ subscriptions }: { subscriptions: any[] }) =>
          `SELECT DISTINCT cstrd.target_data as audience_uuid
          FROM merchantdb.coupon_segment cs
          JOIN merchantdb.coupon_segment_targeting_rule cstr on cs.coupon_segment_id = cstr.coupon_segment_id
          JOIN merchantdb.coupon_segment_targeting_rule_data cstrd on cstr.coupon_segment_targeting_rule_id = cstrd.coupon_segment_targeting_rule_id
          WHERE (cs.end_date is null or cs.end_date > current_date)
          AND cstrd.target_data in (${
            subscriptions && subscriptions.length
              ? subscriptions.map((sub) => `'${sub.audienceUuid}'`).join(',')
              : ''
          })
          ;`,
      ),
    },
  },
  {
    name: 'Update Audience Membership',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: manuallyUpsertAudienceMembership.submit,
    },
    inputData: {
      userId: new InputInjection([0, 'cognitoId']),
      audienceUuid: new InputInjection([3], ({ records }: { records: any }) => {
        if (!records || !Array.isArray(records)) {
          return;
        }
        return `${records[0].audience_uuid}`;
      }),
      status: new InputInjection([0, 'action']),
    },
  },
];

export const updateAudienceMembershipForUser = {
  steps,
  inputValues,
  name: 'Update Audience Membership For User',
  description: 'Add or remove a user from an audience',
  tags: [Tags.audiences],
};
