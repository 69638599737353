import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { redshiftEscape } from '@/utils/strings';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { getContentFeed } from '../lambdaFunctions/getContentFeed';
import { upsertContentFeed } from '../lambdaFunctions/upsertContentFeed';
import { GET_LATEST_DEFAULT_BRAND_ASSETS } from '../graphql/getLatestDefaultBrandAssets.gql';
import { GET_LATEST_VENMO_BRAND_ASSETS } from '../graphql/getLatestVenmoBrandAssets.gql';
import { marketplacesGetNames } from '../lambdaFunctions/marketplacesGetNames';
import {
  AssetComponent,
  BannerObject,
  BrandAssetResults,
  CurrentFeed,
  Marketplace,
  Tags,
} from '@/helpers/types';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'displayStart',
    formLabel: 'Display Start for Banner',
    formSubLabel: 'Format: 2021-11-20 22:00',
  },
  {
    name: 'bannerPosition',
    formLabel: 'Banner Postion',
  },
  {
    name: 'contentFeedMarketplace',
    formLabel: 'Marketplace Content Feed',
    col: 4,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Brand Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
        }: {
          merchantName: string;
        }) => `SELECT mb.brand AS brand_id
          FROM merchantdb.merchants m
          JOIN merchantdb.merchant_brand mb on m.merchant_id = mb.merchant_id
          WHERE m.name = '${redshiftEscape(merchantName)}'
          ;`,
      ),
    },
  },
  {
    name: 'Get Default Banner Image and Headline',
    autorun: true,
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_LATEST_DEFAULT_BRAND_ASSETS,
    },
    inputData: {
      id: new InputInjection(
        [1],
        ({ records }: { records: { brand_id: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0].brand_id || [];
        },
      ),
    },
  },
  {
    name: 'Get Venmo Banner Image and Headline',
    autorun: true,
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_LATEST_VENMO_BRAND_ASSETS,
    },
    inputData: {
      id: new InputInjection(
        [1],
        ({ records }: { records: { brand_id: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0].brand_id || [];
        },
      ),
    },
  },
  {
    name: 'Get current content feed',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getContentFeed.submit,
    },
    inputData: {
      marketplace: new InputInjection(
        [0],
        ({ contentFeedMarketplace }: { contentFeedMarketplace: string }) => {
          if (!contentFeedMarketplace) {
            return;
          }
          if (contentFeedMarketplace.toLowerCase() === 'venmo') {
            return contentFeedMarketplace.toUpperCase();
          }
          return 'DOSH';
        },
      ),
      feedId: 'featured',
    },
  },
  {
    name: 'Get current non-Venmo marketplaces',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: marketplacesGetNames.submit,
    },
    inputData: {},
  },
  {
    name: 'Combine Banner Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      nonVenmoMarketplaces: new InputInjection(
        [5],
        (result: { [key: string]: Marketplace }) => {
          if (!result) {
            return;
          }
          return Object.values(result)
            .map((marketplace: Marketplace) =>
              marketplace.name ? marketplace.name.toUpperCase() : null,
            )
            .filter((name) => !!name && name !== 'VENMO');
        },
      ),
      brand: new InputInjection(
        [1],
        ({ records }: { records: { brand_id: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0].brand_id;
        },
      ),
      bannerPosition: new InputInjection(
        [0],
        ({ bannerPosition }: { bannerPosition: string }) => {
          return parseInt(bannerPosition, 10) - 1;
        },
      ),
      displayStart: new InputInjection(
        [0],
        ({ displayStart }: { displayStart: string }) => {
          return displayStart;
        },
      ),
      contentFeedMarketplace: new InputInjection([0, 'contentFeedMarketplace']),
      defaultBannerObject: new InputInjection(
        [2],
        ({ brand }: { brand: BrandAssetResults }) => {
          if (!brand) {
            return;
          }
          if (brand && (!brand.rawAssets || !brand.rawAssets.bundle)) {
            return {
              imageId: 'dosh/default_banner_img',
              description: 'Featured Headline',
            };
          }
          const defaultBannerAsset = brand.rawAssets.bundle.find(
            (asset: AssetComponent) => asset.component === 'featuredBanner',
          );
          const defaultHeadlineAsset = brand.rawAssets.bundle.find(
            (asset: AssetComponent) =>
              asset.component === 'featuredBannerHeadline',
          );
          return {
            imageId: `dosh/${
              defaultBannerAsset && defaultBannerAsset.path
                ? defaultBannerAsset.path.split('/dosh/')[1].split('.')[0]
                : 'default_banner_img'
            }`,
            description:
              defaultHeadlineAsset && defaultHeadlineAsset.value
                ? defaultHeadlineAsset.value
                : 'Featured Headline',
          };
        },
      ),
      venmoBannerObject: new InputInjection(
        [3],
        ({ brand }: { brand: BrandAssetResults }) => {
          if (!brand) {
            return;
          }
          if (brand && (!brand.rawAssets || !brand.rawAssets.bundle)) {
            return {
              imageId: 'venmo/default_banner_img',
              description: 'Featured Headline',
            };
          }
          const venmoBannerAsset = brand.rawAssets.bundle.find(
            (asset: AssetComponent) => asset.component === 'featuredBanner',
          );
          const venmoHeadlineAsset = brand.rawAssets.bundle.find(
            (asset: AssetComponent) =>
              asset.component === 'featuredBannerHeadline',
          );
          return {
            imageId: `venmo/${
              venmoBannerAsset && venmoBannerAsset.path
                ? venmoBannerAsset.path.split('/venmo/')[1].split('.')[0]
                : 'default_banner_img'
            }`,
            description:
              venmoHeadlineAsset && venmoHeadlineAsset.value
                ? venmoHeadlineAsset.value
                : 'Featured Headline',
          };
        },
      ),
      currentFeed: new InputInjection(
        [4],
        ({ content }: { content: string }) => {
          if (!content) {
            return;
          }
          return JSON.parse(content);
        },
      ),
    },
  },
  {
    name: 'Upsert new content feed',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: upsertContentFeed.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [6],
          ({
            contentFeedMarketplace,
            defaultBannerObject,
            venmoBannerObject,
          }: {
            contentFeedMarketplace: string;
            defaultBannerObject: { imageId: string; description: string };
            venmoBannerObject: { imageId: string; description: string };
          }) => {
            if (
              !contentFeedMarketplace ||
              !defaultBannerObject ||
              !venmoBannerObject
            ) {
              return;
            }
            return contentFeedMarketplace.toLowerCase() !== 'venmo'
              ? defaultBannerObject.imageId === 'dosh/default_banner_img'
              : venmoBannerObject.imageId === 'venmo/default_banner_img';
          },
        ),
      },
      marketplace: new InputInjection(
        [6],
        ({
          nonVenmoMarketplaces,
          contentFeedMarketplace,
        }: {
          nonVenmoMarketplaces: string;
          contentFeedMarketplace: string;
        }) => {
          if (!contentFeedMarketplace || !nonVenmoMarketplaces) {
            return;
          }
          return contentFeedMarketplace.toLowerCase() === 'venmo'
            ? ['VENMO']
            : nonVenmoMarketplaces;
        },
      ),
      feedId: 'featured',
      content: new InputInjection(
        [6],
        ({
          bannerPosition,
          brand,
          contentFeedMarketplace,
          currentFeed,
          defaultBannerObject,
          displayStart,
          venmoBannerObject,
        }: {
          bannerPosition: number;
          brand: string;
          contentFeedMarketplace: string;
          currentFeed: CurrentFeed;
          defaultBannerObject: { imageId: string; description: string };
          displayStart: string;
          venmoBannerObject: { imageId: string; description: string };
        }) => {
          if (!brand || !contentFeedMarketplace) {
            return;
          }
          const newBannerObject = {
            brand,
            imageId:
              contentFeedMarketplace.toLowerCase() !== 'venmo'
                ? defaultBannerObject.imageId
                : venmoBannerObject.imageId,
            description:
              contentFeedMarketplace.toLowerCase() !== 'venmo'
                ? defaultBannerObject.description
                : venmoBannerObject.description,
            filters: {
              displayStart,
            },
          };
          let prevBanner: BannerObject | {} = {};
          const newBannerFeed = currentFeed.banners.map((banner, index) => {
            if (index === bannerPosition) {
              prevBanner = { ...banner };
              return newBannerObject;
            }
            if (index > bannerPosition) {
              const bannerToReturn = prevBanner;
              prevBanner = { ...banner };
              return bannerToReturn;
            }
            return banner;
          });
          return {
            title:
              contentFeedMarketplace.toLowerCase() !== 'venmo'
                ? 'Featured Offers'
                : 'Featured Debit Card Offers',
            banners: [...newBannerFeed, prevBanner],
          };
        },
      ),
    },
  },
  {
    name: 'Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      results: new InputInjection([7], ({ status }: { status: string }) => {
        if (!status) {
          return;
        }
        return status === 'skipped'
          ? 'No banners added, missing approved assets'
          : 'Banners added';
      }),
    },
  },
];

export const addBannerToBannerFeed = {
  steps,
  inputValues,
  name: 'Add Banner to Featured Banners',
  description: 'Add Banner to Featured Banners',
  tags: [Tags.banners],
};
