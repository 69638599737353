import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { userGroupChangeMembership } from '../lambdaFunctions/userGroupChangeMembership';
import { userGroupGetIdByName } from '../lambdaFunctions/userGroupGetIdByName';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'groupName',
    formLabel: 'Group Name',
  },
  {
    name: 'groupId',
    formLabel: 'Group Id',
  },
  {
    name: 'userId',
    formLabel: 'Cognito Id',
  },
  {
    name: 'action',
    formLabel: 'Add or Remove',
    options: [
      { value: 'Add', label: 'Add' },
      { value: 'Remove', label: 'Remove' },
    ],
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Group Id',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: userGroupGetIdByName.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ groupId }: { groupId: string }) => {
            if (!groupId || groupId === '') {
              return false;
            }
            return true;
          },
        ),
      },
      name: new InputInjection([0, 'groupName']),
    },
  },
  {
    name: 'Update Group Membership with Retrieved Group Id',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: userGroupChangeMembership.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection([1], ({ status }: { status: string }) => {
          if (status === 'skipped') {
            return true;
          }
          return false;
        }),
      },
      groupId: new InputInjection([1, 'groupId']),
      userId: new InputInjection([0, 'userId']),
      active: new InputInjection([0], ({ action }: { action: string }) => {
        if (!action) {
          return false;
        }
        if (action.toLowerCase() === 'add') {
          return true;
        }
        return false;
      }),
    },
  },
  {
    name: 'Update Group Membership with Provided Group Id',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: userGroupChangeMembership.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ groupId, status }: { groupId: string; status: string }) => {
            if (status === 'success' && (!groupId || groupId === '')) {
              return true;
            }
            return false;
          },
        ),
      },
      groupId: new InputInjection([0, 'groupId']),
      userId: new InputInjection([0, 'userId']),
      active: new InputInjection([0], ({ action }: { action: string }) => {
        if (!action) {
          return;
        }
        if (action.toLowerCase() === 'add') {
          return true;
        }
        return false;
      }),
    },
  },
];

export const updateUserMUGMembership = {
  steps,
  inputValues,
  name: 'Update User MUG Membership',
  description: 'Add or remove user from an existing MUG',
  tags: [Tags.mugs, Tags.partnerOnboarding],
};
