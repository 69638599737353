import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'venueSource',
    formLabel: 'Venue Source',
  },
  {
    name: 'externalId',
    formLabel: 'External Id',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-venue-service',
  functionName: 'getVenueByExternalId',
  method: HttpMethod.GET,
});

export const getVenueByExternalId = {
  name: 'Get Venue By External Id',
  description: 'Get venue by external Id',
  inputValues,
  outputValueName: 'Venue',
  tags: [Tags.venues],
  submit,
};
