import { pathOr } from 'ramda';

import log from '@/utils/logging';

interface ApiPostProps {
  route: string;
  payload: object;
  errorPath?: string[];
}

const post = ({
  route,
  payload,
  errorPath = ['error', 'displayMessage'],
}: ApiPostProps): Promise<any> => {
  log.info('Starting post request to ', route);

  return fetch(route, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        // Catching errors from the services
        if (result && result.success) {
          log.info('successful post request', result);
          return pathOr({}, ['response', 'result'], result);
        }
        log.error('fetch failed', result);
        const errorMsg = pathOr('Service error', errorPath, result);
        throw new Error(errorMsg);
      },
      // Catching failed requests
      (error) => {
        // todo: report to sentry
        log.error('Api call failed', error);
        throw new Error('API error.  Are you connected to the VPN?');
      },
    );
};

export default post;
