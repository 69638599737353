import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'name',
    formLabel: 'New MUG Name',
    formSubLabel: 'no spaces',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'marketplace-user-groups-svc',
  functionName: 'userGroupCreate',
  method: HttpMethod.POST,
});

export const userGroupCreate = {
  name: 'Create Marketplace User Group',
  description: 'Create a new MUG',
  inputValues,
  tags: [Tags.mugs, Tags.partnerOnboarding],
  submit,
};
