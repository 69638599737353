import { Col, Form, Row, Spinner } from 'react-bootstrap';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  getWithdrawalControls,
  WithdrawalControlStatus,
} from '@/services/lambdaFunctions/getWithdrawalControls';
import {
  buildWithdrawalControlSummaries,
  SummaryDimension,
  WithdrawalControlSummaryByDimension,
} from '@/scenes/MarketplaceControls/components/WithdrawalControlPanel/helpers/summary';
import { WithdrawalControlSummary } from '@/scenes/MarketplaceControls/components/WithdrawalControlPanel/WithdrawalControlsSummary';

import './WithdrawalControlPanel.css';
import {
  updateWithdrawalControls,
  UpdateWithdrawalControlStrategy,
  UpdateWithdrawalControlWithdrawalAction,
  WithdrawalControlInitiator,
} from '@/services/lambdaFunctions/updateWithdrawalControls';
import getErrorMessage from '@/utils/error';

const loadWithdrawalControls = async ({
  setSummaryByDimension,
  setDisplayedError,
  setLoading,
}: {
  setSummaryByDimension: Dispatch<
    SetStateAction<Record<
      SummaryDimension,
      Record<string, WithdrawalControlSummaryByDimension>
    > | null>
  >;
  setDisplayedError: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  setLoading(true);

  const buildErrorMessage = (error: unknown) => {
    const message = getErrorMessage(error) || 'unknown';

    return `Could not load Withdrawal Controls. Error: ${message}`;
  };

  try {
    const response = await getWithdrawalControls.submit({});

    if (
      !response ||
      !response.success ||
      !response.data ||
      !response.data.withdrawalControls
    ) {
      console.error('Received error loading Withdrawal Controls', {
        response,
      });

      setSummaryByDimension(null);
      setDisplayedError(buildErrorMessage(response.error));
      return;
    }

    const summaries = buildWithdrawalControlSummaries({
      withdrawalControls: response.data.withdrawalControls || {},
    });

    setSummaryByDimension(summaries);
    setDisplayedError('');
  } catch (error) {
    console.error('Could not load Withdrawal Controls', { error });

    setSummaryByDimension(null);
    setDisplayedError(buildErrorMessage(error));
  }

  setLoading(false);
};

const updateWithdrawalControl = async ({
  targetStatus,
  withdrawalAction,
  withdrawalQualifier,
  marketplace,
  refreshWithdrawalControls,
  setDisplayedError,
}: {
  targetStatus: WithdrawalControlStatus;
  withdrawalAction: UpdateWithdrawalControlWithdrawalAction;
  withdrawalQualifier: string;
  marketplace: string;
  refreshWithdrawalControls: () => void;
  setDisplayedError: Dispatch<SetStateAction<string>>;
}) => {
  const buildErrorMessage = (error: unknown) => {
    const message = getErrorMessage(error) || 'unknown';
    return `Could not update Withdrawal Control. Target Status: ${targetStatus}, Withdrawal Action: ${withdrawalAction}, Withdrawal qualifier: ${withdrawalQualifier}, Marketplace: ${marketplace}, Error: ${message}`;
  };

  try {
    const response = await updateWithdrawalControls.submit({
      targetStatus,
      withdrawalAction,
      initiator: WithdrawalControlInitiator.OPERATIONS_MANUAL,
      updates: [
        {
          strategy:
            UpdateWithdrawalControlStrategy.BY_WITHDRAWAL_QUALIFIER_AND_MARKETPLACE,
          withdrawalQualifier,
          marketplace,
        },
      ],
    });

    if (!response || !response.success) {
      console.error('Received error updating Withdrawal Controls', {
        response,
      });

      setDisplayedError(buildErrorMessage(response.error));
    }

    refreshWithdrawalControls();
  } catch (error) {
    console.error('Could not update Withdrawal Controls', { error });

    setDisplayedError(buildErrorMessage(getErrorMessage(error)));
  }
};

export const WithdrawalControlPanel = () => {
  const [displayedError, setDisplayedError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [summaryByDimension, setSummaryByDimension] = useState<Record<
    SummaryDimension,
    Record<string, WithdrawalControlSummaryByDimension>
  > | null>(null);
  const [summaryDimension, setSummaryDimension] = useState<SummaryDimension>(
    SummaryDimension.WITHDRAWAL_QUALIFIER,
  );

  const refreshWithdrawalControls = (): void => {
    loadWithdrawalControls({
      setSummaryByDimension,
      setDisplayedError,
      setLoading,
    });
  };

  useEffect(refreshWithdrawalControls, []);

  const onSummaryDimensionChanged = (event: any) => {
    setSummaryDimension(event.target.value);
  };

  return (
    <div className="panel">
      <Row>
        <Col md={3}>
          <h6 style={{ marginBottom: '2em' }}>Withdrawal Controls</h6>
        </Col>
        {loading && (
          <Col md={2}>
            <Spinner
              as="span"
              animation="grow"
              style={{ textAlign: 'center' }}
            />
          </Col>
        )}
      </Row>
      {displayedError && <div className="errorMessage">{displayedError}</div>}
      {summaryByDimension && (
        <>
          <Row>
            <Col lg="4">
              <Form>
                <Form.Group controlId="summarizeBy">
                  <Form.Label>Summarize By</Form.Label>
                  <Form.Control
                    value={summaryDimension}
                    as="select"
                    onChange={onSummaryDimensionChanged}
                  >
                    <option value={SummaryDimension.WITHDRAWAL_QUALIFIER}>
                      Withdrawal Qualifier
                    </option>
                    <option value={SummaryDimension.MARKETPLACE}>
                      Marketplace
                    </option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <WithdrawalControlSummary
            summary={summaryByDimension[summaryDimension]}
            updateWithdrawalControl={({
              targetStatus,
              withdrawalAction,
              withdrawalQualifier,
              marketplace,
            }: {
              targetStatus: WithdrawalControlStatus;
              withdrawalAction: UpdateWithdrawalControlWithdrawalAction;
              withdrawalQualifier: string;
              marketplace: string;
            }) =>
              updateWithdrawalControl({
                targetStatus,
                withdrawalAction,
                withdrawalQualifier,
                marketplace,
                refreshWithdrawalControls,
                setDisplayedError,
              })
            }
          />
        </>
      )}
    </div>
  );
};
