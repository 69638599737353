import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'deviceId',
    formLabel: 'Device Id *',
  },
  {
    name: 'cognitoId',
    formLabel: 'Cognito Id *',
  },
  {
    name: 'sessionId',
    formLabel: 'Session Id *',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'affiliate-offer-svc',
  functionName: 'getAffiliateOffers',
  method: HttpMethod.GET,
});

export const getAffiliateOffers = {
  name: 'Get Affiliate Offers',
  description: 'Find affiliate offers by device ID, cognito ID, and session ID',
  inputValues,
  outputValueName: 'offers', // optional
  tags: [Tags.csResources],
  submit,
};
