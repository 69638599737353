import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import Routes from './Routes';
import { MainLayout } from './components/Layouts';
import './styles/global.css';

const oktaAuth = new OktaAuth({
  issuer: process.env.OKTA_DOMAIN,
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`,
  pkce: false,
});

const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
  window.history.replaceState(
    null,
    '',
    toRelativeUrl(originalUri || '/', window.location.origin),
  );
  window.location.reload();
};

const App: React.FunctionComponent = () => (
  <div className="body">
    <Router>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <MainLayout>
          <Routes />
        </MainLayout>
      </Security>
    </Router>
  </div>
);

export default App;
