import {
  generateMatcher,
  Specificity,
} from '@dosh/commons-node-matcher-generator';
import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { BrandPlaceMatcherResults, MatcherObject, Tags } from '@/helpers/types';
import { GET_PLACE_MATCHERS } from '../graphql/getPlaceMatchers.gql';
import { UPSERT_PLACE_MATCHERS } from '../graphql/upsertPlaceMatchers.gql';
import { regexSpecificityOptions } from '@/helpers/commonInputSelectOptions';

const inputValues = [
  {
    name: 'brandId',
    formLabel: 'Brand Id for matchers',
  },
  {
    name: 'name',
    formLabel: 'Name to use for matcher creation',
  },
  {
    name: 'specificity',
    formLabel: 'Level of Specificity',
    options: regexSpecificityOptions,
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Current Matchers',
    autorun: true,
    inputData: {
      id: new InputInjection([0, 'brandId']),
    },
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_PLACE_MATCHERS,
    },
  },
  {
    name: 'Combine Regex Results with New Regex',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      currentMatchers: new InputInjection(
        [1],
        ({ brand }: { brand: BrandPlaceMatcherResults }) => {
          if (!brand) {
            return;
          }
          return brand.rawPlaceMatchers ? brand.rawPlaceMatchers.matchers : [];
        },
      ),
      createdMatcher: new InputInjection(
        [0],
        ({ specificity, name }: { specificity: string; name: string }) => {
          if (!specificity || !name) {
            return;
          }
          const matcher = generateMatcher({
            name,
            specificity:
              Specificity[specificity as keyof typeof Specificity] ||
              Specificity.SPECIFIC,
          });
          return matcher;
        },
      ),
    },
  },
  {
    name: 'Upsert New Place Matchers',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: UPSERT_PLACE_MATCHERS,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [2],
          ({
            currentMatchers,
            createdMatcher,
          }: {
            currentMatchers: MatcherObject[];
            createdMatcher: MatcherObject;
          }) => {
            if (!currentMatchers) {
              return;
            }
            return !createdMatcher || createdMatcher.pattern.length < 4;
          },
        ),
      },
      id: new InputInjection([0, 'brandId']),
      matchers: new InputInjection(
        [2],
        ({
          currentMatchers,
          createdMatcher,
        }: {
          currentMatchers: MatcherObject[];
          createdMatcher: MatcherObject;
        }) => {
          if (!currentMatchers || !createdMatcher) {
            return;
          }
          return [...currentMatchers, createdMatcher];
        },
      ),
    },
  },
];

export const createAndAppendPlaceMatcherToExisting = {
  steps,
  inputValues,
  name: 'Create and Append Place Matcher To Existing',
  description:
    'Create a matcher from a name and append to existing place matchers',
  tags: [Tags.venues],
};
