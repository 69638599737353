import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid *',
  },
  {
    name: 'name',
    formLabel: 'Offer name *',
  },
  {
    name: 'description',
    formLabel: 'Offer description *',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'affiliate-offer-svc',
  functionName: 'updateOfferContent',
  method: HttpMethod.POST,
});

export const updateOfferContent = {
  name: 'Update Offer Content',
  description: 'Change an offers name and description',
  inputValues,
  outputValueName: 'offer', // optional
  tags: [Tags.offers],
  submit,
};
