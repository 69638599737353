import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { queryAthenaSafegraphPoi } from '../lambdaFunctions/queryAthenaSafegraphPoi';
import { addBrandName } from '../lambdaFunctions/addBrandName';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'name',
    formLabel: 'Name of merchant',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Query Safegraph',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: queryAthenaSafegraphPoi.submit,
    },
    inputData: {
      sql: new InputInjection([0], ({ name }: { name: string }) => {
        if (!name) {
          return;
        }
        return `SELECT brands, count(1) from \"safegraph\".\"core_poi\" WHERE lower(brands) like lower('%${
          name ? name.replace(/[',"]/gi, '%') : ''
        }%')group by 1 order by 2 desc`;
      }),
    },
  },
  {
    name: 'Get Brand Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ name }: { name: string }) =>
          `SELECT mb.brand as brand_id, m.name
          FROM merchantdb.merchant_brand mb
          JOIN merchantdb.merchants m on mb.merchant_id = m.merchant_id
          WHERE m.name ilike '%${name ? name.replace(/[',"]/gi, '%') : ''}%'
          ;`,
      ),
    },
  },
  {
    name: 'Select Merchant Name',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [2],
        ({ records }: { records: { brand_id: string; name: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: record.brand_id,
              name: `Merchant Name: ${record.name}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Select Name For Brand (1 only)',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          results,
        }: {
          // eslint-disable-next-line no-underscore-dangle
          results: { Items: { brands: string; _col1: string }[] };
        }) => {
          if (!results) {
            return;
          }
          return results.Items.map((item) => {
            return {
              id: item.brands,
              // eslint-disable-next-line no-underscore-dangle
              name: `Name: ${item.brands}; Number of Occurrences: ${item._col1}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Add Brand Name',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addBrandName.submit,
    },
    inputData: {
      brandName: new InputInjection([4], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
      brandNameSource: 'SAFEGRAPH',
      brandId: new InputInjection([3], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
    },
  },
];

export const addSafegraphDefinitionToBrand = {
  steps,
  inputValues,
  name: 'Add Safegraph Definition To Brand',
  description: 'Add Safegraph definition to brand',
  tags: [Tags.venues],
};
