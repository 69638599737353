import { WorkflowStepTypes } from '@/components/Workflow';
import { Tags } from '@/helpers/types';
import InputInjection from '@/utils/workflow/InputInjection';
import { addAudienceSubscription } from '../lambdaFunctions/addAudienceSubscription';

const inputValues = [
  {
    name: 'merchantUuid',
    formLabel: 'Merchant Uuid',
    formSubLabel: 'the uuid of the merchant',
  },
  {
    name: 'safegraphBrandName',
    formLabel: 'Safegraph Brand Name *',
    formSubLabel: 'the safegraph brand name to use to find brand engagements',
  },
  {
    name: 'recentlyEngagedLookbackMonths',
    formLabel: 'Recently Engaged Lookback Months *',
    formSubLabel:
      'the number of months to lookback to check for "recent" engagements',
    default: '6',
  },
  {
    name: 'maximumDistinctDaysRecentlyEngaged',
    formLabel: 'Maximum Distinct Days Engaged *',
    formSubLabel:
      'the maximum number of distinct days the user had engaged recently, in order to still be considered "lapsed"',
    default: '0',
  },
  {
    name: 'previouslyEngagedLookbackMonths',
    formLabel: 'Previously Engaged Lookback Months *',
    formSubLabel:
      'the number of months to lookback to check for "previously" engagements',
    default: '18',
  },
  {
    name: 'minimumDistinctDaysPreviouslyEngaged',
    formLabel: 'Minimum Distinct Days Previously Engaged *',
    formSubLabel:
      'the minimum distinct days the user had in the past, in order to be considered "lapsed"',
    default: '1',
  },
];

const steps = [
  {
    name: 'Add Audience Subscription',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addAudienceSubscription.submit,
    },
    inputData: {
      subscriber: 'MERCHANT_SERVICE', // "MERCHANT_SERVICE" is who manages offer targeting, so they are the subscriber
      externalId: new InputInjection(
        [0, 'merchantUuid'],
        (merchantUuid: string) =>
          `${merchantUuid}:::LAPSED_USER:::${
            new Date().toISOString().split('T')[0]
          }`,
      ),
      name: new InputInjection(
        [0, 'safegraphBrandName'],
        (safegraphBrandName: string) =>
          `Lapsed Users for ${safegraphBrandName}`,
      ),
      audience: {
        type: 'STATIC', // all audiences are static for now
        rules: [
          {
            type: 'LAPSED_USER',
            options: {
              safegraphBrandName: new InputInjection([0, 'safegraphBrandName']),
              recentlyEngagedLookbackMonths: new InputInjection(
                [0, 'recentlyEngagedLookbackMonths'],
                parseInt,
              ),
              maximumDistinctDaysRecentlyEngaged: new InputInjection(
                [0, 'maximumDistinctDaysRecentlyEngaged'],
                parseInt,
              ),
              previouslyEngagedLookbackMonths: new InputInjection(
                [0, 'previouslyEngagedLookbackMonths'],
                parseInt,
              ),
              minimumDistinctDaysPreviouslyEngaged: new InputInjection(
                [0, 'minimumDistinctDaysPreviouslyEngaged'],
                parseInt,
              ),
            },
          },
        ],
      },
    },
  },
];

export const addOfferTargetingAudienceLapsedUser = {
  steps,
  inputValues,
  name: 'Add an offer targeting audience of lapsed users for a brand',
  description:
    'Workflow used for creating an audience of lapsed users to be used in offer targeting',
  tags: [Tags.targeting, Tags.audiences],
};
