import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'merchantNumber',
    formLabel: 'Merchant Number',
  },
  {
    name: 'date',
    formLabel: 'Transaction Date and Time (UTC)',
    formSubLabel: 'Format: YYYY-MM-DD HH:MM',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Check RN Offer Status',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantNumber,
          date,
        }: {
          merchantNumber: string;
          date: string;
        }) => `SELECT mo.merchant_name, mos.status
        FROM rnoffersnetworkdb.merchants m
        INNER JOIN rnoffersnetworkdb.merchant_offers mo ON m.id = mo.merchant_id
        JOIN rnoffersnetworkdb.merchant_offer_status mos on mo.offer_status_id = mos.id
        WHERE m.number = ${merchantNumber}
        AND mos.created_at <= '${date}'
        ORDER BY mos.created_at DESC
        LIMIT 1;`,
      ),
      retries: 10,
    },
  },
];

export const checkRNOfferStatusForTransactionDate = {
  steps,
  inputValues,
  name: 'Check RN Offer Status for Transaction Date',
  description:
    'Check if offer was ACTIVE or DISABLED when transaction was created',
  tags: [Tags.specialMerchants],
};
