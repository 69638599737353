import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'sql',
    formLabel: 'SQL',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'places-svc',
  functionName: 'queryAthenaSafegraphPoi',
  method: HttpMethod.POST,
});

export const queryAthenaSafegraphPoi = {
  name: 'Query Athena Safegraph Poi',
  description: 'Query Safegraph data in Athena',
  inputValues,
  outputValueName: 'Results',
  tags: [Tags.venues],
  submit,
};
