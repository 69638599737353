import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'sourceUserId',
    formLabel: 'Source User ID (US2 publicUUID) *',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor<
  {
    sourceUserId: string;
  },
  {
    sessionStart: string;
    token: string;
    userOptedIn: boolean;
    program: {
      locale: string;
      currency: string;
    };
  }
>({
  serviceName: 'cdlx-adapter-svc',
  functionName: 'sessionTokenCreate',
  method: HttpMethod.POST,
});

export const adsSessionTokenCreate = {
  name: 'Create ADS Session Token',
  description: 'Create ADS Session Token via cdlx-adapter-svc',
  inputValues,
  tags: [Tags.cdlx],
  submit,
};
