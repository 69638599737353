import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'venueUuid',
    formLabel: 'Venue Uuid',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-venue-service',
  functionName: 'getVenueMappingsForGenericVenue',
  method: HttpMethod.GET,
});

export const getVenueMappingsForGenericVenue = {
  name: 'Get Venue Mappings By Venue Uuid',
  description: 'Get mappings for onvenue or cnvenue',
  inputValues,
  outputValueName: 'Venue',
  tags: [Tags.venues],
  submit,
};
