import React, { FunctionComponent } from 'react';
import { Container, Row } from 'react-bootstrap';

import './MarketplaceControls.css';
import { WithdrawalControlPanel } from '@/scenes/MarketplaceControls/components/WithdrawalControlPanel/WithdrawalControlPanel';

const MarketplaceControls: FunctionComponent = () => {
  return (
    <>
      <Container className="smallContainer">
        <Row>
          <h1 className="workflowHeader">Wallet Service 2</h1>
        </Row>
        <Row>
          <WithdrawalControlPanel />
        </Row>
      </Container>
    </>
  );
};

export default MarketplaceControls;
