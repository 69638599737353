import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import {
  CardNetwork,
  CardNetworkId,
  CardNetworkIdType,
  LocationType,
  Tags,
  Venue,
} from '@/helpers/types';
import { getVenueByUuid } from '../lambdaFunctions/getVenueByUuid';
import { getVenueMappingsForGenericVenue } from '../lambdaFunctions/getVenueMappingsForGenericVenue';

const inputValues = [
  {
    name: 'cnTransactionId',
    formLabel: 'Cn Transaction Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Transaction',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          cnTransactionId,
        }: {
          cnTransactionId: string;
        }) => `SELECT DISTINCT cted.merchant_name, cted.venue_name, cte.marketplace, cte.card_network, ctecni.card_network_external_id_type, ctecni.card_network_external_id, v.venue_uuid
        FROM empyrdb.card_transaction_events cte
        JOIN empyrdb.card_transaction_events_debug cted on cte.id = cted.card_transaction_event_id
        JOIN empyrdb.card_transaction_events_card_network_ids ctecni on cte.id = ctecni.card_transaction_event_id
        LEFT JOIN empyrdb.card_transaction_events_venue_uuids v on v.card_transaction_event_id = cte.id
        WHERE 1=1
        AND cte.cn_transaction_id = '${cnTransactionId}';`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Condense Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      onvenueUuid: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            merchant_name: string;
            venue_name: string;
            marketplace: string;
            card_network: CardNetwork;
            card_network_external_id_type: CardNetworkIdType;
            card_network_external_id: string;
            venue_uuid: string;
          }[];
        }) => {
          if (!records) {
            return;
          }
          const venueUuids = records
            .map((record) =>
              typeof record?.venue_uuid === 'string' ? record.venue_uuid : null,
            )
            .filter((uuid) => !!uuid);
          return venueUuids[0];
        },
      ),
      cnids: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            merchant_name: string;
            venue_name: string;
            marketplace: string;
            card_network: CardNetwork;
            card_network_external_id_type: CardNetworkIdType;
            card_network_external_id: string;
            venue_uuid: string;
          }[];
        }) => {
          if (!records) {
            return;
          }
          return records
            .map((record) => {
              const cnid: CardNetworkId = {
                cardNetwork: record.card_network,
                idType: record.card_network_external_id_type,
                externalId: record.card_network_external_id,
              };
              const cnidTypeForHandleMappedCNIdMismatchWorkflow =
                cnid.idType === CardNetworkIdType.AuthMID ||
                cnid.idType === CardNetworkIdType.ClearMID ||
                cnid.idType === CardNetworkIdType.CAID ||
                cnid.idType === CardNetworkIdType.VMID ||
                cnid.idType === CardNetworkIdType.VSID ||
                cnid.idType === CardNetworkIdType.SE;
              return cnidTypeForHandleMappedCNIdMismatchWorkflow ? cnid : null;
            })
            .filter((cnid) => !!cnid);
        },
      ),
    },
  },
  {
    name: 'Get ONVenue Information',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueByUuid.submit,
    },
    inputData: {
      venueUuid: new InputInjection([2, 'onvenueUuid']),
    },
  },
  {
    name: 'Get CNVenues',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueMappingsForGenericVenue.submit,
    },
    inputData: {
      venueUuid: new InputInjection(
        [3],
        ({ result, status }: { result: Venue; status: string }) => {
          if (status !== 'success') {
            return;
          }
          return result?.venueSource === 'DOSH' ? result.uuid : null;
        },
      ),
    },
  },
  {
    name: 'Combine Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cnids: new InputInjection([2, 'cnids']),
      cnvenues: new InputInjection([4], ({ result }: { result: Venue[] }) => {
        if (!result) {
          return;
        }
        return result || [];
      }),
      brandCNIdMatch: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            merchant_name: string;
            venue_name: string;
            marketplace: string;
            card_network: CardNetwork;
            card_network_external_id_type: CardNetworkIdType;
            card_network_external_id: string;
            venue_uuid: string;
          }[];
        }) => {
          if (!records) {
            return;
          }
          return records.some(
            (record) =>
              record.card_network_external_id_type === CardNetworkIdType.BRAND,
          );
        },
      ),
      brandId: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            merchant_name: string;
            venue_name: string;
            marketplace: string;
            card_network: CardNetwork;
            card_network_external_id_type: CardNetworkIdType;
            card_network_external_id: string;
            venue_uuid: string;
          }[];
        }) => {
          if (!records) {
            return;
          }
          const brandMatchRecord = records.find(
            (record) =>
              record.card_network_external_id_type === CardNetworkIdType.BRAND,
          );
          return brandMatchRecord?.card_network_external_id;
        },
      ),
      venueNameCausingBrandMatch: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            merchant_name: string;
            venue_name: string;
            marketplace: string;
            card_network: CardNetwork;
            card_network_external_id_type: CardNetworkIdType;
            card_network_external_id: string;
            venue_uuid: string;
          }[];
        }) => {
          if (!records) {
            return;
          }
          const brandMatchRecord = records.find(
            (record) =>
              record.card_network_external_id_type === CardNetworkIdType.BRAND,
          );
          return brandMatchRecord?.merchant_name;
        },
      ),
    },
  },
  {
    name: 'Determine Mapped CNVenue',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cnids: new InputInjection([2, 'cnids']),
      onvenueUuid: new InputInjection([2, 'onvenueUuid']),
      mappedCnvenueUuidWithMismatchedCNId: new InputInjection(
        [5],
        ({
          cnids,
          cnvenues,
        }: {
          cnids: CardNetworkId[];
          cnvenues: Venue[];
        }) => {
          if (!cnids || !cnvenues) {
            return;
          }
          const nonAggregateCnvenueWithMappedMismatch = cnvenues.find(
            (venue) => {
              return (
                venue.locationType !== LocationType.AGGREGATE &&
                venue.cardNetworkIds.some((venueCNId) => {
                  return cnids.some(
                    (cnid) =>
                      venueCNId.cardNetwork === cnid.cardNetwork &&
                      venueCNId.idType === cnid.idType &&
                      venueCNId.externalId === cnid.externalId,
                  );
                })
              );
            },
          );
          const aggregateCnvenueWithMappedMismatch = cnvenues.find((venue) => {
            return (
              venue.locationType === LocationType.AGGREGATE &&
              venue.cardNetworkIds.some((venueCNId) => {
                return cnids.some(
                  (cnid) =>
                    venueCNId.cardNetwork === cnid.cardNetwork &&
                    venueCNId.idType === cnid.idType &&
                    venueCNId.externalId === cnid.externalId,
                );
              })
            );
          });
          return nonAggregateCnvenueWithMappedMismatch
            ? nonAggregateCnvenueWithMappedMismatch?.uuid
            : aggregateCnvenueWithMappedMismatch?.uuid;
        },
      ),
    },
  },
  {
    name: 'Handle Mapped CNId Mismatch',
    autorun: false,
    process: {
      type: WorkflowStepTypes.EXTERNAL_STEP,
    },
    inputData: {
      description: new InputInjection(
        [6],
        ({
          mappedCnvenueUuidWithMismatchedCNId,
          status,
        }: {
          mappedCnvenueUuidWithMismatchedCNId: string;
          status: string;
        }) => {
          if (!status || status !== 'success') {
            return;
          }
          if (!mappedCnvenueUuidWithMismatchedCNId) {
            return 'No action needed, no CNVenue is currently mapped with mismatched CNId (may have been removed already)';
          }
          return 'Handle Mapped CNId Mismatch';
        },
      ),
      link: new InputInjection(
        [6],
        ({
          cnids,
          onvenueUuid,
          mappedCnvenueUuidWithMismatchedCNId,
          status,
        }: {
          cnids: CardNetworkId[];
          onvenueUuid: string;
          mappedCnvenueUuidWithMismatchedCNId: Venue;
          status: string;
        }) => {
          if (!status || status !== 'success') {
            return;
          }
          if (!mappedCnvenueUuidWithMismatchedCNId) {
            return 'https://www.google.com/';
          }
          return `${
            window.location.origin
          }/workflow/handleMappedCNIdMismatch?onvenueUuid=${onvenueUuid}&cnvenueUuid=${mappedCnvenueUuidWithMismatchedCNId}&cnidArray=${JSON.stringify(
            cnids,
          )}`;
        },
      ),
    },
  },
  {
    name: 'Handle Transaction Matcher Mismatch',
    autorun: false,
    process: {
      type: WorkflowStepTypes.EXTERNAL_STEP,
    },
    inputData: {
      description: new InputInjection(
        [5],
        ({
          brandCNIdMatch,
          status,
        }: {
          brandCNIdMatch: boolean;
          status: string;
        }) => {
          if (!status || status !== 'success') {
            return;
          }
          if (!brandCNIdMatch) {
            return 'No action needed, no match with transaction matchers';
          }
          return 'Handle Transaction Matcher Mismatch';
        },
      ),
      link: new InputInjection(
        [5],
        ({
          brandCNIdMatch,
          venueNameCausingBrandMatch,
          brandId,
          status,
        }: {
          brandCNIdMatch: boolean;
          venueNameCausingBrandMatch: string;
          brandId: string;
          status: string;
        }) => {
          if (!status || status !== 'success') {
            return;
          }
          if (!brandCNIdMatch) {
            return 'https://www.google.com/';
          }
          return `${
            window.location.origin
          }/workflow/handleTransactionMatcherMismatch?brandId=${brandId}&merchantName=${encodeURIComponent(
            venueNameCausingBrandMatch,
          )}
          `;
        },
      ),
    },
  },
];

export const handleCNIdMismatchForTransaction = {
  steps,
  inputValues,
  name: 'Handle CNId Mismatch for Transaction',
  description:
    'Handle invalid payouts by transaction number for non-RN merchants',
  tags: [Tags.cnidMismatches],
};
