import React, { FunctionComponent } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

import { formatOptions } from '@/utils/dropdown';

import './DropDown.css';

const CustomOption = ({ data, innerProps }: any) => {
  return (
    <div className="customDropDownOptionWrapper" {...innerProps}>
      <p className="customDropDownOption">{data.label}</p>
      {data.description && (
        <p className="customDropDownOptionDescription">{data.description}</p>
      )}
    </div>
  );
};

export const DropDown: FunctionComponent<any> = ({
  control,
  required = false,
  errors,
  name,
  label,
  labelClass = '',
  secondaryLabel,
  options,
  style,
  className,
  placeholder = '',
  isClearable = true,
}) => {
  const formattedOptions =
    typeof options[0] === 'string' ? formatOptions(options) : options;

  return (
    <Form.Group controlId={name} style={style} className={className}>
      {label && <Form.Label className={labelClass}>{label}</Form.Label>}
      {secondaryLabel && <p style={{ fontSize: '14px' }}>{secondaryLabel}</p>}
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ onChange, value }) => {
          return (
            <Select
              isClearable={isClearable}
              placeholder={placeholder}
              options={formattedOptions}
              components={{ Option: CustomOption }}
              onChange={(e: any) => onChange(e)}
              value={value}
            />
          );
        }}
      />

      {errors[name] && <em className="errorMessage">{errors[name].message}</em>}
    </Form.Group>
  );
};
