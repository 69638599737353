import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { UPSERT_TRANSACTION_MATCHERS } from '../graphql/upsertTransactionMatchers.gql';
import { GET_TRANSACTION_MATCHERS } from '../graphql/getTransactionMatchers.gql';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'brandId',
    formLabel: 'Brand Id for matchers',
  },
  {
    name: 'matcher',
    formLabel: 'REGEX or MCC code to add',
  },
  {
    name: 'type',
    formLabel: 'Type of matcher',
    options: [
      { value: 'REGEX', label: 'REGEX' },
      { value: 'MCC', label: 'MCC' },
    ],
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Current Matchers',
    inputData: {
      id: new InputInjection([0, 'brandId']),
    },
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_TRANSACTION_MATCHERS,
    },
  },
  {
    name: 'Combine Regex Results with New Regex',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      currentMatchers: new InputInjection([1], (result: any) => {
        if (!result || !result.brand) {
          return;
        }
        return result.brand.rawMatchers.matchers;
      }),
      newMatcher: new InputInjection(
        [0],
        ({ matcher, type }: { matcher: string; type: string }) => {
          const pattern = {
            pattern: `${matcher ? matcher.trim() : ''}`,
            type: type === 'REGEX' ? 'REGEX' : 'MERCHANT_CATEGORY_CODE',
          };
          return type === 'REGEX' ? { ...pattern, options: 'i' } : pattern;
        },
      ),
    },
  },
  {
    name: 'Upsert New Transaction Matchers',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: UPSERT_TRANSACTION_MATCHERS,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ matcher, type }: { matcher: string; type: string }) => {
            if (!matcher || !type) {
              return;
            }
            return !!(type === 'MCC' && Number.isNaN(Number(matcher)));
          },
        ),
      },
      id: new InputInjection([0, 'brandId']),
      matchers: new InputInjection(
        [2],
        ({ currentMatchers, newMatcher }: any) => {
          if (!currentMatchers || !newMatcher) {
            return;
          }
          return [...currentMatchers, newMatcher];
        },
      ),
    },
  },
];

export const appendNewTransactionMatchersToExisting = {
  steps,
  inputValues,
  name: 'Append New Transaction Matchers To Existing',
  description:
    'Add additional regex or mcc code to existing transaction matchers',
  tags: [Tags.transactions],
};
