import { Specificity } from '@dosh/commons-node-matcher-generator';
import {
  CardNetwork,
  CardNetworkIdType,
  OfferNetwork,
  OfferLocationTypes,
  StoreLocationStatus,
  nonPlaceLevelCardNetworkIdType,
  VisaMerchantGroup,
  CNIdDenyList,
  YesOrNo,
  VisaActivationType,
} from './types';

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

const createOptionsFromEnums = (enums: any) => {
  return enumKeys(enums).map((key) => {
    return { value: enums[key], label: enums[key] };
  });
};

export const regexSpecificityOptions = createOptionsFromEnums(Specificity);

export const cardNetworkOptions = createOptionsFromEnums(CardNetwork);

export const cardNetworkIdTypeOptions =
  createOptionsFromEnums(CardNetworkIdType);

export const nonPlaceLevelCardNetworkIdTypeOptions = createOptionsFromEnums(
  nonPlaceLevelCardNetworkIdType,
);

export const storeLocationStatusOptions =
  createOptionsFromEnums(StoreLocationStatus);

export const offerLocationTypeOptions =
  createOptionsFromEnums(OfferLocationTypes);

export const offerNetworkOptions = createOptionsFromEnums(OfferNetwork);

export const visaMerchantGroups = createOptionsFromEnums(VisaMerchantGroup);

export const CNIdDenyListOptions = createOptionsFromEnums(CNIdDenyList);

export const yesOrNoOptions = createOptionsFromEnums(YesOrNo);

export const visaActivationTypeOptions =
  createOptionsFromEnums(VisaActivationType);

export const visaMerchantGroupOptions =
  createOptionsFromEnums(VisaMerchantGroup);
