import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { pathOr } from 'ramda';

import ImageNotFound from '@/assets/images/image-not-found.png';

interface WorkflowStepProps {
  name: string;
  active: boolean;
  onCompleteStep: Function;
  inputData: any;
}

interface BrandAsset {
  name: string;
  path: string;
  width: number;
  height: number;
}

export const ViewAssets: FunctionComponent<WorkflowStepProps> = ({
  active,
  onCompleteStep,
  inputData,
}) => {
  const [assets, setAssets] = useState([]) as any[];

  const nextStep = () => {
    onCompleteStep({ status: 'success' });
  };

  useEffect(() => {
    if (active && assets.length === 0) {
      const inputAssets = pathOr([], ['assets'], inputData);
      if (typeof inputAssets !== 'object') {
        return;
      }
      setAssets(
        inputAssets.map((asset) => ({
          name: pathOr('No type given', ['__typename'], asset),
          path: pathOr(ImageNotFound, ['path'], asset),
          width: pathOr(0, ['width'], asset),
          height: pathOr(0, ['height'], asset),
        })),
      );
    }
  }, [inputData]);

  return (
    <>
      <div className="workflowStep-text">
        <Row>
          {assets.map((asset: BrandAsset) => (
            <Col key={asset.name} className="workflowStep-asset-container">
              <p className="workflowStep-text-label">{asset.name}</p>
              <ul>
                <li>{`height = ${asset.height} pixels`}</li>
                <li>{`width = ${asset.width} pixels`}</li>
              </ul>
              <img
                src={asset.path}
                alt={asset.name}
                style={{ width: asset.width, height: asset.height }}
              />
            </Col>
          ))}
          {assets.length === 0 && <span>No assets found</span>}
        </Row>
      </div>
      {active && (
        <Row>
          <Button className="workflowButton" onClick={nextStep}>
            <span>Next Step</span>
          </Button>
        </Row>
      )}
    </>
  );
};
