import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { SET_BRAND_INFO } from '@/services/graphql/setBrandInfo.gql';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'brandId',
    formLabel: 'Brand Id',
  },
  {
    name: 'description',
    formLabel: 'New Description',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Update Brand Information -- Brand Service',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: SET_BRAND_INFO,
    },
    inputData: {
      brandId: new InputInjection([0, 'brandId']),
      info: {
        description: new InputInjection([0, 'description']),
      },
    },
  },
];

export const updateBrandDescription = {
  steps,
  inputValues,
  name: 'Update Brand Description',
  description: 'Update description that displays in app for brand',
  tags: [Tags.merchant, Tags.offers],
};
