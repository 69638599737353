import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { setCouponProperties } from '../lambdaFunctions/setCouponProperties';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'minimumSpendToActivate',
    formLabel: 'Minimum Spend to Activate Offer',
    formSubLabel: 'In dollars without special characters (ex: 15 = $15)',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Current Offer and Location Type',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT
              getuuid(o.offer_id) AS offer_uuid,
              LISTAGG(DISTINCT l.type, ' & ') WITHIN GROUP ( ORDER BY l.type DESC ) AS location_type
            FROM merchantdb.offers o
            JOIN merchantdb.campaigns c ON o.offer_id = c.offer_id
            JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
            JOIN merchantdb.coupon_segment cs on o.coupon_id = cs.coupon_id
            JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
            JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
            JOIN merchantdb.locations l on cl.location_id = l.location_id
            WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
              AND c.end_date > current_date
              AND cs.hide_offer_for_segment = 0
              AND o.deleted = 0
              AND (cs.end_date is null OR cs.end_date > current_date)
            GROUP BY 1
            ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Select Offer Uuid',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection([1], ({ records }: any) => {
        if (!records || !Array.isArray(records)) {
          return;
        }
        return records.map((record: any) => {
          return {
            id: `${record.offer_uuid}`,
            name: `OFFER UUID: ${
              record.offer_uuid
            } | LOCATION TYPE: ${record.location_type.toUpperCase()}`,
          };
        });
      }),
    },
  },
  {
    name: 'Update Minimum Spend to Activate',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: setCouponProperties.submit,
    },
    inputData: {
      offerUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
      minimumSpendToActivate: new InputInjection(
        [0, 'minimumSpendToActivate'],
        parseInt,
      ),
    },
  },
];

export const updateMinSpendToActivate = {
  steps,
  inputValues,
  name: 'Update Minimum Spend to Activate',
  description: 'Sets the minimum a user must spend to activate an offer',
  tags: [Tags.offers],
};
