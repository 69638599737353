import gql from 'graphql-tag';

export const GET_PLACE_MATCHERS = gql`
  query brand($id: ID!) {
    brand(id: $id) {
      rawPlaceMatchers {
        matchers
      }
    }
  }
`;
