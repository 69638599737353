import * as mcc from 'mcc';
import { MccDescriptions } from './types';

const getMccDescription = (mccCode: string): string => {
  const mccDesc: MccDescriptions = mcc.get(mccCode);
  let mccDisplayValue;
  if (!mccDesc) {
    mccDisplayValue = 'unknown';
  } else if (mccDesc.irs_description !== '') {
    mccDisplayValue = mccDesc.irs_description;
  } else {
    mccDisplayValue = mccDesc.edited_description;
  }
  return mccDisplayValue;
};

export { getMccDescription };
