/*
  Array type input requires some work-arounds.
  react-hook-form useFieldArray requires a default value and
  the value must be an array of objects.

  The work around is to store text-array types as an array of objects
  with only one key named "value"

  To make this possible, in the WorkflowFormWithDefault component we construct
  the default for array fields with [{ value: ""}]
  Then during form submission we pass the data through the flattenFormArray function
*/

import React, { FunctionComponent, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import unflatten from 'unflatten';

import { TextField, TextFieldArray } from '@/components/TextField';
import { FileField } from '@/components/FileField';
import { SelectField } from '@/components/SelectField';
import addLocalQueryString from '@/utils/addLocalQueryString';
import getErrorMessage from '@/utils/error';

export enum InputFieldTypes {
  TEXT_ARRAY = 'TEXT_ARRAY',
  TEXT = 'TEXT',
  FILE = 'FILE',
  SELECT = 'SELECT',
}

interface SelectOption {
  label: string;
  value: string;
}

export interface InputFieldConfigs {
  name: string;
  formLabel: string;
  default?: any;
  col?: number; // based on a 12 col grid
  formSubLabel?: string;
  type?: InputFieldTypes;
  options?: SelectOption[];
}

interface WorkflowFormProps {
  inputFieldConfigs: InputFieldConfigs[];
  onSubmit: Function;
  formateRequestPayload?: Function;
  setResponse: Function;
  defaultValues?: any;
  storeInput?: boolean;
  submitButtonText?: string;
  disabled?: boolean;
}

// hack for working with react-hook-form useFieldArray
// A form with an array field needs a default array of objects
const flattenFormArray = (formData: any) => {
  const override: any = {};
  Object.keys(formData).forEach(async (key) => {
    if (Array.isArray(formData[key])) {
      override[key] = formData[key].map((field: any) => field.value);
    }
  });
  return { ...formData, ...override };
};

/**
 * unflattens nested inputs, using period as delimiter
 *
 * for example:
 * ```
 * unflattenNestedFormInputs({ 'a.b.c' : 1 }) => { 'a': { 'b': { 'c': 1 } } }
 * ```
 */
const unflattenNestedFormInputs = (formData: any) => unflatten(formData);

const WorkflowForm: FunctionComponent<WorkflowFormProps> = ({
  inputFieldConfigs,
  onSubmit,
  formateRequestPayload = (props: any) => props,
  setResponse,
  defaultValues = {},
  storeInput = false,
  submitButtonText = 'Submit',
  disabled = false,
}) => {
  const defaults: any = {};

  inputFieldConfigs.forEach((field) => {
    if (field.type === InputFieldTypes.TEXT_ARRAY) {
      // hack for working with react-hook-form useFieldArray
      // A form with an array field needs a default array of objects
      defaults[field.name] = [{ value: '' }];
    }
    if (field.default !== undefined) {
      defaults[field.name] = field.default;
    }
    if (defaultValues[field.name] !== undefined) {
      if (defaultValues[field.name]) {
        defaults[field.name] = defaultValues[field.name];
      } else {
        console.error(
          `setting default value failed for ${field.name}`,
          defaultValues,
        );
      }
    }
  });

  return (
    <WorkflowFormWithDefault
      inputFieldConfigs={inputFieldConfigs}
      onSubmit={onSubmit}
      formateRequestPayload={formateRequestPayload}
      setResponse={setResponse}
      defaultValues={defaults}
      storeInput={storeInput}
      submitButtonText={submitButtonText}
      disabled={disabled}
    />
  );
};

const WorkflowFormWithDefault: FunctionComponent<WorkflowFormProps> = ({
  inputFieldConfigs,
  onSubmit,
  formateRequestPayload = (props: any) => props,
  setResponse,
  defaultValues = {},
  storeInput = false,
  submitButtonText = 'Submit',
  disabled = false,
}) => {
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const { control, register, errors, handleSubmit, reset, setValue } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const clearForm = () => {
    window.history.pushState(null, '', window.location.pathname);
    reset();
  };

  const submitForm = async (data: any) => {
    setShowSpinner(true);
    if (storeInput) {
      addLocalQueryString(data);
    }
    try {
      const res = await onSubmit(
        formateRequestPayload(
          unflattenNestedFormInputs(flattenFormArray(data)),
        ),
      );
      setResponse(res);
    } catch (error) {
      setResponse({ ERROR: getErrorMessage(error) });
    }
    setShowSpinner(false);
  };

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <Row>
        {inputFieldConfigs.map((inputConfig, idx) => (
          <Col xs="12" lg={inputConfig.col || 12} key={inputConfig.name}>
            <Form.Group controlId={inputConfig.name} key={inputConfig.name}>
              {inputConfig.type === InputFieldTypes.TEXT_ARRAY && (
                <TextFieldArray
                  tabIndex={idx + 1}
                  control={control}
                  path={inputConfig.name}
                  className="formInput"
                  formLabel={inputConfig.formLabel}
                  subLabel={inputConfig.formSubLabel}
                  errors={errors}
                  register={register}
                  disabled={disabled}
                />
              )}
              {inputConfig.type === InputFieldTypes.FILE && (
                <FileField
                  tabIndex={idx + 1}
                  path={inputConfig.name}
                  formLabel={inputConfig.formLabel}
                  subLabel={inputConfig.formSubLabel}
                  errors={errors}
                  register={register}
                  disabled={disabled}
                  setValue={setValue}
                />
              )}
              {inputConfig.type === InputFieldTypes.SELECT && (
                <SelectField
                  tabIndex={idx + 1}
                  path={inputConfig.name}
                  formLabel={inputConfig.formLabel}
                  subLabel={inputConfig.formSubLabel}
                  options={inputConfig.options}
                  errors={errors}
                  register={register}
                  disabled={disabled}
                />
              )}
              {(inputConfig.type === InputFieldTypes.TEXT ||
                !inputConfig.type) && (
                <TextField
                  tabIndex={idx + 1}
                  path={inputConfig.name}
                  className="formInput"
                  formLabel={inputConfig.formLabel}
                  subLabel={inputConfig.formSubLabel}
                  errors={errors}
                  register={register}
                  disabled={disabled}
                />
              )}
            </Form.Group>
          </Col>
        ))}
      </Row>

      <Button disabled={disabled} variant="primary" type="submit">
        {showSpinner && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden
          />
        )}
        {!showSpinner && <span>{submitButtonText}</span>}
      </Button>
      <Button
        className="ml-4"
        disabled={disabled}
        onClick={clearForm}
        variant="secondary"
      >
        <span>Clear Form</span>
      </Button>
    </Form>
  );
};

export default WorkflowForm;
