import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'groupId',
    formLabel: 'Group ID',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'marketplace-user-groups-svc',
  functionName: 'userGroupGetNameById',
  method: HttpMethod.GET,
});

export const userGroupGetNameById = {
  name: 'User Group Get Name By ID',
  description: 'Retrieve name for MUG by group ID',
  inputValues,
  outputValueName: 'Results',
  tags: [Tags.mugs],
  submit,
};
