import React, { FunctionComponent } from 'react';

const NotFound: FunctionComponent = (): JSX.Element => {
  return (
    <>
      <h1>404</h1>
      <p>Page not found :(</p>
    </>
  );
};

export default NotFound;
