export const csvToJson = (csv: string) => {
  const lines = csv.split('\n');
  const headers = lines[0].replace(/[\s+\r]/g, '').split(',');

  return lines
    .map((line, index) => {
      if (index === 0) {
        return;
      }
      const row = line.split(',');
      let validatedRow: (string | null)[] = row;
      let quoteIndex: number[] = [];
      if (row.length > headers.length) {
        validatedRow = row
          .map((column, i) => {
            if (column.startsWith('"')) {
              quoteIndex.push(i);
              return 'removeVal';
            }
            if (column.endsWith('"') && quoteIndex.length) {
              quoteIndex.push(i);
              const strArray = quoteIndex.map((val) => {
                return row[val];
              });
              quoteIndex = [];
              return strArray.join(' ').replace(/"/g, '');
            }
            return column;
          })
          .filter((str) => str !== 'removeVal');
      }
      const newObject: any = {};
      headers.forEach((column, idx) => {
        newObject[column] = validatedRow[idx];
      });
      return newObject;
    })
    .filter((obj) => !!obj);
};
