import { WorkflowStepTypes } from '@/components/Workflow';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import {
  CardNetwork,
  CardNetworkIdType,
  MatchBasis,
  Tags,
} from '@/helpers/types';
import InputInjection from '@/utils/workflow/InputInjection';
import { addManualONVenueToCNIdMapping } from '../lambdaFunctions/addManualONVenueToCNIdMapping';
import { findOrCreateBrandVenueForMerchant } from '../lambdaFunctions/findOrCreateBrandVenueForMerchant';
import { getMerchantByName } from '../lambdaFunctions/getMerchantByName';
import { onboardVenueToCardNetworks } from '../lambdaFunctions/onboardVenueToCardNetworks';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'VMID',
    formLabel: 'VMID',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Merchant Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getMerchantByName.submit,
    },
    inputData: {
      merchantName: new InputInjection([0, 'merchantName']),
    },
  },
  {
    name: 'Find or Create Default Location',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: findOrCreateBrandVenueForMerchant.submit,
    },
    inputData: {
      merchantId: new InputInjection([1, 'merchant_uuid']),
    },
  },
  {
    name: 'Register ONVenue (Skip if there is an existing venue)',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: onboardVenueToCardNetworks.submit,
    },
    inputData: {
      metadata: {
        autoSkip: new InputInjection(
          [2],
          ({
            defaultBrandVenue,
            id,
          }: {
            defaultBrandVenue: string;
            id: number;
          }) => {
            if ([defaultBrandVenue][id] !== null) {
              return true;
            }
            return false;
          },
        ),
      },
      locationId: new InputInjection([2, 'defaultBrandVenue', 'id']),
    },
  },
  {
    name: 'Add Aggregate VMID Mapping',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addManualONVenueToCNIdMapping.submit,
    },
    inputData: {
      onvenueUuid: new InputInjection([3, 'venue', 'venueUuid']),
      cardNetwork: CardNetwork.VISA,
      cardNetworkExternalIdType: CardNetworkIdType.VMID,
      cardNetworkExternalId: new InputInjection([0, 'VMID']),
      basis: MatchBasis.AGGREGATE_MATCH,
    },
  },
];

export const addAggregateVMIDMapping = {
  steps,
  inputValues,
  name: 'Add Aggregate VMID Mapping',
  description:
    'Add Aggregate VMID Mapping for a Location -- IMPORTANT: Only for all nearby locations',
  tags: [Tags.cnids, Tags.newMerchantOnboarding],
};
