import React, { FocusEvent } from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import is from 'is_js';
import validate from '@/utils/validator';

import './SelectField.css';

export const SelectField = ({
  register,
  path,
  validationFn,
  onBlur,
  errors,
  formLabel,
  disabled,
  subLabel = '',
  type = 'text',
  className,
  options,
  tabIndex,
}: {
  register: Function;
  path: string;
  validationFn?: Function;
  onBlur?: (e: FocusEvent) => void;
  formLabel?: string;
  className?: string;
  errors?: any;
  disabled?: boolean;
  subLabel?: string;
  type?: string;
  options: any;
  tabIndex?: number;
}): JSX.Element => (
  <div
    className={classNames(className, {
      'form-group': is.edge(),
      'form-label-group': !is.edge(),
    })}
  >
    {formLabel && (
      <Form.Label>
        {formLabel}
        {subLabel && <p className="formLabelSub">{subLabel}</p>}
      </Form.Label>
    )}
    <Form.Control
      as="select"
      custom
      ref={register({ validate: validationFn || validate.notRequired })}
      name={path}
      className="form-control form_input"
      disabled={disabled}
      onBlur={onBlur}
      type={type}
      tabIndex={tabIndex}
    >
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Control>
    {errors && errors[path] ? (
      <em className="errorMessage">{errors[path].message}</em>
    ) : null}
  </div>
);
