import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags, Venue } from '@/helpers/types';
import { getVenueByUuid } from '../lambdaFunctions/getVenueByUuid';
import { updateVenueProperties } from '../lambdaFunctions/updateVenueProperties';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'onvenueUuid',
    formLabel: 'ONVenue Uuid',
  },
  {
    name: 'updatedBy',
    formLabel: 'Updated By',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get ONVenue Information',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueByUuid.submit,
    },
    inputData: {
      venueUuid: new InputInjection([0, 'onvenueUuid']),
    },
  },
  {
    name: 'Select ONVenue to Deactivate',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({ result, status }: { result: Venue; status: string }) => {
          if (status !== 'success') {
            return;
          }
          return [
            {
              id: result.uuid,
              name: `${result.uuid}, ${result.name}, ${result.locationType}, ${result.address.address1}, ${result.address.city}, ${result.address.state} ${result.address.postal}`,
            },
          ];
        },
      ),
    },
  },
  {
    name: 'Get Merchant Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          onvenueUuid,
        }: {
          onvenueUuid: string;
        }) => `SELECT getuuid(l.merchant_id) as merchant_uuid
        FROM merchantdb.locations l
        JOIN merchantdb.source_locations sl on l.location_id = sl.location_id
        WHERE sl.source_location_id = '${onvenueUuid}';`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Update ONVenue Status',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: updateVenueProperties.submit,
    },
    inputData: {
      forMerchant: {
        uuid: new InputInjection(
          [3],
          ({ records }: { records: { merchant_uuid: string }[] }) => {
            if (!Array.isArray(records)) {
              return;
            }
            return records[0].merchant_uuid;
          },
        ),
      },
      forVenues: [
        {
          venueUuid: new InputInjection([2], (result: Record<string, any>) => {
            if (!result || typeof result === 'string') {
              return;
            }
            return getArrayFromCheckboxSelections(result)[0];
          }),
        },
      ],
      set: {
        status: 'inactive',
      },
      updatedBy: new InputInjection([0, 'updatedBy']),
    },
  },
];

export const handleInvalidONVenue = {
  steps,
  inputValues,
  name: 'Handle Invalid ONVenues',
  description: 'Handle invalid locations for Dosh and PB',
  tags: [Tags.venues],
};
