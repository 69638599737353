import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
] as InputFieldConfigs[];
const steps = [
  {
    name: 'Retrieve MCC Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT getuuid(o.offer_id) as offer_id, csm.marketplace as segment_marketplace, cs.priority, st.type as offer_scope_type, omt.type as marketplace_type, mcc.code as mcc_code, mcc.description as mcc_description
          FROM merchantdb.offers o
          JOIN merchantdb.offer_scope_type st on o.offer_scope_type_id = st.offer_scope_type_id
          JOIN merchantdb.images i on o.header_image_id = i.image_id
          JOIN merchantdb.offer_marketplace_type omt on o.offer_marketplace_type_id = omt.offer_marketplace_type_id
          JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
          JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
          JOIN merchantdb.coupon_segment cs on o.coupon_id = cs.coupon_id
          JOIN merchantdb.coupon_segment_marketplace csm on cs.coupon_segment_id = csm.coupon_segment_id
          JOIN merchantdb.coupon_segment_type cst on cs.coupon_segment_type_id = cst.coupon_segment_type_id
          JOIN merchantdb.coupon_segment_targeting_rule cstr on cs.coupon_segment_id = cstr.coupon_segment_id
          JOIN merchantdb.coupon_segment_targeting_rule_type cstrt on cstr.coupon_segment_targeting_rule_type_id = cstrt.coupon_segment_targeting_rule_type_id
          JOIN merchantdb.coupon_segment_targeting_rule_data cstrd on cstr.coupon_segment_targeting_rule_id = cstrd.coupon_segment_targeting_rule_id
          JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.offer_payment_network_mcc opnmcc on opnmcc.offer_id = o.offer_id
          JOIN merchantdb.payment_network_mcc mcc on mcc.id = opnmcc.payment_network_mcc_id
          WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
          AND cst.type = 'BASE'
          AND c.end_date > current_date
          ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      results: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            offer_id: string;
            segment_marketplace: string;
            priority: number;
            offer_scope_type: string;
            marketplace_type: string;
            mcc_code: string;
            mcc_description: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            const obj: any = record;
            obj.expected_priority = record.priority === -15;
            obj.expected_segment_marketplace =
              record.segment_marketplace === 'VENMO';
            obj.expected_offer_scope_type =
              record.offer_scope_type === 'MARKETPLACE';
            obj.expected_marketplace_type = record.marketplace_type === 'VENMO';
            return obj;
          });
        },
      ),
    },
  },
];
export const mccQASession = {
  steps,
  inputValues,
  name: 'QA MCC Code Merchant',
  description: 'QA Session MCC Code Marketplace',
  tags: [Tags.qa, Tags.specialMerchants],
};
