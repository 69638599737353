import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { userGroupGetIdByName } from '../lambdaFunctions/userGroupGetIdByName';
import { Tags } from '@/helpers/types';
import { userGroupChangeMembership } from '../lambdaFunctions/userGroupChangeMembership';
import { userGroupCreate } from '../lambdaFunctions/userGroupCreate';
import { redshiftEscape } from '@/utils/strings';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'cognitoId',
    formLabel: 'Cognito Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Merchant uuid from merchant name',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
        }: {
          merchantName: string;
        }) => `SELECT getuuid(m.merchant_id) as merchantId, m.name as merchantName
        FROM merchantdb.merchants m
        WHERE m.name ilike '%${redshiftEscape(merchantName)}%';`,
      ),
    },
  },
  {
    name: 'Select merchant from checkbox',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection([1], ({ records }: { records: any[] }) => {
        if (!Array.isArray(records)) {
          return;
        }
        return records.map((record) => {
          return {
            name: record.merchantname,
            id: record.merchantid,
          };
        });
      }),
    },
  },
  {
    name: 'Check for existing merchant level MUG',
    autorun: true,
    inputData: {
      name: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        const selections = getArrayFromCheckboxSelections(result);
        return `mcg-${selections[0]}_nearby_online`;
      }),
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: userGroupGetIdByName.submit,
    },
  },
  {
    name: 'Create MUG',
    autorun: false,
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [3],
          ({ groupId }: { groupId: string }) => {
            return Boolean(groupId);
          },
        ),
      },
      name: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        const selections = getArrayFromCheckboxSelections(result);
        return `mcg-${selections[0]}_nearby_online`;
      }),
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: userGroupCreate.submit,
    },
  },
  {
    name: 'Preprocess data to combine cognitoId, existing groupId, and new groupId',
    autorun: false,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cognitoId: new InputInjection([0, 'cognitoId']),
      existingGroupId: new InputInjection(
        [3],
        ({ groupId }: { groupId: string }) => {
          if (!groupId || groupId === '') {
            return;
          }
          return groupId;
        },
      ),
      newGroupId: new InputInjection(
        [4],
        ({ groupId }: { groupId: string }) => {
          if (!groupId || groupId === '') {
            return;
          }
          return groupId;
        },
      ),
    },
  },
  {
    name: 'Add user to new MUG',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: userGroupChangeMembership.submit,
    },
    inputData: {
      groupId: new InputInjection(
        [5],
        ({
          existingGroupId,
          newGroupId,
        }: {
          existingGroupId: string;
          newGroupId: string;
        }) => {
          if (!existingGroupId && !newGroupId) {
            return;
          }
          return existingGroupId || newGroupId;
        },
      ),
      userId: new InputInjection([5, 'cognitoId']),
      active: true,
    },
  },
];

export const hideMerchantOffersFromUser = {
  steps,
  inputValues,
  name: 'Hide Merchant Offers from User',
  description: 'Hide offers from user to prevent abuse of terms',
  tags: [Tags.csResources, Tags.merchant, Tags.mugs, Tags.offers],
};
