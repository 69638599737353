import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'transactionId',
    formLabel: 'Transaction ID',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'card-transaction-service',
  functionName: 'ctesGetByTransactionId',
  method: HttpMethod.POST,
});

export const ctesGetByTransactionId = {
  name: 'Get CTEs By Transaction ID',
  inputValues,
  tags: [Tags.transactions],
  submit,
};
