import { Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  SummaryDimension,
  WithdrawalControlSummaryByDimension,
} from '@/scenes/MarketplaceControls/components/WithdrawalControlPanel/helpers/summary';
import {
  WithdrawalControlAction,
  WithdrawalControlStatus,
} from '@/services/lambdaFunctions/getWithdrawalControls';

import './WithdrawalControlsSummary.css';
import { StatusIndicator } from '@/scenes/MarketplaceControls/components/WithdrawalControlPanel/StatusIndicator';
import { UpdateWithdrawalControlWithdrawalAction } from '@/services/lambdaFunctions/updateWithdrawalControls';
import Button from '@/components/Button';

interface UpdateRequest {
  targetStatus: WithdrawalControlStatus;
  action: WithdrawalControlAction;
  withdrawalQualifier: string;
  marketplace: string;
}

const ConfirmDialog = ({
  show,
  loading,
  handleModalAction,
  updateRequest,
}: {
  show: boolean;
  loading: boolean;
  handleModalAction: (confirmed: boolean) => void;
  updateRequest: UpdateRequest | null;
}) => {
  const confirm = () => handleModalAction(true);
  const cancel = () => handleModalAction(false);

  return (
    <>
      {updateRequest && (
        <Modal show={show} backdrop="static" onHide={cancel}>
          <Modal.Header closeButton={!loading}>
            <Modal.Title>Confirm change</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              <ListGroup.Item>{`Target status: ${updateRequest.targetStatus}`}</ListGroup.Item>
              <ListGroup.Item>{`Action: ${updateRequest.action}`}</ListGroup.Item>
              <ListGroup.Item>{`Withdrawal Qualifier: ${updateRequest.withdrawalQualifier}`}</ListGroup.Item>
              <ListGroup.Item>{`Marketplace: ${updateRequest.marketplace}`}</ListGroup.Item>
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={loading} variant="secondary" onClick={cancel}>
              Cancel
            </Button>
            <Button loading={loading} variant="primary" onClick={confirm}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const getParamsFromDimension = ({
  dimensionType,
  dimensionValue,
  subDimensionType,
  subDimensionValue,
}: {
  dimensionType: SummaryDimension;
  dimensionValue: string;
  subDimensionType: SummaryDimension;
  subDimensionValue: string;
}) => {
  let withdrawalQualifier = '';
  let marketplace = '';

  // do sub dimension first so dimension can override it (they shouldn't really overlap though)
  switch (subDimensionType) {
    case SummaryDimension.WITHDRAWAL_QUALIFIER:
      withdrawalQualifier = subDimensionValue;
      break;
    case SummaryDimension.MARKETPLACE:
      marketplace = subDimensionValue;
      break;
    default:
      break;
  }

  switch (dimensionType) {
    case SummaryDimension.WITHDRAWAL_QUALIFIER:
      withdrawalQualifier = dimensionValue;
      break;
    case SummaryDimension.MARKETPLACE:
      marketplace = dimensionValue;
      break;
    default:
      break;
  }

  return { withdrawalQualifier, marketplace };
};

const onStatusSwitchChange = ({
  updateRequest,
  setUpdateRequest,
  setShow,
  enable,
  action,
  dimensionType,
  dimensionValue,
  subDimensionType,
  subDimensionValue,
}: {
  updateRequest: any;
  setUpdateRequest: Dispatch<SetStateAction<UpdateRequest | null>>;
  setShow: Dispatch<SetStateAction<boolean>>;
  enable: boolean;
  action: WithdrawalControlAction;
  dimensionType: SummaryDimension;
  dimensionValue: string;
  subDimensionType: SummaryDimension;
  subDimensionValue: string;
}) => {
  if (!updateRequest) {
    const targetStatus = enable
      ? WithdrawalControlStatus.ENABLED
      : WithdrawalControlStatus.DISABLED;

    const { withdrawalQualifier, marketplace } = getParamsFromDimension({
      dimensionType,
      dimensionValue,
      subDimensionType,
      subDimensionValue,
    });

    setUpdateRequest({
      targetStatus,
      action,
      withdrawalQualifier,
      marketplace,
    });
    setShow(true);
  }
};

interface UpdateWithdrawalControlParams {
  targetStatus: WithdrawalControlStatus;
  withdrawalAction: UpdateWithdrawalControlWithdrawalAction;
  withdrawalQualifier: string;
  marketplace: string;
}

export const WithdrawalControlSummary = ({
  summary,
  updateWithdrawalControl,
}: {
  summary: Record<string, WithdrawalControlSummaryByDimension>;
  updateWithdrawalControl: (u: UpdateWithdrawalControlParams) => void;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const [updateRequest, setUpdateRequest] = useState<UpdateRequest | null>(
    null,
  );

  const handleModalAction = async (confirmed: boolean) => {
    if (!confirmed) {
      // modal not confirmed so clear update request and hide modal
      setUpdateRequest(null);
      setShow(false);
      return;
    }

    if (!updateRequest) {
      console.error(
        'Invalid logic: there should be an updateRequest if the modal was shown',
      );
      return;
    }

    setUpdateInProgress(true);

    // modal confirmed so attempt to update withdrawal control
    await updateWithdrawalControl({
      targetStatus: updateRequest.targetStatus,
      withdrawalAction:
        UpdateWithdrawalControlWithdrawalAction[updateRequest.action],
      withdrawalQualifier: updateRequest.withdrawalQualifier,
      marketplace: updateRequest.marketplace,
    });

    // regardless of the result we can clear update request and hide modal
    setUpdateRequest(null);
    setShow(false);
    setUpdateInProgress(false);
  };

  return (
    <>
      <ConfirmDialog
        show={show}
        loading={updateInProgress}
        handleModalAction={handleModalAction}
        updateRequest={updateRequest}
      />
      <Row className="justify-content-md-center">
        {Object.values(summary).map((dimensionSummary) => (
          <Col
            md="8"
            xs="12"
            key={dimensionSummary.dimensionValue}
            className="withdrawalControl card"
          >
            <Row>
              <Col xs="auto">
                <span style={{ marginLeft: '1em' }}>
                  {dimensionSummary.dimensionValue}
                </span>
              </Col>
              <Col xs="1">
                <StatusIndicator status={dimensionSummary.status} />
              </Col>
            </Row>
            <Row style={{ marginTop: '1em' }}>
              <Col xs="12">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{dimensionSummary.subDimensionName}</th>
                      <th>Initiate</th>
                      <th>Execute</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(dimensionSummary.summaryBySubDimension).map(
                      (subDimensionSummary) => (
                        <tr key={subDimensionSummary.subDimensionValue}>
                          <td>{subDimensionSummary.subDimensionValue}</td>
                          {Object.values(WithdrawalControlAction).map(
                            (action) => (
                              <td key={action}>
                                <Form.Check
                                  type="switch"
                                  id={`${dimensionSummary.dimensionValue}-${subDimensionSummary.subDimensionValue}-${action}-switch`}
                                  checked={
                                    subDimensionSummary.statusByAction[
                                      action
                                    ] === WithdrawalControlStatus.ENABLED
                                  }
                                  label=""
                                  onChange={(event: any) => {
                                    onStatusSwitchChange({
                                      updateRequest,
                                      setUpdateRequest,
                                      setShow,
                                      enable: !!event.target.checked,
                                      action,
                                      dimensionType:
                                        dimensionSummary.dimensionType,
                                      dimensionValue:
                                        dimensionSummary.dimensionValue,
                                      subDimensionType:
                                        subDimensionSummary.subDimensionType,
                                      subDimensionValue:
                                        subDimensionSummary.subDimensionValue,
                                    });
                                  }}
                                />
                              </td>
                            ),
                          )}
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </>
  );
};
