import {
  WithdrawalControl,
  WithdrawalControlAction,
  WithdrawalControlInitiator,
  WithdrawalControlStatus,
} from '@/services/lambdaFunctions/getWithdrawalControls';

export enum SummaryStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  PARTIALLY_DISABLED = 'PARTIALLY_DISABLED',
}

export enum SummaryDimension {
  WITHDRAWAL_QUALIFIER = 'WITHDRAWAL_QUALIFIER',
  MARKETPLACE = 'MARKETPLACE',
}

export interface WithdrawalControlSummaryByDimension {
  dimensionType: SummaryDimension;
  dimensionName: string;
  dimensionValue: string;
  status: SummaryStatus;
  subDimensionName: string;
  summaryBySubDimension: Record<
    string,
    {
      subDimensionType: SummaryDimension;
      subDimensionName: string;
      subDimensionValue: string;
      statusByAction: Record<WithdrawalControlAction, WithdrawalControlStatus>;
    }
  >;
}

const dimensionToDetailsMap: Record<
  SummaryDimension,
  {
    name: string;
    key: keyof WithdrawalControl;
    type: SummaryDimension;
  }
> = {
  [SummaryDimension.WITHDRAWAL_QUALIFIER]: {
    name: 'Withdrawal Qualifier',
    key: 'withdrawalQualifier',
    type: SummaryDimension.WITHDRAWAL_QUALIFIER,
  },
  [SummaryDimension.MARKETPLACE]: {
    name: 'Marketplace',
    key: 'marketplace',
    type: SummaryDimension.MARKETPLACE,
  },
};

const buildSummaryByDimension = ({
  withdrawalControls,
  dimension,
  subDimension,
}: {
  withdrawalControls: WithdrawalControl[];
  dimension: SummaryDimension;
  subDimension: SummaryDimension;
}): Record<string, WithdrawalControlSummaryByDimension> => {
  const summaryByDimension: Record<
    string,
    WithdrawalControlSummaryByDimension
  > = {};

  const dimensionDetails = dimensionToDetailsMap[dimension];
  const subDimensionDetails = dimensionToDetailsMap[subDimension];

  for (let i = 0; i < withdrawalControls.length; i += 1) {
    const withdrawalControl = withdrawalControls[i];

    const dimensionValue = withdrawalControl[dimensionDetails.key];
    const subDimensionValue = withdrawalControl[subDimensionDetails.key];

    const { action, status } = withdrawalControl;

    // create summary for dimension if it doesn't exist
    summaryByDimension[dimensionValue] = summaryByDimension[dimensionValue] || {
      dimensionType: dimensionDetails.type,
      dimensionName: dimensionDetails.name,
      dimensionValue,
      status: SummaryStatus.DISABLED,
      subDimensionName: subDimensionDetails.name,
      summaryBySubDimension: {},
    };

    // get summary for dimension
    const summary = summaryByDimension[dimensionValue];

    // create summary for subDimension if it does not exist
    summary.summaryBySubDimension[subDimensionValue] = summary
      .summaryBySubDimension[subDimensionValue] || {
      subDimensionType: subDimensionDetails.type,
      subDimensionName: subDimensionDetails.name,
      subDimensionValue,
      statusByAction: {},
    };

    // get summary for subDimension
    const subDimensionSummary =
      summary.summaryBySubDimension[subDimensionValue];

    // set status for action
    subDimensionSummary.statusByAction[action] = status;
  }

  // update summary statuses
  Object.values(summaryByDimension).forEach((_summary) => {
    const summary = _summary;

    const statusCounts = Object.values(summary.summaryBySubDimension).reduce(
      (
        _counts: { enabledCount: number; disabledCount: number },
        subDimensionSummary,
      ) => {
        const counts = _counts;

        Object.values(subDimensionSummary.statusByAction).forEach((status) => {
          if (status === WithdrawalControlStatus.ENABLED) {
            counts.enabledCount++;
          } else if (status === WithdrawalControlStatus.DISABLED) {
            counts.disabledCount++;
          }
        });

        return counts;
      },
      { enabledCount: 0, disabledCount: 0 },
    );

    if (statusCounts.enabledCount > 0 && statusCounts.disabledCount === 0) {
      summary.status = SummaryStatus.ENABLED;
    } else if (
      statusCounts.enabledCount === 0 &&
      statusCounts.disabledCount > 0
    ) {
      summary.status = SummaryStatus.DISABLED;
    } else if (
      statusCounts.enabledCount > 0 &&
      statusCounts.disabledCount > 0
    ) {
      summary.status = SummaryStatus.PARTIALLY_DISABLED;
    }
  });

  return summaryByDimension;
};

export const buildWithdrawalControlSummaries = ({
  withdrawalControls: inWithdrawalControls,
}: {
  withdrawalControls: WithdrawalControl[];
}): Record<
  SummaryDimension,
  Record<string, WithdrawalControlSummaryByDimension>
> => {
  const withdrawalControls = inWithdrawalControls.filter(
    (wc) =>
      wc.initiatorCreatedBy !== WithdrawalControlInitiator.ACCEPTANCE_TEST,
  );

  return {
    [SummaryDimension.WITHDRAWAL_QUALIFIER]: buildSummaryByDimension({
      withdrawalControls,
      dimension: SummaryDimension.WITHDRAWAL_QUALIFIER,
      subDimension: SummaryDimension.MARKETPLACE,
    }),
    [SummaryDimension.MARKETPLACE]: buildSummaryByDimension({
      withdrawalControls,
      dimension: SummaryDimension.MARKETPLACE,
      subDimension: SummaryDimension.WITHDRAWAL_QUALIFIER,
    }),
  };
};
