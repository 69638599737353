import React, { FunctionComponent } from 'react';
import { Icon } from 'react-icons-kit';
import { buttonCheck } from 'react-icons-kit/metrize/buttonCheck';
import { cross } from 'react-icons-kit/metrize/cross';
import { threePoints } from 'react-icons-kit/metrize/threePoints';
import { snow } from 'react-icons-kit/metrize/snow';
import { arrowDownBold } from 'react-icons-kit/metrize/arrowDownBold';

export enum WorkflowStepStatusTypes {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  SKIPPED = 'SKIPPED',
  RUNNING = 'RUNNING',
}

interface WorkflowStepStatusProps {
  status: WorkflowStepStatusTypes;
}

const iconMap = {
  [WorkflowStepStatusTypes.ACTIVE]: arrowDownBold,
  [WorkflowStepStatusTypes.INACTIVE]: threePoints,
  [WorkflowStepStatusTypes.SUCCESS]: buttonCheck,
  [WorkflowStepStatusTypes.FAILED]: cross,
  [WorkflowStepStatusTypes.SKIPPED]: threePoints,
  [WorkflowStepStatusTypes.RUNNING]: snow,
};

const WorkflowStepStatus: FunctionComponent<WorkflowStepStatusProps> = ({
  status,
}) => {
  return (
    <Icon
      className={`workflowStepIcon-${status}`}
      icon={iconMap[status]}
      size={32}
    />
  );
};

export default WorkflowStepStatus;
