// show errors

import React, { FunctionComponent, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from 'react-icons-kit';
import { iosUploadOutline } from 'react-icons-kit/ionicons/iosUploadOutline';
import { useForm } from 'react-hook-form';
import { pathOr, propOr } from 'ramda';

import ImagePlaceholder from '@/assets/images/image-placeholder.png';

import {
  AssetBundle,
  AssetName,
  CLOUDINARY_WIDGET_OPTIONS,
  loadCloudinary,
} from './UploadWidget.helpers';

import './UploadWidget.css';

interface Props {
  name: string;
  label: string;
  merchantName: string;
  assetBundle: AssetBundle;
  assetName: AssetName;
  register: any;
  defaultImage?: string;
  size?: string;
  tabIndex?: number;
}

const onUploadClick =
  (
    brandId: string,
    assetBundle: AssetBundle,
    assetName: AssetName,
    setImage: Function,
    setImgInfo: Function,
    handleSetError: Function,
    handleClearErrors: Function,
  ) =>
  () => {
    if (!brandId) {
      handleSetError('Merchant name can not be blank!');
    } else {
      handleClearErrors();
    }

    if (brandId && assetName) {
      const brandFolder = brandId.replace(/[^0-9a-z]/gi, '').toUpperCase();

      // @ts-ignore
      window.cloudinary.openUploadWidget(
        {
          ...CLOUDINARY_WIDGET_OPTIONS,
          folder: `brand assets/${brandFolder}/${assetBundle}/${assetName}`,
          publicId: Date.now(),
        },
        (err: any, info: any) => {
          if (!err && info.event === 'success') {
            setImage(pathOr('', ['info', 'secure_url'], info));
            setImgInfo(JSON.stringify(propOr({}, 'info', info)));
          }
        },
      );
    }
  };

const UploadWidget: FunctionComponent<Props> = ({
  label,
  name,
  assetName,
  assetBundle,
  merchantName,
  defaultImage,
  register,
}) => {
  const [imageUrl, setImageUrl] = useState<string>('');
  const [imageInfo, setImgInfo] = useState<string>('');
  const { errors, setError, clearErrors } = useForm();

  useEffect(() => {
    loadCloudinary();
  }, []);

  const handleSetError = (message: string) => {
    console.log(message);
    setError(name, { message });
  };

  const handleClearErrors = () => clearErrors([name]);

  const handleUpload = onUploadClick(
    merchantName,
    assetBundle,
    assetName,
    setImageUrl,
    setImgInfo,
    handleSetError,
    handleClearErrors,
  );

  return (
    <div className="uploadWidget-container">
      <label className="uploadWidget-fieldContainer" htmlFor={name}>
        <span className="uploadWidget-fieldLabel">{label}</span>
        <img
          src={imageUrl || defaultImage || ImagePlaceholder}
          alt={name}
          className={`uploadWidget-imageContainer uploadWidget-imageContainer-${assetName}`}
        />
        <Button
          className="uploadWidget-uploadButton"
          type="button"
          onClick={handleUpload}
        >
          <Icon icon={iosUploadOutline} />
          Image
        </Button>

        <input
          type="hidden"
          id={name}
          name={name}
          ref={register}
          value={imageInfo}
        />
      </label>
      {errors[name] && <em>{errors[name].message}</em>}
    </div>
  );
};

export default UploadWidget;
