import { pathOr } from 'ramda';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import log from '@/utils/logging';

export const isProdEnv = pathOr('prod', ['NODE_ENV'], config) === 'prod';

const httpLink = createHttpLink({
  uri: isProdEnv
    ? 'https://api.dosh.cash/brand/prod/graphql'
    : 'https://devapi.dosh.cash/brand/dev/graphql',
});

export const logApolloErrors = ({ graphQLErrors, networkError }: any) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, displayMessage }: any) => {
      log.error(displayMessage || message);
    });
  }

  if (networkError) {
    const statusCode = pathOr(undefined, ['statusCode'], networkError);
    if (statusCode) {
      log.error(
        `[Network error]: Error in response from server. Status code ${statusCode}`,
      );
    } else {
      log.error(`[Network error]: Unable to reach server.`);
    }
  }
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  logApolloErrors({ graphQLErrors, networkError });
});

const authLink = setContext(() => {
  return {
    headers: {
      'x-api-key': isProdEnv
        ? 'sSfKO0UmbC7tERH3YCawK8WwsPD89ZNL8dnomzYj'
        : 'LtzylOwE4x7oLmzHJLngc6MScLnAPMxjaECCAC9g',
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
});
