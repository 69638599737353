import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { getMerchantByName } from '../lambdaFunctions/getMerchantByName';
import { findOrCreateOnlineVenueForMerchant } from '../lambdaFunctions/findOrCreateOnlineVenueForMerchant';
import { onboardVenueToCardNetworks } from '../lambdaFunctions/onboardVenueToCardNetworks';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'website',
    formLabel: 'Website (with https)',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get merchant uuid',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getMerchantByName.submit,
    },
    inputData: {
      merchantName: new InputInjection([0, 'merchantName']),
    },
  },
  {
    name: 'Find or create online venue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: findOrCreateOnlineVenueForMerchant.submit,
    },
    inputData: {
      merchantId: new InputInjection([1, 'merchant_uuid']),
      website: new InputInjection([0, 'website']),
    },
  },
  {
    name: 'Onboard venue',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: onboardVenueToCardNetworks.submit,
    },
    inputData: {
      locationId: new InputInjection([2, 'onlineVenue', 'id']),
    },
  },
];

export const createOnlineVenueForMerchant = {
  steps,
  inputValues,
  name: 'Create Online Venue For Merchant',
  description: 'Create and onboard online venue for merchant',
  tags: [Tags.newMerchantOnboarding, Tags.venues],
};
