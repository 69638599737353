import React, { FunctionComponent, useEffect } from 'react';
import { Button, Spinner, Row } from 'react-bootstrap';
import { Icon } from 'react-icons-kit';
import { ic_file_download as download } from 'react-icons-kit/md/ic_file_download';

import { WorkflowStepStatusTypes } from '@/components/Workflow/components/WorkflowStepStatus';
import getErrorMessage from '@/utils/error';

interface WorkflowStepProps {
  name: string;
  active: boolean;
  status: WorkflowStepStatusTypes;
  onCompleteStep: Function;
  inputData: any;
  outputData: any;
  setStatus: Function;
  onSubmit: any;
  autorun: boolean;
}

export const DownloadData: FunctionComponent<WorkflowStepProps> = ({
  active,
  status,
  onCompleteStep,
  inputData,
  setStatus,
  autorun,
}) => {
  const runStep = async () => {
    setStatus(WorkflowStepStatusTypes.RUNNING);
    try {
      const { ...payload } = inputData || {};

      onCompleteStep({ ...payload, status: 'success' });
    } catch (error) {
      const em = getErrorMessage(error);
      onCompleteStep({ error: em, status: 'failed' });
    }
  };

  useEffect(() => {
    if (status === WorkflowStepStatusTypes.ACTIVE && autorun) {
      runStep();
    }
  });

  const handleFileDownload = (e: any) => {
    const filename = e.target.getAttribute('data-filename');
    let fileData = [];
    if (inputData.header) {
      fileData.push(inputData.header);
    }
    fileData = fileData.concat(inputData.result[filename]);
    if (inputData.footer) {
      fileData.push(inputData.footer);
    }

    const blob = new Blob([fileData.join('\n')], { type: 'text/csv' });
    const link: any = document.createElementNS(
      'http://www.w3.org/1999/xhtml',
      'a',
    );
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.mastercard.csv`;
    link.click();
  };

  return (
    <>
      <div className="workflowStep-text">
        <p className="workflowStep-text-label">Download Files</p>
        <p>
          {active
            ? 'Download the files generated for this step.'
            : 'Files are available after this step is run.'}
        </p>
        {(active || status === WorkflowStepStatusTypes.SUCCESS) &&
          Object.keys(inputData.result).map((filename) => (
            <Row key={filename}>
              <Button
                onClick={handleFileDownload}
                data-filename={filename}
                title="Download CSV file"
                variant="link"
              >
                <Icon icon={download} className="copyIcon" />
                {`${filename}.mastercard.csv`}
              </Button>
            </Row>
          ))}
      </div>
      {active && (
        <Row>
          <Button className="workflowButton" onClick={runStep}>
            {status === WorkflowStepStatusTypes.RUNNING && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden
              />
            )}
            {!(status === WorkflowStepStatusTypes.RUNNING) && (
              <span>Run Step</span>
            )}
          </Button>
        </Row>
      )}
    </>
  );
};
