import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'merchantUuid',
    formLabel: 'Merchant Uuid *',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'merchant-service-2',
  functionName: 'getMerchantByUuid',
  method: HttpMethod.GET,
});

export const getMerchantByUuid = {
  name: 'Get Merchant By Uuid',
  description: 'Find merchant by its uuid',
  inputValues,
  outputValueName: 'merchant', // optional
  tags: [Tags.merchant],
  submit,
};
