import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'cardNetworkIds',
    formLabel: 'Card Network Ids',
  },
  {
    name: 'includeONVenueCAIDMappings',
    formLabel: 'Include ONVenue CAID Mappings',
    default: false,
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-venue-service',
  functionName: 'getONVenueMappingsForCNIds',
  method: HttpMethod.POST,
});

export const getONVenueMappingsForCNIds = {
  name: 'Get ONVenue Mappings for CNIds',
  description: 'Get ONVenue Mappings for CNIds',
  inputValues,
  tags: [Tags.cnids, Tags.venues],
  submit,
};
