import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

export type CNMgmtVenue = any; // not relevant to get into detail yet about what a venue is
export interface CNMgmtSvcMappingChanges {
  venuesMapped: CNMgmtVenue[];
  venuesUnmapped: CNMgmtVenue[];
}
export enum CNMgmtSvcMappingChangesReason {
  AUTOMATIC_MAPPINGS_NOT_ALLOWED_FOR_VENUE = 'AUTOMATIC_MAPPINGS_NOT_ALLOWED_FOR_VENUE',
  NO_PLACE_FOUND_FOR_VENUE = 'NO_PLACE_FOUND_FOR_VENUE',
  SUCCESSFUL_SYNC = 'SUCCESSFUL_SYNC',
}

const inputValues = [
  {
    name: 'venueUuid',
    formLabel: 'Venue Uuid',
    formSubLabel: 'the uuid of the cnvenuedb.venue to sync mappings for',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor<
  {
    venueUuid: string;
  },
  {
    success: true;
    reason: CNMgmtSvcMappingChangesReason;
    mappingChanges: CNMgmtSvcMappingChanges;
  }
>({
  serviceName: 'cn-mgmt-svc',
  functionName: 'syncAutomaticMappingsForVenue',
  method: HttpMethod.POST,
});

export const syncAutomaticMappingsForVenue = {
  name: '[cn-mgmt-svc] Sync Automatic Mappings For Venue',
  description:
    'Syncs the mappings recorded in cnvenuedb with the datasources that power them (e.g., places-svc places). Reports changes and final state',
  tags: [Tags.venues, Tags.cnids],
  submit,
  inputValues,
};
