import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'regex',
    formLabel: 'Regular Expression',
    formSubLabel: 'case insensitive by default',
  },
  {
    name: 'limit',
    formLabel: 'Limit',
    formSubLabel:
      'the number of place descriptions pulled in by the regex to limit scheduling for',
    default: 1000,
  },
  {
    name: 'page',
    formLabel: 'Page',
    formSubLabel:
      'which page of place descriptions pulled in by the regex to schedule for',
    default: 0,
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor<
  {
    regex: string;
    limit: number;
    page: number;
  },
  {
    examplesOfNamesJobsWereRequestedFor: string[];
    totalManuallyRequestedJobsCount: number;
  }
>({
  serviceName: 'places-svc',
  functionName: 'manuallyRequestPropOptJobsByPDNameRegex',
  method: HttpMethod.POST,
});

export const manuallyRequestPropOptJobsByPDNameRegex = {
  name: '[places-svc] Manually Request Property Optimization Jobs by Place Description Name Regex',
  description:
    "Schedules property optimization, mode:MANUALLY_REQUESTED, for all place_descriptions who's name match the input regex",
  tags: [Tags.venues],
  submit,
  inputValues,
};
