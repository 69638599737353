import { pathOr } from 'ramda';

export const isProdEnv = pathOr('prod', ['NODE_ENV'], config) === 'prod';

const info = (...data: any) => {
  if (!isProdEnv) {
    console.log('Ops Portal - Info:', ...data);
  }
};

const warn = (...data: any) => {
  if (!isProdEnv) {
    console.warn('Ops Portal - Warn:', ...data);
  }
};

const error = (...data: any) => {
  if (!isProdEnv) {
    console.error('Ops Portal - Error:', ...data);
  }
};

const log = {
  info,
  warn,
  error,
};

export default log;
