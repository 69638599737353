import { isAfter } from 'date-fns';
import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { setOfferProperties } from '../lambdaFunctions/setOfferProperties';
import { Offer, Tags } from '@/helpers/types';
import { offerGetByUuid } from '../lambdaFunctions/offerGetByUuid';

const inputValues = [
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid',
  },
  {
    name: 'updatedBy',
    formLabel: 'Name of person making updates',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Offer',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: offerGetByUuid.submit,
    },
    inputData: {
      offerUuid: new InputInjection([0, 'offerUuid']),
    },
  },
  {
    name: 'Delete Offer',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: setOfferProperties.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection([1], (result: Offer) => {
          if (!result) {
            return;
          }
          return isAfter(new Date(), new Date(result.offerStartDate));
        }),
      },
      offerUuid: new InputInjection([0, 'offerUuid']),
      endDate: new InputInjection([1], (result: Offer) => {
        if (!result) {
          return;
        }
        return result.offerStartDate;
      }),
      deleted: 1,
      updatedBy: new InputInjection([0, 'updatedBy']),
    },
  },
  {
    name: 'Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      offerDeleted: new InputInjection(
        [2],
        ({ status }: { status: string }) => {
          if (!status) {
            return;
          }
          return status === 'success'
            ? 'Offer was deleted'
            : 'Cannot delete offer that has started';
        },
      ),
    },
  },
];

export const deleteOffer = {
  steps,
  inputValues,
  name: 'Delete Offer',
  description: 'Delete offer that has not started yet',
  tags: [Tags.offers],
};
