import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { Tags, Venue } from '@/helpers/types';
import {
  cardNetworkOptions,
  nonPlaceLevelCardNetworkIdTypeOptions,
} from '@/helpers/commonInputSelectOptions';
import { getVenuesByCNId } from '../lambdaFunctions/getVenuesByCNId';
import { upsertCNVenue } from '../lambdaFunctions/upsertCNVenue';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'cardNetworkExternalId',
    formLabel: 'Card Network External ID',
  },
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
    options: cardNetworkOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'cardNetworkIdType',
    formLabel: 'Card Network ID Type',
    options: nonPlaceLevelCardNetworkIdTypeOptions,
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get CNVenues',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenuesByCNId.submit,
    },
    inputData: {
      venueSource: new InputInjection([0, 'cardNetwork']),
      cardNetworkId: new InputInjection(
        [0],
        ({
          cardNetworkExternalId,
          cardNetwork,
          cardNetworkIdType,
        }: {
          cardNetworkExternalId: string;
          cardNetwork: string;
          cardNetworkIdType: string;
        }) => {
          if (!cardNetworkExternalId || !cardNetwork || !cardNetworkIdType) {
            return;
          }
          return {
            externalId: cardNetworkExternalId,
            cardNetwork,
            idType: cardNetworkIdType,
          };
        },
      ),
      limit: 50,
      offset: 0,
    },
  },
  {
    name: 'Select CNVenue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({ result }: { result: { results: Venue[] } }) => {
          if (!result || !Array.isArray(result.results)) {
            return;
          }
          return result.results.map((venue) => {
            const cnIds = venue.cardNetworkIds
              .map((cn) => cn.externalId)
              .flat();
            const display = `${venue.name}, Enabled:${venue.enabled}, ${venue.uuid},
                ${venue.address.address1}, ${venue.address.address2},
                ${venue.address.city}, ${venue.address.state}, ${venue.address.postal},
                ${cnIds}`;
            return {
              name: display,
              id: venue.uuid,
              uuid: venue.uuid,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Combine Data',
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cardNetworkId: new InputInjection(
        [0],
        ({
          cardNetworkExternalId,
          cardNetwork,
          cardNetworkIdType,
        }: {
          cardNetworkExternalId: string;
          cardNetwork: string;
          cardNetworkIdType: string;
        }) => {
          if (!cardNetworkExternalId || !cardNetwork || !cardNetworkIdType) {
            return;
          }
          return {
            externalId: cardNetworkExternalId,
            cardNetwork,
            idType: cardNetworkIdType,
          };
        },
      ),
      cnvenues: new InputInjection(
        [1],
        ({ result }: { result: { results: Venue[] } }) => {
          if (!result || !Array.isArray(result.results)) {
            return;
          }
          return result.results;
        },
      ),
      cnVenueUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
    },
  },
  {
    name: 'Upsert CNVenue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: upsertCNVenue.submit,
    },
    inputData: {
      venue: new InputInjection(
        [3],
        ({
          cnvenues,
          cardNetworkId,
          cnVenueUuid,
        }: {
          cnvenues: Venue[];
          cardNetworkId: {
            id: number;
            cardNetwork: string;
            idType: string;
            externalId: string;
          };
          cnVenueUuid: string;
        }) => {
          if (!cnvenues || !cardNetworkId) {
            return;
          }
          const filteredVenue = cnvenues.filter(
            (venue) => venue.uuid === cnVenueUuid,
          )[0];
          const editedCardNetworkIds = filteredVenue.cardNetworkIds.filter(
            (cnid) => {
              return !(
                cnid.cardNetwork === cardNetworkId.cardNetwork &&
                cnid.idType === cardNetworkId.idType &&
                cnid.externalId === cardNetworkId.externalId
              );
            },
          );
          return { ...filteredVenue, cardNetworkIds: editedCardNetworkIds };
        },
      ),
    },
  },
];

export const unmapCNIdFromCNVenue = {
  steps,
  inputValues,
  name: 'Unmap CNID From CNVenue',
  description: 'Unmap CNID from CNVenue',
  tags: [Tags.venues],
};
