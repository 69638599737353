import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { updateSegmentTargetingRuleData } from '../lambdaFunctions/updateSegmentTargetingRuleData';
import { Tags } from '@/helpers/types';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'offerUuidWithThrottle',
    formLabel: 'Offer Uuid with Throttle on Base Segment',
  },
  {
    name: 'offerUuidToAddTo',
    formLabel: 'Offer Uuid to Add Throttle To',
  },
  {
    name: 'updatedBy',
    formLabel: 'Name of person making updates',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Throttle Uuid and Hash',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          offerUuidWithThrottle,
          offerUuidToAddTo,
        }: {
          offerUuidWithThrottle: string;
          offerUuidToAddTo: string;
        }) => `SELECT DISTINCT cstrdk.target_data_key, cstrd.target_data, '${offerUuidToAddTo}' as offer_uuid_to_add_to
        FROM merchantdb.offers o
        JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
        JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
        JOIN merchantdb.coupon_segment cs on o.coupon_id = cs.coupon_id
        JOIN merchantdb.coupon_segment_type cst on cs.coupon_segment_type_id = cst.coupon_segment_type_id
        JOIN merchantdb.coupon_segment_targeting_rule cstr on cs.coupon_segment_id = cstr.coupon_segment_id
        JOIN merchantdb.coupon_segment_targeting_rule_type cstrt on cstr.coupon_segment_targeting_rule_type_id = cstrt.coupon_segment_targeting_rule_type_id AND cstrt.type = 'OFFER_THROTTLE_V1'
        JOIN merchantdb.coupon_segment_targeting_rule_data cstrd on cstr.coupon_segment_targeting_rule_id = cstrd.coupon_segment_targeting_rule_id
        JOIN merchantdb.coupon_segment_targeting_rule_data_key cstrdk on cstrdk.coupon_segment_targeting_rule_data_key_id = cstrd.coupon_segment_targeting_rule_data_key_id
        WHERE getuuid(o.offer_id) = '${offerUuidWithThrottle}'
        AND cst.type = 'BASE'
        AND cstrdk.target_data_key in ('OFFER_THROTTLE_UUID', 'HASHMOD_SALT')
        ;`,
      ),
    },
  },
  {
    name: 'Get Segments to Update',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: any[];
        }) => `SELECT DISTINCT cs.coupon_segment_uuid as coupon_segment_uuid
        FROM merchantdb.offers o
        JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
        JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
        JOIN merchantdb.coupon_segment cs on o.coupon_id = cs.coupon_id
        JOIN merchantdb.coupon_segment_type cst on cs.coupon_segment_type_id = cst.coupon_segment_type_id
        JOIN merchantdb.coupon_segment_targeting_rule cstr on cs.coupon_segment_id = cstr.coupon_segment_id
        JOIN merchantdb.coupon_segment_targeting_rule_type cstrt on cstr.coupon_segment_targeting_rule_type_id = cstrt.coupon_segment_targeting_rule_type_id AND cstrt.type = 'OFFER_THROTTLE_V1'
        JOIN merchantdb.coupon_segment_targeting_rule_data cstrd on cstr.coupon_segment_targeting_rule_id = cstrd.coupon_segment_targeting_rule_id
        WHERE getuuid(o.offer_id) = '${
          records && records.length ? records[0].offer_uuid_to_add_to : ''
        }'
        AND cs.hide_offer_for_segment = 0
        AND (cs.end_date > current_date OR cs.end_date is null)
        AND c.end_date > current_date
        AND cstrd.target_data not in (${
          records && records.length
            ? records.map((record) => `'${record.target_data}'`)
            : ''
        })
        ;`,
      ),
    },
  },
  {
    name: 'Select Segment to Add Throttle',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection([2], ({ records }: { records: any[] }) => {
        if (!records || !Array.isArray(records)) {
          return;
        }
        return records.map((record) => {
          return {
            id: `${record.coupon_segment_uuid}`,
            name: `Segment Uuid: ${record.coupon_segment_uuid}`,
          };
        });
      }),
    },
  },
  {
    name: 'Process Selections',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      selectedSegmentUuids: new InputInjection(
        [3],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          return getArrayFromCheckboxSelections(result);
        },
      ),
      offerUuid: new InputInjection([0, 'offerUuidToAddTo']),
      targetThrottleUuid: new InputInjection(
        [1],
        ({ records }: { records: any[] }) => {
          if (!records || !records.length) {
            return;
          }
          const offerThrottleRecord = records.find(
            (record) => record.target_data_key === 'OFFER_THROTTLE_UUID',
          );
          return offerThrottleRecord.target_data;
        },
      ),
      targetHashUuid: new InputInjection(
        [1],
        ({ records }: { records: any[] }) => {
          if (!records || !records.length) {
            return;
          }
          const hashRecord = records.find(
            (record) => record.target_data_key === 'HASHMOD_SALT',
          );
          return hashRecord.target_data;
        },
      ),
      updatedBy: new InputInjection([0, 'updatedBy']),
    },
  },
  {
    name: 'Create Throttle Update Array',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      throttleArray: new InputInjection(
        [4],
        ({
          selectedSegmentUuids,
          offerUuid,
          targetThrottleUuid,
          updatedBy,
        }: {
          selectedSegmentUuids: unknown[];
          offerUuid: string;
          targetThrottleUuid: string;
          updatedBy: string;
        }) => {
          if (!selectedSegmentUuids) {
            return;
          }
          return !Array.isArray(selectedSegmentUuids)
            ? []
            : selectedSegmentUuids.map((segmentUuid) => {
                return {
                  offerUuid,
                  couponSegmentUuid: segmentUuid,
                  targetDataKey: 'OFFER_THROTTLE_UUID',
                  targetData: targetThrottleUuid,
                  updatedBy,
                  dryRun: false,
                };
              });
        },
      ),
    },
  },
  {
    name: 'Update Throttle',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: updateSegmentTargetingRuleData.submit,
    },
    inputData: {
      multipleInvocations: true,
      invocationArray: new InputInjection([5, 'throttleArray']),
    },
  },
  {
    name: 'Create Hash Update Array',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      hashArray: new InputInjection(
        [4],
        ({
          selectedSegmentUuids,
          offerUuid,
          targetHashUuid,
          updatedBy,
        }: {
          selectedSegmentUuids: unknown[];
          offerUuid: string;
          targetHashUuid: string;
          updatedBy: string;
        }) => {
          if (!selectedSegmentUuids) {
            return;
          }
          return !Array.isArray(selectedSegmentUuids)
            ? []
            : selectedSegmentUuids.map((segmentUuid) => {
                return {
                  offerUuid,
                  couponSegmentUuid: segmentUuid,
                  targetDataKey: 'HASHMOD_SALT',
                  targetData: targetHashUuid,
                  updatedBy,
                  dryRun: false,
                };
              });
        },
      ),
    },
  },
  {
    name: 'Update Hash',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: updateSegmentTargetingRuleData.submit,
    },
    inputData: {
      multipleInvocations: true,
      invocationArray: new InputInjection([7, 'hashArray']),
    },
  },
];

export const addExistingThrottleToOffer = {
  steps,
  inputValues,
  name: 'Add Existing Throttle To Offer',
  description: 'Update offer to use an existing throttle and hash',
  tags: [Tags.offers, Tags.throttle],
};
