import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'uuid',
    formLabel: 'Place Description Uuid',
    formSubLabel: 'uuid of the place description to check matches for',
  },
  {
    name: 'limit',
    formLabel: 'Limit',
    default: 1000,
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor<
  {
    uuid: string;
    limit: number;
  },
  any // its an internal lambda, so we should expect output to be subject to change
>({
  serviceName: 'places-svc',
  functionName: 'getDebugDataForPlaceDescriptionMatches',
  method: HttpMethod.POST,
});

export const getDebugDataForPlaceDescriptionMatches = {
  name: '[places-svc] Get Debug Data For Place Description Matches',
  description:
    'Returns all of the place-descriptions that were considered by places-svc when picking the matches - along with what places-svc decided about whether they match or not - and why',
  tags: [Tags.venues],
  submit,
  inputValues,
};
