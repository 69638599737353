import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import {
  CardNetwork,
  CardNetworkId,
  CardNetworkIdType,
  Tags,
  Venue,
} from '@/helpers/types';
import { getVenueByExternalId } from '../lambdaFunctions/getVenueByExternalId';
import { getVenueMappingsForGenericVenue } from '../lambdaFunctions/getVenueMappingsForGenericVenue';
import {
  cardNetworkOptions,
  cardNetworkIdTypeOptions,
} from '@/helpers/commonInputSelectOptions';

const inputValues = [
  {
    name: 'rnMerchantNumber',
    formLabel: 'RN Merchant Number',
  },
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
    options: cardNetworkOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'idType',
    formLabel: 'Card Network Id Type',
    options: cardNetworkIdTypeOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'externalId',
    formLabel: 'Card Network External Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get ONVenue',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueByExternalId.submit,
    },
    inputData: {
      venueSource: 'RN',
      externalId: new InputInjection([0, 'rnMerchantNumber']),
    },
  },
  {
    name: 'Get CNVenues',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueMappingsForGenericVenue.submit,
    },
    inputData: {
      venueUuid: new InputInjection([1], ({ result }: { result: Venue }) => {
        if (!result) {
          return;
        }
        return result.uuid;
      }),
    },
  },
  {
    name: 'Combine Information',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      onvenueCnids: new InputInjection([1], ({ result }: { result: Venue }) => {
        if (!result) {
          return;
        }
        return result.cardNetworkIds;
      }),
      cnvenues: new InputInjection([2], ({ result }: { result: Venue[] }) => {
        if (!result) {
          return;
        }
        return result;
      }),
      cnidProvided: new InputInjection(
        [0],
        ({
          cardNetwork,
          idType,
          externalId,
        }: {
          cardNetwork: CardNetwork;
          idType: string;
          externalId: CardNetworkIdType;
        }) => {
          if (!cardNetwork || !idType || !externalId) {
            return;
          }
          return {
            cardNetwork,
            idType,
            externalId,
          };
        },
      ),
    },
  },
  {
    name: 'Determine if CNId is on ONVenue',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cnidOnOnvenue: new InputInjection(
        [3],
        ({
          onvenueCnids,
          cnidProvided,
        }: {
          onvenueCnids: CardNetworkId[];
          cnidProvided: CardNetworkId;
        }) => {
          if (!onvenueCnids) {
            return;
          }
          return onvenueCnids.some(
            (cnid) =>
              cnid.cardNetwork === cnidProvided.cardNetwork &&
              cnid.idType === cnidProvided.idType &&
              cnid.externalId === cnidProvided.externalId,
          );
        },
      ),
      cnidOnMappedCnvenue: new InputInjection(
        [3],
        ({
          cnvenues,
          cnidProvided,
        }: {
          cnvenues: Venue[];
          cnidProvided: CardNetworkId;
        }) => {
          if (!cnvenues) {
            return;
          }
          return cnvenues.some((venue) =>
            venue.cardNetworkIds.some(
              (cnid) =>
                cnid.cardNetwork === cnidProvided.cardNetwork &&
                cnid.idType === cnidProvided.idType &&
                cnid.externalId === cnidProvided.externalId,
            ),
          );
        },
      ),
    },
  },
  {
    name: 'Determine if mapping is enforced by RN',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cnidOnOnvenue: new InputInjection([4, 'cnidOnOnvenue']),
      cnidOnMappedCnvenue: new InputInjection([4, 'cnidOnMappedCnvenue']),
      cnidOnlyOnCnvenue: new InputInjection(
        [4],
        ({
          cnidOnOnvenue,
          cnidOnMappedCnvenue,
        }: {
          cnidOnOnvenue: boolean;
          cnidOnMappedCnvenue: boolean;
        }) => {
          if (!cnidOnOnvenue && !cnidOnMappedCnvenue) {
            return;
          }
          return cnidOnMappedCnvenue && !cnidOnOnvenue;
        },
      ),
    },
  },
];

export const determineIfCNIdIsProvidedByRN = {
  steps,
  inputValues,
  name: 'Determine If CNId Was Provided By RN',
  description: 'Determine if CNId was Provided By RN',
  tags: [Tags.cnids, Tags.specialMerchants],
};
