import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import {
  BrandTransactionMatcherResults,
  CardNetworkIdType,
  Tags,
} from '@/helpers/types';
import { CardNetworksArray } from '@/helpers/typeHelpers';
import { redshiftEscape } from '@/utils/strings';
import { GET_TRANSACTION_MATCHERS } from '../graphql/getTransactionMatchers.gql';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Transaction Check',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `select
          cted.venue_name,
          cte.card_network,
          cte.marketplace,
          l.type,
          count(DISTINCT cte.transaction_id)
   from empyrdb.card_transaction_events cte
   JOIN empyrdb.card_transaction_events_debug cted on cte.id = cted.card_transaction_event_id
   join empyrdb.card_transaction_events_card_network_ids ctecni on cte.id = ctecni.card_transaction_event_id
   JOIN cnvenuedb.card_network_ids cnis on ctecni.card_network_external_id = cnis.external_id
   JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.card_network_id = cnis.id
   JOIN cnvenuedb.venues cnv on vtcni.venue_id = cnv.id
   JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.cn_venue_id = cnv.id
   JOIN cnvenuedb.venues onv on onv.id = otcvm.on_venue_id
   JOIN merchantdb.source_locations sl on sl.source_location_id = onv.uuid
   JOIN merchantdb.locations l on l.location_id = sl.location_id
   where 1=1
   and cte.transaction_timestamp >= current_date-7
   AND cted.venue_name ilike '%${redshiftEscape(merchantName)}%'
   AND cte.marketplace = 'DOSH'
   AND cnis.card_network in ('VISA','MASTERCARD','AMEX')
   AND onv.enabled = 1
   AND l.type = 'online'
   group by 4,3,2,1
   ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Query to Verify Online Location on Offer, Status, and Has Aggregate CNId mappings',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT l.status, cnis.card_network, cnis.id_type, cnis.external_id
          FROM merchantdb.offers o
          JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
          JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
          JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
          JOIN merchantdb.locations l on cl.location_id = l.location_id
          JOIN merchantdb.source_locations sl on sl.location_id = l.location_id
          JOIN cnvenuedb.venues onv on onv.uuid = sl.source_location_id
          JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on onv.id = otcvm.on_venue_id
          JOIN cnvenuedb.venues cnv on cnv.id = otcvm.cn_venue_id
          JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.venue_id = cnv.id
          JOIN cnvenuedb.card_network_ids cnis on cnis.id = vtcni.card_network_id
          WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
          AND l.type = 'online'
          AND cnis.id_type IN ('VMID', 'VSID', 'BRAND')
          AND c.end_date > current_date
          ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Preprocess mapped Brand CNIDs',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      onlineBrandCNIdsMapped: new InputInjection(
        [2],
        ({
          records,
        }: {
          records: {
            id_type: string;
            external_id: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          const output = records
            .filter((record) => {
              return record.id_type === CardNetworkIdType.BRAND;
            })
            .map((record) => {
              return { id: record.external_id };
            });
          const hashmap: Record<string, boolean> = {};
          return output.filter((item) => {
            if (hashmap[item.id]) {
              return false;
            }
            hashmap[item.id] = true;
            return true;
          });
        },
      ),
    },
  },
  {
    name: 'Retrieves REGEX type transaction matchers for online mapped Brand CNIDs',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_TRANSACTION_MATCHERS,
    },
    inputData: {
      multipleQueries: true,
      inputArray: new InputInjection(
        [3],
        ({
          onlineBrandCNIdsMapped,
        }: {
          onlineBrandCNIdsMapped: { id: string }[];
        }) => {
          if (
            !onlineBrandCNIdsMapped ||
            !Array.isArray(onlineBrandCNIdsMapped)
          ) {
            return;
          }
          return onlineBrandCNIdsMapped;
        },
      ),
    },
  },
  {
    name: 'Membership Offer Check',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT c2.type, c2.exact_spend_to_activate
          FROM merchantdb.offers o
          JOIN merchantdb.campaigns c ON o.offer_id = c.offer_id
          JOIN merchantdb.coupons c2 ON o.coupon_id = c2.coupon_id
          JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
          JOIN merchantdb.locations l on cl.location_id = l.location_id
          WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
          AND c.end_date > current_date
          AND l.type = 'online'
          ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Results',
    autorun: false,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      transactionsReceivedForAllNetworks: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            card_network: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.filter((record) =>
            CardNetworksArray.includes(record.card_network),
          );
        },
      ),
      onlineLocationOnOfferAndActive: new InputInjection(
        [2],
        ({
          records,
        }: {
          records: {
            status: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.length && records[0].status === 'active';
        },
      ),
      onlineBrandCNIdsMapped: new InputInjection(
        [2],
        ({
          records,
        }: {
          records: {
            id_type: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return (
            records.length &&
            records.some((record) => record.id_type === CardNetworkIdType.BRAND)
          );
        },
      ),
      aggregateVMIDMapped: new InputInjection(
        [2],
        ({
          records,
        }: {
          records: {
            id_type: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          const vsidsAndVmidsMapped = records.filter(
            (record) =>
              record.id_type === CardNetworkIdType.VMID ||
              record.id_type === CardNetworkIdType.VSID,
          );
          // an odd number of visa cnid mappings (vsids and vmids only) indicate an aggregate VMID mapping as all other Visa cnvenues will have an even number (VSID and VMID)
          return (
            vsidsAndVmidsMapped.length && vsidsAndVmidsMapped.length % 2 !== 0
          );
        },
      ),
      onlineBrandsTransactionMatchers: new InputInjection(
        [4],
        ({
          results,
        }: {
          results: { brand: BrandTransactionMatcherResults }[];
        }) => {
          if (!results || !Array.isArray(results)) {
            return;
          }
          return results.map((result) => {
            return result.brand.rawMatchers
              ? result.brand.rawMatchers.matchers
              : [];
          });
        },
      ),
      offerIsValidMembershipOffer: new InputInjection(
        [5],
        ({
          records,
        }: {
          records: {
            type: string;
            exact_spend_to_activate: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return (
            typeof records[0].exact_spend_to_activate === 'string' &&
            records[0].type === 'loyalty'
          );
        },
      ),
    },
  },
];

export const onlineQASession = {
  steps,
  inputValues,
  name: 'QA Online Offer',
  description: 'Online Only QA',
  tags: [Tags.qa, Tags.newMerchantOnboarding],
};
