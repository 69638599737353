import gql from 'graphql-tag';

export const APPROVE_BRAND_ASSETS = gql`
  mutation approveBrandAsset(
    $brandId: ID!
    $bundle: BrandAssetBundleName!
    $updatedAt: String!
  ) {
    approveBrandAsset(
      brandId: $brandId
      bundle: $bundle
      updatedAt: $updatedAt
    ) {
      success
    }
  }
`;
