import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { visaMerchantGroups } from '@/helpers/commonInputSelectOptions';

const inputValues = [
  {
    name: 'visaMerchantGroup',
    formLabel: 'Visa Merchant Group',
    options: visaMerchantGroups,
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Active VMIDs By Merchant Group',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          visaMerchantGroup,
        }: {
          visaMerchantGroup: string;
        }) => `with onvenue_total as (
          SELECT COUNT(DISTINCT onv.id) as onvenue_count, m.merchant_id
          FROM cnvenuedb.venues onv
          JOIN merchantdb.source_locations sl on onv.uuid = sl.source_location_id
          JOIN merchantdb.locations l on sl.location_id = l.location_id
          JOIN merchantdb.merchants m on l.merchant_id = m.merchant_id
          WHERE 1=1
          AND onv.venue_source_id = 1
          GROUP BY 2
      ), total_cnvenues_per_vmid as(
          SELECT COUNT(DISTINCT cnv.id) as cnvenues_per_vmid_count, cnis.external_id as vmid, ot.onvenue_count as onvenue_count, m.name as merchant_name
          FROM onvenue_total ot
          JOIN merchantdb.merchants m on ot.merchant_id = m.merchant_id
          JOIN merchantdb.locations l on m.merchant_id = l.merchant_id
          JOIN merchantdb.source_locations sl on l.location_id = sl.location_id
          JOIN cnvenuedb.venues onv on sl.source_location_id = onv.uuid
          JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.on_venue_id = onv.id AND onv.venue_source_id = 1
          JOIN cnvenuedb.venues cnv on cnv.id = otcvm.cn_venue_id AND cnv.venue_source_id = 4 AND cnv.enabled = 1
          JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.venue_id = cnv.id
          JOIN cnvenuedb.card_network_ids cnis on cnis.id = vtcni.card_network_id AND cnis.id_type = 'VMID'
          JOIN cnvenuedb.names n2 on cnv.name_id = n2.id
          WHERE 1=1
          GROUP BY 2, 3, 4
      ) SELECT DISTINCT (CAST(cnvenues_per_vmid_count as float)/onvenue_count)*100 as percent_of_mapped_vmids, tcpv.merchant_name, tcpv.vmid, vtfsvc.merchant_group
      FROM total_cnvenues_per_vmid tcpv
      LEFT JOIN cnmgmtdb.view_transaction_flow_source_visa_current vtfsvc on vtfsvc.card_network_external_id = tcpv.vmid AND vtfsvc.card_network_external_id_type = 'VMID'
      WHERE vtfsvc.state = 'ACTIVE' AND percent_of_mapped_vmids >= 1 AND vtfsvc.merchant_group = '${visaMerchantGroup}';`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Result',
    autorun: true,
    process: {
      type: WorkflowStepTypes.DISPLAY_RESULT,
    },
    inputData: {
      visaMerchantGroup: new InputInjection([0, 'visaMerchantGroup']),
      records: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            percent_of_mapped_vmids: string;
            vmid: string;
            merchant_group: string;
          }[];
        }) => {
          if (records) {
            const dedup = new Set(records.map((s) => JSON.stringify(s)));
            return Array.from(dedup, (s) => JSON.parse(s));
          }
        },
      ),
    },
  },
];

export const getActiveVmidsByMerchantGroup = {
  steps,
  inputValues,
  name: 'Get Active VMIDs By Merchant Group',
  description: 'Get Active VMIDs By Merchant Group',
  tags: [Tags.merchantGroups, Tags.cnids, Tags.transactions],
};
