import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { ADD_BRAND } from '@/services/graphql/addBrand.gql';
import { addManualONVenueToCNIdMapping } from '../lambdaFunctions/addManualONVenueToCNIdMapping';
import {
  CardNetwork,
  CardNetworkIdType,
  MatchBasis,
  Tags,
} from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get online onvenue uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT sl.source_location_id as uuid
        FROM merchantdb.locations l
        JOIN merchantdb.source_locations sl on l.location_id = sl.location_id
        JOIN merchantdb.merchants m on l.merchant_id = m.merchant_id
        WHERE 1=1
        AND m.name ilike '%${redshiftEscape(merchantName)}%'
        AND type = 'online'
        ;`,
      ),
    },
  },
  {
    name: 'Add Brand',
    inputData: {
      brandId: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) => {
          if (!merchantName) {
            return;
          }
          return `${merchantName.replace(/\W*\d*/g, '').toUpperCase()}ONLINE`;
        },
      ),
    },
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: ADD_BRAND,
    },
  },
  {
    name: 'Create and Map Mastercard CNId',
    inputData: {
      onvenueUuid: new InputInjection(
        [1],
        ({ records }: { records: any[] }) => {
          if (!records || !records.length) {
            return;
          }
          return records[0].uuid;
        },
      ),
      cardNetwork: CardNetwork.MASTERCARD,
      cardNetworkExternalIdType: CardNetworkIdType.BRAND,
      cardNetworkExternalId: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) => {
          if (!merchantName) {
            return;
          }
          return `${merchantName.replace(/\W*\d*/g, '').toUpperCase()}ONLINE`;
        },
      ),
      basis: MatchBasis.AGGREGATE_MATCH,
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addManualONVenueToCNIdMapping.submit,
    },
  },
  {
    name: 'Create and Map Visa CNId',
    inputData: {
      onvenueUuid: new InputInjection(
        [1],
        ({ records }: { records: any[] }) => {
          if (!records || !records.length) {
            return;
          }
          return records[0].uuid;
        },
      ),
      cardNetwork: CardNetwork.VISA,
      cardNetworkExternalIdType: CardNetworkIdType.BRAND,
      cardNetworkExternalId: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) => {
          if (!merchantName) {
            return;
          }
          return `${merchantName.replace(/\W*\d*/g, '').toUpperCase()}ONLINE`;
        },
      ),
      basis: MatchBasis.AGGREGATE_MATCH,
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addManualONVenueToCNIdMapping.submit,
    },
  },
];

export const setupOnlineBrandOfferNetworkVenue = {
  steps,
  inputValues,
  name: 'Setup Online Brand Offer Network Venue',
  description:
    'Should only be done if online specific transaction matchers are found',
  tags: [Tags.newMerchantOnboarding, Tags.venues],
};
