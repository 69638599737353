import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { setOfferProperties } from '../lambdaFunctions/setOfferProperties';
import { Tags } from '@/helpers/types';
import { offerLocationTypeOptions } from '@/helpers/commonInputSelectOptions';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'locationType',
    formLabel: 'Location Type',
    options: offerLocationTypeOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'restrictions',
    formLabel: 'Offer Restrictions',
    formSubLabel: 'To remove restrictions set value to NULL',
  },
  {
    name: 'updatedBy',
    formLabel: 'Name of person making updates',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Offer Uuid and Restrictions',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
          locationType,
        }: {
          merchantName: string;
          locationType: string;
        }) =>
          `SELECT DISTINCT getuuid(o.offer_id) as offer_uuid, o.restrictions, l.type
          FROM merchantdb.offers o
          JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
          JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
          JOIN merchantdb.coupon_segment cs on o.coupon_id = cs.coupon_id
          JOIN merchantdb.coupon_segment_type cst on cs.coupon_segment_type_id = cst.coupon_segment_type_id
          JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
          JOIN merchantdb.locations l on cl.location_id = l.location_id
          WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
          AND c.end_date > current_date
          AND l.type = '${locationType}'
          ;`,
      ),
    },
  },
  {
    name: 'Update Offer Restrictions -- IMPORTANT: If a restriction exists (text string), make sure you want to replace it before proceeding',
    autorun: false, // optional, will start running this step as soon as it's active
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: setOfferProperties.submit,
    },
    inputData: {
      offerUuid: new InputInjection([1], ({ records }: any) => {
        if (!records || !Array.isArray(records)) {
          return;
        }
        if (records.length > 1) {
          return;
        }
        return records[0].offer_uuid;
      }),
      restrictions: new InputInjection(
        [0],
        ({ restrictions }: { restrictions: string }) => {
          if (restrictions && restrictions.toLowerCase() === 'null') {
            return null;
          }
          return restrictions;
        },
      ),
      updatedBy: new InputInjection([0, 'updatedBy']),
    },
  },
];

export const updateOfferRestrictions = {
  steps,
  inputValues,
  name: 'Update Offer Restriction',
  description: 'Add, update, or remove offer restriction',
  tags: [Tags.offers],
};
