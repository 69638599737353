import React, { FunctionComponent, MouseEvent, CSSProperties } from 'react';
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
  Spinner,
} from 'react-bootstrap';
import './Button.css';

interface ButtonProps extends BaseButtonProps {
  id?: string;
  style?: CSSProperties;
  icon?: JSX.Element;
  loading?: boolean;
  onClick?(e: MouseEvent<HTMLButtonElement>): void;
}

const Button: FunctionComponent<ButtonProps> = ({
  icon,
  loading,
  disabled,
  children,
  ...props
}): JSX.Element => {
  const isDisabled: boolean = !!disabled || !!loading;

  return (
    <BaseButton {...props} disabled={isDisabled} className="trv-btn">
      {loading ? (
        <>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            className="trv-btn-icon"
          />
          Loading...
        </>
      ) : (
        <>
          {icon && <span className="trv-btn-icon">{icon}</span>}
          {children}
        </>
      )}
    </BaseButton>
  );
};

export default Button;
