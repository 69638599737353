export interface MccDescriptions {
  mcc: string;
  edited_description: string;
  combined_description: string;
  usda_description: string;
  irs_description: string;
  irs_reportable?: string;
  id?: number;
}

export enum YesOrNo {
  yes = 'Yes',
  no = 'No',
}

export enum Tags {
  cdlx = 'CDLX',
  assets = 'Assets',
  audiences = 'Audiences',
  banners = 'Banners',
  budget = 'Budget',
  campaigns = 'Campaigns',
  cnids = 'CNIds',
  csResources = 'CS Resources',
  matchingEngine = 'Matching Engine',
  merchant = 'Merchant',
  mugs = 'MUGs',
  newMerchantOnboarding = 'New Merchant Onboarding',
  offers = 'Offers',
  outage = 'Outage',
  partnerOnboarding = 'Partner Onboarding',
  poweredBy = 'Powered-By',
  specialMerchants = 'Special Merchants',
  targeting = 'Targeting',
  throttle = 'Throttle',
  transactions = 'Transactions',
  venues = 'Venues',
  qa = 'QA',
  merchantGroups = 'Merchant Groups',
  cnidMismatches = 'CNId Mismatches',
}

export enum CardNetworkTransactionMode {
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
}

export enum LocationStatusToggleMode {
  TURN_ON = 'TURN_ON',
  TURN_OFF = 'TURN_OFF',
}

export enum StoreLocationStatus {
  pending = 'pending',
  active = 'active',
}

export enum OfferLocationTypes {
  online = 'online',
  nearby = 'nearby',
}

export enum CardNetwork {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
  PAYPAL = 'PAYPAL',
}

export enum OfferNetwork {
  RN = 'RN',
  R21 = 'R21',
  DOSH = 'DOSH',
  CDLX = 'CDLX',
}

export enum CardNetworkIdType {
  VSID = 'VSID',
  VMID = 'VMID',
  AuthMID = 'AuthMID',
  ClearMID = 'ClearMID',
  LID = 'LID',
  SE = 'SE',
  CAID = 'CAID',
  BRAND = 'BRAND',
  MID = 'MID',
}

export enum nonPlaceLevelCardNetworkIdType {
  CAID = CardNetworkIdType.CAID,
  AuthMID = CardNetworkIdType.AuthMID,
  ClearMID = CardNetworkIdType.ClearMID,
}

export enum CNIdDenyList {
  VSID = CardNetworkIdType.VSID,
  SE = CardNetworkIdType.SE,
  AuthMID = CardNetworkIdType.AuthMID,
  ClearMID = CardNetworkIdType.ClearMID,
  CAID = CardNetworkIdType.CAID,
}

export enum VisaCommunityCode {
  DOSHCL = 'DOSHCL',
  VISAUSPUBCL = 'VISAUSPUBCL',
  DOSHCTECL = 'DOSHCTECL',
  VISAUSPUBCLCTE = 'VISAUSPUBCLCTE',
}

export enum VisaMerchantGroup {
  DEFAULT = 'Dosh Master MG',
  REWARDS_NETWORK = 'Rewards Network Master MG',
  REWARDS_21 = 'Rewards21 Master MG',
  IN_STORE_ONLY = 'In-Store Only Dosh MG',
  FUEL_EXCLUSIONARY_INSTORE = 'Fuel Exclusionary InStore',
  ONLINE_ONLY = 'Online Only Dosh MG',
  BJS_WHOLESALE = 'BJS WHOLESALE MG',
  FUEL_EXCLUSIONARY_DOSH = 'Fuel Exclusionary Dosh MG',
  FUEL_INCLUSIONARY = 'Fuel Inclusionary',
  ACTIVATION_MG = 'ActivationMG',
  PLACEHOLDER_FOR_VISA_OFFER_ACTIVATIONS = '__PLACEHOLDER_FOR_VISA_OFFER_ACTIVATIONS__',
}

export enum MatchBasis {
  AGGREGATE_MATCH = 'AGGREGATE_MATCH',
  MANUAL_MATCH = 'MANUAL_MATCH',
  PLACE_MATCH = 'PLACE_MATCH',
  PLACE_MATCH_MANUAL = 'PLACE_MATCH_MANUAL',
  PLACE_MATCH_AUTOMATIC = 'PLACE_MATCH_AUTOMATIC',
  AGGREGATE_MATCH_CNID_MANUAL = 'AGGREGATE_MATCH_CNID_MANUAL',
  AGGREGATE_MATCH_BRAND_MANUAL = 'AGGREGATE_MATCH_BRAND_MANUAL',
}

export enum LocationType {
  PHYSICAL = 'PHYSICAL',
  ONLINE = 'ONLINE',
  AGGREGATE = 'AGGREGATE',
}

export enum VisaActivation {
  VISA_OFFER = 'Visa Offer',
}

export enum VisaActivationType {
  VMID = CardNetworkIdType.VMID,
  VSID = CardNetworkIdType.VSID,
  VISA_OFFER = VisaActivation.VISA_OFFER,
}

export interface Marketplace {
  name: string;
  fullName: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface AssetComponent {
  path?: string;
  component: string;
  value?: string;
}

export interface BrandAssetResults {
  rawAssets: {
    bundle: AssetComponent[];
  };
}

export enum MatcherTestConditions {
  SHOULD_MATCH = 'SHOULD_MATCH',
  SHOULD_NOT_MATCH = 'SHOULD_NOT_MATCH',
}

export enum MatcherTypes {
  REGEX = 'REGEX',
  MERCHANT_CATEGORY_CODE = 'MERCHANT_CATEGORY_CODE',
}
export interface MatcherTestObject {
  value: string;
  condition: MatcherTestConditions;
}
export interface MatcherObject {
  pattern: string;
  options?: string;
  tests?: MatcherTestObject[];
  type: MatcherTypes;
}

export interface BrandTransactionMatcherResults {
  rawMatchers: {
    matchers: MatcherObject[];
  };
}

export interface BrandPlaceMatcherResults {
  rawPlaceMatchers: {
    matchers: MatcherObject[];
  };
}

export interface BannerObject {
  brand: string;
  imageId: string;
  description: string;
  filters: {
    displayStart: string;
    displayEnd?: string;
  };
}

export interface CurrentFeed {
  title: string;
  banners: BannerObject[];
}

export enum PlaceDescriptionDataSource {
  MAPQUEST = 'MAPQUEST',
  DOSH_OFFER_NETWORK = 'DOSH_OFFER_NETWORK',
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
  INCUBIT = 'INCUBIT',
  SENTRAL = 'SENTRAL',
}

export enum PlaceSubscriber {
  VENUE_SEARCH_SVC = 'VENUE_SEARCH_SVC',
  CN_VENUE_SERVICE = 'CN_VENUE_SERVICE',
  DOSH_OFFER_NETWORK_SERVICE = 'DOSH_OFFER_NETWORK_SERVICE',
}

export interface Address {
  id?: number;
  street?: string;
  address1?: string;
  address2?: string;
  suite?: string;
  city: string;
  state: string;
  postal: string;
  country: string;
}

export interface Geocode {
  id: number;
  latitude: number;
  longitude: number;
}
export interface PlaceDescription {
  id: number;
  uuid: string;
  dataSource: PlaceDescriptionDataSource;
  externalId: string;
  brands: string[];
  name: string;
  address: Address;
  geocode: Geocode;
  confidence: number;
}

export interface PlaceSubscription {
  id: number;
  uuid: string;
  externalId: string;
  subscriber: PlaceSubscriber;
}

export interface Place {
  brands: string[];
  bestDescription: PlaceDescription;
  knownDescriptions: PlaceDescription[];
  confidence: number;
  subscriptions: PlaceSubscription[];
}

export interface CardNetworkId {
  id?: number;
  cardNetwork: CardNetwork;
  idType: CardNetworkIdType;
  externalId: string;
}

export interface Venue {
  id?: number;
  merchantId?: string;
  venueUuid?: string;
  placeSubscriptionUuid?: string;
  version?: number;
  enabled: boolean;
  name: string;
  venueSource: CardNetwork | OfferNetwork;
  externalId: string;
  externalGroupId: string;
  uuid?: string;
  locationType: string;
  address: Address;
  cardNetworkIds: CardNetworkId[];
}

export interface Offer {
  offerStartDate: string;
}

export interface Transaction {
  name: string;
  mcc_code: string;
  occurrences: number;
}

export interface ONVenueMapping {
  cardNetworkId: CardNetworkId;
  cardNetworkMCCs: number[];
  offerNetworkVenue: {
    uuid: string;
    name: string;
    venueSource: OfferNetwork;
    locationType: LocationType;
    enabled: boolean;
    updatedAt: string;
  };
  basis: MatchBasis;
}

export enum TransactionFlowType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface TransactionFlowSource {
  effectiveAt: string;
  state: TransactionFlowType;
  merchantGroup?: VisaMerchantGroup;
  communityCode?: VisaCommunityCode;
  visaExternalId?: string;
  cardNetworkId: {
    cardNetwork: CardNetwork;
    cardNetworkExternalIdType: CardNetworkIdType;
    cardNetworkExternalId: string;
  };
}
