import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { userGroupChangeMembership } from '../lambdaFunctions/userGroupChangeMembership';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'userId',
    formLabel: 'Cognito Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Add User to Throttle Whitelist',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: userGroupChangeMembership.submit,
    },
    inputData: {
      groupId: 'fcb87369-2daa-493a-934b-4452e7c2fed9',
      userId: new InputInjection([0, 'userId']),
      active: true,
    },
  },
];

export const addUserToThrottleWhitelist = {
  steps,
  inputValues,
  name: 'Add User to Throttle Whitelist',
  description: 'Prevent user from being in throttles on any offer',
  tags: [Tags.mugs, Tags.partnerOnboarding],
};
