import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { Dropdown, Col, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BTable from 'react-bootstrap/Table';
import { Column, useTable } from 'react-table';
import { Icon } from 'react-icons-kit';
import { threeHorizontal } from 'react-icons-kit/entypo/threeHorizontal';
import { useForm } from 'react-hook-form';

import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { Tags } from '@/helpers/types';
import { DropDown } from '@/components/DropDown';
import { formatOptions } from '@/utils/dropdown';

const getAppAlertNotifications = lambdaConstructor({
  serviceName: 'notification-service',
  functionName: 'getAppAlertNotifications',
  method: HttpMethod.POST,
});

const removeAppAlertNotification = lambdaConstructor({
  serviceName: 'notification-service',
  functionName: 'removeAppAlertNotification',
  method: HttpMethod.POST,
});

const appAlertAliasOptions = formatOptions(process.env.appAlertAliasOptions);

const AppAlertOptions: FunctionComponent<{
  id: string;
  setEventAlerts: React.Dispatch<React.SetStateAction<EventAlert[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  alias: string;
}> = ({ id, setEventAlerts, setLoading, alias }) => {
  const removeAlertNotification = async () => {
    try {
      setLoading(true);
      await removeAppAlertNotification({
        alias,
        id,
      });
      const data: any = await getAppAlertNotifications({
        alias,
      });
      setEventAlerts(data.eventAlerts);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="link"
        id="appAlertOptions-dropdown"
        className="appAlertOptions-dropdown"
      >
        <Icon icon={threeHorizontal} className="appAlertOptionIcon" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          className="deleteAppAlert"
          onClick={removeAlertNotification}
        >
          Delete Message
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

interface FormValues {
  appAlertAlias: { label: string; value: string };
}

interface EventAlert {
  id: string;
  title: string;
  durationMs: number;
  eventKey: string;
  eventProperties: string[];
}

const AppAlerts: FunctionComponent = () => {
  const [eventAlerts, setEventAlerts] = useState<EventAlert[]>([]);
  const [loading, setLoading] = useState(true);

  const { control, watch, errors } = useForm<FormValues>({
    defaultValues: {
      appAlertAlias: appAlertAliasOptions[0],
    },
  });

  const { appAlertAlias } = watch();

  useEffect(() => {
    (async () => {
      setLoading(true);

      const data: any = await getAppAlertNotifications({
        alias: appAlertAlias.value,
      });

      setEventAlerts(data.eventAlerts);
      setLoading(false);
    })();
  }, [appAlertAlias.value]);

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Event Name',
          accessor: 'eventKey',
        },
        {
          Header: 'Event Property',
          accessor: 'eventProperties[0].name',
        },
        {
          Header: 'Property Value',
          accessor: 'eventProperties[0].value',
        },
        {
          Header: 'Message',
          accessor: 'body',
        },
        {
          Header: 'Options',
          accessor: 'id',
          Cell: ({ row }: any) => (
            <AppAlertOptions
              id={row.original.id}
              setEventAlerts={setEventAlerts}
              setLoading={setLoading}
              alias={appAlertAlias.value}
            />
          ),
        },
      ] as Column<EventAlert>[],
    [appAlertAlias.value],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: eventAlerts,
    });

  return (
    <Col>
      <div className="appAlert-viewAlertsWrapper">
        <div className="appAlert-viewAlertsHeaderWrapper">
          <p style={{ color: 'black', marginBottom: '0px' }}>
            Active App Alerts
          </p>
          <p style={{ fontSize: '11px', marginBottom: '0px' }}>
            *it may take up to a minute for newly created or deleted app alerts
            to be reflected in the table
          </p>
        </div>
        <DropDown
          control={control}
          errors={errors}
          name="appAlertAlias"
          label="view app alerts for the following alias"
          labelClass="viewingAlertAliasLabel"
          placeholder="select an alias"
          options={appAlertAliasOptions}
          isClearable={false}
        />
      </div>

      {loading ? (
        <Row
          style={{
            background: 'white',
            padding: '1rem',
            margin: 'auto',
            justifyContent: 'center',
          }}
        >
          <Spinner animation="border" />
        </Row>
      ) : (
        <BTable {...getTableProps({ className: 'appAlerts-table' })}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps({
                  className: 'appAlerts-tableHeader',
                })}
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps({ className: 'appAlerts-tableBody' })}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </BTable>
      )}
      <Row className="float-right appAlert-buttonRow">
        <Link
          to={{
            pathname: '/customworkflow/CreateAppAlert',
            state: { appAlertAlias },
          }}
          className="btn-primary buttonLink"
        >
          Add New Status Message
        </Link>
      </Row>
    </Col>
  );
};

const AppAlertsConfig = {
  Component: AppAlerts,
  name: 'Set or Remove App Alert',
  description:
    'Add or remove an app alert in the Dosh Consumer Application. For documentation on what app alerts are or how they work, please visit the runbook <a href="https://doshteam.atlassian.net/wiki/spaces/Engineering/pages/1478656045/Sand+Castle+Run+Book" target="_blank">here</a>',
  tags: [Tags.outage],
};

export default AppAlertsConfig;
