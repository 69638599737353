import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'locationId',
    formLabel: 'Location Id',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'dosh-on-venue-svc',
  functionName: 'onboardVenueToCardNetworks',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});

export const onboardVenueToCardNetworks = {
  name: 'Onboard Venue To Card Networks',
  description: 'Onboard venue to card networks',
  inputValues,
  outputValueName: 'result',
  tags: [Tags.cnids, Tags.venues],
  submit,
};
