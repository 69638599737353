import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { redshiftEscape } from '@/utils/strings';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { getContentFeed } from '../lambdaFunctions/getContentFeed';
import { upsertContentFeed } from '../lambdaFunctions/upsertContentFeed';
import { marketplacesGetNames } from '../lambdaFunctions/marketplacesGetNames';
import { CurrentFeed, Marketplace, Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'contentFeedMarketplace',
    formLabel: 'Marketplace Content Feed',
    col: 4,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Brand Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
        }: {
          merchantName: string;
        }) => `SELECT mb.brand AS brand_id
          FROM merchantdb.merchants m
          JOIN merchantdb.merchant_brand mb on m.merchant_id = mb.merchant_id
          WHERE m.name = '${redshiftEscape(merchantName)}'
          ;`,
      ),
    },
  },
  {
    name: 'Get current content feed',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getContentFeed.submit,
    },
    inputData: {
      marketplace: new InputInjection(
        [0],
        ({ contentFeedMarketplace }: { contentFeedMarketplace: string }) => {
          if (!contentFeedMarketplace) {
            return;
          }
          if (contentFeedMarketplace.toLowerCase() === 'venmo') {
            return contentFeedMarketplace.toUpperCase();
          }
          return 'DOSH';
        },
      ),
      feedId: 'featured',
    },
  },
  {
    name: 'Get current non-Venmo marketplaces',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: marketplacesGetNames.submit,
    },
    inputData: {},
  },
  {
    name: 'Combine Banner Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      nonVenmoMarketplaces: new InputInjection(
        [3],
        (result: { [key: string]: Marketplace }) => {
          if (!result) {
            return;
          }
          return Object.values(result)
            .map((marketplace: Marketplace) =>
              marketplace.name ? marketplace.name.toUpperCase() : null,
            )
            .filter((name) => !!name && name !== 'VENMO');
        },
      ),
      brand: new InputInjection(
        [1],
        ({ records }: { records: { brand_id: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0].brand_id;
        },
      ),
      contentFeedMarketplace: new InputInjection([0, 'contentFeedMarketplace']),
      currentFeed: new InputInjection(
        [2],
        ({ content }: { content: string }) => {
          if (!content) {
            return;
          }
          return JSON.parse(content);
        },
      ),
    },
  },
  {
    name: 'Upsert new content feed',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: upsertContentFeed.submit,
    },
    inputData: {
      marketplace: new InputInjection(
        [4],
        ({
          nonVenmoMarketplaces,
          contentFeedMarketplace,
        }: {
          nonVenmoMarketplaces: string;
          contentFeedMarketplace: string;
        }) => {
          if (!contentFeedMarketplace || !nonVenmoMarketplaces) {
            return;
          }
          return contentFeedMarketplace.toLowerCase() === 'venmo'
            ? ['VENMO']
            : nonVenmoMarketplaces;
        },
      ),
      feedId: 'featured',
      content: new InputInjection(
        [4],
        ({
          brand,
          currentFeed,
          contentFeedMarketplace,
        }: {
          brand: string;
          currentFeed: CurrentFeed;
          contentFeedMarketplace: string;
        }) => {
          if (!brand || !currentFeed || !contentFeedMarketplace) {
            return;
          }
          const newBannerFeed = currentFeed.banners.filter(
            (banner) => !!banner && banner.brand !== brand,
          );
          return {
            title:
              contentFeedMarketplace.toLowerCase() !== 'venmo'
                ? 'Featured Offers'
                : 'Featured Debit Card Offers',
            banners: newBannerFeed,
          };
        },
      ),
    },
  },
];

export const deleteBannerFromBannerFeed = {
  steps,
  inputValues,
  name: 'Delete Banner from Featured Banners',
  description: 'Delete Banner from Featured Banners (immediate removal)',
  tags: [Tags.banners],
};
