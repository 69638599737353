import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'name',
    formLabel: 'Merchant Name (partials accepted)',
  },
  {
    name: 'zip',
    formLabel: 'Zip Code',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Retrieve RN Merchant Details',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          name,
          zip,
        }: {
          name: string;
          zip: string;
        }) => `SELECT DISTINCT m.number as rn_merchant_number, mo.merchant_name, ma.address1 as street_address, ma.address2 as suite, ma.city, ma.state, ma.zip
        FROM rnoffersnetworkdb.merchants m
        INNER JOIN rnoffersnetworkdb.merchant_offers mo ON m.id = mo.merchant_id
        JOIN rnoffersnetworkdb.merchant_versions mv on m.id = mv.merchant_id
        JOIN rnoffersnetworkdb.merchant_to_geo mtg on mv.id = mtg.merchant_version
        JOIN rnoffersnetworkdb.merchant_geos mg on mg.id = mtg.geo_id
        JOIN rnoffersnetworkdb.merchant_addresses ma on mg.address_id = ma.id
        WHERE mo.merchant_name ilike '%${redshiftEscape(name)}%'
        AND ma.zip ilike '%${zip}%';`,
      ),
      retries: 10,
    },
  },
];

export const getRnMerchantNumberByNameAndZip = {
  steps,
  inputValues,
  name: 'Get RN Merchant Number By Name and Zip',
  description: 'Look up rn merchant number with name and zip',
  tags: [Tags.venues, Tags.specialMerchants],
};
