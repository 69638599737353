import {
  generateMatcher,
  Specificity,
} from '@dosh/commons-node-matcher-generator';
import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { BrandPlaceMatcherResults, MatcherObject, Tags } from '@/helpers/types';
import { getMccDescription } from '@/helpers/merchantCategoryCodes';
import { GET_PLACE_MATCHERS } from '../graphql/getPlaceMatchers.gql';
import { UPSERT_PLACE_MATCHERS } from '../graphql/upsertPlaceMatchers.gql';
import { regexSpecificityOptions } from '@/helpers/commonInputSelectOptions';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';
import { Option } from '@/components/WorkflowSteps/CheckboxSelector';

const inputValues = [
  {
    name: 'brandId',
    formLabel: 'Brand Id for Matchers',
    type: 'TEXT',
  },
  {
    name: 'specificity',
    formLabel: 'Level of Specificity',
    options: regexSpecificityOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'responseFile',
    formLabel: 'Response File',
    type: InputFieldTypes.FILE,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Retrieve possible names from uploaded file',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      records: new InputInjection([0, 'responseFile'], (data: any) => {
        const records: any = {};
        const lines = data.split('\n');
        lines.forEach((line: string) => {
          const rows = line.split('|');
          if (rows[0] === '25') {
            const record = records[rows[8]] || { occurrences: 0 };
            records[rows[8]] = {
              ...record,
              name: rows[8],
              mcc_code: rows[27],
              occurrences: record.occurrences + 1,
            };
          }
        });
        return Object.values(records);
      }),
    },
  },
  {
    name: 'Select MCC Codes',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: { name: string; mcc_code: string; occurrences: number }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record: any) => {
            const mccDisplayValue = getMccDescription(record.mcc_code);
            return {
              id: `${record.mcc_code}:::${record.name}`,
              name: `NAME: ${record.name} | MCC: ${record.mcc_code}: ${mccDisplayValue} | OCURRENCES: ${record.occurrences}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'MCC codes and Specificity',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      specificity: new InputInjection([0, 'specificity']),
      mccCodes: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        const mccCodes: string[] = [];
        const selections = getArrayFromCheckboxSelections(result);
        selections.forEach((selection) => {
          mccCodes.push(selection.split(':::')[0]);
        });
        return [...new Set(mccCodes)];
      }),
      storeNames: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        const storeNames: string[] = [];
        const selections = getArrayFromCheckboxSelections(result);
        selections.forEach((selection) => {
          storeNames.push(selection.split(':::')[1]);
        });
        return [...new Set(storeNames)];
      }),
    },
  },
  {
    name: 'Select Place Matchers',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [3],
        ({
          specificity,
          storeNames,
        }: {
          specificity: string;
          storeNames: string[];
        }) => {
          if (!specificity || !storeNames) {
            return;
          }
          const options: Option[] = [];
          storeNames.forEach((name) => {
            const matcher = generateMatcher({
              name,
              specificity:
                Specificity[specificity as keyof typeof Specificity] ||
                Specificity.SPECIFIC,
            });
            options.push({
              id: `${JSON.stringify(matcher)}`,
              name: `${matcher.pattern}`,
            });
          });
          return options;
        },
      ),
    },
  },
  {
    name: 'Combine Selected Regex',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      selectedMatchers: new InputInjection(
        [4],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          const matchersToAdd: MatcherObject[] = [];
          const selections = getArrayFromCheckboxSelections(result);
          selections.forEach((selection) => {
            const newMatcher = JSON.parse(selection);
            if (
              newMatcher &&
              !matchersToAdd.some(
                (match) => match.pattern === newMatcher.pattern,
              )
            ) {
              matchersToAdd.push(newMatcher);
            }
          });
          return matchersToAdd;
        },
      ),
    },
  },
  {
    name: 'Get Current Matchers',
    autorun: true,
    inputData: {
      id: new InputInjection([0, 'brandId']),
    },
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_PLACE_MATCHERS,
    },
  },
  {
    name: 'Combine Regex Results with New Regex',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      currentMatchers: new InputInjection(
        [6],
        ({ brand }: { brand: BrandPlaceMatcherResults }) => {
          if (
            !brand ||
            !brand.rawPlaceMatchers ||
            !brand.rawPlaceMatchers.matchers
          ) {
            return;
          }
          return brand.rawPlaceMatchers.matchers;
        },
      ),
      newMatchers: new InputInjection([5, 'selectedMatchers']),
    },
  },
  {
    name: 'Upsert New Place Matchers',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: UPSERT_PLACE_MATCHERS,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ matcher }: { matcher: string }) => {
            if (!matcher) {
              return;
            }
            return matcher.length < 4;
          },
        ),
      },
      id: new InputInjection([0, 'brandId']),
      matchers: new InputInjection(
        [7],
        ({
          currentMatchers,
          newMatchers,
        }: {
          currentMatchers: MatcherObject[];
          newMatchers: MatcherObject[];
        }) => {
          if (!newMatchers) {
            return;
          }
          const matchers =
            !currentMatchers || !currentMatchers.length
              ? newMatchers
              : [...currentMatchers, ...newMatchers];
          return matchers;
        },
      ),
    },
  },
];

export const generateMastercardResponseFileMatchers = {
  steps,
  inputValues,
  name: 'Generate Mastercard Response File Place Matchers',
  description:
    'Generate place matchers for a merchant from Mastercard Response File',
  tags: [Tags.newMerchantOnboarding, Tags.venues, Tags.cnids],
};
