import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'groupId',
    formLabel: 'Group Id',
  },
  {
    name: 'userId',
    formLabel: 'Cognito Id',
  },
  {
    name: 'active',
    formLabel: 'Active',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'marketplace-user-groups-svc',
  functionName: 'userGroupChangeMembership',
  method: HttpMethod.POST,
});

export const userGroupChangeMembership = {
  name: 'Change User MUG Membership',
  description: 'add or remove a user from an existing MUG',
  inputValues,
  tags: [Tags.mugs, Tags.partnerOnboarding],
  submit,
};
