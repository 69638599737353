/*
1. break this out into a workflow component that is config driven
1. Make an enum for step type
1. make an interface for steps
1. this step interface in workflow step input
1. change step index so that i don't have to do -1 everywhere
*/

import React, { FunctionComponent } from 'react';
import { Link, useParams } from 'react-router-dom';

import Workflow from '@/components/Workflow';
import workflows from '@/services/workflows';

interface PathParams {
  workflowName: string;
}

const WorkflowPage: FunctionComponent = () => {
  const { workflowName } = useParams() as PathParams;

  const config = workflows[workflowName];

  if (!config) {
    return (
      <>
        <h1>
          No workflow with the name
          {workflowName}
        </h1>
        <Link to="/">Go Home</Link>
      </>
    );
  }

  return (
    <Workflow
      steps={config.steps}
      inputValues={config.inputValues}
      name={config.name}
      description={config.description}
    />
  );
};

export default WorkflowPage;
