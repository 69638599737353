import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'responseFile',
    formLabel: 'Response File',
    type: InputFieldTypes.FILE,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Split based on the name in the passthru column',
    autorun: false,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      metaData: {},
      header: new InputInjection([0, 'responseFile'], (data: any) => {
        let header;
        const lines = data.split('\n');
        lines.forEach((line: string) => {
          const rows = line.split('|');
          if (rows[0] === '10') {
            header = line;
          }
        });
        return header;
      }),
      footer: new InputInjection([0, 'responseFile'], (data: any) => {
        let footer;
        const lines = data.split('\n');
        lines.forEach((line: string) => {
          const rows = line.split('|');
          if (rows[0] === '30') {
            footer = line;
          }
        });
        return footer;
      }),
      result: new InputInjection([0, 'responseFile'], (data: any) => {
        const result: any = { SUPRESSED: [] };
        const lines = data.split('\n');
        lines.forEach((line: string) => {
          const rows = line.split('|');
          if (rows[0] === '25') {
            if (
              ['PAYMENT FACILITATOR', 'No Merch ID'].indexOf(rows[4]) === -1
            ) {
              result[rows[33]] = result[rows[33]] || [];
              result[rows[33]].push(line);
            } else {
              result.SUPRESSED.push(line);
            }
          }
        });
        return result;
      }),
    },
  },
  {
    name: 'Create CSV files for each name in the passthru column',
    autorun: false,
    process: {
      type: WorkflowStepTypes.DOWNLOAD_DATA,
    },
    inputData: {
      metaData: {},
      header: new InputInjection([1, 'header']),
      result: new InputInjection([1, 'result']),
      footer: new InputInjection([1, 'footer']),
      format: 'json',
    },
  },
];

export const parseMastercardResponseFile = {
  steps,
  inputValues,
  name: 'Parse Mastercard Response File',
  description:
    'Workflow to upload the response file and split based on the name in the passthru column, and allows user to download it afterwards',
  tags: [Tags.cnids, Tags.venues],
};
