import React, { FocusEvent } from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import is from 'is_js';
import validate from '@/utils/validator';

import './TextField.css';

export const TextField = ({
  register,
  path,
  validationFn,
  onBlur,
  placeholder = '',
  errors,
  formLabel,
  disabled,
  subLabel = '',
  type = 'text',
  className,
  tabIndex,
}: {
  register: Function;
  path: string;
  validationFn?: Function;
  onBlur?: (e: FocusEvent) => void;
  placeholder?: string;
  formLabel?: string;
  className?: string;
  errors?: any;
  disabled?: boolean;
  subLabel?: string;
  type?: string;
  tabIndex?: number;
}) => (
  <div
    className={classNames(className, {
      'form-group': is.edge(),
      'form-label-group': !is.edge(),
    })}
  >
    {formLabel && (
      <Form.Label>
        {formLabel}
        {subLabel && <p className="formLabelSub">{subLabel}</p>}
      </Form.Label>
    )}
    <input
      ref={register({ validate: validationFn || validate.notRequired })}
      name={path}
      id={path}
      className="form-control form_input"
      placeholder={placeholder}
      disabled={disabled}
      onBlur={onBlur}
      type={type}
      tabIndex={tabIndex}
    />
    {errors && errors[path] ? (
      <em className="errorMessage">{errors[path].message}</em>
    ) : null}
  </div>
);
