import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import {
  CardNetwork,
  CardNetworkIdType,
  MatchBasis,
  Tags,
} from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';
import { addManualONVenueToCNIdMapping } from '../lambdaFunctions/addManualONVenueToCNIdMapping';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'responseFile',
    formLabel: 'Response File',
    type: InputFieldTypes.FILE,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Split based on the name in the passthru column',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      metaData: {},
      header: new InputInjection([0, 'responseFile'], (data: any) => {
        let header;
        const lines = data.split('\n');
        lines.forEach((line: string) => {
          const rows = line.split('|');
          if (rows[0] === '10') {
            header = line;
          }
        });
        return header;
      }),
      footer: new InputInjection([0, 'responseFile'], (data: any) => {
        let footer;
        const lines = data.split('\n');
        lines.forEach((line: string) => {
          const rows = line.split('|');
          if (rows[0] === '30') {
            footer = line;
          }
        });
        return footer;
      }),
      result: new InputInjection([0, 'responseFile'], (data: any) => {
        const supressed = [];
        const result: any = {};
        const lines = data.split('\n');
        lines.forEach((line: string) => {
          const rows = line.split('|');
          if (rows[0] === '25') {
            if (
              ['PAYMENT FACILITATOR', 'No Merch ID'].indexOf(rows[4]) === -1
            ) {
              result[rows[33]] = result[rows[33]] || [];
              result[rows[33]].push(line);
            } else {
              supressed.push(line);
            }
          }
        });
        return result;
      }),
    },
  },
  {
    name: 'Select Name for Matches',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          result,
        }: {
          result: {
            [key: string]: string[];
          }[];
        }) => {
          if (!result) {
            return;
          }
          return Object.keys(result).map((name) => {
            return {
              id: `${name}`,
              name: `${name}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Get Online ONVenue Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
        }: {
          merchantName: string;
        }) => `SELECT DISTINCT sl.source_location_id as onvenue_uuid, m.name, l.street_address
        FROM merchantdb.merchants m
        JOIN merchantdb.locations l on l.merchant_id = m.merchant_id
        JOIN merchantdb.source_locations sl on l.location_id = sl.location_id
        WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
        AND l.type = 'online';`,
      ),
    },
  },
  {
    name: 'Select Venue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [3],
        ({
          records,
        }: {
          records: {
            onvenue_uuid: string;
            name: string;
            street_address: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.onvenue_uuid}`,
              name: `Merchant Name: ${record.name}; ONVenue UUID: ${record.onvenue_uuid}; Street Address: ${record.street_address}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Process selections',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      splitRows: new InputInjection([1, 'result']),
      selectedNameForMatches: new InputInjection(
        [2],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          return getArrayFromCheckboxSelections(result)[0];
        },
      ),
    },
  },
  {
    name: 'Identify Matches',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      matchingAndUniqueLIDs: new InputInjection(
        [5],
        ({
          splitRows,
          selectedNameForMatches,
        }: {
          splitRows: {
            [key: string]: string[];
          };
          selectedNameForMatches: string;
        }) => {
          if (!splitRows || !selectedNameForMatches) {
            return;
          }
          const externalIds = new Set();
          splitRows[selectedNameForMatches].forEach((row) => {
            externalIds.add(row.split('|')[5]);
          });
          return [...externalIds];
        },
      ),
      selectedOnlineOnvenue: new InputInjection(
        [4],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          return getArrayFromCheckboxSelections(result)[0];
        },
      ),
    },
  },
  {
    name: 'Create Invocation Array',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      invocationArray: new InputInjection(
        [6],
        ({
          matchingAndUniqueLIDs,
          selectedOnlineOnvenue,
        }: {
          matchingAndUniqueLIDs: string[];
          selectedOnlineOnvenue: string;
        }) => {
          if (!matchingAndUniqueLIDs || !selectedOnlineOnvenue) {
            return;
          }
          return matchingAndUniqueLIDs.map((lid) => {
            return {
              onvenueUuid: selectedOnlineOnvenue,
              cardNetwork: CardNetwork.MASTERCARD,
              cardNetworkExternalIdType: CardNetworkIdType.LID,
              cardNetworkExternalId: lid,
              basis: MatchBasis.PLACE_MATCH,
            };
          });
        },
      ),
      selectedOnlineOnvenue: new InputInjection(
        [4],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          return getArrayFromCheckboxSelections(result)[0];
        },
      ),
    },
  },
  {
    name: 'Map',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addManualONVenueToCNIdMapping.submit,
    },
    inputData: {
      multipleInvocations: true,
      invocationArray: new InputInjection(
        [7],
        ({
          invocationArray,
        }: {
          invocationArray: {
            onvenueUuid: string;
            cardNetwork: CardNetwork;
            cardNetworkExternalIdType: CardNetworkIdType;
            cardNetworkExternalId: string;
            basis: MatchBasis;
          }[];
        }) => {
          if (!invocationArray || !Array.isArray(invocationArray)) {
            return;
          }
          return invocationArray;
        },
      ),
    },
  },
];

export const mapMastercardMidsForOnlineMerchant = {
  steps,
  inputValues,
  name: 'Map Mastercard Mids for Online Merchant',
  description:
    'Workflow to upload the Mastercard response file and add mappings to online location for a merchant',
  tags: [Tags.cnids, Tags.venues],
};
