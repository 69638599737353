import { format, subMonths } from 'date-fns';
import {
  generateMatcher,
  Specificity,
} from '@dosh/commons-node-matcher-generator';
import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { Option } from '@/components/WorkflowSteps/CheckboxSelector';
import {
  MatcherObject,
  MatcherTypes,
  Tags,
  Transaction,
} from '@/helpers/types';
import { UPSERT_TRANSACTION_MATCHERS } from '../graphql/upsertTransactionMatchers.gql';
import { regexSpecificityOptions } from '@/helpers/commonInputSelectOptions';
import { getMccDescription } from '@/helpers/merchantCategoryCodes';
import { redshiftEscape } from '@/utils/strings';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'brandId',
    formLabel: 'Brand Id for Matchers',
  },
  {
    name: 'specificity',
    formLabel: 'Level of Specificity',
    options: regexSpecificityOptions,
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Retrieve possible names from transaction feed',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT
            LOWER(d.merchant_name) as name, c.code as mcc_code, count(distinct cte.transaction_id) as occurrences
          FROM empyrdb.card_transaction_events cte
          JOIN empyrdb.card_transaction_events_debug d ON d.card_transaction_event_id = cte.id
          LEFT JOIN empyrdb.card_transaction_events_card_network_ids cnid ON cnid.card_transaction_event_id = cte.id
          LEFT JOIN empyrdb.card_transaction_events_categorization c ON c.card_transaction_event_id = cte.id AND c.source_id = 1
          WHERE
          d.merchant_name ILIKE '%${redshiftEscape(merchantName)}%' AND
          cte.transaction_timestamp > '${format(
            subMonths(new Date(), 1),
            'yyyy-MM-dd',
          )}'
          GROUP BY 1,2
          ORDER BY 3 DESC
          ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Select MCC Codes',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({ records }: { records: Transaction[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record: any) => {
            const mccDisplayValue = getMccDescription(record.mcc_code);
            return {
              id: `${record.mcc_code}:::${record.name}`,
              name: `NAME: ${record.name} | MCC: ${record.mcc_code}: ${mccDisplayValue} | OCURRENCES: ${record.occurrences}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'MCC codes and Specificity',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      specificity: new InputInjection([0, 'specificity']),
      mccCodes: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        const mccCodes: string[] = [];
        const selections = getArrayFromCheckboxSelections(result);
        selections.forEach((selection) => {
          mccCodes.push(selection.split(':::')[0]);
        });
        return [...new Set(mccCodes)];
      }),
      storeNames: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        const storeNames: string[] = [];
        const selections = getArrayFromCheckboxSelections(result);
        selections.forEach((selection) => {
          storeNames.push(selection.split(':::')[1]);
        });

        return [...new Set(storeNames)];
      }),
    },
  },
  {
    name: 'Select Transaction Matchers',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [3],
        ({
          specificity,
          storeNames,
        }: {
          specificity: string;
          storeNames: string[];
        }) => {
          if (!specificity || !storeNames) {
            return;
          }
          const options: Option[] = [];
          storeNames.forEach((name) => {
            const matcher = generateMatcher({
              name,
              specificity:
                Specificity[specificity as keyof typeof Specificity] ||
                Specificity.SPECIFIC,
            });
            options.push({
              id: `${JSON.stringify(matcher)}`,
              name: `${matcher.pattern}`,
            });
          });
          return options;
        },
      ),
    },
  },
  {
    name: 'Combine Selected Mcc Codes and Regex',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      selectedMatchers: new InputInjection(
        [4],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          const matchersToAdd: MatcherObject[] = [];
          const selections = getArrayFromCheckboxSelections(result);
          selections.forEach((selection) => {
            const newMatcher = JSON.parse(selection);
            if (
              newMatcher &&
              !matchersToAdd.some(
                (match) => match.pattern === newMatcher.pattern,
              )
            ) {
              matchersToAdd.push(newMatcher);
            }
          });
          return matchersToAdd;
        },
      ),
      mccCodes: new InputInjection(
        [3],
        ({ mccCodes }: { mccCodes: string[] }) => {
          if (!mccCodes) {
            return;
          }
          const matchersToAdd: MatcherObject[] = [];
          mccCodes.forEach((code) => {
            const newMatcher: MatcherObject = {
              pattern: code,
              type: MatcherTypes.MERCHANT_CATEGORY_CODE,
            };
            matchersToAdd.push(newMatcher);
          });
          return matchersToAdd;
        },
      ),
    },
  },
  {
    name: 'Upsert New Transaction Matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: UPSERT_TRANSACTION_MATCHERS,
    },
    inputData: {
      id: new InputInjection([0, 'brandId']),
      matchers: new InputInjection(
        [5],
        ({
          selectedMatchers,
          mccCodes,
        }: {
          selectedMatchers: MatcherObject[];
          mccCodes: MatcherObject[];
        }) => {
          if (!selectedMatchers || !mccCodes) {
            return;
          }
          return [...selectedMatchers, ...mccCodes];
        },
      ),
    },
  },
];

export const generateTransactionMatchers = {
  steps,
  inputValues,
  name: 'Generate Transaction Matchers',
  description: 'Generate transaction matchers for a merchant',
  tags: [Tags.newMerchantOnboarding, Tags.transactions],
};
