import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'mode',
    formLabel: 'ACTIVATE or DEACTIVATE',
  },
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
  },
  {
    name: 'cardNetworkExternalIdType',
    formLabel: 'Card Network External Id Type',
    default: 'BRAND',
  },
  {
    name: 'cardNetworkExternalId',
    formLabel: 'Card Network External Id',
  },
  {
    name: 'merchantGroup',
    formLabel: 'Merchant Group (Visa only)',
  },
  {
    name: 'communityCode',
    formLabel: 'Community Code (Visa only)',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-mgmt-svc',
  functionName: 'markCNTransactionsForCNId',
  method: HttpMethod.POST,
});

export const markCNTransactionsForCNId = {
  name: 'Mark Transactions for CNId',
  description: 'Mark transactions for brand cnid',
  inputValues,
  outputValueName: 'result',
  tags: [Tags.cnids],
  submit,
};
