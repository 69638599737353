import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid',
  },
  {
    name: 'startDate',
    formLabel: 'Offer Start Date',
    formSubLabel: 'Format: 2020-07-22T00:00:00.000Z (UTC time)',
  },
  {
    name: 'endDate',
    formLabel: 'Offer Start Date',
    formSubLabel: 'Format: 2020-07-22T00:00:00.000Z (UTC time)',
  },
  {
    name: 'offerDescription',
    formLabel: 'Offer Description',
  },
  {
    name: 'visible',
    formLabel: 'Offer Visibility',
    formSubLabel: 'Boolean value: 1 = true, 0 = false',
  },
  {
    name: 'deleted',
    formLabel: 'Offer Deletion',
    formSubLabel: 'Boolean value: 1 = true, 0 = false',
  },
  {
    name: 'priority',
    formLabel: 'Set Offer Priority',
  },
  {
    name: 'headerImagePublicId',
    formLabel: 'Header Image from Cloudinary',
  },
  {
    name: 'keywords',
    formLabel: 'Offer Keywords',
  },
  {
    name: 'updatedBy',
    formLabel: 'Name of person making updates',
  },
  {
    name: 'restrictions',
    formLabel: 'Offer Restrictions',
    formSubLabel: 'To remove restrictions, set value to NULL',
  },
  {
    name: 'legacyVenmoFeatured',
    formLabel: 'Legacy Venmo Featured?',
    formSubLabel: 'Boolean value: 1 = true, 0 = false',
  },
  {
    name: 'legacyVenmoDescriptionOverride',
    formLabel: 'Legacy Venmo Description Override',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'campaign-service',
  functionName: 'setOfferProperties',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});

export const setOfferProperties = {
  name: 'Set Offer Properties',
  description: 'Set Offer Properties',
  inputValues,
  outputValueName: 'Set Offer Properties',
  tags: [Tags.offers],
  submit,
};
