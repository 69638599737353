import { addAggregateVMIDMapping } from './addAggregateVMIDMapping';
import { addOfferTargetingAudienceCompetitiveBrandsUser } from './addOfferTargetingAudienceCompetitiveBrandsUser';
import { addOfferTargetingAudienceCompetitiveCategoriesUser } from './addOfferTargetingAudienceCompetitiveCategoriesUser';
import { addOfferTargetingAudienceLapsedUser } from './addOfferTargetingAudienceLapsedUser';
import { addOfferTargetingAudienceLoyalUser } from './addOfferTargetingAudienceLoyalUser';
import { addOfferTargetingAudienceNewUser } from './addOfferTargetingAudienceNewUser';
import { adjustBudget } from './adjustBudget';
import { approveAssets } from './approveAssets';
import { createOnlineVenueForMerchant } from './createOnlineVenueForMerchant';
import { duplicateCardErrors } from './duplicateCardErrors';
import { dynamicallyAddVenuesToOffer } from './dynamicallyAddVenuesToOffer';
import { generateTransactionMatchers } from './generateTransactionMatchers';
import { getCardIdentifiersForUser } from './getCardIdentifiersForUser';
import { getListOfUsersInMUG } from './getListOfUsersInMUG';
import { mapOnlineSE } from './mapOnlineSE';
import { merchantImplementationPreCheck } from './merchantImplementationPreCheck';
import { onlineQASession } from './onlineQASession';
import { optInUserSms } from './optInUserSms';
import { postLaunchQA } from './postLaunchQA';
import { setupNearbyBrandOfferNetworkVenue } from './setupNearbyBrandOfferNetworkVenue';
import { setupOnlineBrandOfferNetworkVenue } from './setupOnlineBrandOfferNetworkVenue';
import { addBannerToBannerFeed } from './addBannerToBannerFeed';
import { deleteBannerFromBannerFeed } from './deleteBannerFromBannerFeed';
import { updateMaxCashbackPerTransaction } from './updateMaxCashbackPerTransaction';
import { whitelistAddFilteringMarketplace } from './whitelistAddFilteringMarketplace';
import { whitelistRemoveFilteringMarketplace } from './whitelistRemoveFilteringMarketplace';
import { updateMerchantName } from './updateMerchantName';
import { updateMinSpendToActivate } from './updateMinSpendToActivate';
import { updateOfferRestrictions } from './updateOfferRestrictions';
import { updateURL } from './updateURL';
import { updateUserMUGMembership } from './updateUserMUGMembership';
import { updateUserMUGMembershipBatch } from './updateUserMUGMembershipBatch';
import { updateAudienceMembershipForUser } from './updateAudienceMembershipForUser';
import { addExistingThrottleToOffer } from './addExistingThrottleToOffer';
import { addUserToThrottleWhitelist } from './addUserToThrottleWhitelist';
import { replayCardTransactionEventsFromEventIdOrCNTransactionId } from './replayCardTransactionEventsFromEventIdOrCNTransactionId';
import { addGraduationRuleToOffer } from './addGraduationRuleToOffer';
import { appendNewTransactionMatchersToExisting } from './appendNewTransactionMatchersToExisting';
import { updateLocationStatusFromStoreId } from './updateLocationStatusFromStoreId';
import { updateThrottleNameAndDescription } from './updateThrottleNameAndDescription';
import { setupBannersForNewMarketplace } from './setupBannersForNewMarketplace';
import { getTransactionsByCognito } from './getTransactionsByCognito';
import { getTransactionByCnTransactionId } from './getTransactionByCnTransactionId';
import { postDeactivationQA } from './postDeactivationQA';
import { removeTransactionMatcher } from './removeTransactionMatcher';
import { checkVisaActivationStatus } from './checkVisaActivationStatus';
import { addOfferTargetingAudienceRandomlyPartitionedUser } from './addOfferTargetingAudienceRandomlyPartitionedUser';
import { unmapRNMerchantFromCNVenueByCNId } from './unmapRNMerchantFromCNVenueByCNId';
import { determineIfCNIdIsProvidedByRN } from './determineIfCNIdIsProvidedByRN';
import { setOfferTitleOverrideOnSegment } from './setOfferTitleOverrideOnSegment';
import { setCashbackDailyLimitOnSegment } from './setCashbackDailyLimitOnSegment';
import { setPriorityOnSegment } from './setPriorityOnSegment';
import { setHideOfferOnSegment } from './setHideOfferOnSegment';
import { setLoyaltyNumberOfPurchasesOnSegment } from './setLoyaltyNumberOfPurchasesOnSegment';
import { createAndAppendTransactionMatcherToExisting } from './createAndAppendTransactionMatcherToExisting';
import { setupQRNearbyBrandOfferNetworkVenue } from './setupQRNearbyBrandOfferNetworkVenue';
import { createAndMapProvidedPaypalMids } from './createAndMapProvidedPaypalMids';
import { qrCodeQASession } from './qrCodeQASession';
import { checkUpcomingOfferEndDates } from './checkUpcomingOfferEndDates';
import { removePlaceMatcher } from './removePlaceMatcher';
import { createAndAppendPlaceMatcherToExisting } from './createAndAppendPlaceMatcherToExisting';
import { getCurrentPlaceMatchersForBrandId } from './getCurrentPlaceMatchersForBrandId';
import { getCurrentTransactionMatchersForBrandId } from './getCurrentTransactionMatchersForBrandId';
import { appendNewPlaceMatcherToExisting } from './appendNewPlaceMatcherToExisting';
import { addSafegraphDefinitionToBrand } from './addSafegraphDefinitionToBrand';
import { updateBrandDescription } from './updateBrandDescription';
import { nearbyQASession } from './nearbyQASession';
import { nearbyAndOnlineQASession } from './nearbyAndOnlineQASession';
import { deactivateCDLXMerchant } from './deactivateCDLXMerchant';
import { deleteOffer } from './deleteOffer';
import { activateLocations } from './activateLocations';
import { deactivateLocations } from './deactivateLocations';
import { createCDLXRedemptionEvent } from './createCDLXRedemptionEvent';
import { parseMastercardResponseFile } from './parseMastercardResponseFile';
import { generateMastercardResponseFileMatchers } from './generateMastercardResponseFileMatchers';
import { updateExistingBanner } from './updateExistingBanner';
import { checkMatchingBrands } from './checkMatchingBrands';
import { hideMerchantOffersFromUser } from './hideMerchantOffersFromUser';
import { getRnMerchantNumberByNameAndZip } from './getRnMerchantNumberByNameAndZip';
import { unmapCNVenueFromONVenue } from './unmapCNVenueFromONVenue';
import { mapCNVenueToONVenueByCNId } from './mapCNVenueToONVenueByCNId';
import { unmapCNIdFromCNVenue } from './unmapCNIdFromCNVenue';
import { refreshTransactionMatchersByMerchantNameLocationType } from './refreshTransactionMatchersByMerchantNameLocationType';
import { getMerchantGroupForActiveVmid } from './getMerchantGroupForActiveVmid';
import { getActiveVmidsByMerchantGroup } from './getActiveVmidsByMerchantGroup';
import { setOfferToInvisible } from './setOfferToInvisible';
import { addSelectLocationsToOffer } from './addSelectLocationsToOffer';
import { checkCNIdDenyListStatus } from './checkCNIdDenyListStatus';
import { checkRNOfferStatusForTransactionDate } from './checkRNOfferStatusForTransactionDate';
import { unmapCNVenueFromONVenueWithCNId } from './unmapCNVenueFromONVenueWithCNId';
import { lockUserAccount } from './lockUserAccount';
import { handleCNIdMismatchForRN } from './handleCNIdMismatchForRN';
import { handleTransactionMatcherMismatch } from './handleTransactionMatcherMismatch';
import { handleInvalidONVenue } from './handleInvalidONVenue';
import { handleMappedCNIdMismatch } from './handleMappedCNIdMismatch';
import { unmapCNVenueFromONVenueWithUuids } from './unmapCNVenueFromONVenueWithUuids';
import { handleCNIdMismatchForTransaction } from './handleCNIdMismatchForTransaction';
import { getBCN } from './getBCNforPartiallyLinkedMasterCard';
import { mccQASession } from './mccQASession';
import { subscribeMerchantToONVenueDiscovery } from './subscribeMerchantToONVenueDiscovery';
import { addLocationTypeToFutureOffer } from './addLocationTypeToFutureOffer';
import { reviewAggregatorMIDs } from './reviewAggregatorMIDs';
import { addConfirmedNonAggregatorCNIdstoAllowedList } from './addConfirmedNonAggregatorCNIdstoAllowedList';
import { handleConfirmedAggregatorCNIds } from './handleConfirmedAggregatorCNIds';
import { mapMastercardMidsForOnlineMerchant } from './mapMastercardMidsForOnlineMerchant';
import { lookupVisaTransaction } from './lookupVisaTransaction';
import { deactivateVMIDsForMerchant } from './deactivateVMIDsForMerchant';

const workflows: any = {
  addAggregateVMIDMapping,
  addOfferTargetingAudienceCompetitiveBrandsUser,
  addOfferTargetingAudienceCompetitiveCategoriesUser,
  addOfferTargetingAudienceLapsedUser,
  addOfferTargetingAudienceLoyalUser,
  addOfferTargetingAudienceNewUser,
  addOfferTargetingAudienceRandomlyPartitionedUser,
  adjustBudget,
  approveAssets,
  createCDLXRedemptionEvent,
  createOnlineVenueForMerchant,
  deactivateVMIDsForMerchant,
  duplicateCardErrors,
  dynamicallyAddVenuesToOffer,
  generateTransactionMatchers,
  getCardIdentifiersForUser,
  getListOfUsersInMUG,
  mapOnlineSE,
  merchantImplementationPreCheck,
  onlineQASession,
  optInUserSms,
  postLaunchQA,
  setupNearbyBrandOfferNetworkVenue,
  setupOnlineBrandOfferNetworkVenue,
  addBannerToBannerFeed,
  deleteBannerFromBannerFeed,
  updateMaxCashbackPerTransaction,
  whitelistAddFilteringMarketplace,
  whitelistRemoveFilteringMarketplace,
  updateMerchantName,
  updateMinSpendToActivate,
  updateOfferRestrictions,
  updateURL,
  updateUserMUGMembership,
  updateUserMUGMembershipBatch,
  updateAudienceMembershipForUser,
  addExistingThrottleToOffer,
  addUserToThrottleWhitelist,
  replayCardTransactionEventsFromEventIdOrCNTransactionId,
  addGraduationRuleToOffer,
  appendNewTransactionMatchersToExisting,
  updateLocationStatusFromStoreId,
  updateThrottleNameAndDescription,
  setupBannersForNewMarketplace,
  getTransactionsByCognito,
  getTransactionByCnTransactionId,
  postDeactivationQA,
  removeTransactionMatcher,
  checkVisaActivationStatus,
  unmapRNMerchantFromCNVenueByCNId,
  determineIfCNIdIsProvidedByRN,
  setOfferTitleOverrideOnSegment,
  setCashbackDailyLimitOnSegment,
  setPriorityOnSegment,
  setHideOfferOnSegment,
  setLoyaltyNumberOfPurchasesOnSegment,
  createAndAppendTransactionMatcherToExisting,
  setupQRNearbyBrandOfferNetworkVenue,
  createAndMapProvidedPaypalMids,
  qrCodeQASession,
  checkUpcomingOfferEndDates,
  removePlaceMatcher,
  createAndAppendPlaceMatcherToExisting,
  getCurrentPlaceMatchersForBrandId,
  getCurrentTransactionMatchersForBrandId,
  appendNewPlaceMatcherToExisting,
  addSafegraphDefinitionToBrand,
  updateBrandDescription,
  nearbyQASession,
  nearbyAndOnlineQASession,
  parseMastercardResponseFile,
  generateMastercardResponseFileMatchers,
  deactivateCDLXMerchant,
  deleteOffer,
  activateLocations,
  deactivateLocations,
  updateExistingBanner,
  checkMatchingBrands,
  hideMerchantOffersFromUser,
  getRnMerchantNumberByNameAndZip,
  unmapCNVenueFromONVenue,
  mapCNVenueToONVenueByCNId,
  unmapCNIdFromCNVenue,
  refreshTransactionMatchersByMerchantNameLocationType,
  getMerchantGroupForActiveVmid,
  getActiveVmidsByMerchantGroup,
  setOfferToInvisible,
  addSelectLocationsToOffer,
  checkCNIdDenyListStatus,
  checkRNOfferStatusForTransactionDate,
  unmapCNVenueFromONVenueWithCNId,
  lockUserAccount,
  handleCNIdMismatchForRN,
  handleTransactionMatcherMismatch,
  handleInvalidONVenue,
  handleMappedCNIdMismatch,
  unmapCNVenueFromONVenueWithUuids,
  handleCNIdMismatchForTransaction,
  getBCN,
  mccQASession,
  subscribeMerchantToONVenueDiscovery,
  addLocationTypeToFutureOffer,
  reviewAggregatorMIDs,
  addConfirmedNonAggregatorCNIdstoAllowedList,
  handleConfirmedAggregatorCNIds,
  mapMastercardMidsForOnlineMerchant,
  lookupVisaTransaction,
};
export default workflows;
