import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'marketplace',
    formLabel: 'Marketplace Name',
  },
  {
    name: 'feedId',
    formLabel: 'Feed',
    default: 'featured',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'content-feed-svc',
  functionName: 'getContentFeed',
  method: HttpMethod.GET,
});

export const getContentFeed = {
  name: 'Get Content Feed By Marketplace',
  description: 'retrieve current featured banners for marketplace',
  inputValues,
  outputValueName: 'feed', // optional
  tags: [Tags.banners],
  submit,
};
