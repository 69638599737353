import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { pathOr } from 'ramda';

import DisplayJson from '@/components/DisplayJson';
import getApprovedMerchants from '@/services/button/getApprovedMerchants';
import { WorkflowStepStatusTypes } from '@/components/Workflow/components/WorkflowStepStatus';
import getErrorMessage from '@/utils/error';

interface WorkflowStepProps {
  name: string;
  active: boolean;
  onCompleteStep: Function;
  setStatus: Function;
  outputData: any;
}

interface ButtonMerchant {
  name: string;
  id: string;
  url: string;
}

export const ButtonMerchantData: FunctionComponent<WorkflowStepProps> = ({
  name,
  active,
  onCompleteStep,
  outputData,
  setStatus,
}) => {
  const [merchants, setMerchants] = useState([]) as any[];

  const getData = async () => {
    setStatus(WorkflowStepStatusTypes.RUNNING);
    try {
      const res = await getApprovedMerchants.submit();
      setMerchants(
        pathOr([], ['objects'], res)
          .map((merchant) => ({
            name: pathOr('No name found', ['name'], merchant),
            id: pathOr('', ['id'], merchant),
            url: pathOr('', ['urls', 'homepage'], merchant),
          }))
          .sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }),
      );
    } catch (error) {
      console.error(error);
      const em = getErrorMessage(error);
      onCompleteStep({ error: em, status: 'failed' });
    }
  };

  const selectMerchant = (merchant: ButtonMerchant) => {
    onCompleteStep({ ...merchant, status: 'success' });
  };

  useEffect(() => {
    if (active && merchants.length === 0 && !outputData) {
      getData();
    }
  });

  return (
    <>
      <div className="workflowStep-text">
        <p className="workflowStep-text-label">
          {active
            ? 'Select button merchant'
            : 'Merchants will load when step is active'}
        </p>
        <Row>
          {merchants.map((merchant: ButtonMerchant) => (
            <Col xs="6" lg="3" key={merchant.id}>
              <Button variant="link" onClick={() => selectMerchant(merchant)}>
                {merchant.name || 'no name found'}
              </Button>
            </Col>
          ))}
        </Row>
      </div>
      {outputData && (
        <div className="workflowStep-text">
          <p className="workflowStep-text-label">{`${name} - Response`}</p>
          <DisplayJson name={`output for ${name}`} json={outputData} />
        </div>
      )}
    </>
  );
};
