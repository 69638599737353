import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

export type CNMgmtVenue = any; // not relevant to get into detail yet about what a venue is

const inputValues = [
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
    formSubLabel: '`VISA` or `MASTERCARD` or `AMEX`',
  },
  {
    name: 'name',
    formLabel: 'Name',
    formSubLabel: 'the name to search for venues with',
  },
  {
    name: 'address.address1',
    formLabel: 'Address.Address1',
    formSubLabel:
      'the street of the address to search with (optional for all card-networks except amex)',
  },
  {
    name: 'address.city',
    formLabel: 'Address.City',
    formSubLabel: 'the city of the address to search with',
  },
  {
    name: 'address.state',
    formLabel: 'Address.State',
    formSubLabel: 'the state of the address to search with',
  },
  {
    name: 'address.postal',
    formLabel: 'Address.Postal',
    formSubLabel: 'the postal of the address to search with',
  },
  {
    name: 'address.country',
    formLabel: 'Address.Country',
    formSubLabel: 'the country of the address to search with ',
    default: 'US',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor<
  {
    cardNetwork: 'VISA' | 'MASTERCARD' | 'AMEX';
    name: string;
    address: {
      street?: string;
      city?: string;
      state?: string;
      postal: string;
      country?: string;
    };
  },
  {
    cnvenues: CNMgmtVenue[];
  }
>({
  serviceName: 'cn-mgmt-svc',
  functionName: 'searchCardNetworkCNVenues',
  method: HttpMethod.POST,
});

export const searchCardNetworkCNVenues = {
  name: '[cn-mgmt-svc] Search Card Network CNVenues',
  description:
    'Searches the card network for CNVenues by address + name and loads the returned cnvenues into our cn-onboarding platform.',
  tags: [Tags.venues, Tags.cnids],
  submit,
  inputValues,
};
