import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [{}] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'poweredby-marketplace-svc',
  functionName: 'marketplacesGetNames',
  method: HttpMethod.GET,
});

export const marketplacesGetNames = {
  name: 'marketplacesGetNames',
  description: 'Get Marketplace Names',
  inputValues,
  outputValueName: 'marketplaces',
  submit,
  tags: [Tags.partnerOnboarding, Tags.poweredBy],
};
