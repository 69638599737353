import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'method',
    formLabel: 'Deposit Lookup Method',
    formSubLabel:
      'One of INITIATOR_COGNITO_ID, RECIPIENT_COGNITO_ID, or TRANSACTION_ID',
    default: 'TRANSACTION_ID',
  },
  {
    name: 'initiatorCognitoId',
    formLabel: 'Initiator Cognito ID',
    formSubLabel:
      'User which initiated a transaction that resulted in a deposit. Required if deposit lookup method is INITIATOR_COGNITO_ID',
    default: '',
  },
  {
    name: 'recipientCognitoId',
    formLabel: 'Recipient Cognito ID',
    formSubLabel:
      'User which is the recipient of a deposit. Required if deposit lookup method is INITIATOR_COGNITO_ID',
    default: '',
  },
  {
    name: 'transactionId',
    formLabel: 'CTE Transaction ID',
    formSubLabel: 'Required if deposit lookup method is TRANSACTION_ID',
    default: '',
  },
  {
    name: 'transactionSource',
    formLabel: 'Deposit Transaction Source',
    formSubLabel:
      'e.g. "empyr-service-visa" for a Visa Transaction or "card-service" for a Cardlink reward',
    default: 'empyr-service-visa',
  },
  // not bothering with filters param for now
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'matching-engine-service',
  functionName: 'getDeposits',
  method: HttpMethod.POST,
});

export const getMatchingEngineDeposits = {
  name: 'Get Matching Engine Deposits',
  inputValues,
  tags: [Tags.transactions, Tags.matchingEngine],
  submit,
};
