import gql from 'graphql-tag';

export const GET_TRANSACTION_MATCHERS = gql`
  query brand($id: ID!) {
    brand(id: $id) {
      rawMatchers {
        matchers
      }
    }
  }
`;
