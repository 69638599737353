import React, { FocusEvent } from 'react';
import { Form, Button } from 'react-bootstrap';
import classNames from 'classnames';
import is from 'is_js';
import { useFieldArray } from 'react-hook-form';
import validate from '@/utils/validator';

import './TextField.css';

export const TextFieldArray = ({
  register,
  path,
  validationFn,
  onBlur,
  placeholder = '',
  errors,
  formLabel,
  disabled,
  subLabel = '',
  type = 'text',
  className,
  tabIndex,
  control,
}: {
  register: Function;
  path: string;
  validationFn?: Function;
  onBlur?: (e: FocusEvent) => void;
  placeholder?: string;
  formLabel?: string;
  className?: string;
  errors?: any;
  disabled?: boolean;
  subLabel?: string;
  type?: string;
  tabIndex?: number;
  control: any;
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: path,
  });

  return (
    <div
      className={classNames(className, {
        'form-group': is.edge(),
        'form-label-group': !is.edge(),
      })}
    >
      {formLabel && (
        <Form.Label>
          {formLabel}
          {subLabel && <p className="formLabelSub">{subLabel}</p>}
        </Form.Label>
      )}
      {fields.length === 0 && (
        <p className="formLabelSub">{`No ${formLabel}`}</p>
      )}
      {fields.map((item, index) => (
        <div key={item.id} className="input-group">
          <input
            name={`${path}[${index}].value`}
            id={`${path}[${index}].value`}
            className="form-control form_input"
            defaultValue={`${item.value}`}
            ref={register({ validate: validationFn || validate.notRequired })}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={onBlur}
            type={type}
            tabIndex={tabIndex}
          />
          <div className="input-group-append">
            <button
              type="button"
              disabled={disabled}
              onClick={() => remove(index)}
              className="input-group-text"
            >
              Remove
            </button>
          </div>
        </div>
      ))}
      <div className="formLabelSub">
        <Button
          disabled={disabled}
          size="sm"
          onClick={() => {
            append({ value: '' });
          }}
          variant="link"
        >
          {`Add a ${formLabel}`}
        </Button>
      </div>
      {errors && errors[path] ? (
        <em className="errorMessage">{errors[path].message}</em>
      ) : null}
    </div>
  );
};
