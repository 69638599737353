import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import {
  CardNetwork,
  CardNetworkId,
  CardNetworkIdType,
  Tags,
  Venue,
  YesOrNo,
} from '@/helpers/types';
import { getVenueByUuid } from '../lambdaFunctions/getVenueByUuid';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';
import { yesOrNoOptions } from '@/helpers/commonInputSelectOptions';

const inputValues = [
  {
    name: 'onvenueUuid',
    formLabel: 'ONVenue Uuid',
  },
  {
    name: 'cnvenueUuid',
    formLabel: 'CNVenue Uuid',
  },
  {
    name: 'cnidArray',
    formLabel: 'CNId Array',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get ONVenue Information',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueByUuid.submit,
    },
    inputData: {
      venueUuid: new InputInjection([0, 'onvenueUuid']),
    },
  },
  {
    name: 'Check Validity of ONVenue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.EXTERNAL_STEP,
    },
    inputData: {
      description: 'Validate ONVenue on Google',
      link: new InputInjection(
        [1],
        ({ result, status }: { result: Venue; status: string }) => {
          if (status !== 'success') {
            return;
          }
          return `https://www.google.com/search?q=${result?.name}+${result?.address?.address1}+${result?.address?.city}+${result?.address?.state}+${result?.address?.postal}`;
        },
      ),
    },
  },
  {
    name: 'Is ONVenue Valid?',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection([0], () => {
        return yesOrNoOptions.map((opt) => {
          return {
            id: opt.value,
            name: opt.label,
          };
        });
      }),
    },
  },
  {
    name: 'Combine Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      onvenueUuid: new InputInjection([0, 'onvenueUuid']),
      validOnvenue: new InputInjection([3], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0] === YesOrNo.yes;
      }),
    },
  },
  {
    name: 'Handle Invalid ONVenue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.EXTERNAL_STEP,
    },
    inputData: {
      description: new InputInjection(
        [4],
        ({
          validOnvenue,
          status,
        }: {
          validOnvenue: boolean;
          status: string;
        }) => {
          if (status !== 'success') {
            return;
          }
          return validOnvenue
            ? 'Mark as Complete to Review CNVenue'
            : 'Handle Invalid ONVenue';
        },
      ),
      link: new InputInjection(
        [4],
        ({
          onvenueUuid,
          validOnvenue,
          status,
        }: {
          onvenueUuid: string;
          validOnvenue: boolean;
          status: string;
        }) => {
          if (status !== 'success') {
            return;
          }
          return validOnvenue
            ? `https://www.google.com/`
            : `${window.location.origin}/workflow/handleInvalidONVenue?onvenueUuid=${onvenueUuid}`;
        },
      ),
    },
  },
  {
    name: 'Review CNVenue Information',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueByUuid.submit,
    },
    inputData: {
      venueUuid: new InputInjection([0, 'cnvenueUuid']),
    },
  },
  {
    name: 'Is CNVenue a Good Match?',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection([0], () => {
        return yesOrNoOptions.map((opt) => {
          return {
            id: opt.value,
            name: opt.label,
          };
        });
      }),
    },
  },
  {
    name: 'Combine Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      onvenueUuid: new InputInjection([0, 'onvenueUuid']),
      cnvenueUuid: new InputInjection([0, 'cnvenueUuid']),
      validOnvenue: new InputInjection([4, 'validOnvenue']),
      validMatch: new InputInjection([7], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0] === YesOrNo.yes;
      }),
      cardNetwork: new InputInjection(
        [0],
        ({ cnidArray }: { cnidArray: string }) => {
          if (!cnidArray) {
            return;
          }
          const cnids: CardNetworkId[] = JSON.parse(cnidArray);
          return cnids[0].cardNetwork;
        },
      ),
      removableCNId: new InputInjection(
        [0],
        ({ cnidArray }: { cnidArray: string }) => {
          if (!cnidArray) {
            return;
          }
          const cnids: CardNetworkId[] = JSON.parse(cnidArray);
          return cnids.find((cnid) => cnid.idType === CardNetworkIdType.CAID);
        },
      ),
    },
  },
  {
    name: 'Final Steps',
    autorun: false,
    process: {
      type: WorkflowStepTypes.EXTERNAL_STEP,
    },
    inputData: {
      description: new InputInjection(
        [8],
        ({
          validMatch,
          validOnvenue,
          cardNetwork,
          removableCNId,
          status,
        }: {
          validMatch: boolean;
          validOnvenue: boolean;
          cardNetwork: CardNetwork;
          removableCNId: CardNetworkId;
          status: string;
        }) => {
          if (status !== 'success') {
            return;
          }
          // invalid onvenue, no further action needed
          if (!validOnvenue) {
            return `No further action needed post completion of Handle Invalid ONVenue workflow`;
          }
          // cnvenue is not a valid match for onvenue, unmap
          if (!validMatch) {
            return `Unmap ONVenue From CNVenue`;
          }
          // onvenue is valid, cnvenue is valid, but mismatched cnid is removable, unmap cnid
          if (removableCNId) {
            return `Unmap CNId From CNVenue`;
          }
          // onvenue and cnvenue match are valid, but mismatched cnis is not removable, card netwoks need to be contacted
          return `Contact ${cardNetwork} to review CNId, data may need cleaned on their side`;
        },
      ),
      link: new InputInjection(
        [8],
        ({
          onvenueUuid,
          cnvenueUuid,
          validMatch,
          validOnvenue,
          removableCNId,
          status,
        }: {
          onvenueUuid: string;
          cnvenueUuid: string;
          validMatch: boolean;
          validOnvenue: boolean;
          removableCNId: CardNetworkId;
          status: string;
        }) => {
          if (status !== 'success') {
            return;
          }
          // invalid onvenue, no further action needed
          if (!validOnvenue) {
            return `https://www.google.com/`;
          }
          // cnvenue is not a valid match for onvenue, unmap
          if (!validMatch) {
            return `${window.location.origin}/workflow/unmapCNVenueFromONVenueWithUuids?offerNetworkVenueUuid=${onvenueUuid}&cardNetworkVenueUuid=${cnvenueUuid}`;
          }
          // onvenue is valid, cnvenue is valid, but mismatched cnid is removable, unmap cnid
          if (removableCNId) {
            return `${window.location.origin}/workflow/unmapCNIdFromCNVenue?cardNetworkExternalId=${removableCNId.externalId}&cardNetwork=${removableCNId.cardNetwork}&cardNetworkIdType=${removableCNId.idType}`;
          }
          // onvenue and cnvenue match are valid, but mismatched cnis is not removable, card netwoks need to be contacted
          return `https://www.google.com/`;
        },
      ),
    },
  },
];

export const handleMappedCNIdMismatch = {
  steps,
  inputValues,
  name: 'Handle Mapped CNId Mismatch',
  description: 'Handle mapped CNId mismatches',
  tags: [Tags.cnids],
};
