import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [] as InputFieldConfigs[];

const steps = [
  {
    name: 'Check for Offers Ending in Next 3 Months',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        () => `SELECT DISTINCT getuuid(o.offer_id), c.start_date, c.end_date, m.name
        FROM merchantdb.offers o
        JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
        JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
        WHERE c.end_date BETWEEN current_date AND current_date + 92
        AND m.name not in ('Dosh Café', 'DoshKosh B''gosh', 'FROST A Gelato Shoppe')
        AND m.name not ilike '%test%'
        AND o.deleted = 0
        ORDER BY c.end_date
        ;`,
      ),
      retries: 10,
    },
  },
];

export const checkUpcomingOfferEndDates = {
  steps,
  inputValues,
  name: 'Check Upcoming Offer End Dates',
  description: 'Check for offers ending in the next 3 months',
  tags: [Tags.offers],
};
