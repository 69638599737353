import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'payload',
    formLabel: 'Marketplace Update Payload',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'poweredby-marketplace-svc',
  functionName: 'marketplacesCreateOrUpdate',
  method: HttpMethod.POST,
});

export const createOrUpdateMarketplaceConfig = {
  name: 'Create or Update Marketplace Config',
  description: 'Create or Update Marketplace Config',
  inputValues,
  outputValueName: 'config',
  submit,
};
