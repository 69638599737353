export const getArrayFromCheckboxSelections = (result: Record<string, any>) => {
  const selection: any[] = [];
  Object.values(result).forEach((res) => {
    // remove status messages from results
    if (res !== 'success' && res !== 'skipped') {
      selection.push(res);
    }
  });
  return selection;
};
