import gql from 'graphql-tag';

export const UPSERT_PLACE_MATCHERS = gql`
  mutation setBrandPlaceMatchers(
    $id: ID!
    $matchers: [BrandPlaceMatcherInput!]!
  ) {
    setBrandPlaceMatchers(brandId: $id, matchers: $matchers) {
      success
    }
  }
`;
