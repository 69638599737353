import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { pathOr } from 'ramda';
import { ApolloProvider } from '@apollo/client';

import App from './App';
import { apolloClient } from '@/utils/apollo';

export const isProdEnv = pathOr('prod', ['NODE_ENV'], config) === 'prod';

if (isProdEnv) {
  // todo: make a new sentry project
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://18bf38ba129d485e8f3229194e0276b7@sentry.io/1845231',
  });
}

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <App />
  </ApolloProvider>,
  document.getElementById('root'),
);
