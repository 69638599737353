enum BrandTypename {
  BrandAssetVenmoIcon = 'BrandAssetVenmoIcon',
  BrandAssetVenmoTile = 'BrandAssetVenmoTile',
  BrandAssetVenmoBackground = 'BrandAssetVenmoBackground',
  BrandAssetVenmoNote = 'BrandAssetVenmoNote',
  BrandAssetMerchantName = 'BrandAssetMerchantName',
  BrandAssetCard = 'BrandAssetCard',
  BrandAssetIcon = 'BrandAssetIcon',
  BrandAssetBanner = 'BrandAssetBanner',
  BrandAssetPrimaryColor = 'BrandAssetPrimaryColor',
  BrandAssetSecondaryColor = 'BrandAssetSecondaryColor',
}

enum BrandAssetComponent {
  background = 'background',
  icon = 'icon',
  tile = 'tile',
  venmoNote = 'venmoNote',
  venmoNoteQRC = 'venmoNoteQRC',
  merchantName = 'merchantName',
  cardImage = 'cardImage',
  banner = 'banner',
  primaryColor = 'primaryColor',
  secondaryColor = 'secondaryColor',
}

enum AssetBundle {
  BUTTON = 'DEFAULT_BRANDING/BUTTON',
  DEFAULT_BRANDING = 'DEFAULT_BRANDING',
  VENMO_APP = 'VENMO_APP',
}

enum AssetName {
  CARD = 'card',
  BANNER = 'header',
  LOGO = 'logo',
  TILE = 'tile',
}

const CLOUDINARY_WIDGET_OPTIONS = {
  cloudName: 'dosh-cash',
  uploadPreset: 'merchant_portal',
  sources: ['url', 'google_drive', 'dropbox', 'local'],
  showCompletedButton: false,
  showAdvancedOptions: false,
  showPoweredBy: false,
  cropping: true,
  multiple: false,
  defaultSource: 'local',
  tags: ['merchant portal'],
  styles: {
    palette: {
      window: '#C9C3EF',
      windowBorder: '#683CAF',
      tabIcon: '#683CAF',
      menuIcons: '#5A616A',
      textDark: '#120B33',
      textLight: '#FFFFFF',
      link: '#683CAF',
      action: '#683CAF',
      inactiveTabIcon: '#767676',
      error: '#F44235',
      inProgress: '#0078FF',
      complete: '#39E27A',
      sourceBg: '#FFFFFF',
    },
    fonts: { default: null, 'sans-serif': { url: null, active: true } },
  },
};

const loadCloudinary = (callback?: Function) => {
  const existingScript = document.getElementById('cloudinaryWidget');

  if (!existingScript) {
    const script = document.createElement('script');

    script.src = 'https://widget.cloudinary.com/v2.0/global/all.js';
    script.id = 'cloudinaryWidget';
    script.type = 'text/javascript';

    document.body.appendChild(script);

    script.onload = () => {
      if (callback) {
        callback();
      }
    };
  }
  if (existingScript && callback) {
    callback();
  }
};

const getImageDetails = (imageInfo: string) => {
  if (imageInfo === '') {
    return {
      width: 0,
      height: 0,
      format: '',
      path: '',
      publicId: '',
    };
  }

  const { secure_url, public_id, width, height, format } =
    JSON.parse(imageInfo);

  return {
    width,
    height,
    format,
    path: secure_url,
    publicId: public_id,
  };
};

export {
  AssetBundle,
  AssetName,
  CLOUDINARY_WIDGET_OPTIONS,
  loadCloudinary,
  BrandTypename,
  BrandAssetComponent,
  getImageDetails,
};
