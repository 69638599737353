import { pathOr } from 'ramda';

export const account = pathOr('', ['aws', 'accountNumber'], config);
const webpackEnv = pathOr<string>('', ['NODE_ENV'], config);

// since we use 'dev' while webpack uses 'development'
// https://webpack.js.org/configuration/mode/
export const env = webpackEnv === 'development' ? 'dev' : webpackEnv;

interface BuildRouteProps {
  serviceName: string;
  functionName: string;
  payload?: any;
  alias?: string;
}

const buildRoute = ({
  serviceName,
  functionName,
  payload,
  alias,
}: BuildRouteProps): string => {
  const finalAlias = alias || env;

  const qs = !payload
    ? ''
    : `?${Object.keys(payload)
        .map((k) => `${k}=${payload[k]}`)
        .join('&')}`;
  return `https://devtools.dosh.cash/api/us-east-1/${account}/${serviceName}/${env}/${finalAlias}/${functionName}${qs}`;
};

export default buildRoute;
