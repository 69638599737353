/*
  todo:
  - use the validation util to make sure you can't submit form until all assets are upload
  - error out if the brandID isn't given in the input step
*/

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import DisplayJson from '@/components/DisplayJson';
import { WorkflowStepStatusTypes } from '@/components/Workflow/components/WorkflowStepStatus';
import UploadWidget from '@/components/UploadWidget';
import {
  AssetBundle,
  AssetName,
  getImageDetails,
} from '@/components/UploadWidget/UploadWidget.helpers';
import getErrorMessage from '@/utils/error';

interface WorkflowStepProps {
  name: string;
  active: boolean;
  onCompleteStep: Function;
  inputData: any;
  setStatus: Function;
  outputData: any;
}

export const UploadButtonMerchantAssets: FunctionComponent<
  WorkflowStepProps
> = ({ name, active, onCompleteStep, outputData, setStatus, inputData }) => {
  const [merchantName, setMerchantName] = useState('');

  const { register, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const handleSkipStep = () => {
    onCompleteStep({ status: 'skipped' });
  };

  const submitForm = async ({ logo, banner, card }: any) => {
    setStatus(WorkflowStepStatusTypes.RUNNING);
    try {
      const logoImage = getImageDetails(logo);
      const bannerImage = getImageDetails(banner);
      const cardImage = getImageDetails(card);
      onCompleteStep({ logoImage, bannerImage, cardImage, status: 'success' });
    } catch (error) {
      console.error(error);
      const em = getErrorMessage(error);
      onCompleteStep({ error: em, status: 'failed' });
    }
  };

  useEffect(() => {
    setMerchantName(inputData.brandId);
  }, [inputData]);

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <div className="workflowStep-text">
        <p className="workflowStep-text-label">
          {active
            ? `Upload brand assets for - "${merchantName}"`
            : 'You will be able to upload assets when this step is active'}
        </p>
        {active && (
          <>
            <Row>
              <Col>
                <UploadWidget
                  name="logo"
                  label="Logo"
                  merchantName={merchantName}
                  assetBundle={AssetBundle.BUTTON}
                  assetName={AssetName.LOGO}
                  size="small"
                  tabIndex={3}
                  register={register({ required: 'required' })}
                />
              </Col>
              <Col>
                <UploadWidget
                  name="card"
                  label="Merchandising Card"
                  merchantName={merchantName}
                  assetBundle={AssetBundle.BUTTON}
                  assetName={AssetName.CARD}
                  tabIndex={4}
                  register={register()}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <UploadWidget
                  name="banner"
                  label="Detail Header"
                  merchantName={merchantName}
                  assetBundle={AssetBundle.BUTTON}
                  assetName={AssetName.BANNER}
                  tabIndex={5}
                  register={register()}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
      {outputData && (
        <div className="workflowStep-text">
          <p className="workflowStep-text-label">{`${name} - Response`}</p>
          <DisplayJson name={`output for ${name}`} json={outputData} />
        </div>
      )}
      {active && (
        <Row>
          <Button className="workflowButton" type="submit">
            <span>Use these assets</span>
          </Button>
          <Button
            className="workflowButton"
            variant="secondary"
            onClick={handleSkipStep}
          >
            Skip
          </Button>
        </Row>
      )}
    </Form>
  );
};
