import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'mode',
    formLabel: 'TURN_ON or TURN_OFF',
  },
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid',
  },
  {
    name: 'skipCNIdActivation',
    formLabel: 'Skip CNId Activation?',
    default: false,
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'dosh-on-venue-svc',
  functionName: 'toggleVenueTransactionFlowByOfferUuid',
  method: HttpMethod.POST,
});

export const toggleVenueTransactionFlowByOfferUuid = {
  name: 'Toggle Venue Transaction Flow by Offer Uuid',
  description: 'Turn transactions on or off by offer uuid',
  inputValues,
  outputValueName: 'result',
  tags: [Tags.venues, Tags.offers, Tags.transactions],
  submit,
};
