import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { OfferLocationTypes, Tags } from '@/helpers/types';
import { offerLocationTypeOptions } from '@/helpers/commonInputSelectOptions';
import { redshiftEscape } from '@/utils/strings';
import { offerAddVenues } from '../lambdaFunctions/offerAddVenues';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Name of merchant',
  },
  {
    name: 'locationType',
    formLabel: 'Location Type',
    options: offerLocationTypeOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'updatedBy',
    formLabel: 'Updated By',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Query for Future Dated Offers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT getuuid(m.merchant_id) as merchant_uuid, getuuid(o.offer_id) as offer_uuid,
            c.start_date as start_date, c.end_date as end_date
          FROM merchantdb.offers o JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
          WHERE m.name ILIKE '%${redshiftEscape(merchantName)}%'
          AND c.start_date > current_date
          ORDER BY offer_uuid;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Select Offer',
    autorun: true,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            merchant_uuid: string;
            offer_uuid: string;
            start_date: string;
            end_date: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.merchant_uuid}#${record.offer_uuid}`,
              name: `${record.offer_uuid}, ${record.start_date}, ${record.end_date}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Map Coupon to Location Type',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: offerAddVenues.submit,
    },
    inputData: {
      merchantUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return result[0].split('#')[0];
      }),
      offerUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return result[0].split('#')[1];
      }),
      venues: new InputInjection(
        [0],
        ({ locationType }: { locationType: string }) => {
          if (!locationType) {
            return;
          }
          if (locationType === OfferLocationTypes.online) {
            return 'ALL_ONLINE';
          }
          if (locationType === OfferLocationTypes.nearby) {
            return 'ALL_NEARBY';
          }
        },
      ),
      updatedBy: new InputInjection([0, 'updatedBy']),
    },
  },
];

export const addLocationTypeToFutureOffer = {
  steps,
  inputValues,
  name: 'Add Location Type to Future Offer',
  description: 'Add a location type to a future offer',
  tags: [Tags.merchant, Tags.offers, Tags.venues],
};
