import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { matchBrand } from '../lambdaFunctions/matchBrand';
import { matchPlaceToBrand } from '../lambdaFunctions/matchPlaceToBrand';

import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'name',
    formLabel: 'Check Transaction and Place matches for provided name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Check for transaction matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: matchBrand.submit,
    },
    inputData: {
      venueName: new InputInjection([0, 'name']),
    },
  },
  {
    name: 'Check for place matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: matchPlaceToBrand.submit,
    },
    inputData: {
      venueName: new InputInjection([0, 'name']),
    },
  },
  {
    name: 'Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      transactionMatches: new InputInjection(
        [1],
        ({ matches }: { matches: string[] }) => {
          if (!matches) {
            return;
          }
          return matches;
        },
      ),
      placesMatches: new InputInjection(
        [2],
        ({ matches }: { matches: string[] }) => {
          if (!matches) {
            return;
          }
          return matches;
        },
      ),
    },
  },
];

export const checkMatchingBrands = {
  steps,
  inputValues,
  name: 'Check For Matching Transactions and Places',
  description: 'Checks if a name matches with a transaction or a venue',
  tags: [Tags.transactions, Tags.venues],
};
