import { WorkflowStepTypes } from '@/components/Workflow';
import { Tags } from '@/helpers/types';
import InputInjection from '@/utils/workflow/InputInjection';
import { addAudienceSubscription } from '../lambdaFunctions/addAudienceSubscription';

const inputValues = [
  {
    name: 'merchantUuid',
    formLabel: 'Merchant Uuid',
    formSubLabel: 'the uuid of the merchant',
  },
  {
    name: 'safegraphBrandName',
    formLabel: 'Safegraph Brand Name *',
    formSubLabel: 'the safegraph brand name to use to find brand engagements',
  },
  {
    name: 'lookbackMonths',
    formLabel: 'Lookback Months *',
    formSubLabel: 'the number of months to lookback to check for engagements',
    default: '6',
  },
  {
    name: 'minimumDistinctDaysEngaged',
    formLabel: 'Minimum Distinct Days Engaged *',
    formSubLabel:
      'the minimum distinct days the user must have engaged with the brand to be considered loyal (min 1)',
    default: '1',
  },
];

const steps = [
  {
    name: 'Add Audience Subscription',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addAudienceSubscription.submit,
    },
    inputData: {
      subscriber: 'MERCHANT_SERVICE', // "MERCHANT_SERVICE" is who manages offer targeting, so they are the subscriber
      externalId: new InputInjection(
        [0, 'merchantUuid'],
        (merchantUuid: string) =>
          `${merchantUuid}:::LOYAL_USER:::${
            new Date().toISOString().split('T')[0]
          }`,
      ),
      name: new InputInjection(
        [0, 'safegraphBrandName'],
        (safegraphBrandName: string) => `Loyal Users for ${safegraphBrandName}`,
      ),
      audience: {
        type: 'STATIC', // all audiences are static for now
        rules: [
          {
            type: 'LOYAL_USER',
            options: {
              safegraphBrandName: new InputInjection([0, 'safegraphBrandName']),
              lookbackMonths: new InputInjection(
                [0, 'lookbackMonths'],
                parseInt,
              ),
              minimumDistinctDaysEngaged: new InputInjection(
                [0, 'minimumDistinctDaysEngaged'],
                parseInt,
              ),
            },
          },
        ],
      },
    },
  },
];

export const addOfferTargetingAudienceLoyalUser = {
  steps,
  inputValues,
  name: 'Add an offer targeting audience of loyal users for a brand',
  description:
    'Workflow used for creating an audience of loyal users to be used in offer targeting',
  tags: [Tags.targeting, Tags.audiences],
};
