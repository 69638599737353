import { addManualONVenueToCNIdMapping } from './addManualONVenueToCNIdMapping';
import { budgetAdjust } from '@/services/lambdaFunctions/budgetAdjust';
import { campaignGetByUuid } from './campaignGetByUuid';
import { createAgentAndMapToReferralId } from '@/services/lambdaFunctions/createAgentAndMapToReferralId';
import { createMerchant } from '@/services/lambdaFunctions/createMerchant';
import { getAffiliateOffers } from './getAffiliateOffers';
import { getContentFeed } from './getContentFeed';
import { getDebugDataForPlaceDescriptionMatches } from './getDebugDataForPlaceDescriptionMatches';
import { getMerchantByName } from './getMerchantByName';
import { getMerchantByUuid } from './getMerchantByUuid';
import { getONVenueMappingsForCNIds } from './getONVenueMappingsForCNIds';
import { getPlaceBySubscription } from './getPlaceBySubscription';
import { getPlaceByPlaceDescription } from './getPlaceByPlaceDescription';
import { getTransactionFlowSourcesForCNId } from './getTransactionFlowSourcesForCNId';
import { getUser } from './getUser';
import { getWithdrawalControls } from '@/services/lambdaFunctions/getWithdrawalControls';
import { manuallyRequestPropOptJobsByPDNameRegex } from './manuallyRequestPropOptJobsByPDNameRegex';
import { markCNTransactionsForCNId } from './markCNTransactionsForCNId';
import { offerFindBy } from './offerFindBy';
import { offerGetByUuid } from './offerGetByUuid';
import { offerToggleDynamicVenueAdditions } from './offerToggleDynamicVenueAdditions';
import { saveAffiliateOffer } from './saveAffiliateOffer';
import { searchCardNetworkCNVenues } from './searchCardNetworkCNVenues';
import { setCouponProperties } from '@/services/lambdaFunctions/setCouponProperties';
import { setOfferProperties } from './setOfferProperties';
import { setupSegmentsForMarketplace } from './setupSegmentsForMarketplace';
import { syncAutomaticMappingsForVenue } from './syncAutomaticMappingsForVenue';
import { updateOfferContent } from './updateOfferContent';
import { updateVenueProperties } from './updateVenueProperties';
import { upsertCNVenue } from './upsertCNVenue';
import { userGroupCreate } from './userGroupCreate';
import { getMarketplaceConfig } from '@/services/lambdaFunctions/getMarketplaceConfig';
import { userGroupGetIdByName } from './userGroupGetIdByName';
import { manuallyUpsertAudienceMembership } from './manuallyUpsertAudienceMembership';
import { updateSegmentTargetingRuleData } from './updateSegmentTargetingRuleData';
import { userGroupGetNameById } from './userGroupGetNameById';
import { marketplacesGetByName } from './marketplacesGetByName';
import { getVenueByExternalId } from './getVenueByExternalId';
import { getVenueMappingsForGenericVenue } from './getVenueMappingsForGenericVenue';
import { adsGet } from './adsGet';
import { adsSessionTokenCreate } from './adsSessionTokenCreate';
import { visaAuthNotification } from './visaAuthNotification';
import { ctesGetByTransactionId } from './ctesGetByTransactionId';
import { rtmRewardEvent } from './rtmRewardEvent';
import { processNormalizedVisaTxs } from './processNormalizedVisaTxs';
import { getMatchingEngineDeposits } from './getMatchingEngineDeposits';
import { matchBrand } from './matchBrand';
import { matchPlaceToBrand } from './matchPlaceToBrand';
import { marketplacesGetNames } from './marketplacesGetNames';
import { getCNIdStatus } from './getCNIdStatus';

const lambdaFunctions: any = {
  addManualONVenueToCNIdMapping,
  adsGet,
  adsSessionTokenCreate,
  budgetAdjust,
  campaignGetByUuid,
  createAgentAndMapToReferralId,
  createMerchant,
  ctesGetByTransactionId,
  getAffiliateOffers,
  getContentFeed,
  getDebugDataForPlaceDescriptionMatches,
  getMatchingEngineDeposits,
  getMerchantByName,
  getMerchantByUuid,
  getONVenueMappingsForCNIds,
  getPlaceBySubscription,
  getPlaceByPlaceDescription,
  getTransactionFlowSourcesForCNId,
  getUser,
  getWithdrawalControls,
  manuallyRequestPropOptJobsByPDNameRegex,
  markCNTransactionsForCNId,
  offerFindBy,
  offerGetByUuid,
  offerToggleDynamicVenueAdditions,
  processNormalizedVisaTxs,
  rtmRewardEvent,
  saveAffiliateOffer,
  searchCardNetworkCNVenues,
  setCouponProperties,
  setOfferProperties,
  setupSegmentsForMarketplace,
  syncAutomaticMappingsForVenue,
  updateOfferContent,
  updateVenueProperties,
  upsertCNVenue,
  userGroupCreate,
  getMarketplaceConfig,
  userGroupGetIdByName,
  manuallyUpsertAudienceMembership,
  updateSegmentTargetingRuleData,
  userGroupGetNameById,
  marketplacesGetByName,
  getVenueByExternalId,
  getVenueMappingsForGenericVenue,
  visaAuthNotification,
  matchBrand,
  matchPlaceToBrand,
  marketplacesGetNames,
  getCNIdStatus,
};

export default lambdaFunctions;
