import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
  },
  {
    name: 'idType',
    formLabel: 'Id Type',
  },
  {
    name: 'externalId',
    formLabel: 'External Id',
  },
  {
    name: 'allowlisted',
    formLabel: 'Allowlisted?',
    formSubLabel: 'true or false',
    default: true,
  },
  {
    name: 'reason',
    formLabel: 'Reason',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-venue-service',
  functionName: 'upsertAllowlistedAggregatorCnid',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});

export const upsertAllowlistedAggregatorCnid = {
  name: 'Upsert Allowlisted Aggregator Cnid',
  description: 'Add CNId to the Allowlist',
  inputValues,
  tags: [Tags.transactions, Tags.cnids],
  submit,
};
