import React, { FunctionComponent } from 'react';
import ReactJson from 'react-json-view';

import './DisplayJson.css';

interface DisplayJsonProps {
  json: any;
  name?: string;
}

const DisplayJson: FunctionComponent<DisplayJsonProps> = ({
  json,
  name = 'response',
}): JSX.Element => (
  <ReactJson
    theme="rjv-default"
    name={name}
    src={json}
    displayDataTypes={false}
  />
);

export default DisplayJson;
