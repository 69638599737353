import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import LambdaFunctions from '../lambdaFunctions';
import { ADSOffer } from '../lambdaFunctions/adsGet';
import { buildRtmEvent } from '../lambdaFunctions/rtmRewardEvent';
import { normalizedRecordTemplate } from '../lambdaFunctions/processNormalizedVisaTxs';
import * as money from '@/utils/money';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'userId',
    formLabel: 'User ID',
    formSubLabel: 'User Cognito ID',
    col: 4,
  },
  {
    name: 'userLocationLatitude',
    formLabel: 'User Location',
    formSubLabel: 'Latitude',
    col: 2,
    default: 33.772864,
  },
  {
    name: 'userLocationLongitude',
    formLabel: 'User Location',
    formSubLabel: 'Longitude',
    col: 2,
    default: -84.365508,
  },
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
    formSubLabel: '(Visa only for now)',
    col: 2,
    default: 'VISA',
  },
  {
    name: 'cardNetworkTransactionType',
    formLabel: 'Card Network Transaction Event Type',
    formSubLabel: '(Auth only for now)',
    col: 4,
    default: 'AUTHORIZED',
  },
  {
    name: 'cardNetworkTransactionId',
    formLabel: 'Card Network Transaction ID',
    formSubLabel: 'Optional',
    col: 4,
    // CTE transaction_id column is varchar(36), so keep this string length short to avoid truncation
    default: `atest:${uuid().split('-').slice(0, 4).join('-')}`,
  },
  {
    name: 'cardNetworkTransactionAmount',
    formLabel: 'Transaction Amount',
    formSubLabel: 'USD',
    col: 2,
    default: '',
  },
  {
    name: 'cardNetworkTransactionTimestamp',
    formLabel: 'Transaction Timestamp',
    formSubLabel: 'UTC',
    col: 2,
    default: moment().utc().toISOString(),
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get User Public UUID',
    autorun: true, // optional, will start running this step as soon as it's active
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: LambdaFunctions.getUser.submit,
    },
    inputData: {
      userId: new InputInjection([0, 'userId']),
    },
  },
  {
    name: 'Create Session Token',
    autorun: true, // optional, will start running this step as soon as it's active
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: LambdaFunctions.adsSessionTokenCreate.submit,
    },
    inputData: {
      sourceUserId: new InputInjection(
        [1],
        (result: { publicUUID: string }) => {
          return result?.publicUUID;
        },
      ),
    },
  },
  {
    name: 'Fetch CDLX offers from ADS',
    autorun: true, // optional, will start running this step as soon as it's active
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: LambdaFunctions.adsGet.submit,
    },
    inputData: {
      userId: new InputInjection([1], (result: { publicUUID: string }) => {
        return result?.publicUUID;
      }),
      userToken: new InputInjection([2], (result: { token: string }) => {
        return result?.token;
      }),
      userLocation: {
        latitude: new InputInjection(
          [0, 'userLocationLatitude'],
          (value: string) => Number.parseFloat(value),
        ),
        longitude: new InputInjection(
          [0, 'userLocationLongitude'],
          (value: string) => Number.parseFloat(value),
        ),
      },
      _logLevel: 'debug',
    },
  },
  {
    name: 'Aggregate fetched offers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      offers: new InputInjection(
        [3],
        (result: { offers: ADSOffer[]; featuredOffers: ADSOffer[] }) => {
          if (!result?.offers?.length && !result?.featuredOffers?.length) {
            return [];
          }
          const offers = [
            ...result.offers,
            ...(result?.featuredOffers?.length
              ? result.featuredOffers.map((offer) => ({
                  ...offer,
                  featured: true,
                }))
              : []),
          ];
          return offers;
        },
      ),
    },
  },
  {
    name: 'Select an Offer',
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection([4], ({ offers }: { offers: ADSOffer[] }) => {
        if (!offers || offers.length === 0) {
          return [];
        }
        return offers.map((offer) => ({
          id: offer,
          name: `${offer?.brandName} | ${offer?.creatives[0].headline} ${
            offer.featured ? '(Featured)' : ''
          }`,
        }));
      }),
    },
  },
  {
    name: 'Aggregate offer, user details and original inputs',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      selectedOffer: new InputInjection([5, 0]),
      inputValues: new InputInjection([0]),
      user: new InputInjection([1]),
    },
  },
  {
    name: 'Send mocked normalized transaction',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: LambdaFunctions.processNormalizedVisaTxs.submit,
    },
    inputData: {
      Records: new InputInjection([6], (aggregatedData: any) => {
        const {
          cardNetworkTransactionId,
          cardNetworkTransactionAmount,
          cardNetworkTransactionTimestamp,
          cardNetworkTransactionType,
          userId,
        } = aggregatedData?.inputValues || {};
        const record = normalizedRecordTemplate({
          transactionId: cardNetworkTransactionId,
          cnTransactionId: cardNetworkTransactionId,
          amount: money.convertDollarsToCents(cardNetworkTransactionAmount),
          transactionTimeStamp: cardNetworkTransactionTimestamp,
          transactionType: cardNetworkTransactionType,
          userId,
        });
        return [record];
      }),
    },
  },
  // look for CTE
  {
    name: 'Look up CTE',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: LambdaFunctions.ctesGetByTransactionId.submit,
    },
    inputData: {
      transactionId: new InputInjection([0, 'cardNetworkTransactionId']),
    },
  },
  // Dispatch RTM event
  {
    name: 'Send RTM event to cdlx-exchange-svc webhook',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: LambdaFunctions.rtmRewardEvent.submit,
    },
    inputData: {
      body: new InputInjection([6], (aggregatedData: any) => {
        if (!aggregatedData?.inputValues || !aggregatedData?.selectedOffer) {
          return;
        }
        const payload = buildRtmEvent({
          transactionIdentifier:
            aggregatedData?.inputValues?.cardNetworkTransactionId,
          sourceCustomerId: aggregatedData?.user?.publicUUID,
          offer: aggregatedData?.selectedOffer,
        });
        return JSON.stringify(payload);
      }),
    },
  },
  // Look for ME deposit
  {
    name: 'Look up Matching Engine deposits',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: LambdaFunctions.getMatchingEngineDeposits.submit,
    },
    inputData: {
      transactionId: new InputInjection([0, 'cardNetworkTransactionId']),
      transactionSource: 'empyr-service-visa',
      method: 'TRANSACTION_ID',
    },
  },
];

export const createCDLXRedemptionEvent = {
  steps,
  inputValues,
  name: 'Create a redemption event for a CDLX offer',
  description: 'Create a redemption event for a CDLX offer',
  tags: [Tags.cdlx],
};
