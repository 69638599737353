import React, { FunctionComponent, useEffect } from 'react';
import { Button, Spinner, Row } from 'react-bootstrap';

import DisplayJson from '@/components/DisplayJson';
import InputJson from '@/components/InputJson';
import { WorkflowStepStatusTypes } from '@/components/Workflow/components/WorkflowStepStatus';
import getErrorMessage from '@/utils/error';

interface WorkflowStepProps {
  name: string;
  active: boolean;
  status: WorkflowStepStatusTypes;
  onCompleteStep: Function;
  inputData: any;
  outputData: any;
  setStatus: Function;
  onSubmit: any;
  autorun: boolean;
}

export const PreprocessData: FunctionComponent<WorkflowStepProps> = ({
  name,
  active,
  status,
  onCompleteStep,
  inputData,
  outputData,
  setStatus,
  autorun,
}) => {
  const handleSkipStep = () => {
    onCompleteStep({ status: 'skipped' });
  };

  const runStep = async () => {
    setStatus(WorkflowStepStatusTypes.RUNNING);
    try {
      const { ...payload } = inputData || {};
      onCompleteStep({ ...payload, status: 'success' });
    } catch (error) {
      const em = getErrorMessage(error);
      onCompleteStep({ error: em, status: 'failed' });
    }
  };

  useEffect(() => {
    if (status === WorkflowStepStatusTypes.ACTIVE && autorun) {
      runStep();
    }
  });

  return (
    <>
      <div className="workflowStep-text">
        <p className="workflowStep-text-label">Input Variables</p>
        <p>
          {active
            ? 'Review, edit, or copy variables for this step before running.'
            : 'Editing input variables is available when this step is active.'}
        </p>
        <InputJson
          name={`input for ${name}`}
          json={inputData}
          collapsed={!!outputData}
        />
      </div>
      {outputData && (
        <div className="workflowStep-text">
          <p className="workflowStep-text-label">{`${name} - Response`}</p>
          <DisplayJson name={`output for ${name}`} json={outputData} />
        </div>
      )}
      {active && (
        <Row>
          <Button className="workflowButton" onClick={runStep}>
            {status === WorkflowStepStatusTypes.RUNNING && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden
              />
            )}
            {!(status === WorkflowStepStatusTypes.RUNNING) && (
              <span>Run Step</span>
            )}
          </Button>
          <Button
            className="workflowButton"
            variant="secondary"
            onClick={handleSkipStep}
          >
            Skip
          </Button>
        </Row>
      )}
    </>
  );
};
