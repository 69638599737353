import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { budgetAdjust } from '../lambdaFunctions/budgetAdjust';
import { campaignGetByUuid } from '../lambdaFunctions/campaignGetByUuid';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'amount',
    formLabel: 'Budget Amount',
    formSubLabel:
      'No Special Characters ex. 10000 = $10,000. If using an OPEN budget, use 100000000',
  },
  {
    name: 'insertionOrderRef',
    formLabel:
      'Insertion Order Ref For Budget Adjustment (Salesforce CaseSafeID)',
  },
  {
    name: 'budgetAdjustmentName',
    formLabel: 'Budget Adjustment Name',
  },
  {
    name: 'approvalDate',
    formLabel: 'Budget Approval Date',
    formSubLabel: 'YYYY-MM-DD',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Current Budget and Campaign Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT
          sfti.budget_id as budget_uuid,
          sfti.campaign_id as campaign_id,
          COALESCE(LISTAGG(DISTINCT l.type, ' & ') WITHIN GROUP ( ORDER BY l.type DESC ), 'None') AS location_type
        FROM merchantdb.offers o
        JOIN merchantdb.campaigns c ON o.offer_id = c.offer_id 
        JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
        JOIN merchantdb.coupon_segment cs on o.coupon_id = cs.coupon_id
        JOIN merchantdb.coupon_segment_to_sor_financial_term cstsft on cstsft.coupon_segment_id = cs.coupon_segment_id
        JOIN campaigndb.sor_financial_term_item sfti on sfti.sor_financial_term_id = cstsft.sor_financial_term_id
        JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
        LEFT JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
        LEFT JOIN merchantdb.locations l on cl.location_id = l.location_id
        WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
          AND c.end_date > current_date
          AND cs.hide_offer_for_segment = 0
          AND o.deleted = 0
          AND (cs.end_date is null OR cs.end_date > current_date)
        GROUP BY 1,2
        ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Select Campaign -- IMPORTANT: Contact IE if >1 result for a Location Type',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            budget_uuid: string;
            campaign_id: string;
            location_type: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record: any) => {
            return {
              id: `${record.budget_uuid}:::${record.campaign_id}`,
              name: `BUDGET UUID: ${record.budget_uuid} | CAMPAIGN UUID: ${
                record.campaign_id
              } | LOCATION TYPE: ${record.location_type.toUpperCase()}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Adjust Budget',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: budgetAdjust.submit,
    },
    inputData: {
      budgetUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        const selection = getArrayFromCheckboxSelections(result)[0];
        return selection.split(':::')[0];
      }),
      insertionOrderRef: new InputInjection([0, 'insertionOrderRef']),
      amount: new InputInjection([0, 'amount'], parseInt),
      budgetAdjustmentName: new InputInjection([0, 'budgetAdjustmentName']),
      approvalDate: new InputInjection([0, 'approvalDate']),
    },
  },
  {
    name: 'Verify New Budget -- IMPORTANT: Results should contain new budget',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: campaignGetByUuid.submit,
    },
    inputData: {
      campaignUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        const selection = getArrayFromCheckboxSelections(result)[0];
        return selection.split(':::')[1];
      }),
    },
  },
];

export const adjustBudget = {
  steps,
  inputValues,
  name: 'Adjust Budget',
  description: 'Adjust Budget',
  tags: [Tags.budget],
};
