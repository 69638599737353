import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { upsertCNVenue } from '../lambdaFunctions/upsertCNVenue';
import { addManualONVenueToCNIdMapping } from '../lambdaFunctions/addManualONVenueToCNIdMapping';
import { CardNetwork, CardNetworkIdType, Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'amexSE',
    formLabel: 'AMEX SE',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Upsert CN Venue',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: upsertCNVenue.submit,
    },
    inputData: {
      venue: {
        enabled: 'true',
        name: new InputInjection([0, 'merchantName']),
        venueSource: CardNetwork.AMEX,
        externalId: new InputInjection([0, 'amexSE']),
        locationType: 'ONLINE',
        address: {
          address1: '__ONLINE__',
          city: '__ONLINE__',
          state: '__',
          postal: '_____',
          country: '__',
        },
        cardNetworkIds: [
          {
            cardNetwork: CardNetwork.AMEX,
            idType: CardNetworkIdType.SE,
            externalId: new InputInjection([0, 'amexSE']),
          },
        ],
        mode: 'UPSERT',
      },
    },
  },
  {
    name: 'Get ON Venue',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT m.name, l.type, sl.source_location_id as on_venue_uuid
        FROM merchantdb.locations l
        JOIN merchantdb.source_locations sl on l.location_id = sl.location_id
        JOIN merchantdb.merchants m on l.merchant_id = m.merchant_id
        WHERE 1=1
        AND m.name ilike '%${redshiftEscape(merchantName)}%'
        AND type = 'online'
            ;`,
      ),
    },
  },
  {
    name: 'Map SE to ONVenue',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addManualONVenueToCNIdMapping.submit,
    },
    inputData: {
      onvenueUuid: new InputInjection([2], ({ records }: any) => {
        if (!records || !Array.isArray(records)) {
          return;
        }
        if (records.length > 1) {
          return;
        }
        return records[0].on_venue_uuid;
      }),
      cardNetwork: CardNetwork.AMEX,
      cardNetworkExternalIdType: CardNetworkIdType.SE,
      cardNetworkExternalId: new InputInjection([0, 'amexSE']),
      basis: 'PLACE_MATCH',
    },
  },
];

export const mapOnlineSE = {
  steps,
  inputValues,
  name: 'Add and Map Online SE',
  description: 'Maps the online SE (AMEX) to the ONVenue',
  tags: [Tags.newMerchantOnboarding, Tags.cnids],
};
