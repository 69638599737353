import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { setCouponSegmentProperties } from '../lambdaFunctions/setCouponSegmentProperties';
import { Tags } from '@/helpers/types';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid',
  },
  {
    name: 'segmentPriority',
    formLabel: 'Segment Priority',
  },
  {
    name: 'updatedBy',
    formLabel: 'Updated By',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Updateable Segments',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          offerUuid,
        }: {
          offerUuid: string;
        }) => `SELECT DISTINCT cs.coupon_segment_uuid, cs.priority, cs.cashback_amount, cs.cashback_percentage, cs.cashback_daily_limit, cs.start_date, cstrt.type, cstrd.target_data
        FROM merchantdb.offers o
        JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
        JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
        JOIN merchantdb.coupon_segment cs on o.coupon_id = cs.coupon_id
        JOIN merchantdb.coupon_segment_targeting_rule cstr on cs.coupon_segment_id = cstr.coupon_segment_id
        JOIN merchantdb.coupon_segment_targeting_rule_type cstrt on cstr.coupon_segment_targeting_rule_type_id = cstrt.coupon_segment_targeting_rule_type_id AND cstrt.type != 'OFFER_THROTTLE_V1'
        JOIN merchantdb.coupon_segment_targeting_rule_data cstrd on cstr.coupon_segment_targeting_rule_id = cstrd.coupon_segment_targeting_rule_id
        WHERE getuuid(o.offer_id) = '${offerUuid}'
        AND c.end_date > current_date
        AND (cs.end_date is null or cs.end_date > current_date)
        AND cs.start_date > current_date
        ;`,
      ),
    },
  },
  {
    name: 'Select Segment to Update',
    describe: 'Can only update one at a time',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            coupon_segment_uuid: string;
            priority: number;
            cashback_amount: number;
            cashback_percentage: number;
            cashback_daily_limit: number;
            start_date: string;
            type: string;
            target_data: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.coupon_segment_uuid}`,
              name: `Segment Uuid: ${
                record.coupon_segment_uuid
              }, Segment Priority: ${record.priority}, Segment Cashback: ${
                record.cashback_percentage === 0
                  ? record.cashback_amount
                  : record.cashback_percentage
              }, Segment Daily Limit: ${
                record.cashback_daily_limit
              }, Segment Start Date: ${record.start_date}, Segment Target: ${
                record.target_data
              }, Segment Type: ${record.type}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Combine Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      selectedSegmentUuid: new InputInjection(
        [2],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          return getArrayFromCheckboxSelections(result)[0];
        },
      ),
    },
  },
  {
    name: 'Set Segment Property',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: setCouponSegmentProperties.submit,
    },
    inputData: {
      segmentUuid: new InputInjection([3, 'selectedSegmentUuid']),
      segmentPriority: new InputInjection(
        [0],
        ({ segmentPriority }: { segmentPriority: string }) => {
          if (!segmentPriority) {
            return;
          }
          return parseInt(segmentPriority, 10);
        },
      ),
      updatedBy: new InputInjection([0, 'updatedBy']),
    },
  },
];

export const setPriorityOnSegment = {
  steps,
  inputValues,
  name: 'Set Priority On Segment',
  description: 'Set priority on segment',
  tags: [Tags.offers],
};
