import React, { FunctionComponent } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Image } from 'react-bootstrap';
import Logo from '@/assets/images/logos/dosh.svg';
import Loader from '@/components/Loader';
import Button from '@/components/Button';
import Box from '@/components/Box';
import './Login.css';

const Login: FunctionComponent = (): JSX.Element => {
  const { oktaAuth, authState } = useOktaAuth();

  const login = async () => {
    oktaAuth.signInWithRedirect({ redirectUri: `${window.location.origin}/` });
  };

  if (authState && authState.isPending) {
    return <Loader />;
  }

  return (
    <div className="trv-login">
      <Box>
        <Image src={Logo} width={40} height={40} className="trv-login-brand" />
        <h1 className="trv-login-heading">Ops Portal</h1>
        <Button onClick={login} block>
          Log in with Okta
        </Button>
      </Box>
    </div>
  );
};

export default Login;
