import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Option } from '@/components/WorkflowSteps/CheckboxSelector';
import {
  BrandTransactionMatcherResults,
  MatcherObject,
  Tags,
} from '@/helpers/types';
import { UPSERT_TRANSACTION_MATCHERS } from '../graphql/upsertTransactionMatchers.gql';
import { GET_TRANSACTION_MATCHERS } from '../graphql/getTransactionMatchers.gql';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'brandId',
    formLabel: 'Brand Id for Matchers',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Current Matchers',
    inputData: {
      id: new InputInjection([0, 'brandId']),
    },
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_TRANSACTION_MATCHERS,
    },
  },
  {
    name: 'Select Transaction Matcher to Remove',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({ brand }: { brand: BrandTransactionMatcherResults }) => {
          if (!brand || !brand.rawMatchers || !brand.rawMatchers.matchers) {
            return;
          }
          const results: Option[] = [];
          brand.rawMatchers.matchers.forEach((matcher) => {
            if (matcher) {
              results.push({
                id: `${matcher.pattern}`,
                name: `${matcher.pattern}`,
              });
            }
          });
          return results;
        },
      ),
    },
  },
  {
    name: 'Regex Results and Regex to Remove',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      currentMatchers: new InputInjection(
        [1],
        ({ brand }: { brand: BrandTransactionMatcherResults }) => {
          if (!brand || !brand.rawMatchers || !brand.rawMatchers.matchers) {
            return;
          }
          return brand.rawMatchers.matchers;
        },
      ),
      matchersToRemove: new InputInjection(
        [2],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          const matchersToRemove: string[] = [];
          const selections = getArrayFromCheckboxSelections(result);
          selections.forEach((selection) => {
            matchersToRemove.push(selection);
          });
          return matchersToRemove;
        },
      ),
    },
  },
  {
    name: 'Upsert New Transaction Matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: UPSERT_TRANSACTION_MATCHERS,
    },
    inputData: {
      id: new InputInjection([0, 'brandId']),
      matchers: new InputInjection(
        [3],
        ({
          currentMatchers,
          matchersToRemove,
        }: {
          currentMatchers: MatcherObject[];
          matchersToRemove: string[];
        }) => {
          if (!currentMatchers || !matchersToRemove) {
            return;
          }
          return currentMatchers.filter(
            (matcher) =>
              !matchersToRemove.some((removal) => removal === matcher.pattern),
          );
        },
      ),
    },
  },
];

export const removeTransactionMatcher = {
  steps,
  inputValues,
  name: 'Remove Transaction Matcher',
  description:
    'Remove a transaction matcher from existing matchers for a merchant',
  tags: [Tags.transactions],
};
