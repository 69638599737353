import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

export interface ADSOffer {
  brandBanner: string;
  brandCardImage: string;
  brandId: string;
  brandLogo: string;
  brandName: string;
  cashback: Cashback;
  cashbackRestrictions: any[];
  categories: any[];
  createdAt: Date;
  creatives: Creative[];
  currency: string;
  description: string;
  endDate: Date;
  featured: boolean;
  impressionToken: string;
  locale: string;
  locations: any[];
  maxRewardAmount: number;
  minSpendAmount: number;
  offerLocations: any[];
  offerNetwork: string;
  offerNetworkOfferId: string;
  offerReference: string;
  offerStatus: string;
  offerUuid: string;
  url: string;
}

export interface Cashback {
  scaleFactor: number;
  units: string;
  value: number;
}

export interface Creative {
  headline: string;
  preMessage: string;
  shortPreMessage: string;
  thankYouMessage: string;
}
const inputValues = [
  {
    name: 'userId',
    formLabel: 'User ID *',
  },
  {
    name: 'userToken',
    formLabel: 'User Token *',
  },
  {
    name: 'userLocation',
    formLabel: 'User Location *',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor<
  {
    userId: string;
    userToken: string;
    userLocation: {
      latitude: number;
      longitude: number;
    };
    _logLevel?: string;
  },
  {
    offers: ADSOffer[];
    featuredOffers: ADSOffer[];
  }
>({
  serviceName: 'cdlx-adapter-svc',
  functionName: 'adsGet',
  method: HttpMethod.POST,
});

export const adsGet = {
  name: 'Get ADS offers',
  description: 'Get ADS offers from cdlx-adapter-svc',
  inputValues,
  tags: [Tags.csResources],
  submit,
};
