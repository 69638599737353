import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid',
  },
  {
    name: 'minimumSpendToActivate',
    formLabel: 'Minimum Spend to Activate',
  },
  {
    name: 'cashbackPercentageLimit',
    formLabel: 'Cashback Percentage Limit',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'merchant-service-2',
  functionName: 'setCouponProperties',
  method: HttpMethod.POST,
});

export const setCouponProperties = {
  name: 'Set Coupon Properties',
  description:
    'Used to update min spend to activate or set max cashback limit for an offer',
  inputValues,
  outputValueName: 'Set Coupon Properties',
  tags: [Tags.offers],
  submit,
};
