import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid For Update',
  },
  {
    name: 'couponSegmentUuid',
    formLabel: 'Coupon Segment Uuid For Update',
  },
  {
    name: 'targetDataKey',
    formLabel: 'Type of Targeting Rule Data',
    formSubLabel: 'OFFER_THROTTLE_UUID, HASHMOD_SALT, AUDIENCE_UUID, MUG_UUID',
  },
  {
    name: 'targetData',
    formLabel: 'Target Data (Uuid)',
  },
  {
    name: 'updatedBy',
    formLabel: 'Name of Person Updating',
  },
  {
    name: 'dryRun',
    formLabel: 'Dry Run?',
    default: false,
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'merchant-service-2',
  functionName: 'updateSegmentTargetingRuleData',
  method: HttpMethod.POST,
});

export const updateSegmentTargetingRuleData = {
  name: 'Update Segment Targeting Rule Data',
  description:
    'Update target data on segment, including throttles, hashes, audiences, and mugs',
  inputValues,
  outputValueName: 'Result',
  tags: [Tags.offers, Tags.throttle, Tags.audiences, Tags.mugs],
  submit,
};
