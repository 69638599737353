import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'name',
    formLabel: 'Name',
    formSubLabel: 'Marketplace Name Abbreviation (i.e. ELLEV)',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'poweredby-marketplace-svc',
  functionName: 'marketplacesGet',
  method: HttpMethod.GET,
});

export const marketplacesGetByName = {
  name: 'Get Marketplace By Name',
  description: 'Get Marketplace By Name',
  inputValues,
  outputValueName: 'marketplace',
  submit,
  tags: [Tags.poweredBy, Tags.partnerOnboarding],
};
