import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'userId',
    formLabel: 'User Id',
  },
  {
    name: 'audienceUuid',
    formLabel: 'Audience Uuid',
  },
  {
    name: 'status',
    formLabel: 'Status',
    formSubLabel: 'ADDED, REMOVED',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'audience-svc',
  functionName: 'manuallyUpsertAudienceMembership',
  method: HttpMethod.POST,
});

export const manuallyUpsertAudienceMembership = {
  name: 'Manually upsert audience membership',
  description: 'Update user membership in audience',
  inputValues,
  outputValueName: 'results',
  submit,
};
