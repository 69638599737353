import { WorkflowStepTypes } from '@/components/Workflow';
import { Tags } from '@/helpers/types';
import InputInjection from '@/utils/workflow/InputInjection';
import { addAudienceSubscription } from '../lambdaFunctions/addAudienceSubscription';

const inputValues = [
  {
    name: 'merchantUuid',
    formLabel: 'Merchant Uuid',
    formSubLabel: 'the uuid of the merchant',
  },
  {
    name: 'targetSafegraphBrandName',
    formLabel: 'Target Safegraph Brand Name *',
    formSubLabel:
      'the target safegraph brand name to use to find target brand engagements',
  },
  {
    name: 'competitorNaicsCategoryNames',
    formLabel: 'Competitor NAICs Category Names *',
    formSubLabel:
      'the competitor naics category names to use to find competitor category engagements. (separate the distinct names with `|`)',
  },
  {
    name: 'engagedWithTargetBrandLookbackMonths',
    formLabel: 'Engaged With Target Brand Lookback Months *',
    formSubLabel:
      'the number of months to lookback to check for target brand engagements',
    default: '18',
  },
  {
    name: 'maximumDistinctDaysEngagedWithTargetBrand',
    formLabel: 'Maximum Distinct Days Engaged With Target Brand *',
    formSubLabel:
      'the maximum number of distinct days the user had engaged with the target brand, in order to still be considered "competitive"',
    default: '0',
  },
  {
    name: 'engagedWithCompetitorCategoryLookbackMonths',
    formLabel: 'Engaged With Competitor Category Lookback Months *',
    formSubLabel:
      'the number of months to lookback to check for competitor category engagements',
    default: '18',
  },
  {
    name: 'minimumDistinctDaysEngagedWithCompetitorCategory',
    formLabel: 'Minimum Distinct Days Engaged With Competitor Categories *',
    formSubLabel:
      'the minimum number of distinct days the user had engaged with the competitor categories, in order to still be considered "competitive"',
    default: '1',
  },
];

const steps = [
  {
    name: 'Add Audience Subscription',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addAudienceSubscription.submit,
    },
    inputData: {
      subscriber: 'MERCHANT_SERVICE', // "MERCHANT_SERVICE" is who manages offer targeting, so they are the subscriber
      externalId: new InputInjection(
        [0, 'merchantUuid'],
        (merchantUuid: string) =>
          `${merchantUuid}:::COMPETITIVE_CATEGORIES_USER:::${
            new Date().toISOString().split('T')[0]
          }`,
      ),
      name: new InputInjection(
        [0, 'targetSafegraphBrandName'],
        (targetSafegraphBrandName: string) =>
          `Competitive Categories Users for ${targetSafegraphBrandName}`,
      ),
      audience: {
        type: 'STATIC', // all audiences are static for now
        rules: [
          {
            type: 'COMPETITIVE_CATEGORIES_USER',
            options: {
              targetSafegraphBrandName: new InputInjection([
                0,
                'targetSafegraphBrandName',
              ]),
              competitorNaicsCategoryNames: new InputInjection(
                [0, 'competitorNaicsCategoryNames'],
                (value: string) => value.split('|'),
              ),
              engagedWithTargetBrandLookbackMonths: new InputInjection(
                [0, 'engagedWithTargetBrandLookbackMonths'],
                parseInt,
              ),
              maximumDistinctDaysEngagedWithTargetBrand: new InputInjection(
                [0, 'maximumDistinctDaysEngagedWithTargetBrand'],
                parseInt,
              ),
              engagedWithCompetitorCategoryLookbackMonths: new InputInjection(
                [0, 'engagedWithCompetitorCategoryLookbackMonths'],
                parseInt,
              ),
              minimumDistinctDaysEngagedWithCompetitorCategory:
                new InputInjection(
                  [0, 'minimumDistinctDaysEngagedWithCompetitorCategory'],
                  parseInt,
                ),
            },
          },
        ],
      },
    },
  },
];

export const addOfferTargetingAudienceCompetitiveCategoriesUser = {
  steps,
  inputValues,
  name: 'Add an offer targeting audience of competitive categories users for a brand',
  description:
    'Workflow used for creating an audience of competitive categories users to be used in offer targeting',
  tags: [Tags.targeting, Tags.audiences],
};
