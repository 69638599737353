import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'merchantId',
    formLabel: 'Merchant Id',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'dosh-on-venue-svc',
  functionName: 'findOrCreateBrandVenueForMerchant',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});

export const findOrCreateBrandVenueForMerchant = {
  name: 'Find Or Create Brand Venue For Merchant',
  description: 'Find or create brand venue for merchant',
  inputValues,
  outputValueName: 'result',
  tags: [Tags.venues],
  submit,
};
