import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { Tags } from '@/helpers/types';

export const normalizedRecordTemplate = ({
  transactionId,
  cnTransactionId,
  transactionType,
  transactionTimeStamp,
  amount,
  userId,
  cardToken,
}: {
  transactionId?: string;
  cnTransactionId?: string;
  transactionType: string;
  transactionTimeStamp: string;
  amount: number;
  userId?: string;
  cardToken?: string;
}) => {
  const record = {
    event: {
      event_id: uuid(),
      card_network: 'VISA',
      type: transactionType,
      amount,
      currency: 'USD',
      cn_transaction_id: transactionId || uuid(),
      transaction_id: cnTransactionId || uuid(),
      transaction_signature: 'Rsnrxx8P9XP57aygYpVnH0WL7t4=',
      merchant_name: null,
      user_id: userId,
      card_token: cardToken,
      card_network_ids: [
        {
          id_type: 'SE',
          external_id: '4424343481',
        },
      ],
      received_from_network_datetime: transactionTimeStamp,
      card_network_notification_id: '03ef84f9-52fd-4b7f-b684-633a29cdd4e2',
      card_network_notification_id_source: `VISA_NOTIFICATION`,
      notification_service_version: '1.1.1-f5ab9cb',
      transaction_timestamp: transactionTimeStamp,
      transaction_timestamp_heuristic: 'CANONICAL',
      transaction_timestamp_raw: '2018-09-29 20: 30: 15',
    },
    type: 'AUTHORIZED',
  };

  return {
    messageId: uuid(),
    receiptHandle:
      'AQEBDpPAFYfSaNcnMYfLRSUJYpLzej3qPnGEiViiWxezuZF2ycy91GcaWE73Naiq2Vl5mf8xfhtrf01VZLx/Tc+m9FtUzW+7oPx3buOtvY4T22+MuWMXd4HPzpv0V79wjZLQiGHrOhU6VyF3kY83m8MdjPVfZjRDJyUBWdYeSqsf74XUxsBLpUZSDrm4FfXe1ZUMlUYrZWT13aU1IjtVW7gL+S10y6Zg2AqBwf6JNMCRwKkYO4X2i8PooZZOFTh+dUQcRZAqBlKXfzKxHvESu0rFq6Gcis17m+/9PJVhCcrtwQz4nr4ipBRCFdQ1Km/ty9PpUIC9WPwZnPlDMJhpOy+S8LxlI7U47Iqz6Jszk8VC/2Gl3fa91dXw9TiwXd0Tb6tgj9Vz7OSp+J6YwnQjaXq9dg==',
    body: JSON.stringify(record),
    messageAttributes: {},
    awsRegion: 'us-east-1',
  };
};

const inputValues = [
  {
    name: 'Records',
    formLabel: 'Normalized Transaction Event',
    default: JSON.stringify([
      normalizedRecordTemplate({
        transactionType: 'AUTHORIZED',
        amount: 1234,
        transactionTimeStamp: moment().utc().toISOString(),
      }),
    ]),
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'card-transaction-service',
  functionName: 'processNormalizedVisaTxs',
  method: HttpMethod.POST,
});

export const processNormalizedVisaTxs = {
  name: 'processNormalizedVisaTxs',
  description: 'Process a normalized Visa transaction',
  inputValues,
  outputValueName: 'Result',
  submit,
  tags: [Tags.transactions],
};
