import React, { FunctionComponent } from 'react';
import ReactJson from 'react-json-view';

import './InputJson.css';

interface InputJsonProps {
  json: any;
  name?: string;
  collapsed?: boolean;
  onEdit?: any;
  onAdd?: any;
  onDelete?: any;
}

const InputJson: FunctionComponent<InputJsonProps> = ({
  json,
  name = 'response',
  onEdit = false,
  onAdd = false,
  onDelete = false,
  collapsed = false,
}): JSX.Element => (
  <ReactJson
    theme="rjv-default"
    name={name}
    src={json}
    onEdit={onEdit}
    onAdd={onAdd}
    onDelete={onDelete}
    collapsed={collapsed}
    displayDataTypes={false}
  />
);

export default InputJson;
