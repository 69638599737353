import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { addManualONVenueToCNIdMapping } from '../lambdaFunctions/addManualONVenueToCNIdMapping';
import { findOrCreateBrandVenueForMerchant } from '../lambdaFunctions/findOrCreateBrandVenueForMerchant';
import { onboardVenueToCardNetworks } from '../lambdaFunctions/onboardVenueToCardNetworks';
import {
  CardNetwork,
  CardNetworkIdType,
  MatchBasis,
  Tags,
  Venue,
} from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Merchant Uuid and Brand Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT m.name, getuuid(m.merchant_id) as merchant_uuid, mb.brand as brand_id
          FROM merchantdb.merchant_brand mb
          JOIN merchantdb.merchants m on mb.merchant_id = m.merchant_id
          WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
        ;`,
      ),
    },
  },
  {
    name: 'Find/Create Default Nearby ONVenue',
    inputData: {
      merchantId: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: { name: string; merchant_uuid: string; brand_id: string }[];
        }) => {
          if (!records || !records.length) {
            return;
          }
          return records[0].merchant_uuid;
        },
      ),
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: findOrCreateBrandVenueForMerchant.submit,
    },
  },
  {
    name: 'Register ONVenue with Card Networks',
    inputData: {
      locationId: new InputInjection(
        [2],
        ({ defaultBrandVenue }: { defaultBrandVenue: Venue }) => {
          if (!defaultBrandVenue) {
            return;
          }
          return defaultBrandVenue.id;
        },
      ),
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: onboardVenueToCardNetworks.submit,
    },
  },
  {
    name: 'Create and Map PAYPAL CNId',
    inputData: {
      onvenueUuid: new InputInjection([3], ({ venue }: { venue: Venue }) => {
        if (!venue || !venue) {
          return;
        }
        return venue.venueUuid;
      }),
      cardNetwork: CardNetwork.PAYPAL,
      cardNetworkExternalIdType: CardNetworkIdType.BRAND,
      cardNetworkExternalId: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: { name: string; merchant_uuid: string; brand_id: string }[];
        }) => {
          if (!records || !records.length) {
            return;
          }
          return records[0].brand_id;
        },
      ),
      basis: MatchBasis.AGGREGATE_MATCH,
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addManualONVenueToCNIdMapping.submit,
    },
  },
];

export const setupQRNearbyBrandOfferNetworkVenue = {
  steps,
  inputValues,
  name: 'Setup QR Nearby Brand Offer Network Venue',
  description:
    'Setup venue for QR code offers - adds PAYPAL brand cnid to neary unknown location',
  tags: [Tags.newMerchantOnboarding, Tags.venues, Tags.specialMerchants],
};
