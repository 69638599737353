import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
  },
  {
    name: 'cardNetworkExternalIdType',
    formLabel: 'Id Type',
  },
  {
    name: 'cardNetworkExternalId',
    formLabel: 'External Id',
  },
  {
    name: 'reason',
    formLabel: 'Reason',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-mgmt-svc',
  functionName: 'blacklistCNIdAndUnmapFromCNVenues',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});

export const blacklistCNIdAndUnmapFromCNVenues = {
  name: 'Blacklist CNId and Unmap From CNVenues',
  description: 'Add CNId to the blacklist and remove from cnvenues',
  inputValues,
  tags: [Tags.transactions, Tags.cnids],
  submit,
};
