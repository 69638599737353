import React, { FocusEvent } from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import is from 'is_js';
import validate from '@/utils/validator';

import './FileField.css';

export const FileField = ({
  register,
  path,
  validationFn,
  onBlur,
  setValue,
  errors,
  formLabel,
  disabled,
  subLabel = '',
  className,
  tabIndex,
}: {
  register: Function;
  path: string;
  validationFn?: Function;
  onBlur?: (e: FocusEvent) => void;
  setValue: (path: string, value: any) => void;
  formLabel?: string;
  className?: string;
  errors?: any;
  disabled?: boolean;
  subLabel?: string;
  tabIndex?: number;
}) => (
  <div
    className={classNames(className, {
      'form-group': is.edge(),
      'form-label-group': !is.edge(),
    })}
  >
    {formLabel && (
      <Form.Label>
        {formLabel}
        {subLabel && <p className="formLabelSub">{subLabel}</p>}
      </Form.Label>
    )}
    <Form.File
      name={path}
      disabled={disabled}
      onBlur={onBlur}
      tabIndex={tabIndex}
      data-browse="Browse"
      custom
    >
      <Form.File.Input
        name={`${path}-file`}
        onChange={(e: any) => {
          const reader = new FileReader();
          reader.addEventListener(
            'load',
            () => {
              setValue(path, reader.result);
            },
            false,
          );
          const [file] = e.target.files;
          reader.readAsText(file);
        }}
      />
      <Form.File.Label data-browse="Browse">
        {formLabel?.toLowerCase().includes('csv') ||
        path.toLowerCase().includes('csv')
          ? 'path/to/my.csv'
          : 'Select File'}
      </Form.File.Label>
      <input
        type="hidden"
        name={path}
        ref={register({ validate: validationFn || validate.notRequired })}
      />
    </Form.File>
    {errors && errors[path] ? (
      <em className="errorMessage">{errors[path].message}</em>
    ) : null}
  </div>
);
