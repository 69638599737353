import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { WithdrawalControlStatus } from '@/services/lambdaFunctions/getWithdrawalControls';
import { Tags } from '@/helpers/types';

export enum UpdateWithdrawalControlStrategy {
  ALL = 'ALL',
  BY_WITHDRAWAL_QUALIFIER = 'BY_WITHDRAWAL_QUALIFIER',
  BY_MARKETPLACE = 'BY_MARKETPLACE',
  BY_WITHDRAWAL_QUALIFIER_AND_MARKETPLACE = 'BY_WITHDRAWAL_QUALIFIER_AND_MARKETPLACE',
}

export enum UpdateWithdrawalControlWithdrawalAction {
  INITIATE = 'INITIATE',
  EXECUTE = 'EXECUTE',
  ALL = 'ALL',
}

export enum WithdrawalControlInitiator {
  OPERATIONS_MANUAL = 'OPERATIONS_MANUAL',
  ACCEPTANCE_TEST = 'ACCEPTANCE_TEST',
}

export interface WithdrawalControlUpdate {
  strategy: UpdateWithdrawalControlStrategy;
  withdrawalQualifier?: string;
  marketplace?: string;
}

interface UpdateWithdrawalControlsRequest {
  targetStatus: WithdrawalControlStatus;
  withdrawalAction: UpdateWithdrawalControlWithdrawalAction;
  updates: WithdrawalControlUpdate[];
  initiator: WithdrawalControlInitiator;
}

const inputValues: InputFieldConfigs[] = [];

interface UpdateWithdrawalControlsResponse {
  success: boolean;
  data?: {};
  error?: {
    message: string;
  };
}

const submit = lambdaConstructor<
  UpdateWithdrawalControlsRequest,
  UpdateWithdrawalControlsResponse
>({
  serviceName: 'wallet-service-2',
  functionName: 'updateWithdrawalControls',
  method: HttpMethod.POST,
});

export const updateWithdrawalControls = {
  name: 'Update Withdrawal Controls',
  description: 'update withdrawal controls',
  inputValues,
  tags: [Tags.csResources],
  submit,
};
