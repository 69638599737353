import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { replayCardTransactionEvents } from '../lambdaFunctions/replayCardTransactionEvents';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'eventId',
    formLabel: 'Event Id',
    formSubLabel: 'If one provided',
  },
  {
    name: 'cnTransactionId',
    formLabel: 'Cn Transaction Id',
    formSubLabel: 'If event id not known',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Event Ids',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ eventId }: { eventId: string }) => !!eventId,
        ),
      },
      query: new InputInjection(
        [0],
        ({
          cnTransactionId,
        }: {
          cnTransactionId: string;
        }) => `SELECT DISTINCT cte.event_id as event_id
        FROM empyrdb.card_transaction_events cte
        where cte.cn_transaction_id = '${cnTransactionId || ''}';`,
      ),
      retries: 20,
    },
  },
  {
    name: 'Combine Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      eventsFromCNTransactionId: new InputInjection(
        [1],
        ({ records }: { records: { event_id: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return { eventId: record.event_id };
          });
        },
      ),
      eventProvided: new InputInjection(
        [0],
        ({ eventId }: { eventId: string }) => [{ eventId }],
      ),
    },
  },
  {
    name: 'Replay Card Transaction Events',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: replayCardTransactionEvents.submit,
    },
    inputData: {
      cardTransactionEvents: new InputInjection(
        [2],
        ({
          eventsFromCNTransactionId,
          eventProvided,
          status,
        }: {
          eventsFromCNTransactionId: { eventId: string }[];
          eventProvided: { eventId: string }[];
          status: string;
        }) => {
          if (!status || status !== 'success') {
            return;
          }
          return eventsFromCNTransactionId && eventsFromCNTransactionId.length
            ? eventsFromCNTransactionId
            : eventProvided;
        },
      ),
      callCnVenueService: true,
    },
  },
];

export const replayCardTransactionEventsFromEventIdOrCNTransactionId = {
  steps,
  inputValues,
  name: 'Replay Card Transaction Events From Event Id Or CN Transaction Id',
  description: 'Replay event id for a transaction that had no venue resolved',
  tags: [Tags.transactions],
};
