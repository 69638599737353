import React, { FunctionComponent } from 'react';
import './Box.css';

interface BoxProps {
  className?: string;
}

const Box: FunctionComponent<BoxProps> = ({
  className,
  children,
}): JSX.Element => {
  return <div className={`trv-box ${className || ''}`}>{children}</div>;
};

export default Box;
