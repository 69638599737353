import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'updatedBy',
    formLabel: 'Person Updating',
  },
  {
    name: 'forMerchant.uuid',
    formLabel: 'Merchant Uuid',
  },
  {
    name: 'forVenues.venueUuid',
    formLabel: 'Venue Uuid',
  },
  {
    name: 'set.status',
    formLabel: 'Venue Status',
  },
  {
    name: 'set.name',
    formLabel: 'Venue Name',
  },
  {
    name: 'set.hours',
    formLabel: 'Venue Hours',
    formSubLabel: 'Hours should be in 12 format',
  },
  {
    name: 'set.website',
    formLabel: 'Venue Website',
    formSubLabel: 'Needs to start with https',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'dosh-on-venue-svc',
  functionName: 'updateVenueProperties',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});

export const updateVenueProperties = {
  name: 'Update Venue Properties',
  description: 'Update Venue Properties (status, name, hours, website)',
  inputValues,
  outputValueName: 'Template Object',
  tags: [Tags.venues],
  submit,
};
