import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'placeSubscription.subscriber',
    formLabel: 'Subscriber',
    formSubLabel: 'who subscribed to this place',
    default: 'CN_VENUE_SERVICE', // this will be the most common subscriber used
  },
  {
    name: 'placeSubscription.externalId',
    formLabel: 'ExternalId',
    formSubLabel:
      'what id did they use to refer to this place (e.g., the venue uuid)',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor<
  {
    placeSubscription: {
      subscriber: string;
      externalId: string;
    };
  },
  { place: any | null }
>({
  serviceName: 'places-svc',
  functionName: 'getPlace',
  method: HttpMethod.POST,
});

export const getPlaceBySubscription = {
  name: '[places-svc] Get Place By Subscription',
  description: 'Gets the place of a place subscription',
  tags: [Tags.venues],
  submit,
  inputValues,
};
