import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';
import { getTransactionForVisaUser } from '../lambdaFunctions/getTransactionForVisaUser';

const inputValues = [
  {
    name: 'cognitoId',
    formLabel: 'Cognito Id',
  },
  {
    name: 'cardLast4',
    formLabel: 'Card Last 4',
  },
  {
    name: 'transactionAmount',
    formLabel: 'Transaction Amount',
  },
  {
    name: 'transactionDate',
    formLabel: 'Transaction Date',
    formSubLabel: 'YYYY-MM-DD',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get EUID',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ cognitoId, cardLast4 }: { cognitoId: string; cardLast4: string }) =>
          `SELECT DISTINCT c.card_id, c.created_at, c.deleted_at
          FROM carddb.cards c
          JOIN carddb.users u ON c.user_id = u.user_id
          JOIN carddb.card_tokens ct ON c.card_id = ct.card_id
          WHERE 1 = 1
          AND u.cognito_id = '${cognitoId}'
          AND c.last4 = '${cardLast4}'
          AND c.type = 'Visa'
          ORDER BY created_at DESC
          ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Select EUID',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            card_id: string;
            created_at: string;
            deleted_at: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.card_id}`,
              name: `EUID: ${record.card_id}, Link Date: ${
                record.created_at
              }, Deleted Date: ${
                typeof record.deleted_at === 'boolean'
                  ? 'N/A'
                  : record.deleted_at
              }`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Process Selections',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      selectedEUID: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
      cardLast4: new InputInjection([0, 'cardLast4']),
      transactionAmount: new InputInjection([0, 'transactionAmount']),
      transactionDate: new InputInjection([0, 'transactionDate']),
    },
  },
  {
    name: 'Get Visa Transaction',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getTransactionForVisaUser.submit,
    },
    inputData: {
      euid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
      cardLast4: new InputInjection([0, 'cardLast4']),
      transactionAmount: new InputInjection(
        [0],
        ({ transactionAmount }: { transactionAmount: string }) => {
          if (!transactionAmount) {
            return;
          }
          return parseFloat(transactionAmount);
        },
      ),
      transactionDate: new InputInjection([0, 'transactionDate']),
    },
  },
];

export const lookupVisaTransaction = {
  steps,
  inputValues,
  name: 'Lookup Visa Transaction',
  description: 'Check Visa API for User Transaction',
  tags: [Tags.csResources, Tags.transactions],
};
