import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'cardTransactionEvents',
    formLabel: 'Card Transaction Events to Replay',
  },
  {
    name: 'callCnVenueService',
    formLabel: 'Call CnVenueService',
    default: true,
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'card-transaction-service',
  functionName: 'replayCardTransactionEvents',
  method: HttpMethod.POST,
});

export const replayCardTransactionEvents = {
  name: 'Replay Card Transaction Events',
  description:
    'Replay card transaction events, note: only effective if no venue resolved for initial transaction',
  inputValues,
  outputValueName: 'Result',
  submit,
};
