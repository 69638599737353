import React, { FunctionComponent, useState } from 'react';
import { Icon } from 'react-icons-kit';
import { command } from 'react-icons-kit/iconic/command';
import { forkRepo } from 'react-icons-kit/ionicons/forkRepo';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Form } from 'react-bootstrap';
import classNames from 'classnames';
import FuzzySearch from 'fuzzy-search';

import lambdaFunctions from '@/services/lambdaFunctions';
import workflows from '@/services/workflows';
import customWorkflows from '@/services/customWorkflows';

import './Home.css';

const extractPossibleTags = (actions: any) => {
  const allTags = actions.reduce((all: any, currAction: any) => {
    return [...all, ...(currAction.tags || [])];
  }, []);

  return [...new Set(allTags.sort())];
};

const filterActionsByTag = (actions: any, selectedTags: string[]) => {
  if (!selectedTags.length) {
    return actions;
  }

  return actions.filter(({ tags = [] }: any) =>
    tags.some((t: string) => selectedTags.includes(t)),
  );
};

const Home: FunctionComponent = () => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>();

  const customWorkflowActions = Object.keys(customWorkflows)
    .filter((workflowName) => !customWorkflows[workflowName].hidden)
    .map((workflowName) => ({
      id: `customWorkflow-${workflowName}`,
      name: customWorkflows[workflowName].name,
      description: customWorkflows[workflowName].description,
      tags: customWorkflows[workflowName].tags,
      link: `/customworkflow/${workflowName}`,
      icon: forkRepo,
    }));

  const lambdaActions = Object.keys(lambdaFunctions).map((lambdaName) => ({
    id: `lambda-${lambdaName}`,
    name: lambdaFunctions[lambdaName].name,
    description: lambdaFunctions[lambdaName].description,
    tags: lambdaFunctions[lambdaName].tags,
    homePage: lambdaFunctions[lambdaName].homePage,
    link: `/function/${lambdaName}`,
    icon: command,
  }));

  const workflowActions = Object.keys(workflows).map((workflowName) => ({
    id: `workflow-${workflowName}`,
    name: workflows[workflowName].name,
    description: workflows[workflowName].description,
    tags: workflows[workflowName].tags,
    homePage: workflows[workflowName].homePage,
    link: `/workflow/${workflowName}`,
    icon: forkRepo,
  }));

  const allActions = [
    ...customWorkflowActions,
    ...workflowActions,
    ...lambdaActions,
  ];

  const possibleTags = extractPossibleTags(allActions) as string[];
  const filteredActions = filterActionsByTag(allActions, selectedTags);
  const searcher = new FuzzySearch(filteredActions, ['name'], {
    sort: true,
  });
  const searchResults = searcher.search((searchTerm || '').trim());

  return (
    <>
      <Container className="smallContainer">
        <div className="filterTags-container">
          {possibleTags.map((tag: string) => {
            const selected = selectedTags.includes(tag);
            const toggleTagSelection = () => {
              if (selected) {
                const updatedTags = selectedTags.filter((t) => t !== tag);
                setSelectedTags(updatedTags);
              } else {
                setSelectedTags([...selectedTags, tag]);
              }
            };

            return (
              <span
                key={tag}
                tabIndex={0}
                className={classNames('filterTag', {
                  'filterTag-selected': selected,
                })}
                onClick={toggleTagSelection}
                onKeyDown={toggleTagSelection}
                role="button"
              >
                {tag}
              </span>
            );
          })}
        </div>
        <Form.Control
          type="text"
          placeholder="search for a workflow"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Container>
      <Container className="smallContainer">
        {searchResults.map((li: any) => (
          <Link key={li.id} to={li.link} className="linkContainer">
            <Row className="linkCard">
              <Col xs lg="2" className="linkContainer-icon">
                <Icon icon={li.icon} size={32} />
              </Col>
              <Col>
                <div>
                  <h3 className="linkCard-header">{li.name}</h3>
                  <p
                    className="linkCard-description"
                    dangerouslySetInnerHTML={{ __html: li.description }}
                  />
                </div>
              </Col>
            </Row>
          </Link>
        ))}
      </Container>
    </>
  );
};

export default Home;
