import React, { FunctionComponent } from 'react';
import { Col, Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import customWorkflows from '@/services/customWorkflows';

import './CustomWorkflowPage.css';

interface PathParams {
  workflowName: string;
}

const CustomWorkflowPage: FunctionComponent = () => {
  const { workflowName } = useParams() as PathParams;
  const config = customWorkflows[workflowName];

  if (!config) {
    return (
      <>
        <h1>
          No workflow with the name
          {workflowName}
        </h1>
        <Link to="/">Go Home</Link>
      </>
    );
  }

  const { Component } = config;

  return (
    <Container className="customWorkflowContainer">
      <Col>
        <h1 className="customWorkflow-header">{config.name}</h1>
        <p
          className="customWorkflow-description"
          dangerouslySetInnerHTML={{ __html: config.description }}
        />
      </Col>
      <div className="customWorkflow-componentWrapper ">
        <Component />
      </div>
    </Container>
  );
};

export default CustomWorkflowPage;
