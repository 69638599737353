import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';

import { WorkflowStepTypes } from '@/components/Workflow';
import {
  Lambda,
  GraphQLMutation,
  GraphQLQuery,
  ViewAssets,
  ButtonMerchantData,
  CheckboxSelector,
  UploadButtonMerchantAssets,
  RedshiftQuery,
  ExternalStep,
  PreprocessData,
  DownloadData,
  DisplayResult,
} from '@/components/WorkflowSteps';
import WorkflowStepStatus, {
  WorkflowStepStatusTypes,
} from '@/components/Workflow/components/WorkflowStepStatus';

import './WorkflowStepContainer.css';

interface WorkflowStepProps {
  name: string;
  active: boolean;
  onCompleteStep: Function;
  inputData: any;
  outputData: any;
  onInputEdit: Function;
  processType: WorkflowStepTypes;
  onSubmit: any;
  autorun: boolean;
}

const StepDisplay: FunctionComponent<any> = ({
  name,
  active,
  status,
  setStatus,
  onCompleteStep,
  inputData,
  outputData,
  onInputEdit,
  processType,
  onSubmit,
  autorun,
}) => {
  if (processType === WorkflowStepTypes.LAMBDA) {
    return (
      <Lambda
        name={name}
        active={active}
        onCompleteStep={onCompleteStep}
        status={status}
        setStatus={setStatus}
        inputData={inputData}
        outputData={outputData}
        onInputEdit={onInputEdit}
        onSubmit={onSubmit}
        autorun={autorun}
      />
    );
  }
  if (processType === WorkflowStepTypes.GRAPHQL_MUTATION) {
    return (
      <GraphQLMutation
        name={name}
        active={active}
        status={status}
        setStatus={setStatus}
        onCompleteStep={onCompleteStep}
        inputData={inputData}
        outputData={outputData}
        onInputEdit={onInputEdit}
        mutation={onSubmit}
        autorun={autorun}
      />
    );
  }
  if (processType === WorkflowStepTypes.GRAPHQL_QUERY) {
    return (
      <GraphQLQuery
        name={name}
        active={active}
        status={status}
        setStatus={setStatus}
        onCompleteStep={onCompleteStep}
        inputData={inputData}
        outputData={outputData}
        onInputEdit={onInputEdit}
        query={onSubmit}
        autorun={autorun}
      />
    );
  }
  if (processType === WorkflowStepTypes.BUTTON_MERCHANT_DATA) {
    return (
      <ButtonMerchantData
        name={name}
        active={active}
        setStatus={setStatus}
        onCompleteStep={onCompleteStep}
        outputData={outputData}
      />
    );
  }
  if (processType === WorkflowStepTypes.CHECKBOX_SELECTOR) {
    return (
      <CheckboxSelector
        name={name}
        active={active}
        setStatus={setStatus}
        onCompleteStep={onCompleteStep}
        inputData={inputData}
        outputData={outputData}
      />
    );
  }
  if (processType === WorkflowStepTypes.UPLOAD_BUTTON_MERCHANT_ASSETS) {
    return (
      <UploadButtonMerchantAssets
        name={name}
        active={active}
        setStatus={setStatus}
        inputData={inputData}
        onCompleteStep={onCompleteStep}
        outputData={outputData}
      />
    );
  }
  if (processType === WorkflowStepTypes.VIEW_ASSETS) {
    return (
      <ViewAssets
        name={name}
        active={active}
        inputData={inputData}
        onCompleteStep={onCompleteStep}
      />
    );
  }
  if (processType === WorkflowStepTypes.REDSHIFT_QUERY) {
    return (
      <RedshiftQuery
        name={name}
        active={active}
        onCompleteStep={onCompleteStep}
        status={status}
        setStatus={setStatus}
        inputData={inputData}
        outputData={outputData}
        onInputEdit={onInputEdit}
        autorun={autorun}
      />
    );
  }
  if (processType === WorkflowStepTypes.EXTERNAL_STEP) {
    return (
      <ExternalStep
        name={name}
        active={active}
        inputData={inputData}
        onCompleteStep={onCompleteStep}
      />
    );
  }
  if (processType === WorkflowStepTypes.PREPROCESS_DATA) {
    return (
      <PreprocessData
        name={name}
        active={active}
        onCompleteStep={onCompleteStep}
        status={status}
        setStatus={setStatus}
        inputData={inputData}
        outputData={outputData}
        onSubmit={onSubmit}
        autorun={autorun}
      />
    );
  }
  if (processType === WorkflowStepTypes.DOWNLOAD_DATA) {
    return (
      <DownloadData
        name={name}
        active={active}
        onCompleteStep={onCompleteStep}
        status={status}
        setStatus={setStatus}
        inputData={inputData}
        outputData={outputData}
        onSubmit={onSubmit}
        autorun={autorun}
      />
    );
  }
  if (processType === WorkflowStepTypes.DISPLAY_RESULT) {
    return <DisplayResult name={name} inputData={inputData} />;
  }

  return <></>;
};

const WorkflowStepContainer: FunctionComponent<WorkflowStepProps> = ({
  name,
  active,
  onCompleteStep,
  inputData,
  outputData,
  onInputEdit,
  processType,
  onSubmit,
  autorun,
}) => {
  const [status, setStatus] = useState(WorkflowStepStatusTypes.INACTIVE);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!active) {
      return;
    }
    const { metaData: inputMetaData } = inputData || {};
    if (
      inputMetaData &&
      inputMetaData.autoSkip &&
      inputMetaData.autoSkip === true
    ) {
      onCompleteStep({
        status: 'skipped',
        message: 'this step was auto skipped',
      });
    }
  }, [active, inputData]);

  useEffect(() => {
    let newStatus = active
      ? WorkflowStepStatusTypes.ACTIVE
      : WorkflowStepStatusTypes.INACTIVE;
    if (outputData) {
      if (outputData.status === 'success') {
        newStatus = WorkflowStepStatusTypes.SUCCESS;
      } else if (outputData.status === 'failed') {
        newStatus = WorkflowStepStatusTypes.FAILED;
      } else if (outputData.status === 'skipped') {
        newStatus = WorkflowStepStatusTypes.SKIPPED;
      }
    }
    setStatus(newStatus);
  }, [outputData, active]);

  return (
    <Container
      className={`workflowStep-container workflowStep-container-${status}`}
      style={{
        boxShadow: active
          ? 'rgba(0, 0, 0, 0.08) 0px 0px 12px 0px'
          : 'rgba(0, 0, 0, 0.03) 0px 3px 8px 0px',
        background: active ? '#fff' : '#fcfcfc',
      }}
    >
      <Row
        className="workflowStep-header"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <WorkflowStepStatus status={status} />
        <h1 className="workflowStep-name">{name}</h1>
        <div />
        {/* <Icon onClick={openNewTab} className="newTabIcon" icon={newTab} size={32}/> */}
      </Row>
      <Row
        style={{
          borderTop: active || expanded ? '1px #9e9e9e solid' : '',
          padding: active || expanded ? '30px' : '0',
        }}
      >
        {(active || expanded) && (
          <StepDisplay
            name={name}
            active={active}
            status={status}
            onCompleteStep={onCompleteStep}
            inputData={inputData}
            outputData={outputData}
            onInputEdit={onInputEdit}
            processType={processType}
            setStatus={setStatus}
            onSubmit={onSubmit}
            autorun={autorun}
          />
        )}
      </Row>
    </Container>
  );
};

export default WorkflowStepContainer;
