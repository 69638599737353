import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'marketplace',
    formLabel: 'Marketplace',
  },
  {
    name: 'feedId',
    formLabel: 'Feed',
  },
  {
    name: 'content',
    formLabel: 'Content',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'content-feed-svc',
  functionName: 'upsertContentFeed',
  method: HttpMethod.POST,
});

export const upsertContentFeed = {
  name: 'Upsert Content Feed By Marketplace',
  description: 'upsert featured banners for marketplace',
  inputValues,
  outputValueName: 'banners', // optional
  tags: [Tags.banners],
  submit,
};
