import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import {
  CardNetwork,
  CardNetworkId,
  CardNetworkIdType,
  ONVenueMapping,
  Tags,
  Venue,
} from '@/helpers/types';
import {
  cardNetworkIdTypeOptions,
  cardNetworkOptions,
} from '@/helpers/commonInputSelectOptions';
import { getONVenueMappingsForCNIds } from '../lambdaFunctions/getONVenueMappingsForCNIds';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';
import { getVenueByUuid } from '../lambdaFunctions/getVenueByUuid';

const inputValues = [
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
    options: cardNetworkOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'idType',
    formLabel: 'Card Network Id Type',
    options: cardNetworkIdTypeOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'externalId',
    formLabel: 'Card Network External Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get ONVenues Mapped to CNId',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getONVenueMappingsForCNIds.submit,
    },
    inputData: {
      cardNetworkIds: [
        {
          cardNetwork: new InputInjection([0, 'cardNetwork']),
          idType: new InputInjection([0, 'idType']),
          externalId: new InputInjection([0, 'externalId']),
        },
      ],
      includeONVenueCAIDMappings: true,
    },
  },
  {
    name: 'Select RN Venue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({ result }: { result: ONVenueMapping[] }) => {
          if (!result || !Array.isArray(result)) {
            return;
          }
          return result
            .map((onvenue) => {
              if (!onvenue || onvenue.offerNetworkVenue.venueSource !== 'RN') {
                return;
              }
              const { offerNetworkVenue } = onvenue;
              return {
                id: offerNetworkVenue.uuid,
                name: `${offerNetworkVenue.name}, ${offerNetworkVenue.locationType}, ${offerNetworkVenue.enabled}, ${offerNetworkVenue.uuid}`,
              };
            })
            .filter((opt) => !!opt);
        },
      ),
    },
  },
  {
    name: 'Get ONVenue Information',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueByUuid.submit,
    },
    inputData: {
      venueUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
    },
  },
  {
    name: 'Combine Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cnid: new InputInjection(
        [0],
        ({
          cardNetwork,
          idType,
          externalId,
        }: {
          cardNetwork: CardNetwork;
          idType: CardNetworkIdType;
          externalId: string;
        }) => {
          if (!cardNetwork || !idType || !externalId) {
            return;
          }
          return {
            cardNetwork,
            idType,
            externalId,
          };
        },
      ),
      onvenueCNIds: new InputInjection(
        [3],
        ({ result, status }: { result: Venue; status: string }) => {
          if (status !== 'success') {
            return;
          }
          return result ? result.cardNetworkIds : [];
        },
      ),
    },
  },
  {
    name: 'Analyze Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cnidOnOnvenue: new InputInjection(
        [4],
        ({
          cnid,
          onvenueCNIds,
        }: {
          cnid: CardNetworkId;
          onvenueCNIds: CardNetworkId[];
        }) => {
          if (!cnid || !onvenueCNIds) {
            return;
          }
          return onvenueCNIds.some(
            (onvenueCnid) =>
              onvenueCnid.cardNetwork === cnid.cardNetwork &&
              onvenueCnid.idType === cnid.idType &&
              onvenueCnid.externalId === cnid.externalId,
          );
        },
      ),
      cnidRemovableFromCnvenue: new InputInjection(
        [0],
        ({ idType }: { idType: CardNetworkIdType }) => {
          if (!idType) {
            return;
          }
          return (
            idType === CardNetworkIdType.CAID ||
            idType === CardNetworkIdType.AuthMID ||
            idType === CardNetworkIdType.ClearMID
          );
        },
      ),
      onvenueFound: new InputInjection(
        [4],
        ({ onvenueCNIds }: { onvenueCNIds: CardNetworkId[] }) => {
          if (!onvenueCNIds) {
            return;
          }
          return !!onvenueCNIds.length;
        },
      ),
      cnid: new InputInjection([4, 'cnid']),
    },
  },
  {
    name: 'Final Steps',
    autorun: false,
    process: {
      type: WorkflowStepTypes.EXTERNAL_STEP,
    },
    inputData: {
      description: new InputInjection(
        [5],
        ({
          cnidOnOnvenue,
          cnidRemovableFromCnvenue,
          onvenueFound,
          status,
        }: {
          cnidOnOnvenue: boolean;
          cnidRemovableFromCnvenue: boolean;
          onvenueFound: boolean;
          status: string;
        }) => {
          if (!status || status !== 'success') {
            return;
          }
          if (!onvenueFound) {
            return 'No action needed, no onvenue selected';
          }
          if (cnidOnOnvenue) {
            return 'Contact RN to have CNId removed';
          }
          if (cnidRemovableFromCnvenue) {
            return 'Unmap CNId From CNVenue';
          }
          return 'Unmap CNVenue From ONVenue With CNId';
        },
      ),
      link: new InputInjection(
        [5],
        ({
          cnidOnOnvenue,
          cnidRemovableFromCnvenue,
          onvenueFound,
          cnid,
          status,
        }: {
          cnidOnOnvenue: boolean;
          cnidRemovableFromCnvenue: boolean;
          onvenueFound: boolean;
          cnid: CardNetworkId;
          status: string;
        }) => {
          if (!status || status !== 'success') {
            return;
          }
          if (!onvenueFound) {
            return 'https://www.google.com/';
          }
          if (cnidOnOnvenue) {
            return 'https://www.rewardsnetwork.com/';
          }
          if (cnidRemovableFromCnvenue) {
            return `${window.location.origin}/workflow/unmapCNIdFromCNVenue?cardNetworkExternalId=${cnid.externalId}&cardNetwork=${cnid.cardNetwork}&cardNetworkIdType=${cnid.idType}`;
          }
          return `${window.location.origin}/workflow/unmapCNVenueFromONVenueWithCNId?externalId=${cnid.externalId}&cardNetwork=${cnid.cardNetwork}&idType=${cnid.idType}`;
        },
      ),
    },
  },
];

export const handleCNIdMismatchForRN = {
  steps,
  inputValues,
  name: 'Handle CNId Mismatch For RN',
  description: 'Handle invalid transactions paying for RN merchants',
  tags: [Tags.csResources, Tags.specialMerchants, Tags.cnidMismatches],
};
