import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'merchantUuid',
    formLabel: 'Merchant Uuid',
    formSubLabel: 'an optional filter',
  },
  {
    name: 'limit',
    formLabel: 'Limit',
    default: '25',
  },
  {
    name: 'page',
    formLabel: 'Page',
    default: 0,
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'campaign-service',
  functionName: 'offerFindBy',
  method: HttpMethod.POST,
});

export const offerFindBy = {
  name: 'Offer Find By',
  description: 'Find offers by either merchant uuid',
  inputValues,
  tags: [Tags.offers],
  submit,
};
