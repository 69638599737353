import { WorkflowStepTypes } from '@/components/Workflow';
import { GET_PENDING_BRAND_ASSETS } from '@/services/graphql/getPendingBrandAssets.gql';
import { GET_LATEST_DEFAULT_BRAND_ASSETS } from '@/services/graphql/getLatestDefaultBrandAssets.gql';
import { APPROVE_BRAND_ASSETS } from '@/services/graphql/approveBrandAssets.gql';
import InputInjection from '@/utils/workflow/InputInjection';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'brandId',
    formLabel: 'BrandId',
  },
];

const steps = [
  {
    name: 'Retrieve Latest Brand Assets',
    autorun: true,
    inputData: {
      id: new InputInjection([0, 'brandId']),
    },
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_LATEST_DEFAULT_BRAND_ASSETS,
    },
  },
  {
    name: 'View Latest Brand Assets',
    inputData: {
      assets: new InputInjection([1, 'brand', 'rawAssets', 'bundle']),
    },
    process: {
      type: WorkflowStepTypes.VIEW_ASSETS,
    },
  },
  {
    name: 'Retrieve Pending Brand Assets',
    autorun: true,
    inputData: {
      id: new InputInjection([0, 'brandId']),
    },
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_PENDING_BRAND_ASSETS,
    },
  },
  {
    name: 'View Pending Brand Assets',
    inputData: {
      assets: new InputInjection([3, 'brand', 'rawAssets', 'bundle']),
    },
    process: {
      type: WorkflowStepTypes.VIEW_ASSETS,
    },
  },
  {
    name: 'Approve Pending Brand Assets',
    autorun: false,
    inputData: {
      brandId: new InputInjection([0, 'brandId']),
      bundle: 'DEFAULT_BRANDING',
      updatedAt: new InputInjection([3, 'brand', 'rawAssets', 'updatedAt']),
    },
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: APPROVE_BRAND_ASSETS,
    },
  },
];

export const approveAssets = {
  steps,
  inputValues,
  name: 'Approve brand assets',
  description: 'Review and approve images based on a brand id',
  tags: [Tags.assets],
};
