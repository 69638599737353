import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid',
  },
  {
    name: 'targetState',
    formLabel: 'Target State (true or false)',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'campaign-service',
  functionName: 'offerToggleDynamicVenueAdditions',
  method: HttpMethod.POST,
});

export const offerToggleDynamicVenueAdditions = {
  name: 'Toggle Venue Discovery',
  description: 'Toggle Venue Discovery',
  inputValues,
  tags: [Tags.venues, Tags.offers],
  submit,
};
