import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { userGroupGetNameById } from '../lambdaFunctions/userGroupGetNameById';
import { graduationRuleCreate } from '../lambdaFunctions/graduationRuleCreate';
import { Tags } from '@/helpers/types';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'mugGroupId',
    formLabel: 'MUG Group ID',
    formSubLabel: 'MUG to add users to, note must be a MUG and not an audience',
  },
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Segment Details',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          mugGroupId,
          offerUuid,
        }: {
          mugGroupId: string;
          offerUuid: string;
        }) => `SELECT DISTINCT cs.coupon_segment_uuid as segment_uuid, cs.priority as segment_priority,
        cs.cashback_percentage as segment_cashback, cs.cashback_daily_limit as segment_daily_limit,
        cstrd.target_data as segment_target, cstrt.type as segment_type,
        cs.start_date as segment_start
FROM merchantdb.offers o
JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
JOIN merchantdb.coupon_segment cs on o.coupon_id = cs.coupon_id
JOIN merchantdb.coupon_segment_targeting_rule cstr on cs.coupon_segment_id = cstr.coupon_segment_id
JOIN merchantdb.coupon_segment_targeting_rule_type cstrt on cstr.coupon_segment_targeting_rule_type_id = cstrt.coupon_segment_targeting_rule_type_id AND cstrt.type != 'OFFER_THROTTLE_V1'
JOIN merchantdb.coupon_segment_targeting_rule_data cstrd on cstr.coupon_segment_targeting_rule_id = cstrd.coupon_segment_targeting_rule_id
WHERE getuuid(o.offer_id) = '${offerUuid}'
AND c.end_date > current_date
AND (cs.end_date > current_date OR cs.end_date is null)
AND cs.hide_offer_for_segment = 0
AND cstrd.target_data != '${mugGroupId}'
;`,
      ),
    },
  },
  {
    name: 'Select Segment to Add Rule',
    describe: 'Can only add one at a time',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            segment_uuid: string;
            segment_priority: string;
            segment_cashback: string;
            segment_daily_limit: string;
            segment_target: string;
            segment_type: string;
            segment_start: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.segment_uuid}`,
              name: `Segment Uuid: ${record.segment_uuid}, Segment Priority: ${record.segment_priority}, Segment Cashback: ${record.segment_cashback}, Segment Daily Limit: ${record.segment_daily_limit}, Segment Target: ${record.segment_target}, Segment Type: ${record.segment_type}, Segment Start Date: ${record.segment_start}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Retrieve Name for Group Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: userGroupGetNameById.submit,
    },
    inputData: {
      groupId: new InputInjection([0, 'mugGroupId']),
    },
  },
  {
    name: 'Create Graduation Rule',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: graduationRuleCreate.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection([3], (result: any) => {
          if (result && result.status === 'success') {
            return !result.name;
          }
        }),
      },
      offerId: new InputInjection([0, 'offerUuid']),
      couponSegmentId: new InputInjection(
        [2],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          return getArrayFromCheckboxSelections(result)[0];
        },
      ),
      targetEntityId: new InputInjection([0, 'mugGroupId']),
      actionType: 'ADD_USER_TO_GROUP',
    },
  },
];

export const addGraduationRuleToOffer = {
  steps,
  inputValues,
  name: 'Add Graduation Rule To Offer',
  description:
    'Add graduation rule to add transacting users to MUG from an offer',
  tags: [Tags.targeting, Tags.offers, Tags.mugs],
};
