import { WorkflowStepTypes } from '@/components/Workflow';
import { Tags } from '@/helpers/types';
import InputInjection from '@/utils/workflow/InputInjection';
import { addAudienceSubscription } from '../lambdaFunctions/addAudienceSubscription';

const inputValues = [
  {
    name: 'merchantUuid',
    formLabel: 'Merchant Uuid',
    formSubLabel: 'the uuid of the merchant',
  },
  {
    name: 'partitioningKey',
    formLabel: 'Partitioning Key *',
    formSubLabel:
      'A key which specifies the unique set of partitions that will be randomly generated. (a.k.a. hashModSalt)',
  },
  {
    name: 'partitionsToIncludeStartIndex',
    formLabel: 'Partitions To Include Start Index *',
    formSubLabel:
      'the starting index of the partitions to include. range [0,998]',
  },
  {
    name: 'partitionsToIncludeEndIndex',
    formLabel: 'Partitions To Include End Index * ',
    formSubLabel:
      'the ending index of the partitions to include. range [1,999]',
  },
];

const steps = [
  {
    name: 'Make all the inputs accessible at once',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      merchantUuid: new InputInjection([0, 'merchantUuid']),
      partitioningKey: new InputInjection([0, 'partitioningKey']),
      partitionsToIncludeStartIndex: new InputInjection([
        0,
        'partitionsToIncludeStartIndex',
      ]),
      partitionsToIncludeEndIndex: new InputInjection([
        0,
        'partitionsToIncludeEndIndex',
      ]),
    },
  },
  {
    name: 'Add Audience Subscription',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addAudienceSubscription.submit,
    },
    inputData: {
      subscriber: 'MERCHANT_SERVICE', // "MERCHANT_SERVICE" is who manages offer targeting, so they are the subscriber
      externalId: new InputInjection(
        [1],
        ({
          merchantUuid,
          partitioningKey,
          partitionsToIncludeStartIndex,
          partitionsToIncludeEndIndex,
        }: any) => {
          if (
            !merchantUuid ||
            !partitioningKey ||
            !partitionsToIncludeStartIndex ||
            !partitionsToIncludeEndIndex
          ) {
            return;
          }
          return `${merchantUuid}:::RANDOMLY_PARTITIONED_USERS:::${
            new Date().toISOString().split('T')[0]
          }:::${partitioningKey}:::${partitionsToIncludeStartIndex}-${partitionsToIncludeEndIndex}`;
        },
      ),
      name: new InputInjection(
        [1],
        ({
          partitioningKey,
          partitionsToIncludeStartIndex,
          partitionsToIncludeEndIndex,
        }: any) => {
          if (
            !partitioningKey ||
            !partitionsToIncludeStartIndex ||
            !partitionsToIncludeEndIndex
          ) {
            return;
          }
          return `Randomly Partitioned Users from partitions range [${partitionsToIncludeStartIndex}, ${partitionsToIncludeEndIndex}], generated with partitioning key ${partitioningKey}`;
        },
      ),
      audience: {
        type: 'STATIC', // all audiences are static for now
        rules: [
          {
            type: 'RANDOMLY_PARTITIONED_USER',
            options: {
              partitioningKey: new InputInjection([0, 'partitioningKey']),
              partitionsToInclude: {
                startIndex: new InputInjection(
                  [0, 'partitionsToIncludeStartIndex'],
                  parseInt,
                ),
                endIndex: new InputInjection(
                  [0, 'partitionsToIncludeEndIndex'],
                  parseInt,
                ),
              },
            },
          },
        ],
      },
    },
  },
];

export const addOfferTargetingAudienceRandomlyPartitionedUser = {
  steps,
  inputValues,
  name: 'Add an offer targeting audience of randomly partitioned users',
  description:
    'Workflow used for creating an audience of randomly partitioned users to be used in offer targeting',
  tags: [Tags.targeting, Tags.audiences],
};
