import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'brandName',
    formLabel: 'Brand Name',
  },
  {
    name: 'brandNameSource',
    formLabel: 'Brand Name Source',
    default: 'SAFEGRAPH',
  },
  {
    name: 'brandId',
    formLabel: 'Brand Id',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'brand-service',
  functionName: 'addBrandName',
  method: HttpMethod.POST,
});

export const addBrandName = {
  name: 'Add Brand Name',
  description: 'Add brand name',
  inputValues,
  outputValueName: 'Results',
  tags: [Tags.venues],
  submit,
};
