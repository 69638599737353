import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { userGroupChangeMembership } from '../lambdaFunctions/userGroupChangeMembership';

const regex = /^[a-z0-9-]+:[a-zA-Z0-9-]{36}$/;

const inputValues = [
  {
    name: 'groupId',
    formLabel: 'Group Id',
  },
  {
    name: 'action',
    formLabel: 'Add or Remove',
    options: [
      { value: 'Add', label: 'Add' },
      { value: 'Remove', label: 'Remove' },
    ],
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'cognitoIdCsvFile',
    formLabel: 'cognitoIdCsvFile',
    formSubLabel: 'Upload cognitoIds through a CSV file.',
    type: InputFieldTypes.FILE,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Preprocess CSV Batch Upload',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      validatedCognitos: new InputInjection(
        [0],
        ({ cognitoIdCsvFile }: { cognitoIdCsvFile: any }) => {
          if (!cognitoIdCsvFile) {
            return;
          }
          const removedRows: string[] = [];
          const validatedRows: string[] = [];
          const rows: string[] = cognitoIdCsvFile.split('\n');
          rows.forEach((row) => {
            const columns = row.split(',');
            columns.forEach((col) =>
              col.match(regex)
                ? validatedRows.push(col)
                : removedRows.push(col),
            );
          });
          return validatedRows;
        },
      ),
      groupId: new InputInjection([0, 'groupId']),
      action: new InputInjection([0, 'action']),
    },
  },
  {
    name: 'Build Invocation Array',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      invocationArray: new InputInjection(
        [1],
        ({
          validatedCognitos,
          groupId,
          action,
          status,
        }: {
          validatedCognitos: string[];
          groupId: string;
          action: string;
          status: string;
        }) => {
          if (!status || status === 'running') {
            return;
          }
          return validatedCognitos.map((cognito) => {
            return {
              groupId,
              userId: cognito,
              active: action.toLowerCase() === 'add',
            };
          });
        },
      ),
    },
  },
  {
    name: 'Update Group Membership',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: userGroupChangeMembership.submit,
    },
    inputData: {
      multipleInvocations: true,
      invocationArray: new InputInjection(
        [2],
        ({
          invocationArray,
        }: {
          invocationArray: {
            groupId: string;
            userId: string;
            active: string;
          }[];
        }) => {
          if (!invocationArray || !Array.isArray(invocationArray)) {
            return;
          }
          return invocationArray;
        },
      ),
    },
  },
];

export const updateUserMUGMembershipBatch = {
  steps,
  inputValues,
  name: 'Update User MUG Membership Batch',
  description: 'Add or remove user from an existing MUG with csv',
  tags: [Tags.mugs, Tags.partnerOnboarding],
};
