import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'onvenueUuid',
    formLabel: 'Online Venue Uuid',
  },
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
  },
  {
    name: 'cardNetworkExternalIdType',
    formLabel: 'Card Network External Id Type',
  },
  {
    name: 'cardNetworkExternalId',
    formLabel: 'Card Network External Id',
  },
  {
    name: 'basis',
    formLabel: 'Match Type',
    formSubLabel: 'PLACE_MATCH or AGGREGATE_MATCH',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-mgmt-svc',
  functionName: 'addManualONVenueToCNIdMapping',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});

export const addManualONVenueToCNIdMapping = {
  name: 'Add ONVenue to CNId Mapping',
  description: 'Add ONVenue to CNId Mapping',
  inputValues,
  tags: [Tags.cnids, Tags.venues],
  submit,
};
