import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { createMerchant } from '../lambdaFunctions/createMerchant';
import { SET_BRAND_INFO } from '@/services/graphql/setBrandInfo.gql';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Current Merchant Name',
  },
  {
    name: 'newMerchantName',
    formLabel: 'New Merchant Name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Merchant and Brand Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) => `SELECT 
            getuuid(m.merchant_id) AS merchant_uuid, 
            m.name AS merchant_name,
            mb.brand AS brand_id
            FROM merchantdb.merchant_brand mb
            JOIN merchantdb.merchants m on mb.merchant_id = m.merchant_id
            WHERE m.name = '${merchantName}'
            ;`,
      ),
    },
  },
  {
    name: 'Check for Current or Past Live Offers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) => `SELECT DISTINCT
        m.name AS merchant_name,
        CASE WHEN (
            c.start_date > CURRENT_DATE -- offer not yet live
                OR c.start_date = c.end_date -- offer never went live
                OR o.deleted = 1 -- offer deleted
                OR o.offer_id IS NULL -- no existing offer
            )
            THEN FALSE ELSE TRUE END AS has_offer -- check for current or previously existing live offers
    FROM merchantdb.merchants m
        LEFT JOIN merchantdb.offers o ON o.merchant_id = m.merchant_id
        LEFT JOIN merchantdb.campaigns c ON o.offer_id = c.offer_id
    WHERE m.name  = '${merchantName}';`,
      ),
    },
  },
  {
    name: 'Update Brand Information -- Brand Service',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: SET_BRAND_INFO,
    },
    inputData: {
      brandId: new InputInjection([1], ({ records }: any) => {
        if (!records || !Array.isArray(records)) {
          return;
        }
        if (records.length > 1) {
          return;
        }
        return records[0].brand_id;
      }),
      info: {
        name: new InputInjection([0, 'newMerchantName']),
      },
    },
  },
  {
    name: 'Update Brand Information -- Database', // Only update if there is not a current or previously live offer
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: createMerchant.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection([2], ({ records }: any) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.some((record) => record.has_offer === 'true');
        }),
      },
      merchantUuid: new InputInjection([1], ({ records }: any) => {
        if (!records || !Array.isArray(records)) {
          return;
        }
        if (records.length > 1) {
          return;
        }
        return records[0].merchant_uuid;
      }),
      merchantName: new InputInjection([0, 'newMerchantName']),
    },
  },
];

export const updateMerchantName = {
  steps,
  inputValues,
  name: 'Update Merchant Name',
  description: 'Update Merchant Name',
  tags: [Tags.merchant],
};
