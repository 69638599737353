import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { userGroupGetIdByName } from '../lambdaFunctions/userGroupGetIdByName';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'groupName',
    formLabel: 'Group Name',
  },
  {
    name: 'groupId',
    formLabel: 'Group Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Group Id',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: userGroupGetIdByName.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ groupId }: { groupId: string }) => {
            if (!groupId || groupId === '') {
              return false;
            }
            return true;
          },
        ),
      },
      name: new InputInjection([0, 'groupName']),
    },
  },
  {
    name: 'Get List of Users From Retrieved Group Id',
    autorun: false,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection([1], ({ status }: { status: string }) => {
          if (status === 'skipped') {
            return true;
          }
          return false;
        }),
      },
      query: new InputInjection(
        [1],
        ({ groupId }: { groupId: string }) => `SELECT *
            FROM marketplace.group_membership
            WHERE user_group_id = 'gmu#${groupId}';`,
      ),
    },
  },
  {
    name: 'Get List of Users From Provided Group Id',
    autorun: false,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ groupId, status }: { groupId: string; status: string }) => {
            if (status === 'success' && (!groupId || groupId === '')) {
              return true;
            }
            return false;
          },
        ),
      },
      query: new InputInjection(
        [0],
        ({ groupId }: { groupId: string }) => `SELECT *
            FROM marketplace.group_membership
            WHERE user_group_id = 'gmu#${groupId}';`,
      ),
    },
  },
];

export const getListOfUsersInMUG = {
  steps,
  inputValues,
  name: 'Get List of Users in MUG',
  description: 'Retrieve all users in an existing mug',
  tags: [Tags.mugs],
};
