import gql from 'graphql-tag';

export const GET_LATEST_DEFAULT_BRAND_ASSETS = gql`
  query brand($id: ID!) {
    brand(id: $id) {
      rawAssets(bundle: DEFAULT_BRANDING, version: "LATEST") {
        updatedAt
        bundle
      }
    }
  }
`;
