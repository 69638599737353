import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { getContentFeed } from '../lambdaFunctions/getContentFeed';
import { upsertContentFeed } from '../lambdaFunctions/upsertContentFeed';
import { Tags } from '@/helpers/types';
import { marketplacesGetByName } from '../lambdaFunctions/marketplacesGetByName';

const inputValues = [
  {
    name: 'marketplaceName',
    formLabel: 'Marketplace Name (Must be abbrev, i.e. ELLEV)',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get current content feed',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getContentFeed.submit,
    },
    inputData: {
      marketplace: 'DOSH',
      feedId: 'featured',
    },
  },
  {
    name: 'Get marketplace by name',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: marketplacesGetByName.submit,
    },
    inputData: {
      name: new InputInjection([0, 'marketplaceName']),
    },
  },
  {
    name: 'Upsert new content feed (NOTE: if the provided marketplace cannot be resolved, DOSH will be used as default)',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: upsertContentFeed.submit,
    },
    inputData: {
      marketplace: new InputInjection(
        [2],
        ({ name, status }: { name: string; status: string }) => {
          if (!name && status !== 'success') {
            return;
          }
          return name ? [name.toUpperCase()] : ['DOSH'];
        },
      ),
      feedId: 'featured',
      content: new InputInjection([1], ({ content }: { content: string }) => {
        if (!content) {
          return;
        }
        return JSON.parse(content);
      }),
    },
  },
];

export const setupBannersForNewMarketplace = {
  steps,
  inputValues,
  name: 'Setup Banners For New Marketplace',
  description: 'This will upsert the current banner feed for a new marketplace',
  tags: [Tags.banners],
};
