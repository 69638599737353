import gql from 'graphql-tag';

export const UPSERT_TRANSACTION_MATCHERS = gql`
  mutation setBrandTransactionMatchers(
    $id: ID!
    $matchers: [BrandTransactionMatcherInput!]!
  ) {
    setBrandTransactionMatchers(brandId: $id, matchers: $matchers) {
      success
    }
  }
`;
