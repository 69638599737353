import { WorkflowStepTypes } from '@/components/Workflow';
import { Tags } from '@/helpers/types';
import InputInjection from '@/utils/workflow/InputInjection';
import { addAudienceSubscription } from '../lambdaFunctions/addAudienceSubscription';

const inputValues = [
  {
    name: 'merchantUuid',
    formLabel: 'Merchant Uuid',
    formSubLabel: 'the uuid of the merchant',
  },
  {
    name: 'safegraphBrandName',
    formLabel: 'Safegraph Brand Name *',
    formSubLabel: 'the safegraph brand name to use to find brand engagements',
  },
  {
    name: 'lookbackMonths',
    formLabel: 'Lookback Months *',
    formSubLabel: 'the number of months to lookback to check for engagements',
    default: '18',
  },
];

const steps = [
  {
    name: 'Add Audience Subscription',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addAudienceSubscription.submit,
    },
    inputData: {
      subscriber: 'MERCHANT_SERVICE', // "MERCHANT_SERVICE" is who manages offer targeting, so they are the subscriber
      externalId: new InputInjection(
        [0, 'merchantUuid'],
        (merchantUuid: string) =>
          `${merchantUuid}:::NEW_USER:::${
            new Date().toISOString().split('T')[0]
          }`,
      ),
      name: new InputInjection(
        [0, 'safegraphBrandName'],
        (safegraphBrandName: string) => `New Users for ${safegraphBrandName}`,
      ),
      audience: {
        type: 'STATIC', // all audiences are static for now
        rules: [
          {
            type: 'NEW_USER',
            options: {
              safegraphBrandName: new InputInjection([0, 'safegraphBrandName']),
              lookbackMonths: new InputInjection(
                [0, 'lookbackMonths'],
                parseInt,
              ),
            },
          },
        ],
      },
    },
  },
];

export const addOfferTargetingAudienceNewUser = {
  steps,
  inputValues,
  name: 'Add an offer targeting audience of new users for a brand',
  description:
    'Workflow used for creating an audience of new users to be used in offer targeting',
  tags: [Tags.targeting, Tags.audiences],
};
