import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues: InputFieldConfigs[] = [];

export enum WithdrawalControlAction {
  INITIATE = 'INITIATE',
  EXECUTE = 'EXECUTE',
}

export enum WithdrawalControlStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum WithdrawalControlInitiator {
  OPERATIONS_MANUAL = 'OPERATIONS_MANUAL',
  ACCEPTANCE_TEST = 'ACCEPTANCE_TEST',
}

export interface WithdrawalControl {
  id: number;
  withdrawalQualifier: string;
  marketplace: string;
  action: WithdrawalControlAction;
  status: WithdrawalControlStatus;
  initiatorCreatedBy: WithdrawalControlInitiator;
  initiatorUpdatedBy: WithdrawalControlInitiator;
  createdAt: string;
  updatedAt: string;
}

interface GetWithdrawalControlsResponse {
  success: boolean;
  data?: {
    withdrawalControls: WithdrawalControl[];
  };
  error?: {
    message: string;
  };
}

const submit = lambdaConstructor<{}, GetWithdrawalControlsResponse>({
  serviceName: 'wallet-service-2',
  functionName: 'getWithdrawalControls',
  method: HttpMethod.GET,
});

export const getWithdrawalControls = {
  name: 'Get Withdrawal Controls',
  description: 'retrieve withdrawal controls',
  inputValues,
  tags: [Tags.csResources],
  submit,
};
