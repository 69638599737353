import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { lockAccount } from '../lambdaFunctions/lockAccount';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'cognitoId',
    formLabel: 'Cognito Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get User Information',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ cognitoId }: { cognitoId: string }) =>
          `SELECT u.first_name, u.last_name, u.cognito_id
          FROM usersdb.users u
          WHERE u.cognito_id = '${cognitoId}';`,
      ),
      retries: 10,
    },
  },

  {
    name: 'Validate User to Lock',
    describe: 'Warning: account cannot be unlocked',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            first_name: string;
            last_name: number;
            cognito_id: number;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.cognito_id}`,
              name: `Name: ${record.first_name} ${record.last_name}, Cognito Id: ${record.cognito_id}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Lock User Account',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: lockAccount.submit,
    },
    inputData: {
      cognitoId: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
    },
  },
];

export const lockUserAccount = {
  steps,
  inputValues,
  name: 'Lock User Account',
  description:
    'Lock account for users suspected of fraud. WARNING: Cannot be undone',
  tags: [Tags.csResources],
};
