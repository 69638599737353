// NOTE: redshift can only query prod data

import queueJob from './queueJob';
import retrieveQueryResults, { reconstructRecords } from './retrieveQuery';

interface RedshiftProps {
  query: string;
  retries?: number;
}

const queryRedshift = async ({ query, retries = 5 }: RedshiftProps) => {
  try {
    const { queryId } = await queueJob(query);

    // poll the retrieve endpoint with limited retries
    for (let retry = 0; retry < retries; retry++) {
      try {
        const res = await retrieveQueryResults(queryId);
        return { records: reconstructRecords(res) };
      } catch (error) {
        // wait to allow backend to process
        await new Promise((r) => setTimeout(r, 30000));
      }
    }
    throw new Error('Redshift query failed: Retry limit reached');
  } catch (error) {
    throw error;
  }
};

export default queryRedshift;
