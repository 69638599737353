import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Spinner, Row } from 'react-bootstrap';
import { useQuery, useApolloClient } from '@apollo/client';

import DisplayJson from '@/components/DisplayJson';
import InputJson from '@/components/InputJson';

import { WorkflowStepStatusTypes } from '@/components/Workflow/components/WorkflowStepStatus';
import getErrorMessage from '@/utils/error';

interface WorkflowStepProps {
  name: string;
  active: boolean;
  status: WorkflowStepStatusTypes;
  inputData: any;
  outputData: any;
  onInputEdit: Function;
  setStatus: Function;
  query: any;
  onCompleteStep: Function;
  autorun?: boolean;
}

interface QueryProps {
  variables: any;
  query: any;
  onCompleteStep: Function;
}

const Query: FunctionComponent<QueryProps> = ({
  variables,
  query,
  onCompleteStep,
}) => {
  const runQuery = async () => {
    if (variables.multipleQueries && variables.inputArray) {
      try {
        const { inputArray } = variables;
        if (!Array.isArray(inputArray)) {
          throw Error('input must be an array');
        }

        const client = useApolloClient();
        const queryResponses = await Promise.all(
          inputArray.map(async (item) =>
            client.query({ query, variables: item }),
          ),
        );

        const results = queryResponses.map((item) => {
          return item.error ? item.error : item.data;
        });
        onCompleteStep({ results, status: 'success' });
      } catch (error) {
        const em = getErrorMessage(error);
        onCompleteStep({ error: em, status: 'failed' });
      }
    } else {
      const { loading, error, data } = useQuery(query, { variables });
      if (!loading && error) {
        const em =
          error && error.message
            ? error.message
            : 'Failed! No error message given';
        onCompleteStep({ error: em, status: 'failed' });
      }

      if (!loading && data) {
        onCompleteStep({ ...data, status: 'success' });
      }
    }
  };
  runQuery();
  return <></>;
};

export const GraphQLQuery: FunctionComponent<WorkflowStepProps> = ({
  name,
  active,
  status,
  inputData,
  outputData,
  onInputEdit,
  setStatus,
  onCompleteStep,
  query,
  autorun,
}) => {
  const [runQuery, setRunQuery] = useState(false);

  const handleSkipStep = () => {
    onCompleteStep({ status: 'skipped' });
  };

  const runStep = async () => {
    setStatus(WorkflowStepStatusTypes.RUNNING);
    setRunQuery(true);
  };

  useEffect(() => {
    if (active && autorun) {
      setRunQuery(true);
    }
  });

  return (
    <>
      <div className="workflowStep-text">
        <p className="workflowStep-text-label">Input Variables</p>
        <p>
          {active
            ? 'Review, edit, or copy variables for this step before running.'
            : 'Editing input variables is available when this step is active.'}
        </p>
        <InputJson
          name={`input for ${name}`}
          json={inputData}
          onEdit={active ? onInputEdit : false}
          onAdd={active ? onInputEdit : false}
          onDelete={active ? onInputEdit : false}
          collapsed={!!outputData}
        />
      </div>
      {runQuery && (
        <Query
          query={query}
          variables={inputData}
          onCompleteStep={onCompleteStep}
        />
      )}
      {outputData && (
        <div className="workflowStep-text">
          <p className="workflowStep-text-label">{`${name} - Response`}</p>
          <DisplayJson name={`output for ${name}`} json={outputData} />
        </div>
      )}
      {active && (
        <Row>
          <Button className="workflowButton" onClick={runStep}>
            {status === WorkflowStepStatusTypes.RUNNING && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden
              />
            )}
            {!(status === WorkflowStepStatusTypes.RUNNING) && (
              <span>Run Query</span>
            )}
          </Button>
          <Button
            className="workflowButton"
            variant="secondary"
            onClick={handleSkipStep}
          >
            Skip
          </Button>
        </Row>
      )}
    </>
  );
};
