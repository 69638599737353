import React, { FunctionComponent, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { Icon } from 'react-icons-kit';
import { copy } from 'react-icons-kit/icomoon/copy';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';

interface WorkflowStepProps {
  name: string;
  active: boolean;
  onCompleteStep: Function;
  inputData: any;
}

export const ExternalStep: FunctionComponent<WorkflowStepProps> = ({
  active,
  onCompleteStep,
  inputData,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCompleteStep = () => {
    onCompleteStep({ status: 'success' });
  };

  const handleSkipStep = () => {
    onCompleteStep({ status: 'skipped' });
  };

  const openLink = () => {
    window.open(inputData.link, '_blank');
  };

  const copyLink = () => {
    navigator.clipboard.writeText(inputData.link);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

  return (
    <>
      <div className="workflowStep-text">
        <p className="workflowStep-text-label">
          {inputData.description
            ? inputData.description
            : 'External step.  Follow the link to complete then return to this page and mark as complete.'}
        </p>
        <Row>
          <Button size="sm" onClick={openLink} variant="link">
            {inputData.link}
          </Button>
          <Button variant="info" onClick={copyLink}>
            {copySuccess ? <Icon icon={checkmark} /> : <Icon icon={copy} />}
          </Button>
          {copySuccess}
        </Row>
      </div>
      {active && (
        <Row>
          <Button className="workflowButton" onClick={handleCompleteStep}>
            Mark as complete
          </Button>
          <Button
            className="workflowButton"
            variant="secondary"
            onClick={handleSkipStep}
          >
            Skip
          </Button>
        </Row>
      )}
    </>
  );
};
