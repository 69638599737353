import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import {
  CardNetwork,
  CardNetworkId,
  CardNetworkIdType,
  Tags,
} from '@/helpers/types';
import { blacklistCNIdAndUnmapFromCNVenues } from '../lambdaFunctions/blacklistCNIdAndUnmapFromCNVenues';

const inputValues = [
  {
    name: 'cnidArray',
    formLabel: 'CNId Array',
  },
  {
    name: 'reason',
    formLabel: 'Reason',
    formSubLabel: 'i.e. merchant the CNId belongs to',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Build Invocation Array',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      invocationArray: new InputInjection(
        [0],
        ({ cnidArray, reason }: { cnidArray: string; reason: string }) => {
          if (!cnidArray) {
            return;
          }
          const cnids: CardNetworkId[] = JSON.parse(cnidArray);
          return cnids.map((cnid) => {
            return {
              cardNetwork: cnid.cardNetwork,
              cardNetworkExternalIdType: cnid.idType,
              cardNetworkExternalId: cnid.externalId,
              reason,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Add CNId to Deny List and Unmap From CNVenues',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: blacklistCNIdAndUnmapFromCNVenues.submit,
    },
    inputData: {
      multipleInvocations: true,
      invocationArray: new InputInjection(
        [1],
        ({
          invocationArray,
        }: {
          invocationArray: {
            cardNetwork: CardNetwork;
            idType: CardNetworkIdType;
            externalId: string;
            allowlisted: boolean;
            reason: string;
          }[];
        }) => {
          if (!invocationArray || !Array.isArray(invocationArray)) {
            return;
          }
          return invocationArray;
        },
      ),
    },
  },
  {
    name: 'Report in Slack',
    autorun: false,
    process: {
      type: WorkflowStepTypes.EXTERNAL_STEP,
    },
    inputData: {
      description: 'Report in #aggregator-mid-check slack channel',
      link: 'https://cardlytics.slack.com/archives/C03K672PSA2',
    },
  },
];

export const handleConfirmedAggregatorCNIds = {
  steps,
  inputValues,
  name: 'Handle Confirmed Aggregator CNIds',
  description: 'Handle aggregator CNIds',
  tags: [Tags.transactions, Tags.cnids],
};
