import validator from 'validator';

const isEmail = (email = '') => validator.isEmail(email) || 'invalid email';

const isRequired = (value = '') => value.trim() !== '' || 'field required';

const isValidLength = (value = '', min = 1, max = 40) => {
  const requiredError = isRequired(value);

  if (typeof requiredError === 'string') {
    return requiredError;
  }

  return (
    validator.isLength(value.trim(), { min, max }) ||
    `input should be between ${min}-${max} characters`
  );
};

const isPhoneNumber = (phone = '') =>
  validator.isMobilePhone(phone, 'en-US') || 'invalid phone number';

const validate = {
  isEmail,
  isRequired,
  isValidLength,
  isPhoneNumber,
  notRequired: () => true,
  selectionRequired: (value: any) => !!value || 'selection required',
};

export default validate;
