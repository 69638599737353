import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { Tags, Venue } from '@/helpers/types';
import {
  cardNetworkOptions,
  offerNetworkOptions,
} from '@/helpers/commonInputSelectOptions';
import { getVenueMappingsForGenericVenue } from '../lambdaFunctions/getVenueMappingsForGenericVenue';
import { unmapONVenueFromCNVenue } from '../lambdaFunctions/unmapONVenueFromCNVenue';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'name',
    formLabel: 'Name',
  },
  {
    name: 'state',
    formLabel: 'State',
    formSubLabel: "state's two letter abbreviation",
  },
  {
    name: 'ONVenueSource',
    formLabel: 'ONVenue Source',
    options: offerNetworkOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
    options: cardNetworkOptions,
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Query for ONVenue',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          name,
          state,
          ONVenueSource,
        }: {
          name: string;
          state: string;
          ONVenueSource: string;
        }) =>
          `SELECT DISTINCT n.name, onv.uuid, a.address1, NVL(a.address2,'empty') as address2, a.city, a.state, a.postal
          FROM cnvenuedb.venues onv
          JOIN cnvenuedb.venue_sources vs ON vs.id = onv.venue_source_id
          JOIN cnvenuedb.names n ON n.id = onv.name_id
          JOIN cnvenuedb.addresses a ON a.id = onv.address_id
          WHERE 1=1
          AND n.name ilike '%${name}%'
          AND a.state = '${state}'
          AND vs.name = '${ONVenueSource}'
          ORDER BY name, postal
        ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Select ONVenue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            name: string;
            uuid: string;
            address1: string;
            address2: string;
            city: string;
            state: string;
            postal: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            let display = `${record.name}, ${record.uuid}, ${record.address1}, `;
            if (record.address2 !== 'empty') {
              display += `${record.address2}, `;
            }
            display += `${record.city}, ${record.state}, ${record.postal}`;
            return {
              id: record.uuid,
              name: display,
              uuid: record.uuid,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Find Mapped CNVenues',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueMappingsForGenericVenue.submit,
    },
    inputData: {
      venueUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
    },
  },
  {
    name: 'Process variables',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cardNetwork: new InputInjection([0, 'cardNetwork']),
      cnVenues: new InputInjection([3], ({ result }: { result: Venue[] }) => {
        if (!result || !Array.isArray(result)) {
          return;
        }
        return result;
      }),
    },
  },
  {
    name: 'Select CNVenue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [4],
        ({
          cnVenues,
          cardNetwork,
        }: {
          cnVenues: Venue[];
          cardNetwork: string;
        }) => {
          if (!cnVenues || !Array.isArray(cnVenues) || !cardNetwork) {
            return;
          }
          return cnVenues
            .filter((venue) => venue.venueSource === cardNetwork)
            .map((venue) => {
              const cnIds = venue.cardNetworkIds
                .map((cn) => cn.externalId)
                .flat();
              const display = `${venue.name}, ${venue.uuid},
                ${venue.address.address1}, ${venue.address.address2},
                ${venue.address.city}, ${venue.address.state}, ${venue.address.postal},
                CNIDS: ${cnIds}`;
              return {
                name: display,
                id: venue.uuid,
                uuid: venue.uuid,
              };
            });
        },
      ),
    },
  },
  {
    name: 'Combine Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      offerNetworkVenueUuid: new InputInjection(
        [2],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          return getArrayFromCheckboxSelections(result)[0];
        },
      ),
      cardNetworkVenueUuid: new InputInjection(
        [5],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          return getArrayFromCheckboxSelections(result)[0];
        },
      ),
    },
  },
  {
    name: 'Unmapping',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: unmapONVenueFromCNVenue.submit,
    },
    inputData: {
      offerNetworkVenueUuid: new InputInjection([6, 'offerNetworkVenueUuid']),
      cardNetworkVenueUuid: new InputInjection([6, 'cardNetworkVenueUuid']),
      reason: 'MANUAL_MATCH',
    },
  },
];

export const unmapCNVenueFromONVenue = {
  steps,
  inputValues,
  name: 'Unmap CNVenue From ONVenue',
  description: 'Unmap CNVenue from ONVenue',
  tags: [Tags.venues],
};
