import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'marketplaceName',
    formLabel: 'Marketplace Name',
  },
  {
    name: 'marketplaceReference',
    formLabel: 'Marketplace Reference',
    formSubLabel:
      'Add new Marketplace to all segments that currently have this existing marketplace',
    default: 'JELLI',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'merchant-service-2',
  functionName: 'setupSegmentsForMarketplace',
  method: HttpMethod.POST,
});

export const setupSegmentsForMarketplace = {
  name: 'Setup Segments For Marketplace',
  description:
    'Once we have a MUG for a marketplace, and the users were added to that that MUG. We need to create marketplace segments for the new marketplace',
  inputValues,
  outputValueName: 'result', // optional
  tags: [Tags.poweredBy],
  submit,
};
