import React, { FunctionComponent, useEffect } from 'react';
import { Button, Spinner, Row } from 'react-bootstrap';
import { useMutation } from '@apollo/client';

import DisplayJson from '@/components/DisplayJson';
import InputJson from '@/components/InputJson';

import { WorkflowStepStatusTypes } from '@/components/Workflow/components/WorkflowStepStatus';

interface WorkflowStepProps {
  name: string;
  active: boolean;
  status: WorkflowStepStatusTypes;
  inputData: any;
  outputData: any;
  onInputEdit: Function;
  setStatus: Function;
  mutation: any;
  onCompleteStep: Function;
  autorun?: boolean;
}

export const GraphQLMutation: FunctionComponent<WorkflowStepProps> = ({
  name,
  active,
  status,
  inputData,
  outputData,
  onInputEdit,
  setStatus,
  onCompleteStep,
  mutation,
  autorun,
}) => {
  const handleCompleteStep = (res: any) => {
    onCompleteStep({ ...res, status: 'success' });
  };

  const handleErrorStep = (error: any) => {
    console.error(error);
    const em =
      error && error.message ? error.message : 'Failed! No error message given';
    onCompleteStep({ error: em, status: 'failed' });
  };

  const [runMutation] = useMutation(mutation, {
    onCompleted: handleCompleteStep,
    onError: handleErrorStep,
  });

  const handleSkipStep = () => {
    onCompleteStep({ status: 'skipped' });
  };

  const runStep = async () => {
    setStatus(WorkflowStepStatusTypes.RUNNING);
    runMutation({ variables: inputData });
  };

  useEffect(() => {
    if (status === WorkflowStepStatusTypes.ACTIVE && autorun) {
      runStep();
    }
  });

  return (
    <>
      <div className="workflowStep-text">
        <p className="workflowStep-text-label">Input Variables</p>
        <p>
          {active
            ? 'Review, edit, or copy variables for this step before running.'
            : 'Editing input variables is available when this step is active.'}
        </p>
        <InputJson
          name={`input for ${name}`}
          json={inputData}
          onEdit={active ? onInputEdit : false}
          onAdd={active ? onInputEdit : false}
          onDelete={active ? onInputEdit : false}
          collapsed={!!outputData}
        />
      </div>
      {outputData && (
        <div className="workflowStep-text">
          <p className="workflowStep-text-label">{`${name} - Response`}</p>
          <DisplayJson name={`output for ${name}`} json={outputData} />
        </div>
      )}
      {active && (
        <Row>
          <Button className="workflowButton" onClick={runStep}>
            {status === WorkflowStepStatusTypes.RUNNING && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden
              />
            )}
            {!(status === WorkflowStepStatusTypes.RUNNING) && (
              <span>Run Step</span>
            )}
          </Button>
          <Button
            className="workflowButton"
            variant="secondary"
            onClick={handleSkipStep}
          >
            Skip
          </Button>
        </Row>
      )}
    </>
  );
};
