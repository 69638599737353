import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'merchantUuid',
    formLabel: 'Merchant Uuid',
  },
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid',
  },
  {
    name: 'venues',
    formLabel: 'Venues',
  },
  {
    name: 'updatedBy',
    formLabel: 'Updated By',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'campaign-service',
  functionName: 'offerAddVenues',
  method: HttpMethod.POST,
});

export const offerAddVenues = {
  name: 'Offer Add Venues',
  description: 'Add locations to an existing offer',
  inputValues,
  tags: [Tags.offers],
  submit,
};
