import { pathOr } from 'ramda';

import log from '@/utils/logging';

interface ApiGetProps {
  route: string;
}

const get = ({ route }: ApiGetProps): Promise<any> => {
  log.info('Starting get request to ', route);

  return fetch(route, {
    headers: {
      Accept: 'application/json',
    },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        // Catching errors from the services
        log.info('fetch raw result', {
          result,
        });
        if (result && result.success) {
          log.info('successful get request', result);
          return pathOr({}, ['response', 'result'], result);
        }
        log.error('fetch failed', result);
        const errorMsg = pathOr(
          'Service error',
          ['error', 'displayMessage'],
          result,
        );
        throw new Error(errorMsg);
      },
      // Catching failed requests
      (error) => {
        // todo: report to sentry
        log.error('Api call failed', error);
        throw new Error('API error.  Are you connected to the VPN?');
      },
    );
};

export default get;
