import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { getVenueByExternalId } from '../lambdaFunctions/getVenueByExternalId';
import { CardNetwork, CardNetworkIdType, Tags, Venue } from '@/helpers/types';
import { getTransactionFlowSourcesForCNId } from '../lambdaFunctions/getTransactionFlowSourcesForCNId';

const inputValues = [
  {
    name: 'vmid',
    formLabel: 'VMID',
    formSubLabel: 'If VMID is known',
  },
  {
    name: 'vsid',
    formLabel: 'VSID',
    formSubLabel: 'If VMID not provided',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get VSID State',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getTransactionFlowSourcesForCNId.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ vsid, vmid }: { vsid: string; vmid: string }) => !!(!vsid && vmid),
        ),
      },
      cardNetwork: CardNetwork.VISA,
      cardNetworkExternalIdType: CardNetworkIdType.VSID,
      cardNetworkExternalId: new InputInjection([0, 'vsid']),
    },
  },
  {
    name: 'Get CNVenue For VSID',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueByExternalId.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ vsid, vmid }: { vsid: string; vmid: string }) => !!(!vsid && vmid),
        ),
      },
      venueSource: CardNetwork.VISA,
      externalId: new InputInjection([0, 'vsid']),
    },
  },
  {
    name: 'Combine Retrieved Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      providedVMID: new InputInjection([0, 'vmid']),
      retrievedVMID: new InputInjection(
        [2],
        ({ result }: { result: Venue }) => {
          if (!result) {
            return;
          }
          const vmid = result.cardNetworkIds.find(
            (cnid) => cnid.idType === CardNetworkIdType.VMID,
          );
          if (!vmid) {
            return;
          }
          return vmid.externalId;
        },
      ),
    },
  },
  {
    name: 'Get VMID State',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getTransactionFlowSourcesForCNId.submit,
    },
    inputData: {
      cardNetwork: CardNetwork.VISA,
      cardNetworkExternalIdType: CardNetworkIdType.VMID,
      cardNetworkExternalId: new InputInjection(
        [3],
        ({
          providedVMID,
          retrievedVMID,
        }: {
          providedVMID: string;
          retrievedVMID: string;
        }) => {
          if (!providedVMID && !retrievedVMID) {
            return;
          }
          return providedVMID || retrievedVMID;
        },
      ),
    },
  },
];

export const checkVisaActivationStatus = {
  steps,
  inputValues,
  name: 'Check Visa Activation Status',
  description: 'Check if VMID (or VSID) is activated and what MG',
  tags: [Tags.cnids, Tags.transactions],
};
