import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'cognitoId',
    formLabel: 'Cognito Id',
  },
  {
    name: 'last4',
    formLabel: 'Last 4',
    col: 2,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Card Identifiers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        (input: any) =>
          `SELECT 
            CASE WHEN ct.type IN ('AMEX','MASTERCARD') then ct.token
                ELSE c.card_id
            END as txn_identifier,
            c.last4,
            c.type
          FROM carddb.cards c
          JOIN carddb.users u ON c.user_id = u.user_id
          JOIN carddb.card_tokens ct ON c.card_id = ct.card_id
          WHERE 1 = 1
          AND u.cognito_id = '${input.cognitoId}'
          AND c.last4 = '${input.last4}'
          AND length(ct.token) > 28
          ;`,
      ),
    },
  },
];

export const getCardIdentifiersForUser = {
  steps,
  inputValues,
  name: 'Get Card Identifiers For User',
  description: 'Retrieve card identifiers for a user',
  tags: [Tags.csResources],
};
