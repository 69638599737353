// todo: write readme for making a redshift workflow
// todo: define query in another file and import it
// todo: make redshift display component a table instead of json

import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'cognitoId',
    formLabel: 'Cognito ID',
    col: 10,
  },
  {
    name: 'last4',
    formLabel: 'Last 4',
    col: 2,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Query redshift',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        (input: any) =>
          `select
          u.cognito_id,
          u.created_at as user_signup_date,
          c.type,
          c.last4,
          c.created_at as card_enrollment_date,
          c.deleted_at as card_unenrollment_date,
          case
          when c.deleted_at is null then 'Card Enrolled'
          when abs(datediff(MINUTE, c.deleted_at, c.created_at)) < 1 then 'Card Enroll Failed'
          else 'Card Unenrolled'
              end as card_status,
          (select e.event_status from carddb.events e where e.card_id = c.card_id and e.event_type ilike '%enroll%' limit 1) as card_network_enroll_status,
          (select e.response from carddb.events e where e.card_id = c.card_id and e.event_type ilike '%enroll%' limit 1) as card_network_enroll_response
        from carddb.users u
        join carddb.cards c on c.user_id = u.user_id
        where
          c.unique_number_identifier in (
            select c.unique_number_identifier
            from carddb.cards c
            join carddb.users u on u.user_id = c.user_id
            where
              u.cognito_id = '${input.cognitoId}' and
              c.last4 = '${input.last4}'
            limit 1
          )
        order by 1, 6;`,
      ),
      retries: 5,
    },
  },
];

export const duplicateCardErrors = {
  steps,
  inputValues,
  name: 'Report for duplicate Card Errors',
  description:
    "Given a user's Cognito ID and last 4 on their card, run a report to better understand the duplicate card error",
  tags: [Tags.csResources],
};
