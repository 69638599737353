import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'userId',
    formLabel: 'User ID',
  },
  {
    name: 'publicUUID',
    formLabel: 'Public UUID',
  },
  {
    name: 'marketplaceUserId',
    formLabel: 'Marketplace User ID',
  },
  {
    name: 'marketplace',
    formLabel: 'Marketplace ID',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'user-service-2',
  functionName: 'optInUserSMS',
  method: HttpMethod.POST,
});

export const optInUserSMS = {
  inputValues,
  submit,
  name: 'Opt in User SMS',
  description:
    'Finds user by marketplace, internal ID, or public Uuid and opts in their SMS',
  tags: [Tags.csResources],
  outputValueName: 'result', // optional
};
