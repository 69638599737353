import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'brand',
    formLabel: 'Brand',
  },
  {
    name: 'merchant',
    formLabel: 'Merchant Object',
  },
  {
    name: 'active',
    formLabel: 'boolean',
  },
] as InputFieldConfigs[];
const submit = lambdaConstructor({
  serviceName: 'dosh-on-venue-svc',
  functionName: 'upsertBrandToMerchantMapping',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});
export const upsertBrandToMerchantMapping = {
  name: 'Upsert Brand to Merchant Mapping',
  description: '',
  inputValues,
  tags: [Tags.merchant],
  submit,
};
