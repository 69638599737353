import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'externalId',
    formLabel: 'Agent Email',
  },
  {
    name: 'referralId',
    formLabel: 'Merchant Uuid',
  },
  {
    name: 'referralType',
    formLabel: 'Referral Type',
  },
  {
    name: 'referralNetworkName',
    formLabel: 'Referral Network Name',
  },
  {
    name: 'accountType',
    formLabel: 'Account Type',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'referral-svc',
  functionName: 'createAgentAndMapToReferralId',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});

export const createAgentAndMapToReferralId = {
  name: 'Create Derbyshire Agent and Map Referral Id',
  description: 'Create Derbyshire Agent and Map Referral Id',
  inputValues,
  outputValueName: 'agent',
  tags: [Tags.specialMerchants],
  submit,
};
