import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'merchantUuid',
    formLabel: 'Merchant Uuid',
  },
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'merchantSourceCode',
    formLabel: 'Merchant Source Code',
  },
  {
    name: 'logoPublicId',
    formLabel: 'Cloudinary Logo Id',
  },
  {
    name: 'imagePath',
    formLabel: 'Cloudinary Image Path',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'merchant-service-2',
  functionName: 'createMerchant',
  method: HttpMethod.POST,
});

export const createMerchant = {
  name: 'Create Merchant',
  description: 'Create or Update Merchant Attributes',
  inputValues,
  outputValueName: 'Create Merchant',
  submit,
};
