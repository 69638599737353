import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { BrandTransactionMatcherResults, Tags } from '@/helpers/types';
import { GET_TRANSACTION_MATCHERS } from '../graphql/getTransactionMatchers.gql';

const inputValues = [
  {
    name: 'brandId',
    formLabel: 'Brand Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Current Transaction Matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_TRANSACTION_MATCHERS,
    },
    inputData: {
      id: new InputInjection([0, 'brandId']),
    },
  },
  {
    name: 'Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      matchers: new InputInjection(
        [1],
        ({ brand }: { brand: BrandTransactionMatcherResults }) => {
          if (!brand || !brand.rawMatchers) {
            return;
          }
          return brand.rawMatchers.matchers;
        },
      ),
    },
  },
];

export const getCurrentTransactionMatchersForBrandId = {
  steps,
  inputValues,
  name: 'Get Current Transaction Matchers for Brand',
  description: 'Look up current transaction matchers for brand id',
  tags: [Tags.transactions],
};
