import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import CaeEvents from '@/cae.json';
import { DropDown } from '@/components/DropDown';
import { TextField } from '@/components/TextField';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { formatOptions } from '@/utils/dropdown';
import getErrorMessage from '@/utils/error';

const formatName = (name: string) => {
  return name
    .split('-')
    .join(' ')
    .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
    .replace('Pay Pal', 'PayPal');
};

const addAppAlertNotification = lambdaConstructor({
  serviceName: 'notification-service',
  functionName: 'addAppAlertNotification',
  method: HttpMethod.POST,
  errorPath: ['error', 'metadata', 'caught', 'message'],
});

const appAlertAliasOptions = formatOptions(process.env.appAlertAliasOptions);
const ApplicationAreaOptions = formatOptions(
  Object.keys(CaeEvents),
  formatName,
);

type CaeEventKeys = keyof typeof CaeEvents;

interface DropdownOption<V> {
  label: string;
  value: V;
}

interface FormValues {
  appAlertAlias: DropdownOption<string>;
  applicationArea: DropdownOption<CaeEventKeys> | '';
  eventKey: DropdownOption<any> | '';
  eventProperty: DropdownOption<string> | '';
  body: string;
}

const CreateAppAlert: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const { register, control, handleSubmit, watch, errors, setValue } =
    useForm<FormValues>({
      defaultValues: {
        appAlertAlias:
          (location.state && location.state.appAlertAlias) ||
          appAlertAliasOptions[0],
      },
    });

  const { applicationArea, eventKey, eventProperty, appAlertAlias } = watch();
  const selectedAppAreaData =
    applicationArea && (CaeEvents[applicationArea.value] as any);
  const selectedEventData =
    eventKey && selectedAppAreaData && selectedAppAreaData[eventKey.value];
  const selectedEventPropertyOptions =
    eventProperty &&
    selectedEventData &&
    selectedEventData.propertyOptions &&
    selectedEventData.propertyOptions[eventProperty.value];

  const eventOptions =
    selectedAppAreaData &&
    Object.keys(selectedAppAreaData).map((e) => ({
      label: e,
      value: e,
      description: selectedAppAreaData[e].comment,
    }));

  const eventPropertyOptions = selectedEventData && [
    { label: 'No', value: 'No' },
    ...Object.keys(selectedEventData.propertyOptions || {}).map((o) => ({
      label: o,
      value: o,
      description: selectedEventData.propertyOptions[o].comment,
    })),
  ];

  useEffect(() => {
    setValue('applicationArea', '');
  }, [appAlertAlias.value]);

  useEffect(() => {
    setValue('eventKey', '');
  }, [applicationArea]);

  useEffect(() => {
    if (eventKey) {
      setValue('eventProperty', { label: 'No', value: 'No' });
    } else {
      setValue('eventProperty', '');
    }
  }, [eventKey]);

  const onSubmit = async (data: any) => {
    setSubmitting(true);
    const eventProperties =
      data.eventProperty.value !== 'No'
        ? [
            {
              name: data.eventProperty.value,
              value: data.eventPropertyValue.value,
            },
          ]
        : [];

    const payload = {
      alias: data.appAlertAlias.value,
      eventAlert: {
        eventKey: data.eventKey.value,
        title: data.title,
        body: data.body,
        eventProperties,
      },
    };

    try {
      await addAppAlertNotification(payload);
      history.push('/customworkflow/AppAlerts');
    } catch (e) {
      setSubmitting(false);
      setError(getErrorMessage(e));
    }
  };

  return (
    <Col className="appAlert-createAlertContainer">
      <div className="appAlert-createAliasWrapper">
        <DropDown
          control={control}
          errors={errors}
          name="appAlertAlias"
          label="create app alert for the following alias"
          labelClass="createAlertAliasLabel"
          placeholder="select an alias"
          options={appAlertAliasOptions}
          isClearable={false}
        />
      </div>

      <div className="appAlert-createAlertWrapper">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <DropDown
            register={register({ required: true })}
            control={control}
            errors={errors}
            name="applicationArea"
            label="Which area of the consumer application is experiencing a problem?"
            placeholder="select an application area"
            options={ApplicationAreaOptions}
          />
          {selectedAppAreaData && (
            <DropDown
              register={register({ required: true })}
              control={control}
              errors={errors}
              name="eventKey"
              label="What event do you want to target?"
              secondaryLabel="Selection should indicate what event should trigger the app alert to be displayed"
              placeholder="select an event"
              options={eventOptions}
              style={{ paddingTop: '2rem' }}
            />
          )}

          {selectedEventData && (
            <>
              <div style={{ paddingTop: '2rem' }}>
                <Form.Label>
                  Do you want to filter the selected event by an event property?
                </Form.Label>
                <p style={{ fontSize: '14px' }}>
                  If a selection is made here, only events with the selected
                  property parameters will trigger the status message
                </p>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <DropDown
                    register={register}
                    control={control}
                    errors={errors}
                    required="property selection required"
                    name="eventProperty"
                    placeholder="select an event property"
                    defaultValue={{ label: 'No', value: 'No' }}
                    options={eventPropertyOptions}
                    style={{ width: '50%', paddingRight: '1rem' }}
                  />
                  {selectedEventPropertyOptions && (
                    <DropDown
                      register={register}
                      control={control}
                      errors={errors}
                      required={
                        selectedEventPropertyOptions
                          ? 'property value required'
                          : false
                      }
                      name="eventPropertyValue"
                      placeholder="select property value"
                      options={selectedEventPropertyOptions.values}
                      style={{ width: '50%', paddingLeft: '1rem' }}
                    />
                  )}
                </div>
              </div>
              <Form.Group style={{ paddingTop: '2rem' }}>
                <TextField
                  formLabel="Message title"
                  register={register}
                  validationFn={(value = '') =>
                    value.trim() !== '' || 'message title required'
                  }
                  path="title"
                  errors={errors}
                />
              </Form.Group>
              <Form.Group style={{ paddingTop: '2rem' }}>
                <Form.Label>
                  What message should the consumer be shown?
                </Form.Label>
                <Form.Control
                  ref={register({ required: 'status message required' }) as any}
                  name="body"
                  as="textarea"
                  rows={3}
                  style={{ resize: 'none' }}
                />
                {errors && errors.body && (
                  <em className="errorMessage">{errors.body.message}</em>
                )}
              </Form.Group>
              <div
                style={{
                  display: 'flex',
                  marginTop: '3rem',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Button className="ml-4" variant="primary" type="submit">
                  {submitting ? (
                    <Spinner animation="border" />
                  ) : (
                    'Save and Deploy Status Message'
                  )}
                </Button>
                {error && (
                  <em className="errorMessage" style={{ paddingTop: '1rem' }}>
                    {error}
                  </em>
                )}
              </div>
            </>
          )}
        </Form>
      </div>
    </Col>
  );
};

const CreateAppAlertConfig = {
  Component: CreateAppAlert,
  name: 'Add New App Alert',
  description:
    'For documentation on how app alerts work, please visit the runbook <a href="https://doshteam.atlassian.net/wiki/spaces/Engineering/pages/1478656045/Sand+Castle+Run+Book" target="_blank">here</a>',
  tags: [Tags.outage],
  hidden: true,
};

export default CreateAppAlertConfig;
