import React, { FunctionComponent, useEffect, useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Button, Row } from 'react-bootstrap';

import DisplayJson from '@/components/DisplayJson';

interface WorkflowStepProps {
  name: string;
  active: boolean;
  onCompleteStep: Function;
  setStatus: Function;
  inputData: any;
  outputData: any;
}

export interface Option {
  id: string;
  name: string;
}

export const CheckboxSelector: FunctionComponent<WorkflowStepProps> = ({
  name,
  active,
  onCompleteStep,
  inputData,
  outputData,
}) => {
  const [options, setOptions] = useState([]) as any[];
  const [selectedOptions, setSelectedOptions] = useState([]) as any[];

  useEffect(() => {
    if (active && inputData.options && inputData.options.length !== 0) {
      setOptions(inputData.options);
    }
  }, [inputData]);

  const onOptionSelect = (event: any) => {
    setSelectedOptions(event);
  };

  const runStep = async () => {
    onCompleteStep({ ...selectedOptions, status: 'success' });
  };

  const handleSkipStep = () => {
    onCompleteStep({ status: 'skipped' });
  };

  const renderedOptions = () => {
    if (options && options.length) {
      return (
        <ToggleButtonGroup
          type="checkbox"
          value={selectedOptions}
          onChange={onOptionSelect}
          vertical
        >
          {options.map((option: Option) => {
            return (
              <ToggleButton
                key={option.id}
                type="checkbox"
                variant="outline-info"
                value={option.id}
                size="lg"
                style={{ whiteSpace: 'normal' }}
              >
                {option.name}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      );
    }
  };

  return (
    <>
      <div className="workflowStep-text">
        <p className="workflowStep-text-label">
          {active ? `${name}` : `${name} will load when step is active`}
        </p>
        <div>{renderedOptions()}</div>
      </div>
      {outputData && (
        <div className="workflowStep-text">
          <p className="workflowStep-text-label">{`${name} - Response`}</p>
          <DisplayJson name="output" json={outputData} />
        </div>
      )}
      {active && (
        <Row>
          <Button className="workflowButton" onClick={runStep}>
            Next Step
          </Button>
          <Button
            className="workflowButton"
            variant="secondary"
            onClick={handleSkipStep}
          >
            Skip
          </Button>
        </Row>
      )}
    </>
  );
};
