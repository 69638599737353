import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

export enum AudienceSvcSupportedAudienceRuleType {
  HANDPICKED_USER = 'HANDPICKED_USER',
  NEW_USER = 'NEW_USER',
  LOYAL_USER = 'LOYAL_USER',
  LAPSED_USER = 'LAPSED_USER',
  COMPETITIVE_BRANDS_USER = 'COMPETITIVE_BRANDS_USER',
  COMPETITIVE_CATEGORIES_USER = 'COMPETITIVE_CATEGORIES_USER',
  REWARDED_USER = 'REWARDED_USER',
  ACTIVE_APP_USER = 'ACTIVE_APP_USER',
  RANDOMLY_PARTITIONED_USER = 'RANDOMLY_PARTITIONED_USER',
}
interface AudienceSvcAudienceRuleInput {
  type: AudienceSvcSupportedAudienceRuleType; // enum of all the currently supported types
  options: Record<string, unknown>; // the options that each type needs
}
type AudienceSvcAudienceType = 'STATIC'; // | ...
interface AudienceSvcAddAudienceSubscriptionInput {
  subscriber: string;
  externalId: string;
  name: string;
  audience: {
    type: AudienceSvcAudienceType;
    rules: AudienceSvcAudienceRuleInput[];
  };
}
interface AudienceSvcAddAudienceSubscriptionOutput {
  subscription: { uuid: string };
}

const submit = lambdaConstructor<
  AudienceSvcAddAudienceSubscriptionInput,
  AudienceSvcAddAudienceSubscriptionOutput
>({
  serviceName: 'audience-svc',
  functionName: 'addAudienceSubscription',
  method: HttpMethod.POST,
});

export const addAudienceSubscription = {
  name: 'Add a subscription to an audience',
  description:
    'Finds or creates the audience and marks the audience as one that this subscriber is interested in.',
  tags: [Tags.audiences],
  submit,
};
