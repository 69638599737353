import React, { FunctionComponent, useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import queryString from 'query-string';

import WorkflowForm from '@/components/WorkflowForm';
import WorkflowStepContainer from '@/components/Workflow/components/WorkflowStepContainer';
import { injectInput } from '@/utils/workflow/InputInjection';

import './Workflow.css';

export enum WorkflowStepTypes {
  REDSHIFT_QUERY = 'REDSHIFT_QUERY',
  EXTERNAL_STEP = 'EXTERNAL_STEP',
  GRAPHQL_MUTATION = 'GRAPHQL_MUTATION',
  GRAPHQL_QUERY = 'GRAPHQL_QUERY',
  LAMBDA = 'LAMBDA',
  VIEW_ASSETS = 'VIEW_ASSETS',
  BUTTON_MERCHANT_DATA = 'BUTTON_MERCHANT_DATA',
  UPLOAD_BUTTON_MERCHANT_ASSETS = 'UPLOAD_BUTTON_MERCHANT_ASSETS',
  CHECKBOX_SELECTOR = 'CHECKBOX_SELECTOR',
  PREPROCESS_DATA = 'PREPROCESS_DATA',
  DOWNLOAD_DATA = 'DOWNLOAD_DATA',
  DISPLAY_RESULT = 'DISPLAY_RESULT',
}
interface WorkflowProps {
  inputValues: any[];
  steps: any[];
  name: string;
  description: string;
}
interface StepResult {
  status: string;
}

const Workflow: FunctionComponent<WorkflowProps> = ({
  inputValues,
  steps,
  name,
  description,
}) => {
  const [currentStep, setCurrentStep] = useState(-1); // -1 denotes unstarted
  // workflowOutput formate : [initialFormData, step[0], step[1], ... step[n]]
  const [workflowOutputs, setWorkflowOutputs] = useState([]) as any[];
  // workflowInputs maps directly to steps
  const [workflowInputs, setWorkflowInputs] = useState([]) as any[];

  const editInput = (newInput: any, stepNumber: number) => {
    const newInputs = [...workflowInputs];
    newInputs[stepNumber] = newInput;
    setWorkflowInputs(newInputs);
  };

  const editOutput = (newOutput: any, stepNumber: number) => {
    const newOutputs = [...workflowOutputs];
    newOutputs[stepNumber + 1] = newOutput;

    setWorkflowOutputs(newOutputs);
  };

  const activateNextStep = () => {
    const nextStep = currentStep + 1;

    if (nextStep === steps.length) {
      console.log('DONE!');
      setCurrentStep(nextStep);
      return;
    }
    setCurrentStep(nextStep);
  };

  const completeStep = (stepResult: StepResult) => {
    editOutput(stepResult, currentStep);
    if (stepResult.status === 'success' || stepResult.status === 'skipped') {
      activateNextStep();
    }
  };

  useEffect(() => {
    if (steps && steps[currentStep]) {
      const injectedInput = injectInput(
        steps[currentStep].inputData,
        workflowOutputs,
      );
      editInput(injectedInput, currentStep);
    }
  }, [currentStep]);

  useEffect(() => {
    const emptyArr = steps.map(() => undefined);
    setWorkflowOutputs([...emptyArr, undefined]);

    const inputs = steps.map((step) => injectInput(step.inputData, []));
    setWorkflowInputs(inputs);
  }, [steps]);

  return (
    <>
      <Container>
        <Row>
          <h1 className="workflowHeader">{`Workflow - ${name}`}</h1>
        </Row>
        <Row>
          <span className="workflowDescription">{description}</span>
        </Row>
        <Row>
          <div className="workflowForm">
            <WorkflowForm
              defaultValues={queryString.parse(window.location.search)}
              inputFieldConfigs={inputValues}
              onSubmit={(formData: any) => ({ ...formData, status: 'success' })}
              setResponse={completeStep}
              submitButtonText="Start workflow"
              disabled={currentStep !== -1}
              storeInput
            />
          </div>
        </Row>
        {steps.map((step, stepNumber) => (
          <div key={step.name}>
            <Row>
              <WorkflowStepContainer
                name={step.name}
                active={stepNumber === currentStep}
                inputData={workflowInputs[stepNumber]}
                outputData={workflowOutputs[stepNumber + 1]}
                onInputEdit={(e: any) => editInput(e.updated_src, stepNumber)}
                onCompleteStep={completeStep}
                processType={step.process.type}
                onSubmit={step.process.submit}
                autorun={step.autorun}
              />
            </Row>
          </div>
        ))}
      </Container>
    </>
  );
};

export default Workflow;
