import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { toggleCNTransactionsForCNId } from '../lambdaFunctions/toggleCNTransactionsForCNId';
import { markCNTransactionsForCNId } from '../lambdaFunctions/markCNTransactionsForCNId';
import {
  CardNetwork,
  CardNetworkIdType,
  CardNetworkTransactionMode,
  Tags,
  VisaCommunityCode,
  VisaMerchantGroup,
} from '@/helpers/types';
import { offerLocationTypeOptions } from '@/helpers/commonInputSelectOptions';
import { redshiftEscape } from '@/utils/strings';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Name of Merchant',
  },
  {
    name: 'locationType',
    formLabel: 'Location Type',
    options: offerLocationTypeOptions,
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Potential VMIDs to Deactivate',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `with onvenue_total as (
            SELECT COUNT(DISTINCT onv.id) as onvenue_count, m.merchant_id
            FROM cnvenuedb.venues onv
            JOIN merchantdb.source_locations sl on onv.uuid = sl.source_location_id
            JOIN merchantdb.locations l on sl.location_id = l.location_id
            JOIN merchantdb.merchants m on l.merchant_id = m.merchant_id
            WHERE 1=1
            AND m.name ilike '%${redshiftEscape(merchantName)}%'
            AND onv.venue_source_id = 1
            GROUP BY 2
        ), total_cnvenues_per_vmid as(
            SELECT COUNT(DISTINCT cnv.id) as cnvenues_per_vmid_count, cnis.external_id as vmid, ot.onvenue_count as onvenue_count
            FROM onvenue_total ot
            JOIN merchantdb.merchants m on ot.merchant_id = m.merchant_id
            JOIN merchantdb.locations l on m.merchant_id = l.merchant_id
            JOIN merchantdb.source_locations sl on l.location_id = sl.location_id
            JOIN cnvenuedb.venues onv on sl.source_location_id = onv.uuid
            JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.on_venue_id = onv.id AND onv.venue_source_id = 1
            JOIN cnvenuedb.venues cnv on cnv.id = otcvm.cn_venue_id AND cnv.venue_source_id = 4 AND cnv.enabled = 1
            JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.venue_id = cnv.id
            JOIN cnvenuedb.card_network_ids cnis on cnis.id = vtcni.card_network_id AND cnis.id_type = 'VMID'
            JOIN cnvenuedb.names n2 on cnv.name_id = n2.id
            WHERE 1=1
            GROUP BY 2, 3
        ) SELECT DISTINCT (CAST(cnvenues_per_vmid_count as float)/onvenue_count)*100 as percent_of_mapped_vmids, tcpv.vmid, vtfsvc.merchant_group
        FROM total_cnvenues_per_vmid tcpv
        LEFT JOIN cnmgmtdb.view_transaction_flow_source_visa_current vtfsvc on vtfsvc.card_network_external_id = tcpv.vmid AND vtfsvc.card_network_external_id_type = 'VMID'
        WHERE vtfsvc.state = 'ACTIVE'
        ORDER BY percent_of_mapped_vmids DESC;`,
      ),
      retries: 20,
    },
  },
  {
    name: 'Select VMIDs to Deactivate (Verify in Visa Portal)',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [1],
          ({
            records,
          }: {
            records: {
              percent_of_mapped_vmids: string;
              vmid: string;
              merchant_group: string;
            }[];
          }) => {
            if (!records || !Array.isArray(records)) {
              return;
            }
            return !records.length;
          },
        ),
      },
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            percent_of_mapped_vmids: string;
            vmid: string;
            merchant_group: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.vmid}#${record.merchant_group}`,
              name: `Percent of Locations with VMID: ${record.percent_of_mapped_vmids}; VMID: ${record.vmid}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Process selected VMIDs into Visa Offer and toggleable types',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      visaOfferVMIDs: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result).filter((res) => {
          const mg = res.split('#')[1];
          return (
            mg === VisaMerchantGroup.PLACEHOLDER_FOR_VISA_OFFER_ACTIVATIONS
          );
        });
      }),
      toggleableVMIDs: new InputInjection(
        [2],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          return getArrayFromCheckboxSelections(result).filter((res) => {
            const mg = res.split('#')[1];
            return (
              mg !== VisaMerchantGroup.PLACEHOLDER_FOR_VISA_OFFER_ACTIVATIONS
            );
          });
        },
      ),
    },
  },
  {
    name: 'Create VMID Deactivation Array',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [3],
          ({ toggleableVMIDs }: { toggleableVMIDs: string[] }) => {
            if (!toggleableVMIDs) {
              return;
            }
            return toggleableVMIDs.length === 0;
          },
        ),
      },
      deactivationArray: new InputInjection(
        [3],
        ({ toggleableVMIDs }: { toggleableVMIDs: string[] }) => {
          if (!toggleableVMIDs) {
            return;
          }
          return !Array.isArray(toggleableVMIDs)
            ? []
            : toggleableVMIDs.map((selection) => {
                return {
                  mode: CardNetworkTransactionMode.DEACTIVATE,
                  cardNetwork: CardNetwork.VISA,
                  cardNetworkExternalIdType: CardNetworkIdType.VMID,
                  cardNetworkExternalId: selection.split('#')[0],
                  merchantGroup: selection.split('#')[1],
                  communityCode: VisaCommunityCode.DOSHCL,
                };
              });
        },
      ),
    },
  },
  {
    name: 'Deactivate VMIDs',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: toggleCNTransactionsForCNId.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [3],
          ({ toggleableVMIDs }: { toggleableVMIDs: string[] }) => {
            if (!toggleableVMIDs) {
              return;
            }
            return toggleableVMIDs.length === 0;
          },
        ),
      },
      multipleInvocations: true,
      invocationArray: new InputInjection([4, 'deactivationArray']),
    },
  },
  {
    name: 'Pre process VMID array for placeholder merchant group',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [3],
          ({ visaOfferVMIDs }: { visaOfferVMIDs: string[] }) => {
            if (!visaOfferVMIDs) {
              return;
            }
            return visaOfferVMIDs.length === 0;
          },
        ),
      },
      deactivationArray: new InputInjection(
        [3],
        ({ visaOfferVMIDs }: { visaOfferVMIDs: string[] }) => {
          if (!visaOfferVMIDs) {
            return;
          }
          return !Array.isArray(visaOfferVMIDs)
            ? []
            : visaOfferVMIDs.map((selection) => {
                return {
                  mode: CardNetworkTransactionMode.DEACTIVATE,
                  cardNetwork: CardNetwork.VISA,
                  cardNetworkExternalIdType: CardNetworkIdType.VMID,
                  cardNetworkExternalId: selection.split('#')[0],
                  merchantGroup:
                    VisaMerchantGroup.PLACEHOLDER_FOR_VISA_OFFER_ACTIVATIONS,
                  communityCode: VisaCommunityCode.DOSHCL,
                };
              });
        },
      ),
    },
  },
  {
    name: 'Deactivating any placeholder merchant groups',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: markCNTransactionsForCNId.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [3],
          ({ visaOfferVMIDs }: { visaOfferVMIDs: string[] }) => {
            if (!visaOfferVMIDs) {
              return;
            }
            return visaOfferVMIDs.length === 0;
          },
        ),
      },
      multipleInvocations: true,
      invocationArray: new InputInjection([6, 'deactivationArray']),
    },
  },
];

export const deactivateVMIDsForMerchant = {
  steps,
  inputValues,
  name: 'Deactivate VMIDs for a merchant',
  description: 'Deactivate VMIDs only for a merchant',
  tags: [Tags.transactions],
};
