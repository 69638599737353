import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import {
  CardNetwork,
  CardNetworkId,
  CardNetworkIdType,
  Tags,
  YesOrNo,
} from '@/helpers/types';
import { yesOrNoOptions } from '@/helpers/commonInputSelectOptions';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'earliestOccurrence',
    formLabel: 'Earliest Occurrence',
    formSubLabel: 'YYYY-MM-DD',
  },
  {
    name: 'cardNetworkExternalId',
    formLabel: 'Card Network External Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Query for Merchant Names Associated with Transactions',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          earliestOccurrence,
          cardNetworkExternalId,
        }: {
          earliestOccurrence: string;
          cardNetworkExternalId: string;
        }) =>
          `SELECT DISTINCT d.merchant_name, d.venue_name, cte.card_network, cnid.card_network_external_id
          FROM empyrdb.card_transaction_events cte
          JOIN empyrdb.card_transaction_events_debug d ON d.card_transaction_event_id = cte.id
          JOIN empyrdb.card_transaction_events_card_network_ids cnid ON cnid.card_transaction_event_id = cte.id
          LEFT JOIN empyrdb.card_transaction_events_categorization cat ON cat.card_transaction_event_id = cte.id
          WHERE cte.created_at > '${earliestOccurrence}'
          AND cnid.card_network_external_id = '${cardNetworkExternalId}'
          ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Process Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      merchantNames: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            merchant_name: string;
            venue_name: string;
            card_network: string;
            card_network_external_id: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return [...new Set(records.map((record) => record.merchant_name))];
        },
      ),
    },
  },
  {
    name: 'Is MID an Aggregator?',
    describe:
      'If transactions are all different variations of the same merchant, this will be No. All different merchants, this will be yes.',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection([0], () => {
        return yesOrNoOptions.map((opt) => {
          return {
            id: opt.value,
            name: opt.label,
          };
        });
      }),
    },
  },
  {
    name: 'Process Selection',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cnids: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            merchant_name: string;
            venue_name: string;
            card_network: string;
            card_network_external_id: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          const cnids = [];
          if (
            records.find((record) => record.card_network === CardNetwork.VISA)
          ) {
            cnids.push({
              cardNetwork: CardNetwork.VISA,
              idType: CardNetworkIdType.CAID,
              externalId: records[0].card_network_external_id,
            });
          }
          if (
            records.find(
              (record) => record.card_network === CardNetwork.MASTERCARD,
            )
          ) {
            cnids.push({
              cardNetwork: CardNetwork.MASTERCARD,
              idType: CardNetworkIdType.CAID,
              externalId: records[0].card_network_external_id,
            });
            cnids.push({
              cardNetwork: CardNetwork.MASTERCARD,
              idType: CardNetworkIdType.AuthMID,
              externalId: records[0].card_network_external_id,
            });
            cnids.push({
              cardNetwork: CardNetwork.MASTERCARD,
              idType: CardNetworkIdType.ClearMID,
              externalId: records[0].card_network_external_id,
            });
          }
          return cnids;
        },
      ),
      isAggregator: new InputInjection([3], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0] === YesOrNo.yes;
      }),
    },
  },
  {
    name: 'Handle MID',
    autorun: false,
    process: {
      type: WorkflowStepTypes.EXTERNAL_STEP,
    },
    inputData: {
      description: new InputInjection(
        [4],
        ({
          isAggregator,
          status,
        }: {
          isAggregator: boolean;
          status: string;
        }) => {
          if (status !== 'success') {
            return;
          }
          return isAggregator
            ? 'Handle Confirmed Aggregator CNId'
            : 'Add Confirmed Non-Aggregator CNId to Allowed List';
        },
      ),
      link: new InputInjection(
        [4],
        ({
          isAggregator,
          cnids,
          status,
        }: {
          isAggregator: boolean;
          cnids: CardNetworkId[];
          status: string;
        }) => {
          if (status !== 'success') {
            return;
          }
          return isAggregator
            ? `${
                window.location.origin
              }/workflow/handleConfirmedAggregatorCNIds?cnidArray=${JSON.stringify(
                cnids,
              )}`
            : `${
                window.location.origin
              }/workflow/addConfirmedNonAggregatorCNIdstoAllowedList?cnidArray=${JSON.stringify(
                cnids,
              )}`;
        },
      ),
    },
  },
];

export const reviewAggregatorMIDs = {
  steps,
  inputValues,
  name: 'Review Aggregator MIDs',
  description: 'Review Potential Aggregator MID Transactions',
  tags: [Tags.transactions],
};
