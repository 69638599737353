import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'campaignUuid',
    formLabel: 'Campaign Uuid *',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'campaign-service',
  functionName: 'campaignGetByUuid',
  method: HttpMethod.GET,
});

export const campaignGetByUuid = {
  inputValues,
  submit,
  name: 'Get Campaign',
  description: 'Find campaign by its Uuid',
  outputValueName: 'campaign', // optional
  tags: [Tags.campaigns],
};
