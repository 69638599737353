import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'venueName',
    formLabel: 'Name on Transaction',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'brand-service',
  functionName: 'matchBrand',
  method: HttpMethod.POST,
});

export const matchBrand = {
  name: 'Match Brand Name',
  description: 'Searches for a brand name matching the input',
  inputValues,
  outputValueName: 'matches',
  tags: [Tags.transactions],
  submit,
};
