import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { getVenueByExternalId } from '../lambdaFunctions/getVenueByExternalId';
import {
  CardNetworkId,
  CardNetworkIdType,
  CardNetwork,
  Tags,
  Venue,
} from '@/helpers/types';
import { getVenueMappingsForGenericVenue } from '../lambdaFunctions/getVenueMappingsForGenericVenue';
import { unmapONVenueFromCNVenue } from '../lambdaFunctions/unmapONVenueFromCNVenue';
import {
  cardNetworkIdTypeOptions,
  cardNetworkOptions,
} from '@/helpers/commonInputSelectOptions';

const inputValues = [
  {
    name: 'rnMerchantNumber',
    formLabel: 'RN Merchant Number',
  },
  {
    name: 'cardNetwork',
    formLabel: 'Card Network',
    options: cardNetworkOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'idType',
    formLabel: 'Card Network Id Type',
    options: cardNetworkIdTypeOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'externalId',
    formLabel: 'Card Network External Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get ONVenue',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueByExternalId.submit,
    },
    inputData: {
      venueSource: 'RN',
      externalId: new InputInjection([0, 'rnMerchantNumber']),
    },
  },
  {
    name: 'Get CNVenues',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getVenueMappingsForGenericVenue.submit,
    },
    inputData: {
      venueUuid: new InputInjection([1], ({ result }: { result: Venue }) => {
        if (!result) {
          return;
        }
        return result.uuid;
      }),
    },
  },
  {
    name: 'Combine Selected CNVenue and CNID Information',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      cnvenues: new InputInjection([2], ({ result }: { result: Venue[] }) => {
        if (!result) {
          return;
        }
        return result;
      }),
      cnidProvided: new InputInjection(
        [0],
        ({
          cardNetwork,
          idType,
          externalId,
        }: {
          cardNetwork: CardNetwork;
          idType: string;
          externalId: CardNetworkIdType;
        }) => {
          if (!cardNetwork || !idType || !externalId) {
            return;
          }
          return {
            cardNetwork,
            idType,
            externalId,
          };
        },
      ),
    },
  },
  {
    name: 'Unmap CNVenue From ONVenue',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: unmapONVenueFromCNVenue.submit,
    },
    inputData: {
      offerNetworkVenueUuid: new InputInjection(
        [1],
        ({ result }: { result: Venue }) => {
          if (!result) {
            return;
          }
          return result.uuid;
        },
      ),
      cardNetworkVenueUuid: new InputInjection(
        [3],
        ({
          cnvenues,
          cnidProvided,
        }: {
          cnvenues: Venue[];
          cnidProvided: CardNetworkId;
        }) => {
          if (!cnvenues || !cnvenues.length || !cnidProvided) {
            return;
          }
          const cnvenue = cnvenues.find((venue) =>
            venue.cardNetworkIds.some(
              (cnid) =>
                cnid.cardNetwork === cnidProvided.cardNetwork &&
                cnid.externalId === cnidProvided.externalId &&
                cnid.idType === cnidProvided.idType,
            ),
          );
          if (!cnvenue) {
            return;
          }
          return cnvenue.uuid;
        },
      ),
      reason: 'MANUAL_MATCH',
    },
  },
];

export const unmapRNMerchantFromCNVenueByCNId = {
  steps,
  inputValues,
  name: 'Unmap RN Merchant From CNVenue By CNId',
  description: 'Unmap RN merchant from cnvenue by cnid',
  tags: [Tags.cnids, Tags.venues],
};
