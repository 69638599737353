import { format, subMonths } from 'date-fns';
import {
  generateMatcher,
  Specificity,
} from '@dosh/commons-node-matcher-generator';
import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { Option } from '@/components/WorkflowSteps/CheckboxSelector';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import {
  BrandTransactionMatcherResults,
  MatcherObject,
  MatcherTypes,
  Tags,
  Transaction,
} from '@/helpers/types';
import {
  regexSpecificityOptions,
  offerLocationTypeOptions,
} from '@/helpers/commonInputSelectOptions';
import { getMccDescription } from '@/helpers/merchantCategoryCodes';
import { GET_TRANSACTION_MATCHERS } from '../graphql/getTransactionMatchers.gql';
import { UPSERT_TRANSACTION_MATCHERS } from '../graphql/upsertTransactionMatchers.gql';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'name',
    formLabel: 'Merchant Name',
  },
  {
    name: 'locationType',
    formLabel: 'Location Type',
    options: offerLocationTypeOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'specificity',
    formLabel: 'Regex Matcher Specificity',
    options: regexSpecificityOptions,
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Query for mapped Brand ID',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ name, locationType }: { name: string; locationType: string }) =>
          `SELECT DISTINCT cnis.external_id
          FROM merchantdb.merchants m
          JOIN merchantdb.locations l on m.merchant_id = l.merchant_id
          JOIN merchantdb.source_locations sl on sl.location_id = l.location_id
          JOIN cnvenuedb.venues onv on sl.source_location_id = onv.uuid
          JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.on_venue_id = onv.id
          JOIN cnvenuedb.venues cnv on cnv.id = otcvm.cn_venue_id
          JOIN cnvenuedb.venue_to_card_network_id vtcni on cnv.id = vtcni.venue_id
          JOIN cnvenuedb.card_network_ids cnis on vtcni.card_network_id = cnis.id
          WHERE m.name ILIKE '%${name ? name.replace(/[',"]/gi, '%') : ''}%'
          AND l.type = '${locationType}'
          AND cnis.id_type = 'BRAND'`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Select Brand',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({ records }: { records: { external_id: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: record.external_id,
              name: record.external_id,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Get transaction matchers for brand',
    autorun: true,
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_TRANSACTION_MATCHERS,
    },
    inputData: {
      id: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
    },
  },
  {
    name: 'Process brand matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      currentMatchers: new InputInjection(
        [3],
        ({ brand }: { brand: BrandTransactionMatcherResults }) => {
          if (!brand) {
            return;
          }
          return brand.rawMatchers ? brand.rawMatchers.matchers : [];
        },
      ),
    },
  },
  {
    name: 'Retrieve possible names from transaction feed',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ name }: { name: string }) =>
          `SELECT DISTINCT
          LOWER(d.merchant_name) as name, c.code as mcc_code, count(distinct cte.transaction_id) as occurrences
          FROM empyrdb.card_transaction_events cte
          JOIN empyrdb.card_transaction_events_debug d ON d.card_transaction_event_id = cte.id
          LEFT JOIN empyrdb.card_transaction_events_card_network_ids cnid ON cnid.card_transaction_event_id = cte.id
          LEFT JOIN empyrdb.card_transaction_events_categorization c ON c.card_transaction_event_id = cte.id AND c.source_id = 1
          WHERE d.venue_name IS NULL
          AND d.merchant_name ILIKE '%${
            name ? name.replace(/[',"]/gi, '%') : ''
          }%'
          AND cte.transaction_timestamp > '${format(
            subMonths(new Date(), 1),
            'yyyy-MM-dd',
          )}'
          GROUP BY 1,2
          ORDER BY 3 DESC
          ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Combine brand transaction matchers and list of transactions',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      brandRegexMatchers: new InputInjection(
        [4],
        ({ currentMatchers }: { currentMatchers: MatcherObject[] }) => {
          if (!currentMatchers || !Array.isArray(currentMatchers)) {
            return;
          }
          return currentMatchers
            .filter((match) => {
              return match.type === MatcherTypes.REGEX;
            })
            .map((match) => {
              return match.pattern;
            });
        },
      ),
      brandMccMatchers: new InputInjection(
        [4],
        ({ currentMatchers }: { currentMatchers: MatcherObject[] }) => {
          if (!currentMatchers || !Array.isArray(currentMatchers)) {
            return;
          }
          return currentMatchers
            .filter((match) => {
              return match.type === MatcherTypes.MERCHANT_CATEGORY_CODE;
            })
            .map((match) => {
              return match.pattern;
            });
        },
      ),
      transactions: new InputInjection(
        [5],
        ({ records }: { records: Transaction[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records;
        },
      ),
    },
  },
  {
    name: 'Filter out transactions with regex matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      matchedTransactions: new InputInjection(
        [6],
        ({
          brandRegexMatchers,
          transactions,
        }: {
          brandRegexMatchers: string[];
          transactions: Transaction[];
        }) => {
          if (
            !brandRegexMatchers ||
            !Array.isArray(brandRegexMatchers) ||
            !transactions ||
            !Array.isArray(transactions)
          ) {
            return;
          }
          const regexMatchers = brandRegexMatchers.map((match) => {
            return new RegExp(match, 'i');
          });
          return transactions.filter((trx) => {
            return !regexMatchers.reduce((boo: boolean, rx) => {
              return boo || rx.test(trx.name);
            }, false);
          });
        },
      ),
      specificity: new InputInjection([0, 'specificity']),
    },
  },
  {
    name: 'Generate regex matcher from remaining transactions',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [7],
        ({
          matchedTransactions,
          specificity,
        }: {
          matchedTransactions: Transaction[];
          specificity: string;
        }) => {
          if (!matchedTransactions || !Array.isArray(matchedTransactions)) {
            return;
          }
          const set = new Set<string>();
          const options: Option[] = [];
          matchedTransactions.forEach((trx) => {
            const matcher = generateMatcher({
              name: trx.name,
              specificity:
                Specificity[specificity as keyof typeof Specificity] ||
                Specificity.SPECIFIC,
            });
            const { size } = set;
            set.add(matcher.pattern);
            if (size !== set.size) {
              options.push({
                id: `${JSON.stringify(matcher)}`,
                name: `${matcher.pattern}`,
              });
            }
          });
          return options;
        },
      ),
    },
  },
  {
    name: 'Filter out transactions with mcc matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      matchedTransactions: new InputInjection(
        [6],
        ({
          brandMccMatchers,
          transactions,
        }: {
          brandMccMatchers: string[];
          transactions: Transaction[];
        }) => {
          if (
            !brandMccMatchers ||
            !Array.isArray(brandMccMatchers) ||
            !transactions ||
            !Array.isArray(transactions)
          ) {
            return;
          }
          return transactions.filter((trx) => {
            return !brandMccMatchers.includes(trx.mcc_code);
          });
        },
      ),
      specificity: new InputInjection([0, 'specificity']),
    },
  },
  {
    name: 'Generating MCC Matchers from remaining transactions',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [9],
        ({ matchedTransactions }: { matchedTransactions: Transaction[] }) => {
          if (!matchedTransactions || !Array.isArray(matchedTransactions)) {
            return;
          }
          const set = new Set<string>();
          const options: Option[] = [];
          matchedTransactions
            .filter((trx) => trx.mcc_code !== null && trx.mcc_code !== 'true')
            .sort((a, b) => {
              if (a.mcc_code < b.mcc_code) {
                return -1;
              }
              if (a.mcc_code > b.mcc_code) {
                return 1;
              }
              return 0;
            })
            .forEach((trx) => {
              const { size } = set;
              set.add(trx.mcc_code);
              if (size !== set.size) {
                const matcher: MatcherObject = {
                  type: MatcherTypes.MERCHANT_CATEGORY_CODE,
                  pattern: trx.mcc_code,
                };
                const mccDisplayValue = getMccDescription(trx.mcc_code);
                options.push({
                  id: `${JSON.stringify(matcher)}`,
                  name: `Code: ${matcher.pattern} Category: ${mccDisplayValue}`,
                });
              }
            });
          return options;
        },
      ),
    },
  },
  {
    name: 'Aggregating current matchers and new matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      currentMatchers: new InputInjection([4, 'currentMatchers']),
      newRegexMatchers: new InputInjection(
        [8],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          const matchers: MatcherObject[] = [];
          const selections = getArrayFromCheckboxSelections(result);
          selections.forEach((selection) => {
            const newMatcher = JSON.parse(selection);
            if (
              newMatcher &&
              !matchers.some((match) => match.pattern === newMatcher.pattern)
            ) {
              matchers.push(newMatcher);
            }
          });
          return matchers;
        },
      ),
      newMccMatchers: new InputInjection(
        [10],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          const matchers: MatcherObject[] = [];
          const selections = getArrayFromCheckboxSelections(result);
          selections.forEach((selection) => {
            const newMatcher = JSON.parse(selection);
            if (
              newMatcher &&
              !matchers.some((match) => match.pattern === newMatcher.pattern)
            ) {
              matchers.push(newMatcher);
            }
          });
          return matchers;
        },
      ),
    },
  },
  {
    name: 'Append newly selected matchers to existing and upsert',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: UPSERT_TRANSACTION_MATCHERS,
    },
    inputData: {
      id: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
      matchers: new InputInjection(
        [11],
        ({
          currentMatchers,
          newRegexMatchers,
          newMccMatchers,
        }: {
          currentMatchers: MatcherObject[];
          newRegexMatchers: MatcherObject[];
          newMccMatchers: MatcherObject[];
        }) => {
          if (!currentMatchers || !newRegexMatchers || !newMccMatchers) {
            return;
          }
          return [...currentMatchers, ...newRegexMatchers, ...newMccMatchers];
        },
      ),
    },
  },
];

export const refreshTransactionMatchersByMerchantNameLocationType = {
  steps,
  inputValues,
  name: 'Refresh Transaction Matchers by Merchant Name and Location Type',
  description:
    'Update transaction matchers with new regex/mcc based on recent transactions',
  tags: [Tags.transactions],
};
