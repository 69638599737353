import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'budgetUuid',
    formLabel: 'Budget Uuid',
  },
  {
    name: 'insertionOrderRef',
    formLabel: 'Insertion Order',
  },
  {
    name: 'amount',
    formLabel: 'Budget Adjustment Amount',
  },
  {
    name: 'budgetAdjustmentName',
    formLabel: 'Budget Adjustment Name',
  },
  {
    name: 'approvalDate',
    formLabel: 'Budget Approval Date',
    formSubLabel: 'YYYY-MM-DD',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'campaign-service',
  functionName: 'budgetAdjust',
  method: HttpMethod.POST, // note: if you need this to be a GET, refer to the README
});

export const budgetAdjust = {
  name: 'Adjust Budget',
  description: 'Adjust Budget',
  inputValues,
  outputValueName: 'Adjust Budget',
  tags: [Tags.budget],
  submit,
};
