import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'placeDescription.dataSource',
    formLabel: 'Data Source',
    formSubLabel: 'Source of Place Description',
  },
  {
    name: 'placeDescription.externalId',
    formLabel: 'External Id',
    formSubLabel: 'External Id for Place description',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'places-svc',
  functionName: 'getPlace',
  method: HttpMethod.POST,
});

export const getPlaceByPlaceDescription = {
  name: '[places-svc] Get Place By Description',
  description: 'Retrieve place by information from place description',
  tags: [Tags.venues],
  submit,
  inputValues,
};
