import { WorkflowStepTypes } from '@/components/Workflow';
import { optInUserSMS } from '@/services/lambdaFunctions/optInUserSMS';
import { getUser } from '@/services/lambdaFunctions/getUser';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'userId',
    formLabel: 'User ID',
  },
  {
    name: 'publicUUID',
    formLabel: 'Public UUID',
  },
  {
    name: 'marketplaceUserId',
    formLabel: 'Marketplace User ID',
    col: 6,
  },
  {
    name: 'marketplace',
    formLabel: 'Marketplace ID',
    col: 6,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get user details',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getUser.submit,
    },
    inputData: {
      userId: new InputInjection([0, 'userId']),
      publicUUID: new InputInjection([0, 'publicUUID']),
      marketplaceUserId: new InputInjection([0, 'marketplaceUserId']),
      marketplace: new InputInjection([0, 'marketplace']),
    },
  },
  {
    name: 'Opt in SMS',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: optInUserSMS.submit,
    },
    inputData: {
      userId: new InputInjection([0, 'userId']),
      publicUUID: new InputInjection([0, 'publicUUID']),
      marketplaceUserId: new InputInjection([0, 'marketplaceUserId']),
      marketplace: new InputInjection([0, 'marketplace']),
    },
  },
  {
    name: 'Check updated user details',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: optInUserSMS.submit,
    },
    inputData: {
      userId: new InputInjection([0, 'userId']),
      publicUUID: new InputInjection([0, 'publicUUID']),
      marketplaceUserId: new InputInjection([0, 'marketplaceUserId']),
      marketplace: new InputInjection([0, 'marketplace']),
    },
  },
];

export const optInUserSms = {
  steps,
  inputValues,
  name: 'Opt in Users SMS',
  description:
    'If a user has opted out of SMS this tool allows us to opt the user back into SMS messages',
  tags: [Tags.csResources],
};
