import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'offerId',
    formLabel: 'Offer ID',
  },
  {
    name: 'couponSegmentId',
    formLabel: 'Coupon Segment ID',
  },
  {
    name: 'targetEntityId',
    formLabel: 'Target Entity ID',
  },
  {
    name: 'actionType',
    formLabel: 'Action Type',
    formSubLabel: 'ADD_USER_TO_GROUP, REMOVE_USER_FROM_GROUP',
    default: 'ADD_USER_TO_GROUP',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'campaign-service',
  functionName: 'graduationRuleCreate',
  method: HttpMethod.POST,
});

export const graduationRuleCreate = {
  name: 'Graduation Rule Create',
  description:
    'Create graduation rule for segment to add or remove users from MUG',
  inputValues,
  outputValueName: 'Results',
  submit,
};
