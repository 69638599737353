import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import {
  BrandTransactionMatcherResults,
  MatcherObject,
  Tags,
} from '@/helpers/types';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';
import { GET_TRANSACTION_MATCHERS } from '../graphql/getTransactionMatchers.gql';
import { UPSERT_TRANSACTION_MATCHERS } from '../graphql/upsertTransactionMatchers.gql';

const inputValues = [
  {
    name: 'brandId',
    formLabel: 'Brand Id',
  },
  {
    name: 'merchantName',
    formLabel: 'Mismatching Merchant Name from Transaction',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Transaction Matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.GRAPHQL_QUERY,
      submit: GET_TRANSACTION_MATCHERS,
    },
    inputData: {
      id: new InputInjection([0, 'brandId']),
    },
  },
  {
    name: 'Combine Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      matchers: new InputInjection(
        [1],
        ({ brand }: { brand: BrandTransactionMatcherResults }) => {
          if (!brand) {
            return;
          }
          return brand.rawMatchers ? brand.rawMatchers.matchers : [];
        },
      ),
      merchantName: new InputInjection([0, 'merchantName']),
    },
  },
  {
    name: 'Determine Matching Matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      problematicMatchers: new InputInjection(
        [2],
        ({
          matchers,
          merchantName,
        }: {
          matchers: MatcherObject[];
          merchantName: string;
        }) => {
          if (!Array.isArray(matchers) || !merchantName) {
            return;
          }
          return matchers
            .map((matcher) => {
              if (!matcher) {
                return;
              }
              const regex = new RegExp(matcher.pattern, 'i');
              return regex.test(merchantName) ? matcher.pattern : null;
            })
            .filter((matcher) => !!matcher);
        },
      ),
    },
  },
  {
    name: 'Select Problematic Matchers to Remove',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [3],
        ({ problematicMatchers }: { problematicMatchers: string[] }) => {
          if (!Array.isArray(problematicMatchers)) {
            return;
          }
          return problematicMatchers
            .map((matcher) => {
              if (!matcher) {
                return;
              }
              return {
                id: matcher,
                name: matcher,
              };
            })
            .filter((matcher) => !!matcher);
        },
      ),
    },
  },
  {
    name: 'Regex Results and Regex to Remove',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      currentMatchers: new InputInjection(
        [1],
        ({ brand }: { brand: BrandTransactionMatcherResults }) => {
          if (!brand || !brand.rawMatchers || !brand.rawMatchers.matchers) {
            return;
          }
          return brand.rawMatchers.matchers;
        },
      ),
      matchersToRemove: new InputInjection(
        [4],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          const matchersToRemove: string[] = [];
          const selections = getArrayFromCheckboxSelections(result);
          selections.forEach((selection) => {
            matchersToRemove.push(selection);
          });
          return matchersToRemove;
        },
      ),
    },
  },
  {
    name: 'Upsert New Transaction Matchers',
    autorun: true,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: UPSERT_TRANSACTION_MATCHERS,
    },
    inputData: {
      id: new InputInjection([0, 'brandId']),
      matchers: new InputInjection(
        [5],
        ({
          currentMatchers,
          matchersToRemove,
        }: {
          currentMatchers: MatcherObject[];
          matchersToRemove: string[];
        }) => {
          if (!currentMatchers || !matchersToRemove) {
            return;
          }
          return currentMatchers.filter(
            (matcher) =>
              !matchersToRemove.some((removal) => removal === matcher.pattern),
          );
        },
      ),
    },
  },
];

export const handleTransactionMatcherMismatch = {
  steps,
  inputValues,
  name: 'Handle Transaction Matcher Mismatch',
  description: 'Handle invalid transactions paying out on transaction matchers',
  tags: [Tags.csResources, Tags.transactions, Tags.cnidMismatches],
};
