import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { getPlaceByPlaceDescription } from '../lambdaFunctions/getPlaceByPlaceDescription';
import {
  Place,
  PlaceDescriptionDataSource,
  PlaceSubscriber,
  Tags,
} from '@/helpers/types';
import { updateVenueProperties } from '../lambdaFunctions/updateVenueProperties';
import { storeLocationStatusOptions } from '@/helpers/commonInputSelectOptions';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'merchantNameOnStoreList',
    formLabel: 'Merchant Name for Location in Store List',
    formSubLabel:
      'This will only work if this store list was previously loaded in our system',
  },
  {
    name: 'storeId',
    formLabel: 'Store Id for Location in Store List',
    formSubLabel:
      'This will only work if this store list was previously loaded in our system',
  },
  {
    name: 'newStatus',
    formLabel: 'New Status',
    options: storeLocationStatusOptions,
    type: InputFieldTypes.SELECT,
  },
  {
    name: 'updatedBy',
    formLabel: 'Updated By',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Place Description',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getPlaceByPlaceDescription.submit,
    },
    inputData: {
      placeDescription: {
        dataSource: PlaceDescriptionDataSource.DOSH_OFFER_NETWORK,
        externalId: new InputInjection(
          [0],
          ({
            merchantNameOnStoreList,
            storeId,
          }: {
            merchantNameOnStoreList: string;
            storeId: string;
          }) => {
            if (!merchantNameOnStoreList || !storeId) {
              return;
            }
            return `${merchantNameOnStoreList}:::${storeId}`;
          },
        ),
      },
    },
  },
  {
    name: 'Get Merchant Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) => `SELECT 
            getuuid(m.merchant_id) AS merchant_uuid
            FROM merchantdb.merchants m
            WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
            ;`,
      ),
    },
  },
  {
    name: 'Combine Retrieved Data',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      venueUuid: new InputInjection([1], ({ place }: { place: Place }) => {
        if (!place) {
          return;
        }
        const subscriptionForONVenue = place.subscriptions.find(
          (sub) =>
            sub.subscriber === PlaceSubscriber.DOSH_OFFER_NETWORK_SERVICE,
        );
        return !subscriptionForONVenue
          ? null
          : subscriptionForONVenue.externalId;
      }),
      merchantUuid: new InputInjection(
        [2],
        ({ records }: { records: { merchant_uuid: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0].merchant_uuid;
        },
      ),
    },
  },
  {
    name: 'Update Location Status',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: updateVenueProperties.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [3],
          ({
            venueUuid,
            merchantUuid,
            status,
          }: {
            venueUuid: string;
            merchantUuid: string;
            status: string;
          }) => status === 'success' && (!venueUuid || !merchantUuid),
        ),
      },
      forMerchant: {
        uuid: new InputInjection(
          [3],
          ({ merchantUuid }: { merchantUuid: string }) => {
            if (!merchantUuid) {
              return;
            }
            return merchantUuid;
          },
        ),
      },
      forVenues: [
        {
          venueUuid: new InputInjection(
            [3],
            ({ venueUuid }: { venueUuid: string }) => {
              if (!venueUuid) {
                return;
              }
              return venueUuid;
            },
          ),
        },
      ],
      set: {
        status: new InputInjection([0, 'newStatus']),
      },
      updatedBy: new InputInjection([0, 'updatedBy']),
    },
  },
];

export const updateLocationStatusFromStoreId = {
  steps,
  inputValues,
  name: 'Update Location Status from Store Id',
  description:
    'Update location status using data from previously loaded store list',
  tags: [Tags.venues],
};
