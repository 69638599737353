import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'venueName',
    formLabel: 'Name for Venue',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'brand-service',
  functionName: 'matchPlaceToBrand',
  method: HttpMethod.POST,
});

export const matchPlaceToBrand = {
  name: 'Match Place to Brand Name',
  description:
    'Searches for a Place matching the brand name matching the input',
  inputValues,
  outputValueName: 'matches',
  tags: [Tags.transactions],
  submit,
};
