import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Transactions for Merchant',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `
          SELECT
          cted.venue_name,
          ctecni.card_network_external_id_type,
          COUNT(DISTINCT cte.transaction_id)
      FROM empyrdb.card_transaction_events cte
      JOIN empyrdb.card_transaction_events_debug cted ON cte.id = cted.card_transaction_event_id
      JOIN empyrdb.card_transaction_events_card_network_ids ctecni ON cte.id = ctecni.card_transaction_event_id
      WHERE 1=1
          AND cte.transaction_timestamp >= current_date
            AND cted.venue_name ilike '%${redshiftEscape(merchantName)}%'   
            AND cte.marketplace ilike 'dosh'
            AND ctecni.card_network_external_id_type in ('VSID', 'SE', 'AuthMID', 'ClearMID')
            AND cte.type = 'AUTHORIZED'
        GROUP BY 2,1
        ORDER BY 2`,
      ),
      retries: 10,
    },
  },
];

export const postDeactivationQA = {
  steps,
  inputValues,
  name: 'Post Deactivation QA',
  description:
    'Verify transactions have stopped in Dosh 7 days post deactivation',
  tags: [Tags.transactions, Tags.qa],
};
