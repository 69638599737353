import log from '@/utils/logging';

const get = (): Promise<any> => {
  log.info('Starting get request to button approved merchants');

  return fetch('https://api.usebutton.com/v1/merchants?status=approved', {
    headers: {
      Authorization: 'Basic c2stQmhSdWp3SzVPaTBMUDVHaFhkbklrOg==',
    },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        log.info('successful get request', result);
        return result;
      },
      // Catching failed requests
      (error) => {
        log.error('Button get call failed', error);
        throw new Error(
          'Button API error.  Check the console for more information',
        );
      },
    );
};

const getApprovedMerchants = {
  name: 'Get Button Merchants',
  description:
    'Get a list of all approved button merchants.  Used for onboarding new button merchants',
  outputValueName: 'merchants',
  submit: get,
};

export default getApprovedMerchants;
