import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'euid',
    formLabel: 'EUID',
  },
  {
    name: 'cardLast4',
    formLabel: 'Last 4 of Card',
  },
  {
    name: 'transactionAmount',
    formLabel: 'Transaction Amount',
  },
  {
    name: 'transactionDate',
    formLabel: 'Transaction Date',
    formSubLabel: 'YYYY-MM-DD',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-mgmt-svc',
  functionName: 'getTransactionForVisaUser',
  method: HttpMethod.GET,
});

export const getTransactionForVisaUser = {
  name: 'Get Transaction for Visa User',
  description: 'Check Visa API for User Transaction',
  inputValues,
  tags: [Tags.transactions],
  submit,
};
