import { CardNetwork } from './types';

const cardNetworksArray = () => {
  const cardNetworks = [];
  for (let i = 0; i < Object.values(CardNetwork).length; i++) {
    cardNetworks.push(Object.values(CardNetwork)[i].toString());
  }
  return cardNetworks;
};

export const CardNetworksArray = cardNetworksArray();
