import React, { FunctionComponent } from 'react';
import DisplayJson from '../DisplayJson';

interface WorkflowStepProps {
  name: string;
  inputData: any;
}

export const DisplayResult: FunctionComponent<WorkflowStepProps> = ({
  name,
  inputData,
}) => (
  <>
    <div className="workflowStep-text">
      <p className="workflowStep-text-label">Displaying Results for Workflow</p>
    </div>
    {inputData && (
      <div className="workflowStep-text">
        <DisplayJson name={name} json={inputData} />
      </div>
    )}
  </>
);
