import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'cardNetworkId.cardNetwork',
    formLabel: 'Card Network',
  },
  {
    name: 'cardNetworkId.idType',
    formLabel: 'Card Network Id Type',
  },
  {
    name: 'cardNetworkId.externalId',
    formLabel: 'External Id',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-venue-service',
  functionName: 'getCNIdStatus',
  method: HttpMethod.POST,
});

export const getCNIdStatus = {
  name: 'Get CNId status',
  description: 'Get CNId status',
  inputValues,
  outputValueName: 'status',
  tags: [Tags.cnids],
  submit,
};
