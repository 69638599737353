import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'activationDate',
    formLabel: 'Date of Launch',
    formSubLabel: 'YYYY-MM-DD',
  },
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get CLOFs',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          offerUuid,
          activationDate,
        }: {
          offerUuid: string;
          activationDate: string;
        }) =>
          `
            SELECT SUM(clof.reward_amount), clof.marketplace, clof.offer_segment_id
            FROM cardlinkedoffersdb.card_linked_offer_fulfillments clof
            WHERE clof.offer_id = '${offerUuid}'
                AND clof.created_at >= '${activationDate}'
            GROUP BY 2,3
            ORDER BY 2,3
            ;`,
      ),
      retries: 20,
    },
  },
];

export const postLaunchQA = {
  steps,
  inputValues,
  name: 'Post Launch QA',
  description: 'Verify transactions and CLOFs post launch',
  tags: [Tags.newMerchantOnboarding, Tags.qa],
};
