import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { updateThrottleProperties } from '../lambdaFunctions/updateThrottleProperties';

const inputValues = [
  {
    name: 'offerUuidWithThrottle',
    formLabel: 'Offer Uuid with Throttle on Base Segment',
  },
  {
    name: 'newThrottleName',
    formLabel: 'New Throttle Name',
  },
  {
    name: 'newThrottleDescription',
    formLabel: 'New Throttle Description',
  },
  {
    name: 'updatedBy',
    formLabel: 'Name of person making updates',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Throttle Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          offerUuidWithThrottle,
        }: {
          offerUuidWithThrottle: string;
        }) => `SELECT DISTINCT cstrd.target_data as throttle_uuid
        FROM merchantdb.offers o
        JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
        JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
        JOIN merchantdb.coupon_segment cs on o.coupon_id = cs.coupon_id
        JOIN merchantdb.coupon_segment_type cst on cs.coupon_segment_type_id = cst.coupon_segment_type_id
        JOIN merchantdb.coupon_segment_targeting_rule cstr on cs.coupon_segment_id = cstr.coupon_segment_id
        JOIN merchantdb.coupon_segment_targeting_rule_type cstrt on cstr.coupon_segment_targeting_rule_type_id = cstrt.coupon_segment_targeting_rule_type_id AND cstrt.type = 'OFFER_THROTTLE_V1'
        JOIN merchantdb.coupon_segment_targeting_rule_data cstrd on cstr.coupon_segment_targeting_rule_id = cstrd.coupon_segment_targeting_rule_id
        JOIN merchantdb.coupon_segment_targeting_rule_data_key cstrdk on cstrdk.coupon_segment_targeting_rule_data_key_id = cstrd.coupon_segment_targeting_rule_data_key_id
        WHERE getuuid(o.offer_id) = '${offerUuidWithThrottle}'
        AND cst.type = 'BASE'
        AND cstrdk.target_data_key in ('OFFER_THROTTLE_UUID')
        ;`,
      ),
    },
  },
  {
    name: 'Update Throttle',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: updateThrottleProperties.submit,
    },
    inputData: {
      uuid: new InputInjection(
        [1],
        ({ records }: { records: { throttle_uuid: string }[] }) => {
          if (!records) {
            return;
          }
          return records[0].throttle_uuid;
        },
      ),
      name: new InputInjection([0, 'newThrottleName']),
      description: new InputInjection([0, 'newThrottleDescription']),
      ticketUrl: 'OpsPortalUpdate',
      updatedBy: new InputInjection([0, 'updatedBy']),
    },
  },
];

export const updateThrottleNameAndDescription = {
  steps,
  inputValues,
  name: 'Update Throttle Name and Description',
  description: 'Update name and description to use on an existing throttle',
  tags: [Tags.offers, Tags.throttle],
};
