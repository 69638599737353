import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'cognitoId',
    formLabel: 'Cognito ID',
  },
  {
    name: 'lastFour',
    formLabel: 'Last 4',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Query for BCN',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ cognitoId, lastFour }: { cognitoId: string; lastFour: string }) =>
          `SELECT DISTINCT JSON_EXTRACT_PATH_TEXT(e.request, 'bcn') as bcn_number
          FROM carddb.cards c
          JOIN carddb.events e on c.card_id = e.card_id
          JOIN carddb.users u ON c.user_id = u.user_id
          LEFT JOIN carddb.card_tokens ct on c.card_id = ct.card_id
          WHERE 1 = 1
          AND u.cognito_id in ('${redshiftEscape(cognitoId)}')
          AND c.last4 in ('${redshiftEscape(lastFour)}')
          AND e.event_type = 'MASTERCARD_ENROLL'
          AND e.response not in ('{"code":"-45","description":"Bank Account Number Already Enrolled.","type":"Error","recoverable":"true"}')
          AND e.event_status = 'FAILED'
          ORDER BY c.created_at
          LIMIT 1;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      BCN: new InputInjection(
        [1],
        ({ records }: { records: { bcn_number: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0].bcn_number;
        },
      ),
    },
  },
];

export const getBCN = {
  steps,
  inputValues,
  name: 'Get BCN',
  description: 'Get BCN for partially linked Mastercard',
  tags: [Tags.csResources],
};
