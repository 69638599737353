import get from '@/utils/lambdaApi/get';

const REDSHIFT_RETRIEVE_RESULTS_ROUTE =
  'https://devtools.dosh.cash/api/us-east-1/803350353089/redshift-access-svc/prod/prod/retrieveQueryResults';

interface RedshiftColumnInterface {
  label: string;
}

interface RedshiftExecutedQueryInterface {
  ColumnMetadata: RedshiftColumnInterface[];
  Records: any[][];
  TotalNumRows: number;
}

interface RedshiftResponseInterface {
  success: boolean;
  executedQuery: RedshiftExecutedQueryInterface;
}

// reconstruct response
export const reconstructRecords = (
  queryResponse: RedshiftExecutedQueryInterface,
) => {
  const columns = queryResponse.ColumnMetadata.map((column) => column.label);
  return queryResponse.Records.map((record) => {
    const constructedRecord: any = {};
    record.forEach((value, index) => {
      constructedRecord[columns[index]] = value[Object.keys(value)[0]];
    });
    return constructedRecord;
  });
};

const retrieveQueryResults = (
  queryId: string,
): Promise<RedshiftExecutedQueryInterface> => {
  return get({
    route: `${REDSHIFT_RETRIEVE_RESULTS_ROUTE}?id=${queryId}`,
  }).then((res: RedshiftResponseInterface) => {
    // only return when successful
    if ('success' in res && res.success && res.executedQuery) {
      return res.executedQuery;
    }
    throw res;
  });
};

export default retrieveQueryResults;
