import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'cnTransactionId',
    formLabel: 'Cn Transaction Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Retrieve Transactions',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ cnTransactionId }: { cnTransactionId: string }) => `SELECT
        cte.cn_transaction_id,
        cte.cognito_id,
        cted.merchant_name,
        cted.venue_name,
        cte.transaction_timestamp,
        cte.type,
        cte.card_network,
        cte.card_network_notification_id_source,
        ctecni.card_network_external_id_type,
        ctecni.card_network_external_id,
        cte.marketplace,
        cte.amount,
        c.code
    FROM empyrdb.card_transaction_events cte
    JOIN empyrdb.card_transaction_events_debug cted on cte.id = cted.card_transaction_event_id
    JOIN empyrdb.card_transaction_events_card_network_ids ctecni on cte.id = ctecni.card_transaction_event_id
    LEFT JOIN empyrdb.card_transaction_events_categorization c on c.card_transaction_event_id = cte.id and c.source_id = 1
    WHERE 1=1
    AND cte.cn_transaction_id = '${cnTransactionId}';`,
      ),
      retries: 10,
    },
  },
];

export const getTransactionByCnTransactionId = {
  steps,
  inputValues,
  name: 'Get Transaction by Cn Transaction Id',
  description: 'Look up transaction by cn_transaction_id',
  tags: [Tags.transactions],
};
